import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Loader from "../../../../../components/Loader";
import {
  updateIntegration,
  validateGithubIntegration,
} from "../../../../../slices/integrationSlice";

import styles from "./Integration.module.sass";

const Integration = ({ account_id, integration_name, repository_url }) => {
  const { status, loading, error, testResponseGithub } = useSelector(
    (state) => state.integration
  );
  const [informMessage, setInformMessage] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [values, setValues] = useState({
    account_id: account_id,
    integration_name: integration_name,
    repository_url: repository_url,
  });
  const [canProceed, setCanProceed] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleTestConnection = () => {
    dispatch(
      validateGithubIntegration({
        name: "",
        org_name: values["org_name"],
        connector: {
          type: "GITHUB",
          token: values["token"],
        },
      })
    );
  };

  useEffect(() => {
    setCanProceed(
      !Object.keys(values).some((val) => values[val] === "") &&
        Object.keys(values).length >= 3
    );
  }, [values]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setInformMessage(null);
    setFormSubmitted(true);
    dispatch(updateIntegration(values));
  };

  const preventSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (formSubmitted) {
      if (error) {
        setInformMessage("Integration failed to update.");
      } else if (status === "succeeded") {
        setInformMessage("Integration updated.");
      }
    }
  }, [status, error, formSubmitted]);

  useEffect(() => {
    setInformMessage(error);
    let timer = setTimeout(() => setInformMessage(null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  useEffect(() => {
    setInformMessage(testResponseGithub);
    let timer = setTimeout(() => setInformMessage(null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [testResponseGithub]);

  return (
    <div className={styles.integration}>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.content}>
              <form onSubmit={preventSubmit}>
                <div className={styles.infoNote}>
                  Enter the name under your organization is registered in GitHub.
                </div>
                <input
                  name="org_name"
                  placeholder="Organization Name"
                  className={styles.input}
                  type="text"
                  onChange={handleChange}
                  value={repository_url}
                />
                <div className={styles.infoNote}>
                  Add a{" "}
                  <Link
                    className={styles.emptyItem}
                    to="https://github.com/settings/tokens"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Personal Access Token
                  </Link>{" "}
                  below with at least read:org access:
                </div>
                <input
                  name="token"
                  placeholder="GitHub OAuth token"
                  className={styles.input}
                  type="text"
                  onChange={handleChange}
                />
                {informMessage && (
                  <div className={styles.errorNote}>{informMessage}</div>
                )}
                <button
                  className={cn(
                    `button button-stroke-blue button-small`,
                    styles.buttonTest
                  )}
                  onClick={() => handleTestConnection()}
                >
                  Test Connection
                </button>
                <button
                  type="submit"
                  className={cn(`button button-small`, styles.button, {
                    disabled: !canProceed,
                  })}
                  onClick={handleSubmit}
                >
                  {loading && (
                    <Loader
                      className={styles.loader}
                      white
                    />
                  )}
                  <span>Update</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
