import React, { useEffect } from "react";

import cn from "classnames";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import {
  getInsights as getTagInsights,
  getActivities as getTagActivities,
} from "slices/tagSlice";

import TooltipGlodal from "../../components/TooltipGlodal";
import {
  getHomeOverview,
  getInsights,
  getEmployees,
  getActivities,
} from "../../slices/homeSlice";

import Departments from "./Departments";
import styles from "./Home.module.sass";
import Overview from "./Overview";

const Home = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  let title = ["Home"];
  if (params) {
    Object.keys(params).forEach((param) => title.push(params[param]));
  }

  useEffect(() => {
    if (!params.department && !params.tag) {
      dispatch(getHomeOverview());
    }

    if (params.tag) {
      // dispatch(getTagInsights({ name: params.tag, period: 7 }));
    } else {
      if (params.department) {
        // dispatch(getInsights({ name: params.department, period: 7 }));
      }
    }
  }, [dispatch, params.department, params.tag]);

  return (
    <>
      <div className={styles.section}>
        {title.length === 1 && <Overview className={styles.card} />}
        <div className={styles.row}></div>
        <Departments
          departmentView={params.department}
          tagView={params.tag}
        />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Home;
