import { Link } from "react-router-dom";

import styles from "./Tag.module.sass";

const variants = [3, 6, 9, 12, 15, 18, 21];

const Tag = ({ name, variant = 3, redirect, random = false }) => {
  if (random) {
    variant = variants[Math.floor(Math.random() * variants.length)];
  }

  return (
    <Link
      to={redirect || "#"}
      className={styles[`tag${variant}`]}
    >
      {name}
    </Link>
  );
};

export default Tag;
