import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";

import { setCollapsed } from "slices/appSlice";

import Icon from "../Icon";
import Image from "../Image";

import Collapser from "./Collapser";
import Dropdown from "./Dropdown";
import Help from "./Help";
import styles from "./Sidebar.module.sass";

const navigationLinks = [
  {
    title: "Home",
    icon: "home",
    url: "/",
    visible: true,
  },
  {
    title: "Employees",
    icon: "profile-circle",
    url: "/employees",
  },
  {
    title: "Teams & Tags",
    icon: "ticket",
    url: "/teams-tags",
  },
  {
    title: "Integrations",
    icon: "store",
    url: "/integrations",
  },
  {
    title: "Settings",
    icon: "setting",
    url: "/settings",
    className: "onboarding-fifth-step",
  },
  // {
  //   title: "Ask Metra A.I.",
  //   icon: "message",
  //   url: "/chat",
  // },
];

const Sidebar = ({ className, onClose }) => {
  const dispatch = useDispatch();
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);
  const [navigation, setNavigation] = useState(navigationLinks);
  const [expandedSidebar, setExpandedSidebar] = useState(false);

  const { collapsed } = useSelector((state) => state.app);

  const location = useLocation();

  const getBasePath = (pathname) => {
    const segments = pathname.split("/");
    return segments.length > 0 ? `/${segments[1]}` : "/";
  };

  const basePath = getBasePath(location.pathname);

  useEffect(() => {
    setNavigation(
      navigationLinks.map((link) => ({
        ...link,
        visible:
          location.pathname === link.url ||
          (link.url.startsWith(basePath) && location.pathname !== "/"),
      }))
    );
  }, [location.pathname, basePath]);

  const expandChatToggler = () => setExpandedSidebar((prev) => !prev);

  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
          [styles.collapsed]: collapsed,
        })}
      >
        <button
          className={styles.close}
          onClick={onClose}
        >
          <Icon
            name="close"
            size="24"
          />
        </button>
        <Link
          className={cn(styles.logo, { [styles.collapsed]: collapsed })}
          to="/"
          onClick={onClose}
        >
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
          <Image
            className={styles.smallpic}
            src="/images/small-logo-light.png"
            srcDark="/images/small-logo-light.png"
            alt="Core"
          />
        </Link>
        <Collapser
          collapsed={collapsed}
          collapseHandler={() => dispatch(setCollapsed(!collapsed))}
        />
        <div className={styles.menu}>
          {navigation?.map((x, index) =>
            x.url ? (
              <NavLink
                className={cn(styles.item, x.className, {
                  [styles.active]: x.visible,
                  [styles.collapsed]: collapsed,
                })}
                activeClassName={styles.active}
                to={x.url}
                key={index}
                exact
                onClick={() => {
                  setNavigation(
                    navigationLinks.map((link, i) => ({
                      ...link,
                      visible: i === index,
                    }))
                  );
                  onClose();
                }}
              >
                <Icon
                  name={x.icon}
                  size="24"
                />
                {x.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button
          className={styles.toggle}
          onClick={() => setVisible(!visible)}
        >
          <Icon
            name="arrow-right"
            size="24"
          />
          <Icon
            name="close"
            size="24"
          />
        </button>

        {/*{window.location.pathname !== "/chat" && (*/}
        {/*  <SidebarChatMinimized onClick={expandChatToggler} />*/}
        {/*)}*/}

        {/* <div className={styles.foot}>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div> */}
        {/*<SidebarChat*/}
        {/*  expandChatToggler={expandChatToggler}*/}
        {/*  isMinimized={!expandedSidebar}*/}
        {/*  collapsed={collapsed}*/}
        {/*/>*/}
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
