import React, { useState, useCallback } from "react";

import cn from "classnames";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Stepper } from "components";
import { getTeamsOverview } from "slices/homeV2Slice";
import {
  onCreateSectionDataChange,
  createNewSection,
  assignEmployeesToSection,
  addIntegrationsToSection,
  clearCreateSectionData,
  getAllSectionTags,
} from "slices/sectionSlice";
import { periodConverter } from "utils";

import AddEmployee from "./AddEmployee";
import BasicInfo from "./BasicInfo";
import styles from "./CreateSection.module.sass";
import Integrations from "./Integrations";
import Success from "./Success";

const CreateSection = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newSection, setNewSection] = useState(false);

  const { createSectionData, error } = useSelector((state) => state.section);
  const { selectedFilterInterval } = useSelector((state) => state.app);
  const { integrationStartDate, integrationEndDate } = useSelector(
    (state) => state.employee
  );

  const steps = [
    {
      name: "Basic Info",
      component: (
        <BasicInfo
          onChange={(e) =>
            dispatch(
              onCreateSectionDataChange({ [e.target.name]: e.target.value })
            )
          }
          values={createSectionData}
        />
      ),
    },
    {
      name: "Integrations",
      component: (
        <Integrations
          onChange={(integrationIds) =>
            dispatch(
              onCreateSectionDataChange({ integrationIds: integrationIds })
            )
          }
          values={createSectionData}
        />
      ),
    },
    {
      name: "Add Employees",
      component: (
        <AddEmployee
          onChange={(employeeIds) =>
            dispatch(onCreateSectionDataChange({ employeeIds: employeeIds }))
          }
          values={createSectionData}
        />
      ),
    },
  ];

  const handleAssignEmployees = useCallback(
    async (createdSection) => {
      if (createSectionData?.employeeIds?.length > 0) {
        await dispatch(
          assignEmployeesToSection({
            sectionTagId: createdSection.id,
            employeeIds: createSectionData.employeeIds,
          })
        );
      }
    },
    [createSectionData.employeeIds, dispatch]
  );

  const handleAddIntegrations = useCallback(
    async (createdSection) => {
      if (
        createSectionData?.integrationIds &&
        createSectionData?.integrationIds[0]
      ) {
        await dispatch(
          addIntegrationsToSection({
            sectionTagId: createdSection.id,
            integrations: createSectionData.integrationIds,
          })
        );
      }
    },
    [createSectionData.integrationIds, dispatch]
  );

  const handleOnFinish = async () => {
    setLoading(true);

    const action = await dispatch(createNewSection(createSectionData));
    const {
      data: { section_tag: createdSection },
    } = action.payload;

    setNewSection(createdSection);

    await handleAddIntegrations(createdSection);
    await handleAssignEmployees(createdSection);
    await dispatch(getAllSectionTags());

    dispatch(clearCreateSectionData());
    setLoading(false);
    setSuccess(true);
  };

  const fetchTeamsOverview = useCallback(() => {
    if (integrationStartDate && integrationEndDate) {
      dispatch(
        getTeamsOverview({
          startDate: format(integrationStartDate, "yyyy-MM-dd"),
          endDate: format(integrationEndDate, "yyyy-MM-dd"),
        })
      );
    } else {
      dispatch(
        getTeamsOverview({
          period: periodConverter(selectedFilterInterval),
        })
      );
    }
  }, [
    dispatch,
    integrationEndDate,
    integrationStartDate,
    selectedFilterInterval,
  ]);

  return (
    <Modal
      visible={visible}
      onClose={() => {
        if (success) {
          dispatch(getAllSectionTags());
          fetchTeamsOverview();
          setSuccess(false);
        }

        onClose();
        dispatch(clearCreateSectionData());
        setStep(1);
      }}
      outerClassName={styles.modal}
    >
      <div className={cn("title-purple", styles.title, { hide: success })}>
        Create new Team
      </div>

      {!success ? (
        loading ? (
          <div className={styles.loading}>
            <div className={styles.info}>
              Please wait while we set the section tag for you...
            </div>
          </div>
        ) : (
          <Stepper
            steps={steps}
            step={step}
            footer
            onNext={() => {
              if (step < steps.length) setStep((step) => step + 1);
            }}
            onBack={() => {
              if (step > 1) setStep((step) => step - 1);
            }}
            onFinish={handleOnFinish}
            lastStepContent="Finish and create the Team"
            disableNext={
              !createSectionData["name"] ||
              (step === 2 &&
                (!createSectionData["integrationIds"] ||
                  !createSectionData["integrationIds"].length))
            }
          />
        )
      ) : (
        <Success
          loading={loading}
          error={error}
          section={newSection}
        />
      )}
    </Modal>
  );
};

export default CreateSection;
