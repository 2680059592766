import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../app/api";
import validateGithub from "../app/githubConnectionTester";
import { integrationLastEntryParser } from "../utils";

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  myIntegrations: null,
  myIntegrationsBasics: null,
  allIntegrations: null,
  integrationOverview: null,
  integrationEmployees: null,
  integrationUnassignedEmployees: null,
  integrationLogs: null,
  integration: null,

  integrating: null,

  testResponseJira: null,
  testResponseConfluence: null,
  testResponseGithub: null,
  testResponseZoom: null,
  testResponseTeams: null,
  testResponseOutlook: null,
  testResponseSalesforce: null,

  myIntegrationsLoading: false,
};

export const integration = createSlice({
  name: "integration",
  initialState,
  reducers: {
    placeholder_action_one: (state, { payload }) => {
      state.loading = payload;
    },
    placeholder_action_two: (state, action) => {
      state.loading = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(placeholder_action_three.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = false;
      })
      .addCase(placeholder_action_three.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.error = false;
      })
      .addCase(placeholder_action_three.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllIntegrations.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getAllIntegrations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.allIntegrations = action.payload?.data?.integrations
          ? action.payload?.data?.integrations
          : null;
      })
      .addCase(getAllIntegrations.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMyIntegrations.pending, (state) => {
        state.status = "loading";
        state.myIntegrationsLoading = true;
      })
      .addCase(getMyIntegrations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.myIntegrationsLoading = false;
        state.myIntegrations = action.payload?.data?.integrations
          ? action.payload?.data?.integrations
          : null;
      })
      .addCase(getMyIntegrations.rejected, (state, action) => {
        state.status = "failed";
        state.myIntegrationsLoading = false;
        state.error = action.error.message;
      })

      .addCase(getMyIntegrationsEmployees.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMyIntegrationsEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        let myIntegrations = state.myIntegrations?.map((myIntegration) =>
          action.payload?.data?.company_integration_id &&
          action.payload?.data?.company_integration_id ===
            myIntegration.company_integration_id
            ? { ...myIntegration, employees: action.payload?.data?.employees }
            : myIntegration
        );
        state.myIntegrations = myIntegrations;
      })
      .addCase(getMyIntegrationsEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getMyIntegrationsDifferenceByDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMyIntegrationsDifferenceByDate.fulfilled, (state, action) => {
        state.status = "succeeded";
        let myIntegrations = state.myIntegrations?.map((myIntegration) =>
          action.payload?.data?.company_integration_id &&
          action.payload?.data?.company_integration_id ===
            myIntegration.company_integration_id
            ? {
                ...myIntegration,
                difference_by_date: action.payload?.data?.difference_by_date,
              }
            : myIntegration
        );
        state.myIntegrations = myIntegrations;
      })
      .addCase(getMyIntegrationsDifferenceByDate.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getMyIntegrationsInteractionCountByDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getMyIntegrationsInteractionCountByDate.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          let myIntegrations = state.myIntegrations?.map((myIntegration) =>
            action.payload?.data?.company_integration_id &&
            action.payload?.data?.company_integration_id ===
              myIntegration.company_integration_id
              ? { ...myIntegration, data: action.payload?.data?.data }
              : myIntegration
          );
          state.myIntegrations = myIntegrations;
        }
      )
      .addCase(
        getMyIntegrationsInteractionCountByDate.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        }
      )
      .addCase(getBasicsOfMyIntegrations.pending, (state) => {
        state.status = "loading";
        state.myIntegrationsLoading = true;
      })
      .addCase(getBasicsOfMyIntegrations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.myIntegrationsLoading = false;
        state.myIntegrationsBasics = action.payload?.data?.integrations
          ? action.payload?.data?.integrations
          : null;
      })
      .addCase(getBasicsOfMyIntegrations.rejected, (state, action) => {
        state.status = "failed";
        state.myIntegrationsLoading = false;
        state.error = action.error.message;
      })
      .addCase(getIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integration = action.payload?.data?.integration || null;
      })
      .addCase(getIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(updateIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integration = action.payload?.data?.integration || null;
      })
      .addCase(updateIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(deleteIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integration = action.payload?.data?.integration || null;
      })
      .addCase(deleteIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getIntegrationOverview.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getIntegrationOverview.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrationOverview = action.payload?.data?.overview
          ? action.payload?.data?.overview
          : null;
      })
      .addCase(getIntegrationOverview.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getIntegrationEmployees.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getIntegrationEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrationEmployees = action.payload?.data?.employees
          ? action.payload?.data?.employees
          : null;
      })
      .addCase(getIntegrationEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getIntegrationUnassignedEmployees.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getIntegrationUnassignedEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrationUnassignedEmployees =
          action.payload?.data?.employees || [];
      })
      .addCase(getIntegrationUnassignedEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getIntegrationLogs.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getIntegrationLogs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrationLogs = action.payload?.data?.last_entries
          ? integrationLastEntryParser(action.payload?.data?.last_entries)
          : null;
      })
      .addCase(getIntegrationLogs.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(integrateEmployee.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(integrateEmployee.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(integrateEmployee.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(removeEmployee.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(removeEmployee.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(removeEmployee.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(integrateSlackIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.integrating = "slack";
        state.error = null;
      })
      .addCase(integrateSlackIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrating = null;
        let error = action?.payload?.data?.error;
        state.error = error ? error : null;
        state.allIntegrations = action.payload?.data?.integrations
          ? action.payload?.data?.integrations
          : state.allIntegrations;
      })
      .addCase(integrateSlackIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.integrating = "slack";
        state.error = action.error ? action.error : null;
      })
      .addCase(integrateGoogleIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.integrating = "google";
        state.error = null;
      })
      .addCase(integrateGoogleIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrating = null;
        let error = action?.payload?.data?.error;
        state.error = error ? error : null;
        state.allIntegrations = action.payload?.data?.integrations
          ? action.payload?.data?.integrations
          : state.allIntegrations;
      })
      .addCase(integrateGoogleIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.integrating = "google";
        state.error = action.error ? action.error : null;
      })
      .addCase(integrateGithubIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.integrating = "github";
        state.error = null;
      })
      .addCase(integrateGithubIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrating = null;
        state.error = action?.payload?.data?.error || null;
        state.allIntegrations =
          action.payload?.data?.integrations || state.allIntegrations;
      })
      .addCase(integrateGithubIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.integrating = "github";
        state.error = action.error ? action.error : null;
      })
      .addCase(integrateJiraIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.integrating = "jira";
        state.error = null;
      })
      .addCase(integrateJiraIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrating = null;
        state.error = action?.payload?.data?.error || null;
        state.allIntegrations =
          action.payload?.data?.integrations || state.allIntegrations;
      })
      .addCase(integrateJiraIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.integrating = "jira";
        state.error =
          action?.payload?.data?.error || "Integration failed to start.";
      })
      .addCase(integrateZoomIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.integrating = "zoom";
        state.error = null;
      })
      .addCase(integrateZoomIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrating = null;
        state.error = action?.payload?.data?.error || null;
        state.allIntegrations =
          action.payload?.data?.integrations || state.allIntegrations;
      })
      .addCase(integrateZoomIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.integrating = "zoom";
        state.error =
          action?.payload?.data?.error || "Integration failed to start.";
      })
      .addCase(integrateConfluenceIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.integrating = "confluence";
        state.error = null;
      })
      .addCase(integrateConfluenceIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrating = null;
        state.error = action?.payload?.data?.error || null;
        state.allIntegrations =
          action.payload?.data?.integrations || state.allIntegrations;
      })
      .addCase(integrateConfluenceIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.integrating = "confluence";
        state.error =
          action?.payload?.data?.error || "Integration failed to start.";
      })
      .addCase(integrateTeamsIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.integrating = "teams";
        state.error = null;
      })
      .addCase(integrateTeamsIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrating = null;
        state.error = action?.payload?.data?.error || null;
        state.allIntegrations =
          action.payload?.data?.integrations || state.allIntegrations;
      })
      .addCase(integrateTeamsIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.integrating = "teams";
        state.error =
          action?.payload?.data?.error || "Integration failed to start.";
      })
      .addCase(integrateOutlookIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.integrating = "outlook";
        state.error = null;
      })
      .addCase(integrateOutlookIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrating = null;
        state.error = action?.payload?.data?.error || null;
        state.allIntegrations =
          action.payload?.data?.integrations || state.allIntegrations;
      })
      .addCase(integrateOutlookIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.integrating = "outlook";
        state.error =
          action?.payload?.data?.error || "Integration failed to start.";
      })
      .addCase(integrateSalesforceIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.integrating = "salesforce";
        state.error = null;
      })
      .addCase(integrateSalesforceIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrating = null;
        state.error = action?.payload?.data?.error || null;
        state.allIntegrations =
          action.payload?.data?.integrations || state.allIntegrations;
      })
      .addCase(integrateSalesforceIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.integrating = "salesforce";
        state.error =
          action?.payload?.data?.error || "Integration failed to start.";
      })

      .addCase(validateGithubIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
        state.testResponseGithub = null;
      })
      .addCase(validateGithubIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.error = null;
        let response = null;
        if (action.payload.status >= 200 && action.payload.status < 300) {
          response = "Successfully tested.";
        } else if (action.payload.status === 404) {
          response =
            "Repository not found. Does your token have both repo and read:org permissions?";
        } else {
          response = "A connection error occurred.";
        }
        state.testResponseGithub = response;
      })
      .addCase(validateGithubIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = null;
        state.testResponseGithub = "Test failed.";
      })
      .addCase(validateJiraIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
        state.testResponseJira = null;
      })
      .addCase(validateJiraIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.error = null;
        let response = null;
        if (action.payload.data.status === "ok") {
          response = "Successfully tested.";
        } else {
          response =
            "Account not accessed. Does your token have required permissions?";
        }
        state.testResponseJira = response;
      })
      .addCase(validateJiraIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = null;
        state.testResponseJira = "Test failed.";
      })
      .addCase(validateZoomIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
        state.testResponseZoom = null;
      })
      .addCase(validateZoomIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.error = null;
        let response = null;
        if (action.payload.data.status === "ok") {
          response = "Successfully tested.";
        } else {
          response =
            "Account not accessed. Does your token have required permissions?";
        }
        state.testResponseZoom = response;
      })
      .addCase(validateZoomIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = null;
        state.testResponseZoom = "Test failed.";
      })
      .addCase(validateConfluenceIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
        state.testResponseConfluence = null;
      })
      .addCase(validateConfluenceIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.error = null;
        let response = null;
        if (action.payload.data.status === "ok") {
          response = "Successfully tested.";
        } else {
          response =
            "Account not accessed. Does your token have required permissions?";
        }
        state.testResponseConfluence = response;
      })
      .addCase(validateConfluenceIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = null;
        state.testResponseConfluence = "Test failed.";
      })
      .addCase(validateTeamsIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
        state.testResponseTeams = null;
      })
      .addCase(validateTeamsIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.error = null;
        let response = null;
        if (action.payload.data.status === "ok") {
          response = "Successfully tested.";
        } else {
          response =
            "Account not accessed. Does your token have required permissions?";
        }
        state.testResponseTeams = response;
      })
      .addCase(validateTeamsIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = null;
        state.testResponseTeams = "Test failed.";
      })

      .addCase(validateSalesforceIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
        state.testResponseSalesforce = null;
      })
      .addCase(validateSalesforceIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.error = null;
        let response = null;
        if (action.payload.data.status === "ok") {
          response = "Successfully tested.";
        } else {
          response =
            "Account not accessed. Does your token have required permissions?";
        }
        state.testResponseSalesforce = response;
      })
      .addCase(validateSalesforceIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = null;
        state.testResponseSalesforce = "Test failed.";
      })

      .addCase(validateOutlookIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
        state.testResponseOutlook = null;
      })
      .addCase(validateOutlookIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.error = null;
        let response = null;
        if (action.payload.data.status === "ok") {
          response = "Successfully tested.";
        } else {
          response =
            "Account not accessed. Does your token have required permissions?";
        }
        state.testResponseOutlook = response;
      })
      .addCase(validateOutlookIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = null;
        state.testResponseOutlook = "Test failed.";
      });
  },
});

export const getAllIntegrations = createAsyncThunk(
  "getAllIntegrations",
  async () => {
    const response = await api.get("/integrations");
    return response;
  }
);

export const getMyIntegrations = createAsyncThunk(
  "getMyIntegrations",
  async () => {
    const response = await api.get("/integrations/my");
    return response;
  }
);

export const getMyIntegrationsEmployees = createAsyncThunk(
  "getMyIntegrationsEmployees",
  async (data) => {
    const response = await api.get(
      `/integrations/my/employees/${data.company_integration_id}`
    );
    return response;
  }
);

export const getMyIntegrationsDifferenceByDate = createAsyncThunk(
  "getMyIntegrationsDifferenceByDate",
  async (data) => {
    const response = await api.get(
      `/integrations/my/difference_by_date/${data.company_integration_id}`
    );
    return response;
  }
);

export const getMyIntegrationsInteractionCountByDate = createAsyncThunk(
  "getMyIntegrationsInteractionCountByDate",
  async (data) => {
    const response = await api.get(
      `/integrations/my/interaction_count_by_date/${data.company_integration_id}`
    );
    return response;
  }
);

export const getBasicsOfMyIntegrations = createAsyncThunk(
  "getBasicsOfMyIntegrations",
  async () => {
    const response = await api.get("/integrations/basic_my");
    return response;
  }
);

export const getIntegrationOverview = createAsyncThunk(
  "getIntegrationOverview",
  async (data) => {
    const response = await api.get(
      `/integration/${data.integration}/integration_overview/${data.period}`
    );
    return response;
  }
);

export const getIntegration = createAsyncThunk(
  "getIntegration",
  async (data) => {
    const response = await api.get(`/integration/settings/${data.integration}`);
    return response;
  }
);

export const updateIntegration = createAsyncThunk(
  "updateIntegration",
  async (data) => {
    const response = await api.put(`integration/update`, data);
    return response;
  }
);

export const deleteIntegration = createAsyncThunk(
  "deleteIntegration",
  async (data) => {
    const response = await api.put(`integration/delete/${data}`);
    return response;
  }
);

export const getIntegrationEmployees = createAsyncThunk(
  "getIntegrationEmployees",
  async (data) => {
    const response = await api.get(
      `/integration/${data.integration}/integration_employees/${data.period}`
    );
    return response;
  }
);

export const getIntegrationUnassignedEmployees = createAsyncThunk(
  "getIntegrationUnassignedEmployees",
  async (integrationName) => {
    const response = await api.get(
      `/integration/${integrationName}/integration_unassigned_employees`
    );
    return response;
  }
);

export const getIntegrationLogs = createAsyncThunk(
  "getIntegrationLogs",
  async (data) => {
    const response = await api.get(
      `/integration/${data.integration}/integration_logs/${data.period}`
    );
    return response;
  }
);

export const integrateEmployee = createAsyncThunk(
  "integrateEmployee",
  async ({ integrationId, employeeId }) => {
    const response = await api.post(
      `/integration/${integrationId}/add_employee/${employeeId}`
    );
    return response;
  }
);

export const integrationRemoveEmployee = createAsyncThunk(
  "integrationRemoveEmployee",
  async ({ integrationId, employeeId }) => {
    const response = await api.post(
      `/integration/${integrationId}/remove_employee/${employeeId}`
    );
    return response;
  }
);

export const removeEmployee = createAsyncThunk(
  "removeEmployee",
  async ({ integrationId, employeeId }) => {
    const response = await api.post(
      `/integration/${integrationId}/remove_employee/${employeeId}`
    );
    return response;
  }
);

export const integrateSlackIntegration = createAsyncThunk(
  "integrateSlackIntegration",
  async (data) => {
    const response = await api.post(`/integrate/slack`, data);
    return response;
  }
);

export const integrateGoogleIntegration = createAsyncThunk(
  "integrateGoogleIntegration",
  async (data) => {
    const response = await api.post(`/integrate/google`, data);
    return response;
  }
);

export const integrateGithubIntegration = createAsyncThunk(
  "integrateGithubIntegration",
  async (data) => {
    const response = await api.post(`/integrate/github`, data);
    return response;
  }
);

export const integrateJiraIntegration = createAsyncThunk(
  "integrateJiraIntegration",
  async (data) => {
    const response = await api.post(`/integrate/jira`, data);
    return response;
  }
);

export const integrateZoomIntegration = createAsyncThunk(
  "integrateZoomIntegration",
  async (data) => {
    const response = await api.post(`/integrate/zoom`, data);
    return response;
  }
);

export const integrateConfluenceIntegration = createAsyncThunk(
  "integrateConfluenceIntegration",
  async (data) => {
    const response = await api.post(`/integrate/confluence`, data);
    return response;
  }
);

export const integrateTeamsIntegration = createAsyncThunk(
  "integrateTeamsIntegration",
  async (data) => {
    const response = await api.post(`/integrate/teams`, data);
    return response;
  }
);

export const integrateSalesforceIntegration = createAsyncThunk(
  "integrateSalesforceIntegration",
  async (data) => {
    const response = await api.post(`/integrate/salesforce`, data);
    return response;
  }
);

export const integrateOutlookIntegration = createAsyncThunk(
  "integrateOutlookIntegration",
  async (data) => {
    const response = await api.post(`/integrate/outlook`, data);
    return response;
  }
);

export const validateGithubIntegration = createAsyncThunk(
  "validateGithubIntegration",
  async (data) => {
    const response = await validateGithub(data).get("");
    return response;
  }
);

export const validateJiraIntegration = createAsyncThunk(
  "validateJiraIntegration",
  async (data) => {
    const response = await api.post("/integrate/validate/jira", data);
    return response;
  }
);

export const validateZoomIntegration = createAsyncThunk(
  "validateZoomIntegration",
  async (data) => {
    const response = await api.post("/integrate/validate/zoom", data);
    return response;
  }
);

export const validateConfluenceIntegration = createAsyncThunk(
  "validateConfluenceIntegration",
  async (data) => {
    const response = await api.post("/integrate/validate/confluence", data);
    return response;
  }
);

export const validateTeamsIntegration = createAsyncThunk(
  "validateTeamsIntegration",
  async (data) => {
    const response = await api.post("/integrate/validate/teams", data);
    return response;
  }
);

export const validateOutlookIntegration = createAsyncThunk(
  "validateOutlookIntegration",
  async (data) => {
    const response = await api.post("/integrate/validate/outlook", data);
    return response;
  }
);

export const validateSalesforceIntegration = createAsyncThunk(
  "validateSalesforceIntegration",
  async (data) => {
    const response = await api.post(`/integrate/validate/salesforce`, data);
    return response;
  }
);

export const { placeholder_action_one, placeholder_action_two } =
  integration.actions;

export const placeholder_action_three = createAsyncThunk(
  "placeholder_action_three",
  async (data) => {
    const response = await api.post("/endpoint", data);
    return response;
  }
);

export default integration.reducer;
