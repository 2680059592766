import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as DollarCircleIcon } from "assets/icons/dollar-circle.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/people2.svg";
import { ReactComponent as PresentionChartIcon } from "assets/icons/presention-chart.svg";
import { ReactComponent as TaskIcon } from "assets/icons/task.svg";
import { ReactComponent as UserOctagonIcon } from "assets/icons/user-octagon.svg";
import { ReactComponent as UserSearchIcon } from "assets/icons/user-search.svg";
import { getGoals } from "slices/homeSlice";

import Goal from "./Goal";
import styles from "./Goals.module.sass";

const icons = {
  task: <TaskIcon />,
  people: <PeopleIcon />,
  "user-search": <UserSearchIcon />,
  "presention-chart": <PresentionChartIcon />,
  "user-octagon": <UserOctagonIcon />,
  "dollar-circle": <DollarCircleIcon />,
};

const Goals = ({ selection, onSelection }) => {
  const dispatch = useDispatch();

  const [selectedGoals, setSelectedGoals] = useState([]);
  const { goals } = useSelector((state) => state.home);

  const handleCheck = (id) => {
    if (selectedGoals.length === 3 && !selectedGoals.includes(id)) return;

    const newSelectedGoals = selectedGoals.includes(id)
      ? selectedGoals.filter((goalId) => goalId !== id)
      : [...selectedGoals, id];

    setSelectedGoals(newSelectedGoals);
    onSelection(newSelectedGoals);
  };

  useEffect(() => {
    setSelectedGoals(selection);
  }, [selection]);

  useEffect(() => {
    dispatch(getGoals());
  }, [dispatch]);

  return (
    <div className={styles.goals}>
      {goals?.map((goal) => (
        <Goal
          key={goal.id}
          onCheck={handleCheck}
          value={selectedGoals.includes(goal.id)}
          {...goal}
          icon={icons[goal.icon]}
        />
      ))}
    </div>
  );
};

export default Goals;
