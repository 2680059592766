import React, { useState } from "react";

import cn from "classnames";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

import Item from "./Item";
import styles from "./Schedule.module.sass";

const Schedule = ({ className, integrationDate, setIntegrationDate, onboarding }) => {
  const [visibleDate, setVisibleDate] = useState(false);

  return (
    <div className={cn(styles.schedule, className)}>
      <div className={styles.note}>Choose a day that uploaded file belongs</div>
      <div className={styles.list}>
        <Item
          className={styles.item}
          category="Date"
          icon="calendar"
          value={integrationDate && format(integrationDate, "MMMM dd, yyyy")}
          visible={visibleDate}
          setVisible={setVisibleDate}
          onboarding={onboarding}
        >
          <div className={styles.date}>
            <DatePicker
              selected={integrationDate}
              onChange={(date) => setIntegrationDate(date)}
              dateFormatCalendar={"MMMM yyyy"}
              maxDate={new Date()}
              inline
            />
            <div className={styles.foot}>
              <button
                className={cn("button-small", styles.button)}
                onClick={() => setVisibleDate(false)}
              >
                Close
              </button>
            </div>
          </div>
        </Item>
      </div>
    </div>
  );
};

export default Schedule;
