import React from "react";

import cn from "classnames";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Employees from "./Employees";
import styles from "./IntegrationDetails.module.sass";
import IntegrationLogs from "./IntegrationLogs";
import Overview from "./Overview";
import TopButtons from "./TopButtons";

const IntegrationDetails = () => {
  const params = useParams();
  let title = ["Integrations"];
  if (params) {
    Object.keys(params).forEach((param) => title.push(params[param]));
  }
  const { integrationOverview } = useSelector((state) => state.integration);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Overview className={styles.card} />
        </div>
        <div className={styles.col}>
          <TopButtons
            className={styles.card}
            title={title[1]}
          />
          <IntegrationLogs
            className={styles.card}
            viewAll={true}
          />
        </div>
      </div>
      <Employees
        className={styles.card}
        integrationId={integrationOverview?.id}
      />
    </>
  );
};

export default IntegrationDetails;
