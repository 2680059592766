import React from "react";

import cn from "classnames";

import { ReactComponent as TagIcon } from "assets/icons/tag.svg";
import { Avatar, Icon } from "components";

import styles from "./Item.module.sass";

const Item = ({ className, item, handleMarkAsRead }) => {
  const extractName = (content) => {
    const verbs = [
      " has ",
      " reaches ",
      " achieves ",
      " reports ",
      " records ",
    ];
    const includesVerb = verbs.find((verb) => content.includes(verb));
    const teamName = includesVerb ? content.split(includesVerb)[0] : null;
    const restructuredTeamName =
      teamName.charAt(0).toUpperCase() + teamName.slice(1);
    const sentence = (includesVerb + content.split(includesVerb)[1]).trim();
    const restructureSentence =
      sentence.charAt(0).toUpperCase() + sentence.slice(1);

    return {
      title: restructuredTeamName ? restructuredTeamName.trim() : content,
      sentence: restructureSentence,
    };
  };

  return (
    <div
      onClick={() => handleMarkAsRead(item.id)}
      className={cn(styles.item, { [styles.unread]: item.unread }, className)}
    >
      <div className={styles.avatar}>
        {(item.category === "employee" || !item.category) && (
          <Avatar
            src={item.avatar_url}
            alt="Avatar"
          />
        )}
        {item.category === "integration" && (
          <div className={styles.integrationIcon}>
            <Icon
              name="store"
              size="24"
            />
          </div>
        )}
        {item.category === "team" && (
          <div className={styles.teamIcon}>
            <Icon
              name="activity"
              size="24"
            />
          </div>
        )}
        {item.category === "tag" && (
          <div className={styles.tagIcon}>
            <TagIcon />
          </div>
        )}
      </div>
      <div className={styles.details}>
        <div className={styles.line}>
          <div className={styles.subtitle}>
            {item.category === "employee" || item.category === "team"
              ? extractName(item.content).title
              : item.title[0].toUpperCase() + item.title.slice(1)}
          </div>
          <div className={styles.login}></div>
          <div className={styles.time}>{item.time}</div>
        </div>
        <div className={styles.comment}>
          {item.category === "team" || item.category === "employee"
            ? extractName(item.content).sentence
            : item.content}
        </div>
      </div>
    </div>
  );
};

export default Item;
