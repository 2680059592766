import React, { useState, useRef } from "react";

import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

import Checkbox from "../Checkbox";
import TextInput from "../TextInput";

import styles from "./Dropdown.module.sass";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  small,
  transparent,
  upBody,
  placeholder,
  enableCustomInput,
  onSubmitCustom,
  customPlaceholder,
}) => {
  const [visible, setVisible] = useState(false);
  const [custom, setCustom] = useState(false);
  const [customValue, setCustomValue] = useState("");

  const customInputRef = useRef(null);

  const handleClick = (value) => {
    setValue(value);
    setVisible(false);
    setCustom(false);
  };

  const handleCustomSelect = () => {
    if (customInputRef.current && !custom) {
      setCustom((prev) => !prev);
      customInputRef.current.focus();
    } else {
      setCustom((prev) => !prev);
    }
  };

  const handleKeyPress = async (event) => {
    const isEnterKey = event.key === "Enter";

    if (isEnterKey) {
      event.preventDefault();
      await onSubmitCustom(customValue);
      setVisible(false);
      setCustomValue("");
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>{label} </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead, {
            [styles.transparent]: transparent,
          })}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>
            {!value && placeholder ? (
              <span className={styles.placeholder}>{placeholder}</span>
            ) : (
              value
            )}
          </div>
        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          {options?.map((x, index) => (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: x === value,
                [styles.deleteOption]: x === "delete tag",
              })}
              onClick={() => handleClick(x, index)}
              key={index}
            >
              {typeof x === "string"
                ? x.replace("Outlook", "Exchange Online")
                : x}
            </div>
          ))}
          {enableCustomInput && (
            <div className={styles.custom}>
              <div className={styles.customCheckbox}>
                <Checkbox
                  className={styles.checkbox}
                  value={custom}
                  onChange={handleCustomSelect}
                />
                <span onClick={handleCustomSelect}>Other</span>
              </div>
              <TextInput
                ref={customInputRef}
                className={styles.field}
                onFocus={() => setCustom(true)}
                name="custom_value"
                placeholder={customPlaceholder || "Custom"}
                value={customValue}
                onChange={(e) => setCustomValue(e.target.value)}
                onKeyDown={handleKeyPress}
                type="text"
                required
              />
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
