import { useState } from "react";

import { Modal } from "components";

import { PrivacyPolicyTitle, PrivacyPolicyBody } from "./PrivacyPolicy";
import questions from "./questions";
import { SecurityTitle, SecurityBody } from "./Security";
import styles from "./Security.module.sass";

const SecurityAndPrivacy = ({ visible, onClose }) => {
  const [securitySearch, setSecuritySearch] = useState("");
  const [privacyPolicySearch, setPrivacyPolicySearch] = useState("");
  const [showPrivacy, setShowPrivacy] = useState(false);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      outerClassName={styles.modal}
      title="Security Questions"
      className="modal-security-questions"
    >
      <div className={styles.title}>Metra</div>
      {showPrivacy ? (
        <PrivacyPolicyTitle onClick={() => setShowPrivacy(false)} />
      ) : (
        <SecurityTitle onClick={() => setShowPrivacy(true)} />
      )}
      <div className={styles.divider} />

      {showPrivacy ? (
        <PrivacyPolicyBody
          onChange={(e) => setPrivacyPolicySearch(e.target.value)}
          value={privacyPolicySearch}
        />
      ) : (
        <SecurityBody
          questions={questions}
          onChange={(e) => setSecuritySearch(e.target.value)}
          value={securitySearch}
        />
      )}
    </Modal>
  );
};

export default SecurityAndPrivacy;
