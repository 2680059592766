import React from "react";

import cn from "classnames";

import { TextInput, Tooltip } from "components";

import styles from "./BasicInfo.module.sass";

const handleKeyDown = (event) => {
  if (event.key.search(/[0-9a-zA-Z\s_-]/) == -1) {
    event.preventDefault();
  }
};

const BasicInfo = ({ values, onChange }) => (
  <div className={styles.basicInfo}>
    <TextInput
      className={styles.sectionInput}
      onChange={onChange}
      value={values.name || ""}
      placeholder="Squad 1"
      type="text"
      name="name"
      label="Team Name"
      tooltip
      onKeyDown={handleKeyDown}
    />

    <div>
      <div className={cn(styles.label)}>
        Description
        <Tooltip
          className={styles.tooltip}
          title={"Description"}
          icon="info"
          place="right"
        />
      </div>
      <textarea
        name="description"
        value={values.description || ""}
        placeholder="Description"
        className={styles.description}
        onChange={onChange}
      />
    </div>
  </div>
);

export default BasicInfo;
