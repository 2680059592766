import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Icon, TextInput, Avatar, TextArea, TagInput } from "components";
import {
  getEmployeeProfile,
  updateEmployeeProfile,
  checkEmail,
  getAllEmployees,
} from "slices/employeeSlice";

import Item from "../Item";

import ConfirmModal from "./ConfirmModal";
import styles from "./ProfileInformation.module.sass";

const initialForm = {
  avatar: "",
  name: "",
  email: [],
  location: "",
  bio: "",
};

const ProfileInformation = ({ className }) => {
  const [form, setForm] = useState(initialForm);
  const [intersectingEmails, setIntersectingEmails] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employee: employeeId } = useParams();
  const { employeeProfile } = useSelector((state) => state.employee);

  useEffect(() => {
    dispatch(getEmployeeProfile(employeeId));
  }, [dispatch, employeeId]);

  useEffect(() => {
    if (employeeProfile) {
      setForm({
        avatar: employeeProfile.avatar_url,
        name: employeeProfile.name,
        email: employeeProfile.emails_array,
        location: employeeProfile.location,
        bio: employeeProfile.bio,
      });
    }
  }, [employeeProfile]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (form.avatar !== employeeProfile.avatar_url)
      formData.append("avatar", form.avatar);
    formData.append("name", form.name);
    formData.append("email", form.email.join(","));
    formData.append("location", form.location);
    formData.append("bio", form.bio);

    const res = await dispatch(
      updateEmployeeProfile({
        id: employeeId,
        data: formData,
      })
    );

    if (res?.error) {
      toast.error("Unable to Update Profile Information. Try again please!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    setShowConfirmModal(false);
    if (res?.payload?.data?.message?.includes("merged")) {
      toast.success(`User got ${res.payload.data.message}!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      dispatch(getAllEmployees());
      navigate(`/employees`);
    } else {
      toast.success("Successfully Updated Profile Information!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const blobAvatar = new Blob([reader.result], { type: file.type });
      setForm({
        ...form,
        avatar: blobAvatar,
      });
    };

    reader.readAsArrayBuffer(file);
    // reader.readAsDataURL(file);
  };

  const handleAddEmail = async (tags) => {
    const res = await dispatch(checkEmail(tags[tags.length - 1].text));
    if (res.payload?.data?.does_exist) {
      setIntersectingEmails((emails) => [
        ...emails,
        tags[tags.length - 1].text,
      ]);
    }

    setForm({
      ...form,
      email: tags.map((t) => t.text),
    });
  };

  const handleDeleteEmail = (deletedTag, tags) => {
    setIntersectingEmails((emails) =>
      emails.filter((e) => e !== deletedTag.text)
    );

    setForm({
      ...form,
      email: tags.map((t) => t.text),
    });
  };

  let img = "/images/content/avatar.jpg";
  if (form?.avatar && typeof form?.avatar == "string") {
    img = form?.avatar;
  } else if (form?.avatar && typeof form?.avatar == "object") {
    img = URL.createObjectURL(form?.avatar);
  }

  return (
    <Item
      className={cn(styles.card, className)}
      title="Profile information"
      classTitle="title-green"
    >
      <div className={styles.profile}>
        <div className={styles.avatar}>
          <Avatar
            src={img}
            alt="Avatar"
          />
          <button className={styles.remove}>
            <Icon name="close" />
          </button>
        </div>
        <div className={styles.file}>
          <input
            type="file"
            onChange={handleUpload}
          />
          <button
            className={cn("button", styles.button)}
            type="button"
          >
            <Icon
              name="add"
              size="24"
            />
            <span>Upload new picture</span>
          </button>
        </div>
        <button
          className={cn("button-stroke", styles.button)}
          onClick={() => {
            setForm({
              ...form,
              avatar: "",
            });
          }}
        >
          Remove
        </button>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Display name"
          placeholder="John Doe"
          name="name"
          value={form.name}
          onChange={handleChange}
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />
        <div className={styles.field}>
          <TagInput
            label="Linked emails/username"
            tooltip="-"
            tags={form?.email?.map((e) => ({ id: e, text: e }))}
            onAdd={(tags) => handleAddEmail(tags)}
            onDelete={(deletedTag, tags) => handleDeleteEmail(deletedTag, tags)}
          />
          {intersectingEmails.length > 0 && (
            <div className={styles.warnEmailIntersection}>
              Warning: One of these emails already exists.
            </div>
          )}
        </div>
        <TextInput
          className={styles.field}
          label="Location"
          name="location"
          value={form.location}
          onChange={handleChange}
          placeholder="New York, NY"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />
        <TextArea
          className={styles.field}
          label="Bio"
          name="bio"
          value={form.bio}
          onChange={handleChange}
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />
      </div>

      <button
        className={cn("button", styles.button)}
        onClick={(e) => {
          if (intersectingEmails.length > 0) {
            setShowConfirmModal(true);
            return;
          }
          handleSubmit(e);
        }}
      >
        Save
      </button>

      <ConfirmModal
        visible={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={handleSubmit}
        onClose={() => setShowConfirmModal(false)}
      />
    </Item>
  );
};

export default ProfileInformation;
