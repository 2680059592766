import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Loader, TextInput } from "components";
import {
  integrateGithubIntegration,
  validateGithubIntegration,
} from "slices/integrationSlice";


import styles from "./Integration.module.sass";

const Integration = ({ onClose, onboarding }) => {
  const { status, loading, error, testResponseGithub, integrating } = useSelector(
    (state) => state.integration
  );
  const [informMessage, setInformMessage] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [values, setValues] = useState({});
  const [canProceed, setCanProceed] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleTestConnection = () => {
    dispatch(
      validateGithubIntegration({
        name: "",
        org_name: values["org_name"],
        connector: {
          type: "GITHUB",
          token: values["token"],
        },
      })
    );
  };

  useEffect(() => {
    setCanProceed(
      !Object.keys(values).some((val) => values[val] === "") &&
        Object.keys(values).length >= 2
    );
  }, [values]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setInformMessage(null);
    setFormSubmitted(true);
    dispatch(integrateGithubIntegration(values));
  };

  const preventSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (formSubmitted) {
      if (error && integrating === 'github') {
        setInformMessage("Integration failed to start.");
      } else if (status === "succeeded") {
        setInformMessage(
          "Integration is in progress, we will notify you once it is complete."
        );
        setTimeout(onClose, 5000);
      }
    }
  }, [status, error, formSubmitted, onClose, integrating]);

  useEffect(() => {
    if (integrating !== 'github') return;
    setInformMessage(error);
    let timer = setTimeout(() => setInformMessage(null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [error, integrating]);

  useEffect(() => {
    setInformMessage(testResponseGithub);
    let timer = setTimeout(() => setInformMessage(null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [testResponseGithub]);

  return (
    <div
      className={cn(styles.integration, {
        [styles.onboarding]: onboarding,
      })}
    >
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red", styles.subtitle)}>
              Add Repository
            </div>
            <div className={styles.content}>
              <form onSubmit={preventSubmit}>
                <div className={styles.inputWrapper}>
                  <TextInput
                    name="org_name"
                    placeholder="**** - **** - *****"
                    label="Organization Name"
                    className={styles.input}
                    type="text"
                    onChange={handleChange}
                  />
                  <div className={styles.infoNote}>
                    Enter the name under your organization is registered in GitHub.
                  </div>
                </div>

                <div className={styles.inputWrapper}>
                  <TextInput
                    name="token"
                    placeholder="**** - **** - *****"
                    label="GitHub OAuth token"
                    className={styles.input}
                    type="text"
                    onChange={handleChange}
                  />
                  <div className={styles.infoNote}>
                    Add a{" "}
                    <Link
                      className={styles.emptyItem}
                      to="https://github.com/settings/tokens"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Personal Access Token
                    </Link>{" "}
                    above with at least read:org access:
                  </div>
                </div>
                {informMessage && (
                  <div className={styles.errorNote}>{informMessage}</div>
                )}
                <button
                  className={cn(
                    `button button-stroke-blue button-small`,
                    styles.buttonTest
                  )}
                  onClick={() => handleTestConnection()}
                >
                  Test Connection
                </button>
                <button
                  type="submit"
                  className={cn(`button button-small`, styles.button, {
                    disabled: !canProceed || loading,
                  })}
                  onClick={handleSubmit}
                >
                  {loading && integrating === 'github' && <Loader className={styles.loader} white />}
                  <span>Integrate</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
