import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Icon, TextInput, Avatar, Autocomplete } from "components";
import Loader from "components/Loader"; 
import { getCompanyInformation, updateCompany, getTimezones } from "slices/companySlice";

import Item from "../Item";

import styles from "./CompanyInformation.module.sass";
import { countries } from "./data";

const initialForm = {
  avatar: "",
  name: "",
  email: "",
  aiKey: "",
  location: "",
  timezone: "",
};

const CompanyInformation = ({ className }) => {
  const [form, setForm] = useState(initialForm);
  const [initialFormState, setInitialFormState] = useState(initialForm);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { company, timezones } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(getCompanyInformation());
    dispatch(getTimezones());
  }, [dispatch]);

  useEffect(() => {
    if (company) {
      const roles = company.roles?.split(",").map((role) => {
        return {
          id: role,
          text: role,
        };
      });
      setRoles(roles || []);

      const updatedForm = {
        avatar: company.avatar_url,
        name: company.name,
        email: company.email,
        aiKey: company.aiKey,
        location: company.location,
        timezone: company.timezone,
      };

      setForm(updatedForm);
      setInitialFormState(updatedForm);
    }
  }, [company]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();
    if (form?.avatar !== company?.avatar_url)
      formData.append("avatar", form.avatar);
    formData.append("name", form.name);
    formData.append("email", form.email);
    formData.append("ai_key", form.aiKey);
    formData.append("roles", roles?.map((role) => role.text).join(",") || "");
    formData.append("location", form.location);
    formData.append("timezone", form.timezone);

    try {
      const res = await dispatch(updateCompany(formData));
      if (res.error) {
        toast.error("Unable to Update Company Information. Try again please!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }

      await dispatch(getCompanyInformation());
      toast.success("Successfully Updated Company Information!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      toast.error("An error occurred while updating company information.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const blobAvatar = new Blob([reader.result], { type: file.type });
      setForm({
        ...form,
        avatar: blobAvatar,
      });
    };

    reader.readAsArrayBuffer(file);
     // reader.readAsDataURL(file);
  };

  let img = "/images/content/avatar.jpg";
  if (form?.avatar && typeof form?.avatar === "string") {
    img = form?.avatar;
  } else if (form?.avatar && typeof form?.avatar === "object") {
    img = URL.createObjectURL(form?.avatar);
  }

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const handleDelete = (i) => {
    setRoles(roles?.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    if (roles.length < 12) {
      setRoles([...roles, tag]);
    }
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...roles].slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setRoles(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const onClearAll = () => {
    setRoles([]);
  };

  const onTagUpdate = (i, newTag) => {
    const updatedTags = roles.slice();
    updatedTags.splice(i, 1, newTag);
    setRoles(updatedTags);
  };

  const isChanged = JSON.stringify(form) !== JSON.stringify(initialFormState);

  return (
    <Item
      className={cn(styles.card, className)}
      title="Company information"
      classTitle="title-green"
    >
      <div className={styles.profile}>
        <div className={styles.avatar}>
          <Avatar src={img} alt="Avatar" />
          <button className={styles.remove}>
            <Icon name="close" />
          </button>
        </div>
        <div className={styles.file}>
          <input type="file" onChange={handleUpload} />
          <button className={cn("button", styles.button)} type="button">
            <Icon name="add" size="24" />
            <span>Upload new picture</span>
          </button>
        </div>
        <button
          className={cn("button-stroke", styles.button)}
          onClick={() =>
            setForm({
              ...form,
              avatar: "",
            })
          }
        >
          Remove
        </button>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Company name"
          name="name"
          type="text"
          value={form.name}
          onChange={handleChange}
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />
        <TextInput
          className={styles.field}
          label="Company Email"
          name="email"
          type="email"
          value={form.email}
          onChange={handleChange}
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />
        <TextInput
          className={styles.field}
          label="AI API key"
          name="aiKey"
          type="text"
          value={form.aiKey}
          onChange={handleChange}
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />

        {/* <div className={styles.field}>
          <div className={cn(styles.label)}>
            Roles
          </div>
          <ReactTags
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            delimiters={delimiters}
            handleTagClick={handleTagClick}
            onClearAll={onClearAll}
            onTagUpdate={onTagUpdate}
            suggestions={[{ id: "1", text: "Engineering" }]}
            placeholder="Enter roles inside your company"
            minQueryLength={2}
            maxLength={20}
            autofocus={false}
            allowDeleteFromEmptyInput={true}
            autocomplete={true}
            readOnly={false}
            allowUnique={true}
            allowDragDrop={true}
            inline={true}
            inputFieldPosition="inline"
            allowAdditionFromPaste={true}
            editable={true}
            clearAll={true}
            tags={roles}
          />
        </div> */}
        <Autocomplete
          className={styles.field}
          classDropdownHead={styles.dropdownHead}
          value={form.location}
          setValue={(value) => {
            setForm({
              ...form,
              location: value,
            });
          }}
          options={countries.map((country) => country.name.common)}
          label="Location"
          name="location"
          tooltip="-"
        />
        <Autocomplete
          classDropdownHead={styles.dropdownHead}
          value={form.timezone}
          setValue={(value) => {
            setForm({
              ...form,
              timezone: value,
            });
          }}
          options={timezones}
          label="Timezone"
          name="timezone"
          tooltip="-"
        />
      </div>

      <button
        className={cn("button", styles.saveButton, {
          [styles.buttonDisabled]: !isChanged || loading})}
        onClick={handleSubmit}
        disabled={!isChanged || loading}
      >
        {loading ? <Loader white /> : "Save"}
      </button>
    </Item>
  );
};

export default CompanyInformation;
