import { useEffect, useState } from "react";

import cn from "classnames";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import { Card, Icon, NewSectionCard } from "components";
import CreateSection from "screens/Tags/SectionTag/CreateSection";
import Activities from "screens/Team/Activities";
import { getTeamsOverview } from "slices/homeV2Slice.js";
import { periodConverter } from "utils";

import styles from "./Overview.module.sass";
import TeamCard from "./TeamCard";

const Overview = () => {
  const dispatch = useDispatch();
  const [createSectionModal, setCreateSectionModal] = useState(false);
  const { teamsOverview } = useSelector((state) => state.homeV2);
  const { onboardingInProgress, selectedFilterInterval } = useSelector(
    (state) => state.app
  );
  const { integrationStartDate, integrationEndDate } = useSelector(
    (state) => state.employee
  );

  useEffect(() => {
    if (integrationStartDate && integrationEndDate) {
      dispatch(
        getTeamsOverview({
          startDate: format(integrationStartDate, "yyyy-MM-dd"),
          endDate: format(integrationEndDate, "yyyy-MM-dd"),
        })
      );
    } else {
      dispatch(
        getTeamsOverview({
          period: periodConverter(selectedFilterInterval),
        })
      );
    }
  }, [
    dispatch,
    integrationEndDate,
    integrationStartDate,
    selectedFilterInterval,
  ]);

  return (
    <>
      <Card
        classCardHead={styles.cardHead}
        title={
          <div>
            <div className={styles.title}>
              Teams Overview <span>({teamsOverview?.length})</span>
            </div>
            <span className={styles.subTitle}>
              Select the Team you'd like to see more details
            </span>
          </div>
        }
        classTitle="title-transparent"
        head={
          <button
            className={cn("button button-small", styles.button)}
            onClick={() => setCreateSectionModal(true)}
          >
            <Icon
              name="add"
              size="24"
            />
            <span>Create New Team</span>
          </button>
        }
      >
        {!teamsOverview?.length && (
          <NewSectionCard
            onClick={() => setCreateSectionModal(true)}
            className="onboarding-third-step"
            onboardingFocus={onboardingInProgress === ".onboarding-third-step"}
          />
        )}
        <div className={styles.cards}>
          {teamsOverview?.map((team, index) => (
            <TeamCard
              team={team}
              key={index}
            />
          ))}
        </div>

        <CreateSection
          visible={createSectionModal}
          onClose={() => setCreateSectionModal(false)}
        />
      </Card>
      <Activities teamName="All" />
    </>
  );
};

export default Overview;
