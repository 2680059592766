import { useEffect } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";

import NotificationSetting from "components/Notification/NotificationSetting";
import {
  getNotificationPersonalisedPreferences,
  updateNotificationPreferences,
  getNotificationPreferences,
} from "slices/notificationSlice";

import Icon from "components/Icon";

import styles from "../Notifications.module.sass";

const settings = [
  {
    title: "Integrations Notifications",
    subtitle: "Modify the notifications regarding the Integrations.",
    optionsKey: "integration",
  },
  {
    title: "Employees Notifications",
    subtitle: "Modify the notifications regarding the Employees.",
    optionsKey: "employee",
  },
  {
    title: "Teams & Tags Notifications",
    subtitle: "Modify the notifications regarding the Teams & Tags.",
    optionsKey: "team",
  },
];

const Personalized = ({ disabled }) => {
  const dispatch = useDispatch();

  const { notificationPersonalisedPreferences, notificationPreferences } =
    useSelector((state) => state.notification);

  useEffect(() => {
    dispatch(getNotificationPersonalisedPreferences());
  }, [dispatch]);

  const labels = notificationPersonalisedPreferences?.friendly_names;
  const personalized =
    notificationPersonalisedPreferences?.personalized_preferences;

  const handleNotificationChange = async (x, key, data) => {
    const newPreferences = {
      ...notificationPreferences,
      preferences: {
        ...notificationPreferences.preferences,
        [`personalized_${x.optionsKey}`]: {
          ...notificationPreferences.preferences[
            `personalized_${x.optionsKey}`
          ],
          [key]: notificationPreferences.preferences[
            `personalized_${x.optionsKey}`
          ][key]?.includes(data.id)
            ? notificationPreferences.preferences[
                `personalized_${x.optionsKey}`
              ][key]?.filter((id) => id !== data.id)
            : [
                ...notificationPreferences.preferences[
                  `personalized_${x.optionsKey}`
                ][key],
                data.id,
              ],
        },
      },
    };
    await dispatch(updateNotificationPreferences(newPreferences));
    await dispatch(getNotificationPreferences());
  };

  let emptyNotifications = true;
  for (let key in personalized) {
    for (let type in personalized[key]) {
      if (personalized[key][type].length > 0) {
        emptyNotifications = false;
        break;
      }
    }
  }

  return (
    <div>
      {!emptyNotifications ? (
        settings?.map((x) => (
          <div key={x.title}>
            <div className={cn(styles.line, styles.cols)}>
              <div className={styles.title}>{x.title}</div>
              <div className={styles.subtitle}>{x.subtitle}</div>
            </div>

            <div>
              {personalized &&
                personalized[x.optionsKey] &&
                Object.keys(personalized[x.optionsKey])?.map(
                  (key) =>
                    personalized[x.optionsKey] &&
                    personalized[x.optionsKey][key]?.map((data) => (
                      <NotificationSetting
                        key={data.id}
                        title={labels[x.optionsKey][key]?.replace(
                          "this Employee",
                          `employee ${data.name}`
                        )}
                        value={
                          notificationPreferences.preferences[
                            `personalized_${x.optionsKey}`
                          ][key]?.includes(data.id) || false
                        }
                        onChange={async () => {
                          handleNotificationChange(x, key, data);
                        }}
                        disabled={disabled}
                      />
                    ))
                )}
            </div>
          </div>
        ))
      ) : (
        <div className={styles.noResult}>
          <Icon
            className={styles.clock}
            name="dashed-clock"
            size="24"
            viewBox="0 0 20 20"
          />
          <div>
            No results so far. <br />
            <br /> Create personalized Notifications for Employees <br />
            and Integrations on their settings page.
          </div>
        </div>
      )}
    </div>
  );
};

export default Personalized;
