import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";

import {
  getNotificationPreferences,
  updateNotificationPreferences,
} from "slices/notificationSlice";

import Item from "../Item";

import General from "./General";
import HeaderSwitch from "./HeaderSwitch";
import MuteModal from "./MuteModal";
import styles from "./Notifications.module.sass";
import Personalized from "./Personalized";

const Notifications = ({ className }) => {
  const dispatch = useDispatch();
  const [type, setType] = useState("general");
  const [muteModal, setMuteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getNotificationPreferences());
  }, [dispatch]);

  const { notificationPreferences } = useSelector(
    (state) => state.notification
  );

  const handleMuteUnmute = async () => {
    setLoading(true);
    await dispatch(
      updateNotificationPreferences({
        ...notificationPreferences,
        preferences: {
          ...notificationPreferences.preferences,
          is_enabled: !notificationPreferences.preferences.is_enabled,
        },
      })
    );

    await dispatch(getNotificationPreferences());
    setMuteModal(false);
    setLoading(false);
  };

  const notificationsEnabled = notificationPreferences?.preferences?.is_enabled;

  return (
    <Item
      className={cn(styles.card, className)}
      title={
        <HeaderSwitch
          value={type}
          setValue={(value) => setType(value)}
        />
      }
      classTitle={cn("title-red", styles.itemTitle)}
    >
      {type === "general" ? (
        <>
          <div
            className={notificationsEnabled ? styles.mute : styles.unmute}
            onClick={() => {
              if (notificationsEnabled) {
                setMuteModal(true);
              } else {
                handleMuteUnmute();
              }
            }}
          >
            {`${notificationsEnabled ? "Mute" : "Unmute"} All Notifications`}
            <img
              src={`/icons/volume-slash-${notificationsEnabled ? "mute" : "unmute"}.svg`}
              className={styles.icon}
              alt="arrow"
            />
          </div>

          <General disabled={!notificationsEnabled} />
        </>
      ) : (
        <Personalized disabled={!notificationsEnabled} />
      )}

      <MuteModal
        visible={muteModal}
        loading={loading}
        onConfirm={() => {
          handleMuteUnmute();
        }}
        onClose={() => setMuteModal(false)}
      />
    </Item>
  );
};

export default Notifications;
