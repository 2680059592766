import { useRef, useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { register } from "swiper/element/bundle";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as ShieldSecurityIcon } from "assets/icons/shield-security.svg";
import { Icon, Modal } from "components";
import AddUserModal from "screens/Settings/AddDeleteUser/AddUserModal";
import { getCompanyUsers, inviteUser } from "slices/companySlice";
import { getAllIntegrations } from "slices/integrationSlice";

import SecurityAndPrivacy from "../SecurityAndPrivacy";

import Credentials from "./Credentials";
import styles from "./Integration.module.sass";

import "swiper/css";
import "swiper/css/pagination";

register();

// TODO: Change Swiper to another carousel library
const Integration = ({ selection }) => {
  const swiperElRef = useRef(null);

  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  // const swiperHook = useSwiper();

  useEffect(() => {
    dispatch(getAllIntegrations());
  }, [dispatch]);

  const { allIntegrations } = useSelector((state) => state.integration);

  const selectedIntegration = allIntegrations.filter((i) =>
    selection.includes(i.id)
  );
  return (
    <div className={styles.integration}>
      {/* <swiper-container
        ref={swiperElRef}
        slides-per-view="3"
        navigation="true"
        pagination="true"
        centeredSlides="true"
      >
        {selectedIntegration.map((integration, index) => (
          <swiper-slide style={{ height: "min-content" }}><Credentials key={integration.id} integration={integration} /></swiper-slide>
          // <SwiperSlide
          //   className={cn({ [styles.active]: index === activeIndex })}
          //   style={{ height: "min-content" }}
          //   onClick={() => {

          //   }}
          // >
          //   <Credentials key={integration.id} integration={integration} />
          // </SwiperSlide>
        ))}
      </swiper-container> */}
      <Swiper
        ref={swiperElRef}
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
        onTransitionEnd={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {selectedIntegration.map((integration, index) => (
          <SwiperSlide
            className={cn({ [styles.active]: index === activeIndex })}
            style={{ height: "min-content" }}
            onClick={() => {
              if (index !== activeIndex) {
                swiperElRef?.current?.swiper?.slideTo?.(index);
              }
            }}
          >
            <Credentials
              key={integration.id}
              integration={integration}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export const IntegrateHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [securityModal, setSecurityModal] = useState(false);

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const onAddUser = async (data) => {
    setLoading(true);
    const action = await dispatch(inviteUser(data));
    setLoading(false);
    if (inviteUser.rejected.match(action)) {
      toast.error("Failed to invite the employee. Please try again.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (action.payload.data.error) {
      toast.error(action.payload.data.error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    setShowModal(false);
    setShowSuccessModal(true);
    dispatch(getCompanyUsers());
  };

  return (
    <div className={styles.header}>
      <button
        className={cn("button button-small", styles.shieldbutton)}
        onClick={() => setSecurityModal(true)}
      >
        <ShieldSecurityIcon /> How do we secure your key?
      </button>
      <div style={{ textAlign: "center" }}>
        You’re almost there! <br />
        Each service may have different credentials, we’ll help you find it.
      </div>
      <button
        className={cn("button button-small", styles.editbutton)}
        onClick={() => setShowModal(true)}
      >
        <EditIcon /> Need help? Invite an editor
      </button>

      <AddUserModal
        visible={showModal && !loading}
        onClose={() => setShowModal(false)}
        onAddUser={onAddUser}
        editor={true}
      />

      <Modal
        visible={showSuccessModal && !loading}
        hideCloseBtn={true}
        onClose={() => setShowSuccessModal(false)}
      >
        <div className={styles.sucessModal}>
          <div className={styles.title}>Editor invitation sent by email!</div>
          <div className={styles.message}>
            To access Metra, the editor may input credentials and complete the
            Onboarding process.
            <br />
            <br />
            Don’t worry, we will notify you when this happens.
          </div>

          <button
            className={cn("button button-stroke", styles.button)}
            onClick={() => {
              setShowSuccessModal(false);
              navigation("/");
            }}
          >
            Finish Onboarding&nbsp;
            <Icon
              name="arrow-right"
              size="20"
            />
          </button>
        </div>
      </Modal>

      <SecurityAndPrivacy
        visible={securityModal}
        onClose={() => setSecurityModal(false)}
      />
    </div>
  );
};

export default Integration;
