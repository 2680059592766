import React, { useState, useEffect, useRef, useCallback } from "react";

import cn from "classnames";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as ReportIcon } from "assets/icons/report.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";

import {
  getEmployee,
  getActivitySnapshot,
  getIntegrationStats,
  getIntegrationStat,
} from "../../slices/employeeSlice";
import { periodConverter, createScreenshotPDF } from "../../utils";

import styles from "./EmployeeDetails.module.sass";
import GenerateReport from "./GenerateReport";
import IntegrationMetrics from "./IntegrationMetrics";
import LastEntries from "./LastEntries";
import Notes from "./Notes";
import Overview from "./Overview";
import Profile from "./Profile";
import Report from "./Report";

const EmployeeDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [modifiedIntegrationStats, setModifiedIntegrationStats] = useState();
  const [visibleReport, setVisibleReport] = useState(false);
  const { employee } = useSelector((state) => state.employee);

  const { integrationStats, integrationStartDate, integrationEndDate } =
    useSelector((state) => state.employee);
  const { columnNames, statusColors } = useSelector((state) => state.app);

  const toggleReport = () =>
    setVisibleReport((prevVisibility) => !prevVisibility);

  const employeeDetailsRef = useRef(null);
  const onGenerateReport = async () => {
    if (employeeDetailsRef.current) {
      try {
        await createScreenshotPDF(
          employeeDetailsRef,
          employee,
          integrationStartDate,
          integrationEndDate
        );
      } catch (error) {
        toast.error("PDF generation failed");
      }
    }
  };

  useEffect(() => {
    const stats = [];
    integrationStats?.forEach((integration) => {
      const stat = {
        id: integration.id,
        title: integration.title,
        data: Object.keys(integration?.stats.sum?.[0] || {}).map((s, i) => ({
          title: columnNames[s],
          statusColor: statusColors[Math.floor(Math.random() * 5)],
          statusText: integration?.stats.sum[0][s],
          value: integration?.stats.percentage[0][s],
        })),
      };
      stats.push(stat);
    });
    setModifiedIntegrationStats(stats);
  }, [columnNames, integrationStats, statusColors]);

  useEffect(() => {
    dispatch(getEmployee(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (integrationStartDate && integrationEndDate) {
      dispatch(
        getActivitySnapshot({
          id: params.id,
          startDate: format(integrationStartDate, "yyyy-MM-dd"),
          endDate: format(integrationEndDate, "yyyy-MM-dd"),
        })
      );
      dispatch(
        getIntegrationStats({
          id: params.id,
          startDate: format(integrationStartDate, "yyyy-MM-dd"),
          endDate: format(integrationEndDate, "yyyy-MM-dd"),
        })
      );
    } else {
      dispatch(getActivitySnapshot({ id: params.id, period: 7 }));
      dispatch(getIntegrationStats({ id: params.id }));
    }
  }, [integrationStartDate, integrationEndDate, dispatch, params.id]);

  const handleStatPeriod = useCallback(
    (intergartionId, period) => {
      dispatch(
        getIntegrationStat({
          id: params.id,
          integration: intergartionId,
          period: periodConverter(period),
        })
      );
    },
    [dispatch, params.id]
  );

  const handleSnapshotPeriod = useCallback(
    (period) => {
      dispatch(
        getActivitySnapshot({ id: params.id, period: periodConverter(period) })
      );
    },
    [dispatch, params.id]
  );

  return (
    <>
      <div className={styles.settings}>
        <button
          className={cn("button button-stroke", styles.generateReportBtn)}
          onClick={toggleReport}
        >
          <span>
            <ReportIcon />
          </span>
          <span>Generate Report</span>
        </button>
        <Link
          to={`/settings/${params.id}`}
          className={cn("button", styles.settingBtn)}
        >
          <span>
            <SettingsIcon />
          </span>
          <span>Employee Settings</span>
        </Link>
      </div>

      <div>
        <div className={styles.row}>
          <Profile />
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Overview
              className={styles.card}
              snapshotPeriod={handleSnapshotPeriod}
            />
          </div>
          <div className={styles.col}>
            <LastEntries
              className={styles.card}
              viewAll={true}
            />
          </div>
        </div>
        {modifiedIntegrationStats?.map((integration, index) =>
          index === 0 ? (
            <div
              className={styles.row}
              key={integration.id}
            >
              <div className={styles.col}>
                <IntegrationMetrics
                  integration={integration}
                  statPeriod={handleStatPeriod}
                />
              </div>
              <div className={styles.col}>
                <Notes viewAll={true} />
              </div>
            </div>
          ) : (
            <div className={styles.row}>
              <IntegrationMetrics
                integration={integration}
                statPeriod={handleStatPeriod}
              />
            </div>
          )
        )}
      </div>

      <GenerateReport
        visible={visibleReport}
        onClose={toggleReport}
        onConfirm={onGenerateReport}
      />

      {visibleReport && (
        <div className={styles.hide}>
          <Report
            ref={employeeDetailsRef}
            modifiedIntegrationStats={modifiedIntegrationStats}
          />
        </div>
      )}
    </>
  );
};

export default EmployeeDetails;
