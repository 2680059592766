import cn from "classnames";
import { useSelector } from "react-redux";

import Balance from "../Balance";

import styles from "./Indicator.module.sass";

const Indicator = ({ value }) => {
  const { selectedFilterInterval } = useSelector((state) => state.app);

  const intervalLabel = {
    "Last 7 days": "last 7 days",
    "Last 30 days": "last 30 days",
    "Last 365 days": "last 365 days",
  }[selectedFilterInterval];

  return (
    <div
      className={cn(styles.indicator, {
        [styles.negative]: value <= -10,
        [styles.midNegative]: value > -10 && value <= 0,
        [styles.positive]: value > 0,
      })}
    >
      <Balance
        className={styles.balance}
        value={value}
        showValue
      />
      <span>{intervalLabel}</span>
    </div>
  );
};

export default Indicator;
