import axios from "axios";

const githubDefaultHeaders = {
  Accept: "application/vnd.github.v3+json",
};
const GITHUB_URL_PREFIX = "https://github.com/";
function getDefaultHeaders(repoConfig) {
  return {
    ...githubDefaultHeaders,
    Authorization: `token ${repoConfig.connector.token}`,
  };
}
function getRepoFromUrl(repoUrl) {
  if (!repoUrl.startsWith(GITHUB_URL_PREFIX)) {
    throw new Error(`Invalid Github URL: ${repoUrl}`);
  }
  return repoUrl.slice(GITHUB_URL_PREFIX.length).split("/")[1];
}
function getOwnerFromUrl(repoUrl) {
  if (!repoUrl.startsWith(GITHUB_URL_PREFIX)) {
    throw new Error(`Invalid Github URL: ${repoUrl}`);
  }

  return repoUrl.slice(GITHUB_URL_PREFIX.length).split("/")[0];
}
function repoUrl(org_name) {
  return `https://api.github.com/orgs/${org_name}`;
}

export default function validateGithub(repoConfig) {
  return axios.create({
    baseURL: `${repoUrl(repoConfig.org_name)}`,
    headers: getDefaultHeaders(repoConfig),
  });
}