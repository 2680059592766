import { useState } from "react";

import cn from "classnames";
import Joyride from "react-joyride";
import { useDispatch } from "react-redux";

import { ReactComponent as DoneIcon } from "assets/icons/done-tick.svg";
import { Modal, Icon } from "components";
import { confirmOnboarding, changeOnboardingInProgress } from "slices/appSlice";

import styles from "./Onboarding.module.sass";

import { Tooltip, steps } from "./";

const Onboarding = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { step, status } = data;
    dispatch(changeOnboardingInProgress(step.target));

    if (status === "finished" || status === "skipped") {
      window.localStorage.setItem("onboarded", "true");
      dispatch(confirmOnboarding());
      dispatch(changeOnboardingInProgress(false));
      setVisible(true);
    }
  };

  return (
    <>
      <Joyride
        steps={steps}
        continuous={true}
        showProgress={false}
        showSkipButton={true}
        run={
          window.localStorage.getItem("onboarded") === "false" ? true : false
        }
        callback={handleJoyrideCallback}
        tooltipComponent={({
          index: stepIndex,
          size,
          continuous,
          step,
          backProps,
          primaryProps,
          skipProps,
          tooltipProps,
          ...rest
        }) => (
          <Tooltip
            continuous={continuous}
            step={step}
            stepIndex={stepIndex}
            backProps={backProps}
            primaryProps={primaryProps}
            skipProps={skipProps}
            tooltipProps={tooltipProps}
            size={size}
            {...rest}
          />
        )}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <div className={styles.welcome}>
          <div className={styles.doneIcon}>
            <DoneIcon />
          </div>
          <div className={styles.title}>Welcome to Metra!</div>
          <div className={styles.message}>
            You're now ready to get the best out of your employees.
          </div>

          <button
            className={cn("button button-small", styles.button)}
            onClick={() => setVisible(false)}
          >
            Go to Homepage &nbsp;
            <Icon
              name="arrow-right"
              size="20"
            />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Onboarding;
