import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as HideIcon } from "assets/icons/hide.svg";
import { ReactComponent as ShowIcon } from "assets/icons/show.svg";
import { Dropdown, Tag } from "components";
import {
  getAssignedEmployeesByTag,
  getEmployeeTags,
  updateEmployeeTag,
  deleteTag,
} from "slices/tagSlice";
import { formatDateUK, statusConverter, statusToNumber } from "utils";

import DeleteTagModal from "../../DeleteModal";

import AddNewEmployee from "./AddNewEmployee";
import AssignedEmployeeRow from "./AssignedEmployeeRow";
import styles from "./Row.module.sass";

const activeDropdownOptions = ["active", "inactive", "delete tag"];

const Row = ({ item, activeId, loading }) => {
  const [status, setStatus] = useState(item.status);
  const [showEmployees, setShowEmployees] = useState(false);
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false);

  const dispatch = useDispatch();
  const { assignedEmployee } = useSelector((state) => state.tag);

  useEffect(() => {
    setStatus(item.status);
  }, [item.status]);

  const handleStatusChange = (value) => {
    if (value === "delete tag") {
      setShowDeleteTagModal(true);
    } else {
      dispatch(
        updateEmployeeTag({
          id: item.id,
          data: { status: statusToNumber(value) },
        })
      );
      setStatus(statusToNumber(value));
    }
  };

  const confirmRemoveTag = () => {
    dispatch(deleteTag(item.id)).then(() => {
      dispatch(getEmployeeTags());
    });

    setShowDeleteTagModal(false);
  };

  return (
    <>
      <div
        className={cn(styles.row, {
          [styles.selected]: activeId === item.id,
          [styles.loading]: loading,
        })}
      >
        <Tag
          name={item.name}
          redirect={`/tags/${item.name}`}
          className={styles.col}
          variant={3}
        />
        <div
          className={cn(styles.col, styles.employee, {
            [styles.show]: showEmployees,
          })}
          onClick={() => {
            dispatch(getAssignedEmployeesByTag(item));
            setShowEmployees((showEmployees) => !showEmployees);
          }}
        >
          {item?.count} Employees &nbsp;
          {showEmployees ? <ShowIcon /> : <HideIcon />}
        </div>

        <div className={cn(styles.col, styles.date)}>
          {formatDateUK(item?.created_at)}
        </div>
        <div className={cn(styles.col, styles.date)}>
          {item.last_assigned ? formatDateUK(item.last_assigned) : "-"}
        </div>
        <div className={styles.col}>
          <div className={cn(styles.dropdown)}>
            <Dropdown
              className={styles.dropdownElement}
              value={statusConverter(status)}
              setValue={handleStatusChange}
              options={activeDropdownOptions}
              small
              transparent
            />
          </div>
        </div>
      </div>

      {showEmployees && (
        <>
          {assignedEmployee[item.id]?.map((employee, index) => (
            <AssignedEmployeeRow
              key={index}
              tag={item}
              employee={employee}
            />
          ))}
          <AddNewEmployee item={item} />
        </>
      )}
      <DeleteTagModal
        visible={showDeleteTagModal}
        onConfirm={() => confirmRemoveTag(showDeleteTagModal)}
        onClose={() => setShowDeleteTagModal(null)}
        name={showDeleteTagModal?.name}
      />
    </>
  );
};

export default Row;
