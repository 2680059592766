import React, { useState, useEffect, useCallback } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Actions, Card, Dropdown } from "components";
import {
  getAllNotifications,
  readNotification,
} from "slices/notificationSlice";

import Item from "./Item";
import styles from "./List.module.sass";

const List = ({ className, selectedFilters }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMarkAsRead = useCallback(
    (id = "all") => {
      dispatch(readNotification({ id: id }));
    },
    [dispatch]
  );

  const handleGoSetting = useCallback(() => {
    navigate("/settings");
  }, [navigate]);

  const { notifications } = useSelector((state) => state.notification);

  const [navigation] = useState(["All", "Unread", "Read"]);
  const [activeTab, setActiveTab] = useState("All");
  const [notificationsFetched, setNotificationsFetched] = useState(false);
  const [actions, setActions] = useState([
    {
      title: "Go to settings",
      icon: "setting",
      action: () => handleGoSetting(),
    },
  ]);

  useEffect(() => {
    if (notifications.length > 0) {
      setActions([
        {
          title: "Mark as read",
          icon: "check",
          action: () => handleMarkAsRead(),
        },
        {
          title: "Go to settings",
          icon: "setting",
          action: () => handleGoSetting(),
        },
      ]);
    }
  }, [handleGoSetting, handleMarkAsRead, notifications]);

  useEffect(() => {
    if (!notificationsFetched) {
      setNotificationsFetched(true);
      dispatch(getAllNotifications({}));
    } else {
      setActiveTab("All");
    }
  }, [dispatch, notifications, notificationsFetched]);

  const filterNotifications = (notification) => {
    const category = notification.category.toLowerCase();
    return selectedFilters.some((filter) =>
      filter
        .toLowerCase()
        .split(/\s+/)
        .some((word) => category.includes(word))
    );
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title={activeTab}
      classTitle={cn("title-red", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <div className={cn(styles.dropdown)}>
            <Dropdown
              classDropdownHead={styles.dropdownHead}
              value={activeTab}
              setValue={setActiveTab}
              options={navigation}
              small
            />
          </div>
          <Actions
            className={styles.actions}
            classActionsHead={styles.actionsHead}
            items={actions}
          />
        </>
      }
    >
      <div className={styles.notifications}>
        <div className={styles.list}>
          {notifications.length > 0 ? (
            notifications
              .filter(
                (notification) =>
                  activeTab === "Read"
                    ? !notification.unread
                    : activeTab === "Unread"
                      ? notification.unread
                      : true // return all notifications because the active tab is "All"
              )
              .filter(filterNotifications)
              .map((x, index) => (
                <Item
                  className={cn(styles.item, className)}
                  item={x}
                  key={index}
                  handleMarkAsRead={handleMarkAsRead}
                />
              ))
          ) : (
            <p>There are no notifications available.</p>
          )}
        </div>
      </div>
    </Card>
  );
};

export default List;
