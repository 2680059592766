import { useState } from "react";

import cn from "classnames";

import styles from "./Accordion.module.sass";
import AccordionItem from "./AccordionItem";

const Accordion = ({ questions, itemClassName, secondary, ...rest }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div {...rest}>
      {questions.map((item, index) => (
        <div key={index}>
          <AccordionItem
            question={item.question}
            answer={item.answer}
            isOpen={activeIndex === index}
            onClick={() => handleItemClick(index)}
            className={itemClassName}
            secondary={secondary}
          />
        </div>
      ))}
    </div>
  );
};

const AccordionTitle = ({ title, icon, className, ...rest }) => {
  return (
    <div className={cn(styles.title, className)} {...rest}>
      <div className={styles.iconWrapper}>
        <span className={styles.icon}>{icon}</span>
      </div>
      {title}
    </div>
  );
};

Accordion.Title = AccordionTitle;

export default Accordion;
