import cn from "classnames";

import { ReactComponent as AddCircled } from "assets/icons/add-circled.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { Icon } from "components";

import styles from "./SectionTagCard.module.sass";

const SectionTagCard = ({ section, added = false, onAdd, onRemove }) => {
  let totalIntegrations = 0;

  if (section?.users?.length > 0) {
    for (const [key, value] of Object.entries(section.users[0])) {
      if (key.indexOf("_integrated")) {
        if (value == true) {
          totalIntegrations++;
        }
      }
    }
  }

  return (
    <div className={styles.section}>
      <div className={styles.head}>
        <div className={cn("h5", styles.name)}>{section?.name}</div>

        <div className={styles.stat}>
          <ProfileIcon /> &nbsp;
          {section?.users?.length} employees
        </div>
      </div>
      <p className={styles.description}>{section.description}</p>
      <div className={styles.foot}>
        <div className={styles.metrics}>
          {"</> "}
          {totalIntegrations}{" "}
          {totalIntegrations > 1 ? "Integrations" : "Integration"}
        </div>

        {added ? (
          <button
            className={cn("button button-small", styles.button)}
            onClick={() => onRemove(section)}
          >
            <Icon
              name="check"
              size="24"
            />
            Employee Added
          </button>
        ) : (
          <button
            className={cn(
              "button button-small button-stroke-blue",
              styles.button
            )}
            onClick={() => onAdd(section)}
          >
            <AddCircled />
            Add Employee to the Team
          </button>
        )}
      </div>
    </div>
  );
};

export default SectionTagCard;
