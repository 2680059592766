import React, { useCallback, useEffect } from "react";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import cn from "classnames";
import { createPortal } from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";

import Icon from "../Icon";

import styles from "./Modal.module.sass";

const Modal = ({
  modalClassName,
  outerClassName,
  visible,
  onClose,
  hideCloseBtn,
  children,
}) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal");
      disableBodyScroll(target);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [visible]);

  return createPortal(
    visible && (
      <div
        id="modal"
        className={cn(styles.modal, modalClassName)}
      >
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler onOutsideClick={onClose}>
            {children}
            {!hideCloseBtn && (
              <button
                className={styles.close}
                onClick={onClose}
              >
                <Icon
                  name="close"
                  size="20"
                />
              </button>
            )}
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
