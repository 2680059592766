import React, { useState } from "react";

import Filters from "./Filters";
import List from "./List";
import styles from "./Notification.module.sass";

const filters = [
  "Employee Notifications",
  "Integration Notifications",
  "Team Notifications",
  "Tags Notifications",
];

const Notification = () => {
  const [selectedFilters, setSelectedFilters] = useState(filters);

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <List
          className={styles.card}
          selectedFilters={selectedFilters}
        />
      </div>
      <div className={styles.col}>
        <Filters
          className={styles.filters}
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </div>
    </div>
  );
};

export default Notification;
