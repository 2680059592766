import { format } from "date-fns";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
};

export const statusConverter = (status) => {
  if (status === 0) {
    return "active";
  } else if (status === 86) {
    return "inactive";
  } else if (status === 200) {
    return "archived";
  }
  return "unknown";
};

export const statusToNumber = (status) => {
  if (status === "active") {
    return 0;
  } else if (status === "inactive") {
    return 86;
  } else if (status === "archived") {
    return 200;
  }
  return 0;
};

export const onlyUniqueByObject = (array, byKey) => {
  const arrayUniqueByKey = [
    ...new Map(array?.map((item) => [item[byKey], item]))?.values(),
  ];

  return arrayUniqueByKey;
};

export const capitalize = (string) => {
  if (!string) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const periodConverter = (period) => {
  if (!period || period === "Last 7 days") {
    return 7;
  }
  return period === "Last 30 days" ? 30 : 365;
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

export const formatDateUK = (date) => {
  return new Date(date).toLocaleDateString("en-UK");
};

function isToday(date) {
  let resp = false;
  try {
    const today = new Date();
    if (today.toDateString() === date.toDateString()) {
      resp = true;
    }
  } catch (err) {
    resp = false;
  }
  return resp;
}

function lastEntriesHelper(key, value) {
  if (key === "num_emails_sent") {
    return {
      action: `Sent ${value} mail${pulurizer(value)}`,
      url: "/images/integration/google.png",
    };
  } else if (key === "num_spam_emails_received") {
    return {
      action: `Received ${value} spam mail${pulurizer(value)}`,
      url: "/images/integration/google.png",
    };
  } else if (key === "num_emails_received") {
    return {
      action: `Received ${value} mail${pulurizer(value)}`,
      url: "/images/integration/google.png",
    };
  } else if (key === "days_active") {
    return {
      action: `Active`,
      url: "/images/integration/slack.png",
    };
  } else if (key === "messages_posted") {
    return {
      action: `Posted ${value} message${pulurizer(value)}`,
      url: "/images/integration/slack.png",
    };
  } else if (key === "reactions_added") {
    return {
      action: `Added ${value} reaction${pulurizer(value)}`,
      url: "/images/integration/slack.png",
    };
  } else if (key === "commits") {
    return {
      action: `Commited ${value} time${pulurizer(value)}`,
      url: "/images/integration/github.png",
    };
  } else if (key === "change_requests") {
    return {
      action: `Requested ${value} change${pulurizer(value)}`,
      url: "/images/integration/github.png",
    };
  } else if (key === "approvals_given") {
    return {
      action: `Gave ${value} approve${pulurizer(value)}`,
      url: "/images/integration/github.png",
    };
  } else if (key === "comments_received") {
    return {
      action: `Received ${value} review comment${pulurizer(value)}`,
      url: "/images/integration/github.png",
    };
  } else if (key === "rejections_given") {
    return {
      action: `Gave ${value} rejection${pulurizer(value)}`,
      url: "/images/integration/github.png",
    };
  } else if (key === "approvals_received") {
    return {
      action: `Received ${value} approval${pulurizer(value)}`,
      url: "/images/integration/github.png",
    };
  } else if (key === "rejections_received") {
    return {
      action: `Received ${value} rejection${pulurizer(value)}`,
      url: "/images/integration/github.png",
    };
  } else if (key === "comments_to_others") {
    return {
      action: `Commented ${value} time${pulurizer(value)} to others`,
      url: "/images/integration/github.png",
    };
  } else if (key === "comments") {
    return {
      action: `Left ${value} comment${pulurizer(value)}`,
      url: "/images/integration/jira.png",
    };
  } else if (key === "time_spent") {
    return {
      action: `${value} minutes${pulurizer(value)}`,
      url: "/images/integration/jira.png",
    };
  } else if (key === "tickets_created") {
    return {
      action: `Created ${value} ticket${pulurizer(value)}`,
      url: "/images/integration/jira.png",
    };
  } else if (key === "tickets_started") {
    return {
      action: `Started to work on ${value} ticket${pulurizer(value)}`,
      url: "/images/integration/jira.png",
    };
  } else if (key === "tickets_completed") {
    return {
      action: `Completed working on ${value} ticket${pulurizer(value)}`,
      url: "/images/integration/jira.png",
    };
  } else if (key === "edits") {
    return {
      action: `Left ${value} page edit${pulurizer(value)}`,
      url: "/images/integration/confluence.png",
    };
  } else if (key === "comments_c") {
    return {
      action: `Left ${value} comment${pulurizer(value)} on pages`,
      url: "/images/integration/confluence.png",
    };
  } else if (key === "creations") {
    return {
      action: `Created ${value} page${pulurizer(value)}`,
      url: "/images/integration/confluence.png",
    };
  } else if (key === "publishes") {
    return {
      action: `Published ${value} page${pulurizer(value)}`,
      url: "/images/integration/confluence.png",
    };
  } else if (key === "meetings_attended") {
    return {
      action: `Attended ${value} meeting${pulurizer(value)}`,
      url: "/images/integration/zoom.png",
    };
  } else if (key === "messages_sent") {
    return {
      action: `Sent ${value} message${pulurizer(value)}`,
      url: "/images/integration/zoom.png",
    };
  } else if (key === "meetings_hosted") {
    return {
      action: `Hosted ${value} meeting${pulurizer(value)}`,
      url: "/images/integration/zoom.png",
    };
  } else if (key === "minutes_spent") {
    return {
      action: `Spent ${value} minutes${pulurizer(value)} in meeting`,
      url: "/images/integration/zoom.png",
    };
  } else if (key === "hours_logged") {
    return {
      action: `Logged ${value} hour${pulurizer(value)}`,
      url: "/images/integration/jira.png",
    };
  } else if (key === "files_shared") {
    return {
      action: `Shared ${value} file${pulurizer(value)}`,
      url: "/images/integration/zoom.png",
    };
  } else if (key === "organized_meetings") {
    return {
      action: `HAd ${value} organized meeting${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "attended_meetings") {
    return {
      action: `Attended ${value} meeting${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "adhoc_organized_meetings") {
    return {
      action: `Had ${value} adhoc organized meeting${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "adhoc_attended_meetings") {
    return {
      action: `Attended ${value} adhoc meetings${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "scheduled_ontime_organized_meetings") {
    return {
      action: `Had ${value} scheduled ontime organized meeting${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "scheduled_ontime_attended_meetings") {
    return {
      action: `Attended ${value} scheduled ontime meeting${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "scheduled_recurring_organized_meetings") {
    return {
      action: `Had ${value} scheduled recurring organized meeting${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "scheduled_recurring_attended_meetings") {
    return {
      action: `Attended ${value} scheduled recurring meetings${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "urgent_messages") {
    return {
      action: `Had ${value} urgent message${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "post_messages") {
    return {
      action: `Posted ${value} message${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "reply_messages") {
    return {
      action: `Replied ${value} message${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "audio_duration_seconds") {
    return {
      action: `Spent ${value} second${pulurizer(value)} in audio call`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "video_duration_seconds") {
    return {
      action: `Spent ${value} second${pulurizer(value)} in video call`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "screenshare_duration_seconds") {
    return {
      action: `Shared screen ${value} second${pulurizer(value)}`,
      url: "/images/integration/teams.png",
    };
  } else if (key === "sent") {
    return {
      action: `Sent ${value} mail${pulurizer(value)}`,
      url: "/images/integration/exchangeonline.png",
    };
  } else if (key === "received") {
    return {
      action: `Received ${value} mail${pulurizer(value)}`,
      url: "/images/integration/exchangeonline.png",
    };
  } else if (key === "read") {
    return {
      action: `Read ${value} mail${pulurizer(value)}`,
      url: "/images/integration/exchangeonline.png",
    };
  } else if (key === "meetings_created") {
    return {
      action: `Created ${value} meeting${pulurizer(value)}`,
      url: "/images/integration/exchangeonline.png",
    };
  } else if (key === "meetings_interacted") {
    return {
      action: `Interacted ${value} meeting${pulurizer(value)}`,
      url: "/images/integration/exchangeonline.png",
    };
  } else if (key === "sf_meetings") {
    return {
      action: `Attended ${value} meeting${pulurizer(value)}`,
      url: "/images/integration/salesforce.png",
    };
  } else if (key === "sf_calls") {
    return {
      action: `Interacted ${value} call${pulurizer(value)}`,
      url: "/images/integration/salesforce.png",
    };
  } else if (key === "sf_emails") {
    return {
      action: `Sent ${value} mail${pulurizer(value)}`,
      url: "/images/integration/salesforce.png",
    };
  } else if (key === "sf_quotes") {
    return {
      action: `Created ${value} quote${pulurizer(value)}`,
      url: "/images/integration/salesforce.png",
    };
  } else if (key === "sf_others") {
    return {
      action: `${value} other activities`,
      url: "/images/integration/salesforce.png",
    };
  } else if (key === "sf_total_opportunities") {
    return {
      action: `Create ${value} opportunities`,
      url: "/images/integration/salesforce.png",
    };
  } else if (key === "sf_won_opportunities") {
    return {
      action: `Won ${value} opportunities`,
      url: "/images/integration/salesforce.png",
    };
  } else if (key === "sf_converted_leads") {
    return {
      action: `Converted ${value} lead${pulurizer(value)}`,
      url: "/images/integration/salesforce.png",
    };
  } else {
    return {
      action: null,
      url: null,
    };
  }
}
export const pulurizer = (value) => {
  return value && value > 1 ? "s" : "";
};

export const toMinute = (value) => {
  return Math.round(parseInt(value) / 6) / 10;
};

export const lastEntryParser = (entries) => {
  const lastEntries = [];
  Object.keys(entries).forEach((key) => {
    Object.keys(entries[key]).forEach((k) => {
      let humanEntry = lastEntriesHelper(k, entries[key][k]);
      if (entries[key][k] > 0) {
        lastEntries.push({
          id: entries[key].id,
          action: humanEntry.action,
          avatar_url: humanEntry.url,
          time: entries[key].name,
          isToday: isToday(new Date(entries[key].name)) ? "Today" : "All",
        });
      }
    });
  });
  return lastEntries;
};

export const integrationLastEntryParser = (entries) => {
  const lastEntries = [];
  Object.keys(entries).forEach((key) => {
    Object.keys(entries[key]).forEach((k) => {
      let humanEntry = lastEntriesHelper(k, entries[key][k]);
      if (entries[key][k] > 0 && humanEntry.action) {
        lastEntries.push({
          id: entries[key].person_id,
          action: humanEntry.action,
          avatar_url: entries[key].avatar_url,
          name: entries[key].person_name,
          emails: entries[key].emails_array,
          time: entries[key].name,
          isToday: isToday(new Date(entries[key].name)) ? "Today" : "All",
        });
      }
    });
  });
  return lastEntries;
};

export const normalizeDate = (dateToFormat) => {
  let d = new Date(dateToFormat);
  if (Object.prototype.toString.call(d) === "[object Date]") {
    if (isNaN(d)) {
      return "-";
    } else {
      let dformat = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/");
      return dformat;
    }
  } else {
    return "-";
  }
};

export const isBottomReached = () => {
  return (
    window.innerHeight + document.documentElement.scrollTop >=
    document.documentElement.offsetHeight - 100
  );
};

export const getEmployeeActivities = (employee) => {
  const activities = { ...employee };
  delete activities["id"];
  delete activities["name"];
  delete activities["avatar_url"];
  delete activities["total"];

  return activities;
};

export const refactorName = (name) => {
  return name.split("_").slice(1).join(" ");
};

export const textToArrayFormatter = (str) => {
  if (Array.isArray(str)) {
    return str?.length > 0 ? str?.join(",") : "";
  } else {
    return str?.replace(/[^a-zA-Z0-9\s,@.(),:;<>[\]]+/g, "") || "";
  }
};

export const createScreenshotPDF = async (
  elRef,
  employee,
  startDate,
  endDate
) => {
  const MARGIN = 0;
  const width = elRef.current?.offsetWidth || 500;
  const height = elRef.current?.offsetHeight || 1000;

  if (!elRef.current) throw new Error("Element not found");

  try {
    const canvas = await html2canvas(elRef.current, {
      scrollX: 0, // scroll to X origin
      scrollY: 0, // scroll to Y origin
      scale: 5, // 5 is quality scale
    });

    const img = canvas.toDataURL("image/jpeg", 5); // 5 is quality scale

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px", // set pixel as unit
      format: [width + MARGIN * 2, height + MARGIN * 2],
    });

    // add canvas as an image
    doc.addImage(img, "JPEG", MARGIN, MARGIN, width, height);

    // save the content to screenshot.pdf
    doc.save(
      `${employee.name} Report (${format(startDate, "dd MMM yyyy")} - ${format(
        endDate,
        "dd MMM yyyy"
      )}).pdf`
    );
  } catch (err) {
    console.log(err);
    throw new Error("PDF generation failed");
  }
};

export const countActivities = (activities) => {
  return Object.keys(activities).reduce((acc, key) => {
    if (key.includes("sum")) {
      acc += activities[key];
    }
    return acc;
  }, 0);
};

export const getEmployeeIntegrations = (employee) =>
  Object.keys(employee)?.reduce((acc, key) => {
    if (key.includes("_sum")) {
      const integrationName = key.split("_")[0];
      if (!acc.includes(integrationName)) {
        acc.push(integrationName);
      }
    }
    return acc;
  }, []);

export const getTeamIntegrations = (team) => {
  return Object.keys(team)
    ?.map((key) => {
      if (key === "date") return null;
      return { name: key.split("_")[0] };
    })
    .filter((x) => x);
};

export const getActivitiesSumByIntegration = (
  activities,
  selectedIntegration
) => {
  return activities?.data?.map((activity) => {
    const newActivity = { ...activity };
    const sum = Object.keys(activity).reduce((acc, key) => {
      if (
        key.includes("_integrated") &&
        (!selectedIntegration || key.includes(selectedIntegration))
      ) {
        acc += activity[key];
      }
      return acc;
    }, 0);
    newActivity.activities = sum;
    newActivity.name = format(new Date(newActivity.date), "MMM dd");
    return newActivity;
  });
};

export const fillArray = (arr, length) => {
  const newArr = [...arr];
  while (newArr.length < length) {
    newArr.push(null);
  }
  return newArr;
};
