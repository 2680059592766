import React, { useState } from "react";

import cn from "classnames";

import Actions from "../../Actions";
import Modal from "../../Modal";
import ModalEmployee from "../../ModalEmployee";
import Schedule from "../../Schedule";

import styles from "./Row.module.sass";

const Row = ({ item, columns, tagsRow }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [visibleModalIntegration, setVisibleModalIntegration] = useState(false);
  const [visibleModalSchedule, setVisibleModalSchedule] = useState(false);

  const actions = [
    {
      title: "Schedule integration",
      icon: "calendar",
      action: () => setVisibleModalSchedule(true),
    },
    {
      title: "Edit title & description",
      icon: "edit",
      action: () => console.log("Edit title & description"),
    },
    {
      title: "Delete forever",
      icon: "trash",
      action: () => console.log("Delete forever"),
    },
  ];

  return (
    <>
      <tr className={styles.row}>
        {columns.map((column, index) => (
          <td
            className={cn(styles.col, { [styles.tagsRow]: tagsRow })}
            key={index}
          >
            {column.render &&
              column.render(
                typeof item[column.dataIndex] !== "undefined"
                  ? item[column.dataIndex]
                  : item
              )}
          </td>
        ))}
      </tr>
      {/* <Actions
        className={styles.actions}
        classActionsHead={styles.actionsHead}
        items={actions}
      /> */}
      <ModalEmployee
        visible={visibleModalIntegration}
        onClose={() => setVisibleModalIntegration(false)}
      />
      <Modal
        visible={visibleModalSchedule}
        onClose={() => setVisibleModalSchedule(false)}
      >
        <Schedule
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Modal>
    </>
  );
};

export default Row;
