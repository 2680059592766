import React from "react";

import cn from "classnames";
import { Link } from "react-router-dom";

import Row from "./Row";
import styles from "./Table.module.sass";

const Table = ({ className, items, viewAll, id }) => {
  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.table)}>
        {items &&
          items?.map((x, index) => (
            <Row
              item={x}
              key={index}
            />
          ))}
      </div>
      <div className={styles.foot}>
        {items && items.length > 0 && viewAll && (
          <Link
            className={cn("button-stroke button-small", styles.button)}
            to={`/employee/entries/${id}`}
          >
            <span>See details</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Table;
