import { useState } from "react";

import cn from "classnames";
import { Link } from "react-router-dom";

import { Icon, Modal, TextInput, Accordion } from "components";

import MetricsFAQModal from "../MetricsFAQModal";
import styles from "./MetricsModal.module.sass";

const MetricsModal = ({ isOpen, onClose, onOpen, integration, icon }) => {
  const [search, setSearch] = useState("");
  const [metricsFAQ, setMetricsFAQ] = useState(false);
  const metrics = integration.metrics.split(",");
  const integrationQuestions = metrics.reduce((acc, metric) => {
    acc.push({
      question: metric,
      answer: `${metric} is a metric that is used to measure the performance of ${metric}.`,
    });
    return acc;
  }, []);

  return (
    <>
      <Modal
        visible={isOpen}
        onClose={onClose}
        outerClassName={styles.modal}
        title="Metrics"
        className="modal-metrics"
      >
        <div className={styles.title}>
          <img width={36} src={icon} alt={integration.name} />
          {integration.name}
        </div>
        <div className={styles.subtitle}>
          <span>
            Select which metrics you’d like to pull from your Integration
          </span>
          <Link
            to="/"
            className={cn(
              `button button-small button-transparent ${styles.button}`
            )}
            onClick={(e) => {
              e.preventDefault();
              setMetricsFAQ(true);
              setTimeout(() => onClose(), 500);
            }}
          >
            Need help? See FAQ
            <Icon name="arrow-right" />
          </Link>
        </div>

        <TextInput
          classInput={styles.search}
          className={styles.searchWrapper}
          placeholder="Search by employee"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          type="text"
          name="search"
          icon="search"
        />

        <div className={styles.metricsNumber}>
          Available metrics ({metrics.length})
        </div>
        <Accordion
          questions={integrationQuestions}
          className={styles.accordion}
          itemClassName={styles.accordionItem}
          secondary
        />

      </Modal>
      <MetricsFAQModal
        integration={integration}
        icon={icon}
        isOpen={metricsFAQ}
        onClose={() => {
          onOpen()
          setTimeout(() => setMetricsFAQ(false), 500);
        }}
      />
    </>
  );
};

export default MetricsModal;
