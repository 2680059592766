import React, { useState } from "react";

import cn from "classnames";

import { Icon } from "components";

import AddTagToEmployeeModal from "../../AddTagToEmployeeModal";

import styles from "./Row.module.sass";

const AddNewEmployee = ({ item }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={cn(styles.row, styles.assignedEmployes)}>
      <div className={styles.col}></div>
      <div className={cn(styles.col)}>
        <button
          className={cn("button button-small", styles.button)}
          onClick={() => setShowModal(true)}
        >
          <Icon
            name="add"
            size="24"
          />
          <span>Add new Employee</span>
        </button>
      </div>
      <div className={styles.col}></div>
      <div className={styles.col}></div>
      <div className={styles.col}></div>

      {showModal && (
        <AddTagToEmployeeModal
          item={item}
          visible={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default AddNewEmployee;
