import cn from "classnames";

import { Icon } from "components";

import styles from "./Stepper.module.sass";

const Stepper = ({
  steps,
  step = 1,
  footer,
  onNext,
  onBack,
  onFinish,
  lastStepContent,
  disableNext,
}) => (
  <>
    <div className={styles.stepper}>
      {steps.map((item, index) => (
        <div
          key={index}
          className={cn(styles.step, { [styles.active]: index + 1 === step })}
        >
          <div className={styles.stepName}>{`${index + 1}. ${item.name}`}</div>
        </div>
      ))}
    </div>
    {steps[step - 1].component || null}
    {footer && (
      <div className={styles.footer}>
        <button
          className={cn("button button-small button-stroke", {
            [styles.hide]: step === 1,
          })}
          onClick={onBack}
        >
          <Icon
            name="arrow-left"
            size="20"
          />
          Back
        </button>

        <button
          className={cn("button button-small", styles.button)}
          onClick={step === steps.length ? onFinish : onNext}
          disabled={disableNext}
        >
          {step === steps.length ? lastStepContent || "Finish" : "Next"} &nbsp;
          <Icon
            name="arrow-right"
            size="20"
          />
        </button>
      </div>
    )}
  </>
);

export default Stepper;
