import React, { useState, useEffect } from "react";

import cn from "classnames";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import { Loader, TextInput } from "components";
import {
  integrateTeamsIntegration,
  validateTeamsIntegration,
} from "slices/integrationSlice";

import styles from "./Integration.module.sass";
import Schedule from "./Schedule";

const Integration = ({ onClose, onboarding }) => {
  const { status, loading, error, testResponseTeams, integrating } =
    useSelector((state) => state.integration);
  const [informMessage, setInformMessage] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [values, setValues] = useState({});
  const [canProceed, setCanProceed] = useState(false);
  const calculateStartDate = new Date();
  calculateStartDate.setDate(calculateStartDate.getDate() - 14);
  const [integrationStartDate, setIntegrationStartDate] =
    useState(calculateStartDate);
  const [integrationEndDate, setIntegrationEndDate] = useState(new Date());

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleTestConnection = () => {
    dispatch(validateTeamsIntegration(values));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setInformMessage(null);
    setFormSubmitted(true);
    const formData = new FormData();
    Object.keys(values).forEach((value) => {
      formData.append(value, values[value]);
    });
    formData.append("start_date", format(integrationStartDate, "yyyy-MM-dd"));
    formData.append("end_date", format(integrationEndDate, "yyyy-MM-dd"));
    dispatch(integrateTeamsIntegration(formData));
  };

  const preventSubmit = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    setCanProceed(
      !Object.keys(values).some((val) => values[val] === "") &&
        Object.keys(values).length >= 3
    );
  }, [values]);

  useEffect(() => {
    if (formSubmitted) {
      if (error && integrating === "teams") {
        setInformMessage("Integration failed to start.");
      } else if (status === "succeeded") {
        setInformMessage(
          "Integration is in progress, we will notify you once it is complete."
        );
        setTimeout(onClose, 5000);
      }
    }
  }, [status, error, formSubmitted, onClose, integrating]);

  useEffect(() => {
    if (error && integrating !== "teams") return;
    setInformMessage(error);
    let timer = setTimeout(() => setInformMessage(null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  useEffect(() => {
    setInformMessage(testResponseTeams);
    let timer = setTimeout(() => setInformMessage(null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [testResponseTeams]);

  return (
    <div
      className={cn(styles.integration, {
        [styles.onboarding]: onboarding,
      })}
    >
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red", styles.subtitle)}>Add Account</div>
            <div className={styles.content}>
              <form onSubmit={preventSubmit}>
                <Schedule
                  integrationStartDate={integrationStartDate}
                  setIntegrationStartDate={setIntegrationStartDate}
                  integrationEndDate={integrationEndDate}
                  setIntegrationEndDate={setIntegrationEndDate}
                  onboarding={onboarding}
                />
                <TextInput
                  name="tenant_id"
                  placeholder="**** - **** - *****"
                  label="Tenant ID"
                  className={styles.input}
                  type="text"
                  onChange={handleChange}
                />
                <TextInput
                  name="application_id"
                  placeholder="**** - **** - *****"
                  label="Application ID"
                  className={styles.input}
                  type="text"
                  onChange={handleChange}
                />
                <TextInput
                  name="client_secret"
                  placeholder="**** - **** - *****"
                  label="Client Secret"
                  className={styles.input}
                  type="text"
                  onChange={handleChange}
                />
                {informMessage && (
                  <div className={styles.errorNote}>{informMessage}</div>
                )}
                <button
                  className={cn(
                    `button button-stroke-blue button-small`,
                    styles.buttonTest
                  )}
                  onClick={() => handleTestConnection()}
                >
                  Test Connection
                </button>
                <button
                  type="submit"
                  className={cn(`button button-small`, styles.button, {
                    disabled: !canProceed || loading,
                  })}
                  onClick={handleSubmit}
                >
                  {loading && integrating === "teams" && (
                    <Loader
                      className={styles.loader}
                      white
                    />
                  )}
                  <span>Integrate</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
