import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as AddCircled } from "assets/icons/add-circled.svg";
import { Modal, TableComponent, UserDetails, Icon } from "components";
import {
  getIntegrationUnassignedEmployees,
  integrateEmployee,
  removeEmployee,
  getIntegrationEmployees,
} from "slices/integrationSlice";

import styles from "./Integrate.module.sass";

// Alert: Filter is deleted to not mess out the code
// Please use VSCode Previous Revision to see the code

const getColumns = (onRemoveEmployee, onAssignEmployee, assigned) => [
  {
    title: "Name",
    key: "name",
    sort: true,
    dataIndex: "data",
    render: (data) => <UserDetails user={data} />,
  },
  {
    render: (data) =>
      assigned[data.id] ? (
        <button
          className={cn("button-stroke-red button-small", styles.button)}
          onClick={() => onRemoveEmployee(data)}
        >
          <Icon
            name="trash"
            size="20"
          />
          Deactivate Employee
        </button>
      ) : (
        <button
          className={cn(
            "button button-small button-stroke-blue",
            styles.button
          )}
          onClick={() => onAssignEmployee(data)}
        >
          <AddCircled />
          Integrate Employee
        </button>
      ),
  },
];

const Integrate = ({ className, title, integrationId }) => {
  const [visible, setVisible] = useState(false);
  const [assigned, setAssigned] = useState({});

  const dispatch = useDispatch();
  const params = useParams();
  const { integrationUnassignedEmployees } = useSelector(
    (state) => state.integration
  );

  useEffect(() => {
    dispatch(getIntegrationUnassignedEmployees(params?.integration));
  }, [dispatch, params?.integration]);

  const onRemoveEmployee = async (user) => {
    const action = await dispatch(
      removeEmployee({ integrationId, employeeId: user.id })
    );

    if (removeEmployee.fulfilled.match(action)) {
      toast.success(`Employee ${user.name} removed successfully`);
      setAssigned({ ...assigned, [user.id]: false });
    } else {
      toast.error("Something went wrong");
    }
    dispatch(
      getIntegrationEmployees({ integration: params.integration, period: 7 })
    );
  };

  const onAssignEmployee = async (user) => {
    const action = await dispatch(
      integrateEmployee({ integrationId, employeeId: user.id })
    );

    if (integrateEmployee.fulfilled.match(action)) {
      toast.success(`Employee ${user.name} integrated successfully`);
      setAssigned({ ...assigned, [user.id]: true });
    } else {
      toast.error("Something went wrong");
    }
    dispatch(
      getIntegrationEmployees({ integration: params.integration, period: 7 })
    );
  };

  return (
    <div className={cn(styles.filters, className)}>
      <button
        className={cn("button-stroke button-small", styles.button)}
        onClick={() => setVisible(true)}
      >
        <Icon
          name="plus-circle"
          size="20"
        />
        {title}
      </button>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        outerClassName={styles.modal}
      >
        <TableComponent
          columns={getColumns(onRemoveEmployee, onAssignEmployee, assigned)}
          data={integrationUnassignedEmployees}
          className={styles.table}
        />
      </Modal>
      <div
        className={styles.overlay}
        onClick={() => setVisible(false)}
      ></div>
    </div>
  );
};

export default Integrate;
