import React from "react";

import cn from "classnames";

import Avatar from "../../../../../components/Avatar";

import styles from "./Row.module.sass";

const Row = ({ item }) => {
  return (
    <>
      <div className={cn(styles.row)}>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.avatar}>
              <Avatar
                src={item.avatar_url}
                alt="Avatar"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>{item.name}</div>
              <div className={styles.title}>{item.title}</div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.time}>{item.time}</div>
        </div>
      </div>
      <div className={styles.note}>{item.note}</div>
    </>
  );
};

export default Row;
