import React from "react";

import cn from "classnames";

import styles from "./Checkbox.module.sass";

const Checkbox = ({
  className,
  classCheckboxTick,
  content,
  value,
  onChange,
  onClick,
  reverse,
  type = "checkbox",
  disabled,
}) => {
  return (
    <label
      className={cn(styles.checkbox, className, { [styles.reverse]: reverse })}
    >
      <input
        className={cn(styles.input, {
          [styles.disabledInput]: disabled,
        })}
        type="checkbox"
        onChange={onChange}
        checked={value}
        onClick={onClick}
        disabled={disabled}
      />
      <span className={cn(styles.inner, { [styles.disabledInner]: disabled })}>
        <span
          className={cn(styles.tick, classCheckboxTick, {
            [styles.radio]: type === "radio",
          })}
        ></span>
        {content && (
          <>
            <span
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: content }}
            ></span>
          </>
        )}
      </span>
    </label>
  );
};

export default Checkbox;
