import React from "react";

import cn from "classnames";

import { Modal } from "components";

import styles from "./DeleteModal.module.sass";

const DeleteEmployeeModal = ({ visible, onConfirm, onClose, name }) => {
  return (
    <Modal
      visible={visible}
      onClose={onClose}
      hideCloseBtn
    >
      <div className={styles.delete}>
        <div className={styles.info}>Are you sure about the removal?</div>

        <div className={styles.text}>
          Removing an Employee means you’ll no longer be able to access their
          data in the Team’s page. Proceed with removing <b>{name}</b> from
          Team?
        </div>

        <div className={styles.foot}>
          <button
            className={cn("button button-stroke", styles.button)}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className={cn("button", styles.button, styles.confirm)}
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteEmployeeModal;
