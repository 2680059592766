import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { WithContext as ReactTags } from "react-tag-input";

import { Avatar, Tooltip, Card, Tag } from "components";

import { updateEmployeeTags } from "../../../slices/employeeSlice";

import styles from "./Profile.module.sass";

const Profile = ({ report }) => {
  const dispatch = useDispatch();
  const { employee } = useSelector((state) => state.employee);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    let temp_tags = "";
    if (Array.isArray(employee?.tags_array)) {
      temp_tags =
        employee?.tags_array.length > 0 ? employee?.tags_array?.join(",") : "";
    } else {
      temp_tags =
        employee?.tags_array?.replace(/[^a-zA-Z0-9\s,@.(),:;<>[\]]+/g, "") ||
        "";
    }
    if (temp_tags.length > 0) {
      setTags(
        temp_tags?.split(",")?.map((tag) => {
          return {
            id: tag,
            text: tag,
          };
        })
      );
    }
  }, [employee]);

  useEffect(() => {
    tags &&
      employee &&
      dispatch(
        updateEmployeeTags({
          id: employee.id,
          tags: tags?.map((tag) => tag.text).join(","),
        })
      );
  }, [dispatch, employee, tags]);

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const handleDelete = (i) => {
    setTags(tags?.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    if (tags.length < 12) {
      setTags([...tags, tag]);
    }
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...tags].slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const onClearAll = () => {
    setTags([]);
  };

  const onTagUpdate = (i, newTag) => {
    const updatedTags = tags.slice();
    updatedTags.splice(i, 1, newTag);
    setTags(updatedTags);
  };
  return (
    <Card
      className={styles.card}
      title=""
      classTitle="title-purple"
    >
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={report ? styles.item : styles.head}>
            <div
              className={styles.avatar}
              data-html2canvas-ignore="true"
            >
              <Avatar
                src={employee?.avatar_url || "/images/content/avatar.jpg"}
                alt="Avatar"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.name}>{employee?.name}</div>
              {/* Will be hidden untill the People HR implementation is done
                <div className={styles.score}>
                Metra Score:
                <span>{employee?.metraScore}</span>
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.head}>
            <div className={styles.label}>
              Tags{" "}
              <Tooltip
                className={styles.tooltip}
                title="Maximum 100 characters. No HTML or emoji allowed"
                icon="info"
                place="right"
              />
            </div>
            <div className={styles.counter}>
              <span>{tags?.length || 0}</span>/12 tags
            </div>
          </div>
          <div className={styles.tags}>
            {report ? (
              <div>
                {tags?.map((tag, index) => (
                  <Tag
                    key={index}
                    random
                    name={tag.text}
                  />
                ))}
              </div>
            ) : (
              <ReactTags
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                delimiters={delimiters}
                handleTagClick={handleTagClick}
                onClearAll={onClearAll}
                onTagUpdate={onTagUpdate}
                suggestions={[{ id: "1", text: "Engineering" }]}
                placeholder="Enter tags to describe your person"
                minQueryLength={2}
                maxLength={20}
                autofocus={false}
                allowDeleteFromEmptyInput={true}
                autocomplete={true}
                readOnly={false}
                allowUnique={true}
                allowDragDrop={true}
                inline={true}
                inputFieldPosition="inline"
                allowAdditionFromPaste={true}
                editable={true}
                clearAll={true}
                tags={tags}
              />
            )}
          </div>
        </div>
      </div>
      {/* Will be hidden untill the People HR implementation is done
        <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.info}>
            Start of employment:
            <span> </span>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.info}>
            Contract:
            <span>{employee?.contract}</span>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.info}>
            Vacation days:
            <span>{employee?.vacationDays}</span>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.info}>
            Sick days:
            <span>{employee?.sickDays}</span>
          </div>
        </div>
      </div> */}
    </Card>
  );
};

export default Profile;
