import React, { useEffect } from "react";

import cn from "classnames";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Icon } from "components";
import { getHomeOverview } from "slices/homeSlice";

import styles from "./Success.module.sass";

const Success = ({ loading, error, section }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeOverview());
  }, [dispatch]);

  return (
    <div className={styles.success}>
      {error && (
        <div className={cn(styles.info, styles.error)}>
          Something went wrong! Please try again or contact support.
        </div>
      )}

      {!error && !loading && (
        <>
          <div className={styles.icon}>
            <Icon
              name="check"
              size="24"
            />
          </div>
          <div className={styles.info}>New Team created successfully!</div>
          <div className={styles.text}>
            Kudos for organizing your operation for better Insights! <br />
            Visit the Section's page for more details.
          </div>
          <Link
            to={`/team/${section?.name || 'details'}/${section?.id}`}
            className={cn("button", styles.button)}
          >
            Go to Team Detail page &nbsp; <Icon name="arrow-right" />
          </Link>
        </>
      )}
    </div>
  );
};

export default Success;
