import React from "react";

import cn from "classnames";

import styles from "./Item.module.sass";

const Item = ({ className, classTitle, title, head, headTitle, children }) => {
  return (
    <div className={cn(styles.item, className)}>
      <div className={cn(headTitle, styles.head)}>
        <div className={cn(classTitle, styles.title)}>{title}</div>
        {head && head}
      </div>
      {children}
    </div>
  );
};

export default Item;
