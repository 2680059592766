import { useState } from "react";

import cn from "classnames";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as SendIcon } from "assets/icons/send.svg";
import { Icon, Modal, TextInput, Accordion } from "components";

import styles from "./MetricsFAQModal.module.sass";
import { integrationFAQ } from "./questions";

const MetricsFAQModal = ({ isOpen, onClose, integration, icon }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  return (
    <Modal
      visible={isOpen}
      onClose={onClose}
      outerClassName={styles.modal}
      title="Metrics"
      className="modal-metrics"
    >
      <div className={styles.title}>
        <img
          width={36}
          src={icon}
          alt={integration.name}
        />
        {integration.name}
      </div>
      <div className={styles.subtitle}>
        <Link
          to="/"
          className={cn(
            `button button-small button-transparent ${styles.button}`
          )}
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          <Icon name="arrow-left" />
          Back to Metrics
        </Link>
        <div
          className={cn(`${styles.goToIntegration}`)}
          onClick={() => navigate("/integrations")}
        >
          Go to Integration's page
          <SendIcon />
        </div>
      </div>

      <div className={styles.divider} />

      <div className={styles.searchContainer}>
        <span>Frequently Asked Questions</span>
        <TextInput
          classInput={styles.search}
          className={styles.searchWrapper}
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          type="text"
          name="search"
          icon="search"
        />
      </div>

      {integrationFAQ(integration).map((item) => (
        <>
          <Accordion.Title
            title={item.topic}
            icon={item.icon}
            className={styles.accordionTitle}
          />

          <Accordion
            questions={item.questions}
            className={styles.accordion}
          />
        </>
      ))}
    </Modal>
  );
};

export default MetricsFAQModal;
