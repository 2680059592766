import styles from "./Home.module.sass";
import Insights from "./Insights";
import OnboardWarning from "./OnboardWarning";
import Overview from "./Overview";

const Home = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.highlights}>Highlights</h1>
      <OnboardWarning />
      <Insights />
      <Overview />
    </div>
  );
};

export default Home;
