import React, { useState } from "react";

import cn from "classnames";

import Avatar from "../../../../Avatar";
import Icon from "../../../../Icon";

import styles from "./Share.module.sass";

const socials = [
  {
    icon: "facebook",
    url: "https://www.facebook.com/metra.ai/",
  },
  {
    icon: "twitter",
    url: "https://twitter.com/ui8",
  },
  {
    icon: "instagram",
    url: "https://www.instagram.com/ui8net/",
  },
];

const Share = () => {
  const [value, setValue] = useState("https://metra.ai/feel-travel");
  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>
          Share this integration
        </div>
      </div>
      <div className={styles.field}>
        <div className={styles.icon}>
          <Icon
            name="link"
            size="24"
          />
        </div>
        <input
          className={styles.input}
          type="text"
          name="site"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          readOnly
        />
        <button className={styles.copy}>Copy</button>
      </div>
      <div className={styles.preview}>
        <Avatar
          src="/images/content/photo-1.jpg"
          alt="Share"
        />
      </div>
      <div className={styles.btns}>
        {socials.map((x, index) => (
          <a
            className={cn("button-stroke", styles.button)}
            href={x.url}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
          >
            <Icon
              name={x.icon}
              size="24"
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Share;
