import { ReactComponent as ShieldTickIcon } from "assets/icons/shield-tick.svg";

export default [
  {
    topic: "Security Questions",
    icon: <ShieldTickIcon />,
    questions: [
      {
        question: "What is Metra's security policy?",
        answer:
          "Q1: Metra's security policy is to ensure that all data is protected and secure.",
      },
      {
        question: "What is Metra's security policy?",
        answer:
          "Q2: Metra's security policy is to ensure that all data is protected and secure.",
      },
    ],
  },
  {
    topic: "General Questions",
    icon: <ShieldTickIcon />,
    questions: [
      {
        question: "What is Metra's security policy?",
        answer:
          "Q1: Metra's security policy is to ensure that all data is protected and secure.",
      },
      {
        question: "What is Metra's security policy?",
        answer:
          "Q2: Metra's security policy is to ensure that all data is protected and secure.",
      },
    ],
  },
  {
    topic: "General Questions",
    icon: <ShieldTickIcon />,
    questions: [
      {
        question: "What is Metra's security policy?",
        answer:
          "Q1: Metra's security policy is to ensure that all data is protected and secure.",
      },
      {
        question: "What is Metra's security policy?",
        answer:
          "Q2: Metra's security policy is to ensure that all data is protected and secure.",
      },
    ],
  },
];
