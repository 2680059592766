import React, { useState, useRef, useEffect } from "react";

import { createPortal } from "react-dom";

const Tooltip = ({ text, children, position: placement }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const ref = useRef();

  useEffect(() => {
    const onMouseEnter = (e) => {
      let pos = placement;
      if (window.innerHeight - e.target.getBoundingClientRect().y < 250) {
        pos = "left";
      }

      let xPosition = e.target.getBoundingClientRect().x;
      let yPosition = e.target.getBoundingClientRect().y;

      if (pos === "left") {
        xPosition += -200;
        yPosition -= 50;
      }

      if (pos === "top") {
        yPosition += -100;
      }

      setPosition({
        x: xPosition,
        y: yPosition,
      });
      setShowTooltip(true);
    };
    const onMouseLeave = () => setShowTooltip(false);
    ref.current.addEventListener("mouseenter", onMouseEnter);
    ref.current.addEventListener("mouseleave", onMouseLeave);

    return () => {
      ref?.current?.removeEventListener("mouseenter", onMouseEnter);
      ref?.current?.removeEventListener("mouseleave", onMouseLeave);
    };
  }, []);

  const tooltip = showTooltip ? (
    <div
      className="tooltip"
      style={{
        position: "absolute",
        top: position.y + window.scrollY + ref.current.clientHeight + 10,
        left: position.x,
        zIndex: 1000,
        transform: `translateX(-30%)`,
      }}
    >
      {text}
    </div>
  ) : null;

  return (
    <div ref={ref}>
      {children}
      {createPortal(tooltip, document.body)}
    </div>
  );
};

export default Tooltip;
