import React, { useEffect } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getIntegration } from "../../../slices/integrationSlice";

import styles from "./IntegrationSpecifications.module.sass";

const IntegrationSpecifications = ({ className }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { integration } = useSelector((state) => state.integration);

  useEffect(() => {
    if (!integration || integration.name !== params.integration) {
      dispatch(
        getIntegration({
          integration: params.integration,
        })
      );
    }
  }, [dispatch, integration, params]);

  return (
    <div className={cn(styles.item, className)}>
      <div className={cn("title-green", styles.title)}>
        {"API Specifications"}
      </div>
      <div className={styles.fieldset}>
        <div className={cn("h5", styles.altTitle)}>
          {integration?.name.replace("Outlook", "Exchange Online")} API
          Description
        </div>
        <div className={styles.subtitle}>
          {integration?.specifications?.description}
        </div>
        <div className={cn("h5", styles.altTitle)}>Available Metrics</div>
        <div className={styles.subtitle}>
          {integration?.specifications?.metrics}
        </div>
      </div>
    </div>
  );
};

export default IntegrationSpecifications;
