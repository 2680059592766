import cn from "classnames";

import { Checkbox } from "components";

import styles from "./Goal.module.sass";

const Goal = ({ id, icon, title, description, value, onCheck }) => {
  return (
    <div
      className={cn(styles.goal, { [styles.selected]: value })}
      onClick={() => onCheck(id)}
    >
      <div className={cn(styles.tick, { [styles.selected]: value })}>
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          value={value}
        />
        <div className={styles.icon}>{icon}</div>
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{description}</div>
    </div>
  );
};

export default Goal;
