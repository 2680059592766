import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as AddCircled } from "assets/icons/add-circled.svg";
import { ReactComponent as SortAscIcon } from "assets/icons/sort-desc.svg";
import { Modal, TextInput, UserDetails, Icon } from "components";
import {
  getUnAssignedBySectionTag,
  assignEmployeeToSection,
  unAssignEmployeeToSection,
  getAssignedBySectionTag,
} from "slices/sectionSlice";

import styles from "./AddEmployeeToSectionModal.module.sass";

const columns = [
  {
    name: "Name",
    sort: true,
  },
  {},
];

const AddEmployeeToSectionModal = ({ sectionId, visible, onClose }) => {
  const [search, setSearch] = useState("");
  const [assigned, setAssigned] = useState({});
  const { unAssignedEmployees } = useSelector((state) => state.section);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUnAssignedBySectionTag(sectionId));
  }, [dispatch, sectionId]);

  const handleAssignEmployee = async (employee) => {
    await dispatch(
      assignEmployeeToSection({
        sectionTagId: sectionId,
        employeeId: employee.id,
      })
    );
    await dispatch(getAssignedBySectionTag(sectionId));
    setAssigned({ ...assigned, [employee.id]: true });
  };

  const handleUnAssignEmployee = async (employee) => {
    await dispatch(
      unAssignEmployeeToSection({
        sectionId: sectionId,
        employeeId: employee.id,
      })
    );
    await dispatch(getUnAssignedBySectionTag(sectionId));
    setAssigned({ ...assigned, [employee.id]: false });
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      outerClassName={styles.modal}
    >
      <p>Add Employee to the Team</p>

      <div className={styles.head}>
        <TextInput
          className={styles.search}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search for employees"
          type="text"
          name="search"
          icon="search"
        />
      </div>
      <div className={cn(styles.wrapper)}>
        <div className={cn(styles.table)}>
          <div className={cn(styles.headerRow)}>
            {columns.map((column, index) => (
              <div
                className={styles.headerCol}
                key={index}
              >
                {column.sort && <SortAscIcon />}
                &nbsp; {column.name}
              </div>
            ))}
          </div>

          {unAssignedEmployees
            .filter((x) => x.name.toLowerCase().includes(search.toLowerCase()))
            .map((employee) => (
              <div className={cn(styles.row)}>
                <div className={styles.col}>
                  <UserDetails user={employee} />
                </div>
                <div className={styles.col}>
                  {assigned[employee.id] ? (
                    <button
                      className={cn("button button-small", styles.button)}
                      onClick={() => handleUnAssignEmployee(employee)}
                    >
                      <Icon
                        name="check"
                        size="24"
                      />
                      Employee added to the team
                    </button>
                  ) : (
                    <button
                      className={cn(
                        "button button-small button-stroke-blue",
                        styles.button
                      )}
                      onClick={() => handleAssignEmployee(employee)}
                    >
                      <AddCircled />
                      Add this Employee to Team
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default AddEmployeeToSectionModal;
