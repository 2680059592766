import { useParams } from "react-router-dom";

import About from "./About";
import Activities from "./Activities";
import Stats from "./Stats";
import styles from "./Team.module.sass";

const Team = () => {
  const { teamName } = useParams();
  const params = useParams();

  let title = ["Home"];
  if (params) {
    Object.keys(params).forEach((param) => title.push(params[param]));
  }

  return (
    <>
      <div className={styles.info}>
        <About teamName={teamName} />
        <Stats teamName={teamName} />
      </div>
      <Activities teamName={teamName} />
    </>
  );
};

export default Team;
