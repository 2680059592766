import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import NoResult from "../NoResult";

import styles from "./Insights.module.sass";
import Overview from "./Overview";

const Insights = () => {
  const { insights, insightsLoading } = useSelector((state) => state.home);
  const { columnNames, statusColors, statusIcons } = useSelector(
    (state) => state.app
  );
  const [modifiedInsights, setModifiedInsights] = useState();

  useEffect(() => {
    const stats = [];
    insights?.forEach((integration) => {
      const stat = {
        id: integration.id,
        title: integration.title,
        data: Object.keys(integration?.stats.sum[0]).map((s, i) => ({
          title: columnNames[s],
          color: statusColors[Math.floor(Math.random() * 5)],
          counter: integration?.stats.sum[0][s],
          tooltip: columnNames[s],
          value: integration?.stats.percentage[0][s],
          icon: statusIcons[Math.floor(Math.random() * 5)],
        })),
      };
      stats.push(stat);
    });
    setModifiedInsights(stats);
  }, [columnNames, insights, statusColors, statusIcons]);
  return (
    <>
      {!modifiedInsights?.length > 0 && <NoResult loading={insightsLoading} />}
      {modifiedInsights?.map((item, index) => (
        <div
          className={styles.section}
          key={index}
        >
          <Overview
            className={styles.card}
            item={item}
          />
        </div>
      ))}
    </>
  );
};

export default Insights;
