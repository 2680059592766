import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api, { apiV2 } from "../app/api";

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  overview: null,
  insights: null,
  employees: null,
  activities: {},
  goals: null,
  companyGoals: null,

  integrationTabs: [],
  insightsLoading: false,
  activitiesLoading: false,
};

export const home = createSlice({
  name: "home",
  initialState,
  reducers: {
    clearActivities: (state) => {
      state.activities = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getHomeOverview.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getHomeOverview.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.overview = action.payload?.data?.overview
          ? action.payload?.data?.overview
          : null;
      })
      .addCase(getHomeOverview.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getCompanyIntegrations.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getCompanyIntegrations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrationTabs = action.payload?.data?.integrations || [];
      })
      .addCase(getCompanyIntegrations.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getInsights.pending, (state) => {
        state.status = "loading";
        state.insightsLoading = true;
      })
      .addCase(getInsights.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.insightsLoading = false;
        state.insights = action.payload?.data?.insights
          ? action.payload?.data?.insights
          : null;
      })
      .addCase(getInsights.rejected, (state, action) => {
        state.status = "failed";
        state.insightsLoading = false;
        state.error = action.error.message;
      })
      .addCase(getEmployees.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.employees = action.payload?.data?.employees
          ? action.payload?.data?.employees
          : null;
      })
      .addCase(getEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getActivities.pending, (state) => {
        state.status = "loading";
        state.activitiesLoading = true;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.status = "succeeded";
        const integration = JSON.parse(
          action?.payload?.config?.data
        )?.integration;
        // If pagination is needed, go back on commits history to get the logic
        state.activities[integration] = action.payload?.data;
        state.activitiesLoading = false;
      })
      .addCase(getActivities.rejected, (state, action) => {
        state.status = "failed";
        state.activitiesLoading = false;
        state.error = action.error.message;
      })

      .addCase(getGoals.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getGoals.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.goals = action.payload?.data?.goals
          ? action.payload?.data?.goals
          : null;
      })
      .addCase(getGoals.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getCompanyGoals.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getCompanyGoals.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        console.log("action.payload?.data", action.payload?.data)
        state.companyGoals = action.payload?.data?.goals
          ? action.payload?.data?.goals
          : null;
      })
      .addCase(getCompanyGoals.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getHomeOverview = createAsyncThunk("getHomeOverview", async () => {
  const response = await api.get("/home/overview");
  return response;
});

export const getCompanyIntegrations = createAsyncThunk(
  "getCompanyIntegrations",
  async (department = "") => {
    const response = await api.get(
      `/home/company_integrations${department ? `/${department}` : ""}`
    );
    return response;
  }
);

export const getInsights = createAsyncThunk("getInsights", async (data) => {
  const response = await api.get(
    `/home/insights/${data.period}/${data.name}/${data.integration ? data.integration : "all"}`
  );
  return response;
});

export const getEmployees = createAsyncThunk("getEmployees", async (data) => {
  const response = await api.get(
    `/home/employees/${data.period}/${data.name ? data.name : "all"}`
  );
  return response;
});

export const getActivities = createAsyncThunk("getActivities", async (data) => {
  const response = await apiV2.post(
    `/home/activities/${data.period}/${data.section || "home"}${
      data?.startDate && data?.endDate
        ? `?start_date=${data.startDate}&end_date=${data.endDate}`
        : ""
    }`,
    data
  );
  return response;
});

export const getGoals = createAsyncThunk("getGoals", async (data) => {
  const response = await api.get("/goals");
  return response;
});

export const getCompanyGoals = createAsyncThunk("getCompanyGoals", async (data) => {
  const response = await api.get("/company/goals");
  return response;
});

export const addGoals = createAsyncThunk("addGoals", async (data) => {
  const response = await api.post("/company/add_goals/", data);
  return response;
});

export const { clearActivities } = home.actions;

export default home.reducer;
