import { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  Checkbox,
  Modal,
  Dropdown,
  TableComponent,
  UserDetails,
  Loader,
} from "components";
import {
  getArchiveInBulkEmployees,
  updateArchiveInBulkEmployees,
  getAllEmployees,
} from "slices/employeeSlice";
import { formatDateUK } from "utils";

import styles from "./ArchiveBulk.module.sass";

const options = {
  "No activity in the last 30 days": 30,
  "No activity in the last 60 days": 60,
  "No activity in the last 90 days": 90,
};

const EmployeeToSectionModal = ({ user, visible, onClose }) => {
  const [selectedEmployeesToArchive, setSelectedEmployeesToArchive] = useState(
    []
  );
  const dispatch = useDispatch();

  const { archiveInBulkEmployees, archiveLoading } = useSelector(
    (state) => state.employee
  );

  useEffect(() => {
    if (archiveInBulkEmployees.length) {
      setSelectedEmployeesToArchive(archiveInBulkEmployees);
    }
  }, [archiveInBulkEmployees]);

  const getColumns = (
    affectedNumber,
    onSelectEmployee,
    onBuldSelection,
    anySelected
  ) => [
    {
      title: (
        <div className={styles.bulkSelect}>
          <Checkbox
            className={styles.checkbox}
            value={!anySelected}
            onChange={onBuldSelection}
          />
          Employees affected ({affectedNumber}):
        </div>
      ),
      key: "name",
      dataIndex: "data",
      render: (data) => (
        <UserDetails
          user={data}
          selectable
          value={!data.exclude}
          onChange={onSelectEmployee}
        />
      ),
    },
    {
      title: "Last activity",
      key: "last_activity",
      dataIndex: "last_activity",
      render: (last_activity) => formatDateUK(last_activity),
    },
  ];

  const handleArchivePeriod = async (value) => {
    await dispatch(getArchiveInBulkEmployees(options[value]));
  };

  const handleConfirm = async () => {
    const action = dispatch(
      updateArchiveInBulkEmployees({
        employees: selectedEmployeesToArchive
          .filter(({ exclude }) => !exclude)
          .map(({ id }) => id),
      })
    );
    toast.promise(
      action,
      {
        pending: "Archiving Employee...",
        success: {
          render() {
            dispatch(getAllEmployees());
            onClose();
            return `Employee archived successfully!`;
          },
        },
        error: "Failed to archive Employees!",
      },
      {
        autoClose: 1500,
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
  };

  const onSelectEmployee = (e, user) => {
    if (user.exclude) {
      setSelectedEmployeesToArchive((prev) =>
        prev.map((employee) =>
          employee.id === user.id ? { ...employee, exclude: false } : employee
        )
      );
    } else {
      setSelectedEmployeesToArchive((prev) =>
        prev.map((employee) =>
          employee.id === user.id ? { ...employee, exclude: true } : employee
        )
      );
    }
  };

  const anySelected = selectedEmployeesToArchive.find(({ exclude }) => exclude);
  const onBuldSelection = () => {
    if (!anySelected) {
      setSelectedEmployeesToArchive((prev) =>
        prev.map((employee) => ({ ...employee, exclude: true }))
      );
    } else {
      setSelectedEmployeesToArchive((prev) =>
        prev.map((employee) => ({ ...employee, exclude: false }))
      );
    }
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      outerClassName={styles.modal}
    >
      <div className={cn(styles.title)}>Bulk Archive</div>

      <div className={styles.head}>Archive employees who's had:</div>
      <Dropdown
        classDropdownHead={styles.dropdownHead}
        value={""}
        setValue={handleArchivePeriod}
        placeholder="Select activity range"
        options={Object.keys(options)}
        name="timezone"
      />

      {archiveLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <div
        className={cn(styles.employees, {
          hide: !selectedEmployeesToArchive.length || archiveLoading,
        })}
      >
        <TableComponent
          columns={getColumns(
            selectedEmployeesToArchive.length,
            onSelectEmployee,
            onBuldSelection,
            anySelected
          )}
          data={selectedEmployeesToArchive}
          loading={false}
        />
      </div>

      <div className={styles.foot}>
        <button
          className={cn("button button-stroke", styles.button)}
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className={cn("button", styles.button, styles.confirm)}
          onClick={handleConfirm}
          disabled={!selectedEmployeesToArchive.length}
        >
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default EmployeeToSectionModal;
