import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useSelector } from "react-redux";

import Card from "../../../components/Card";
import { onlyUnique } from "../../../utils";

import styles from "./Employees.module.sass";
import Integrate from "./Integrate";
// import Filters from "../../../components/Filters";
// import Settings from "./Settings";
import Table from "./Table";

const EmployeeList = ({ integrationId }) => {
  const { integrationEmployees } = useSelector((state) => state.integration);

  const navigation = integrationEmployees
    ?.map((employee) => employee.status)
    ?.filter(onlyUnique);

  const [activeTab, setActiveTab] = useState(navigation ? navigation[0] : "");

  useEffect(() => {
    if (!navigation?.includes(activeTab)) {
      setActiveTab(navigation ? navigation[0] : "");
    }
  }, [activeTab, navigation]);

  return (
    <Card
      className={styles.card}
      title="Employees Integrated"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={cn(styles.head)}
      head={
        <>
          <Integrate
            title="Add new employee"
            integrationId={integrationId}
          />
          <div className={styles.nav}>
            {navigation?.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x === activeTab,
                })}
                onClick={() => setActiveTab(x)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
          {/* <Filters className={styles.filters} title="Smart Filter">
            <Settings filters={navigation} />
          </Filters> */}
        </>
      }
    >
      <div className={cn(styles.row)}>
        {navigation?.map(
          (nav, index) =>
            activeTab === nav && (
              <Table
                key={index}
                className={styles.table}
                integrationId={integrationId}
                items={integrationEmployees?.filter(
                  (employee) => employee.status === nav
                )}
              />
            )
        )}
      </div>
    </Card>
  );
};

export default EmployeeList;
