import cn from "classnames";
import { Link } from "react-router-dom";

import { ReactComponent as SendIcon } from "assets/icons/send.svg";
import { Icon, TextInput } from "components";

import styles from "./Security.module.sass";

export const PrivacyPolicyTitle = ({ onClick }) => (
  <div className={styles.subtitle}>
    <div
      className={cn(`button button-small button-transparent ${styles.button}`)}
      onClick={onClick}
    >
      <Icon name="arrow-left" />
      Back to Security and Compliance
    </div>
    <div onClick={() => {}}>
      Go to Terms & Conditions
      <SendIcon />
    </div>
  </div>
);

export const PrivacyPolicyBody = ({ onChange, value }) => (
  <>
    <div className={styles.privacySearch}>
      Privacy Policy
      <TextInput
        classInput={styles.search}
        className={styles.searchWrapper}
        placeholder="Search"
        onChange={onChange}
        value={value}
        type="text"
        name="search"
        icon="search"
      />
    </div>
    <div className={styles.privacyText}>
      <p>
        The following terms and conditions (“Website Terms of Use”) apply to
        your access and use of the website located at https://www.metra.ai (or
        any successor links) and all associated web pages, websites, and social
        media pages (“Site”).{" "}
        <span className={styles.highlighted}>
          By accessing or using this Site, you agree to these Website Terms of
          Use, including the Acceptable Use Policy, together which shall
          constitute a legally binding agreement between you and Xogito Inc. dba
          Metra (“Metra”, “we”, “our”, or “us”).
        </span>{" "}
        You should review these Website Terms of Use regularly as they may
        change at any time at our sole discretion. If you do not agree to any
        term or condition, you should not access or otherwise use this site. In
        connection with your use of and/or access to Content contained in
        certain areas of this Site, it may be necessary for you to consent to
        policies or other terms and conditions in addition to the Terms set
        forth herein.
      </p>

      <p>
        BY AGREEING TO THESE WEBSITE TERMS OF USE, EXCEPT FOR (I) CERTAIN TYPES
        OF DISPUTES DESCRIBED IN SECTION 18, (II) WHERE YOU EXERCISE YOUR RIGHT
        TO OPT OUT OF ARBITRATION AS DESCRIBED IN SECTION 18, OR (III) TO THE
        EXTENT PROHIBITED BY LAW, DISPUTES BETWEEN YOU AND ACTIVTRAK WILL BE
        RESOLVED SOLELY ON AN INDIVIDUAL BASIS AND NOT AS A CLASS ARBITRATION,
        CLASS ACTION, ANY OTHER KIND OF REPRESENTATIVE PROCEEDING, OR BY JURY
        TRIAL.
      </p>
    </div>
  </>
);
