import React, { useState } from "react";

import cn from "classnames";

import Icon from "../../Icon";
import ModalEmployee from "../../ModalEmployee";

import styles from "./Control.module.sass";

const Control = ({ className }) => {
  const [visibleModalIntegration, setVisibleModalIntegration] = useState(false);

  const actions = [
    {
      icon: "edit",
      action: () => console.log("edit"),
    },
    {
      icon: "trash",
      action: () => console.log("remove"),
    },
    {
      icon: "arrow-right",
      action: () => setVisibleModalIntegration(true),
    },
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button
            className={styles.button}
            key={index}
            onClick={x.action}
          >
            <Icon
              name={x.icon}
              size="20"
            />
          </button>
        ))}
      </div>
      <ModalEmployee
        visible={visibleModalIntegration}
        onClose={() => setVisibleModalIntegration(false)}
      />
    </>
  );
};

export default Control;
