import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getSectionTagById } from "slices/sectionSlice";

import Header from "../Header";
import Sidebar from "../Sidebar";

import styles from "./Page.module.sass";

const Page = ({ wide, children, title, isTeamDetails, innerStyle }) => {
  const [visible, setVisible] = useState(false);
  const [pageName, setPageName] = useState(title);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn, collapsed } = useSelector((state) => state.app);
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/sign-in");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (isTeamDetails) {
      (async () => {
        const action = await dispatch(getSectionTagById(params.id));

        if (action.payload) {
          setPageName(action.payload?.data?.section_tag?.name);
        }
      })();
    } else {
      setPageName(title);
    }
  }, [dispatch, params.id]);

  return isLoggedIn ? (
    <>
      <div className={cn(styles.page, { [styles.collapsed]: collapsed })}>
        <Sidebar
          className={cn(styles.sidebar, { [styles.visible]: visible })}
          onClose={() => setVisible(false)}
        />
        <Header onOpen={() => setVisible(true)} />
        <div
          className={styles.inner}
          style={innerStyle}
        >
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            <div className={styles.title}></div>
            {children}
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default Page;
