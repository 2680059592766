import React from "react";

import cn from "classnames";

import Icon from "../Icon";

import styles from "./NewSectionCard.module.sass";

const NewSectionCard = ({ onClick, className, onboardingFocus, ...rest }) => (
  <div
    className={cn(styles.emptyItem, className, {
      [styles.focused]: onboardingFocus,
    })}
    onClick={onClick}
    {...rest}
  >
    <div className={styles.addNewTag}>
      <Icon
        name="plus"
        size="15"
      />
    </div>
    <div className={styles.inform}>Create new Section</div>
  </div>
);

export default NewSectionCard;
