import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { getAllIntegrations, getMyIntegrations } from "slices/integrationSlice";

import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";

import AllIntegrations from "./AllIntegrations";
import styles from "./Category.module.sass";
import MyIntegrations from "./MyIntegrations";

const Category = () => {
  const dispatch = useDispatch();

  const { myIntegrations, allIntegrations } = useSelector(
    (state) => state.integration
  );

  useEffect(() => {
    if (!allIntegrations) dispatch(getAllIntegrations());
    if (!myIntegrations) dispatch(getMyIntegrations());
  }, [allIntegrations, myIntegrations, dispatch]);

  const navigation = ["My Integrations", "All Integrations"];

  const [activeTab, setActiveTab] = useState(navigation ? navigation[0] : "");

  return (
    <Card
      className={styles.card}
      showWithoutTitle={true}
      classTitle={cn("title-blue", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <div className={cn(styles.nav, "tablet-hide")}>
            {navigation?.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x === activeTab,
                })}
                onClick={() => setActiveTab(x)}
                key={index}
              >
                {x}
                {x === "My Integrations"
                  ? ` (${myIntegrations?.length || 0})`
                  : ` (${allIntegrations?.length || 0})`}
              </button>
            ))}
          </div>
          <div className={cn(styles.dropdown, "tablet-show")}>
            <Dropdown
              classDropdownHead={styles.dropdownHead}
              value={activeTab}
              setValue={setActiveTab}
              options={navigation}
              small
            />
          </div>
        </>
      }
    >
      <div className={styles.category}>
        <div className={styles.wrapper}>
          {activeTab === navigation[0] && (
            <MyIntegrations
              noResultHandler={() => setActiveTab(navigation[1])}
            />
          )}
          {activeTab === navigation[1] && <AllIntegrations />}
        </div>
      </div>
    </Card>
  );
};

export default Category;
