import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Icon, TextInput, Avatar, Dropdown } from "components";
import Loader from "components/Loader";
import { getAdminInformation, updateAdmin, getCompanyRoles } from "slices/companySlice";

import Item from "../Item";
import Login from "../Login";

import styles from "./AdminInformation.module.sass";

const initialForm = {
  avatar: "",
  name_surname: "",
  email: "",
  location: "",
  timezone: "",
  role: "",
};

const AdminInformation = ({ className, onSubmit }) => {
  const [form, setForm] = useState(initialForm);
  const [initialFormState, setInitialFormState] = useState(initialForm);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { admin, roles } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(getAdminInformation());
    dispatch(getCompanyRoles());
  }, [dispatch]);

  useEffect(() => {
    if (admin) {
      const newForm = {
        avatar: admin.avatar_url,
        name_surname: admin.name_surname,
        email: admin.email,
        location: admin.location,
        role: admin.role,
      };
      setForm(newForm);
      setInitialFormState(newForm);
    }
  }, [admin]);


  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();
    if (form.avatar !== admin.avatar_url)
      formData.append("avatar", form.avatar);
    formData.append("name", form.name_surname);
    formData.append("email", form.email);
    formData.append("location", form.location);
    formData.append("role", form.role);

    try {
      const res = await dispatch(updateAdmin({ id: admin.id, data: formData }));

      if (res.error) {
        toast.error("Unable to Update Admin Information. Try again please!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }

      await dispatch(getAdminInformation());

      toast.success("Successfully Updated Admin Information!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      toast.error("An error occurred while updating admin information.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const blobAvatar = new Blob([reader.result], { type: file.type });
      setForm({
        ...form,
        avatar: blobAvatar,
      });
    };

    reader.readAsArrayBuffer(file);
  };

  let img = "/images/content/avatar.jpg";
  if (form?.avatar && typeof form?.avatar === "string") {
    img = form?.avatar;
  } else if (form?.avatar && typeof form?.avatar === "object") {
    img = URL.createObjectURL(form?.avatar);
  }

  const isChanged = JSON.stringify(form) !== JSON.stringify(initialFormState);

  return (
    <Item
      className={cn(styles.card, className)}
      title="Admin information"
      classTitle="title-green"
    >
      <div className={styles.profile}>
        <div className={styles.avatar}>
          <Avatar src={img} alt="Avatar" />
          <button className={styles.remove}>
            <Icon name="close" />
          </button>
        </div>
        <div className={styles.file}>
          <input type="file" onChange={handleUpload} />
          <button className={cn("button", styles.button)} type="button">
            <Icon name="add" size="24" />
            <span>Upload new picture</span>
          </button>
        </div>
        <button
          className={cn("button-stroke", styles.button)}
          onClick={() =>
            setForm({
              ...form,
              avatar: "",
            })
          }
        >
          Remove
        </button>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Admin name"
          name="name_surname"
          value={form.name_surname}
          onChange={handleChange}
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />
        <TextInput
          className={styles.field}
          label="Admin Email"
          name="email"
          type="email"
          value={form.email}
          onChange={handleChange}
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />
        <TextInput
          className={styles.field}
          label="Location"
          name="location"
          value={form.location}
          onChange={handleChange}
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />
        <Dropdown
          classDropdownHead={styles.dropdownHead}
          value={form.role}
          setValue={(value) => {
            setForm({
              ...form,
              role: value,
            });
          }}
          options={roles?.split(",")}
          placeholder="Position or role here"
          label="Roles"
          name="roles"
          tooltip="-"
        />
      </div>

      <Login onSubmit={onSubmit} />

      <button
        className={cn("button", styles.saveButton, {
          [styles.buttonDisabled]: !isChanged || loading,
        })}
        onClick={handleSubmit}
        disabled={!isChanged || loading}
      >
        {loading ? <Loader white /> : "Save"}
      </button>
    </Item>
  );
};

export default AdminInformation;
