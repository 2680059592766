import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as AddCircled } from "assets/icons/add-circled.svg";
import { ReactComponent as SortAscIcon } from "assets/icons/sort-desc.svg";
import { Tag, Modal, TextInput, UserDetails, Icon } from "components";
import {
  getUnAssignedEmployeesByTag,
  assignEmployeeToTag,
  unAssignEmployeeFromTag,
  getAssignedEmployeesByTag,
} from "slices/tagSlice";

import styles from "./AddTagToEmployeeModal.module.sass";

const columns = [
  {
    name: "Name",
    sort: true,
  },
  {},
];

const AddTagToEmployeeModal = ({ item, visible, onClose }) => {
  const [search, setSearch] = useState("");
  const [assigned, setAssigned] = useState({});
  const { unAssignedEmployees } = useSelector((state) => state.tag);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUnAssignedEmployeesByTag(item));
    return () => {};
  }, [dispatch, item]);

  const handleAssignEmployee = async (employee) => {
    await dispatch(
      assignEmployeeToTag({ tagId: item.id, userId: employee.id })
    );
    await dispatch(getAssignedEmployeesByTag(item));
    setAssigned({ ...assigned, [employee.id]: true });
  };

  const handleUnAssignEmployee = async (employee) => {
    await dispatch(
      unAssignEmployeeFromTag({
        tagId: item.id,
        userId: employee.id,
      })
    );
    await dispatch(getAssignedEmployeesByTag(item));
    setAssigned({ ...assigned, [employee.id]: false });
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
    >
      <p>Add Tag to Employee</p>

      <div className={styles.head}>
        <Tag
          name={item?.name}
          variant={3}
        />
        <TextInput
          className={styles.search}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search for employees"
          type="text"
          name="search"
          icon="search"
        />
      </div>
      <div className={cn(styles.wrapper)}>
        <div className={cn(styles.table)}>
          <div className={cn(styles.headerRow)}>
            {columns.map((column, index) => (
              <div
                className={styles.headerCol}
                key={index}
              >
                {column.sort && <SortAscIcon />}
                &nbsp; {column.name}
              </div>
            ))}
          </div>

          {unAssignedEmployees
            .filter((employee) =>
              employee.name.toLowerCase().includes(search.toLowerCase())
            )
            .map((employee) => (
              <div className={cn(styles.row)}>
                <div className={styles.col}>
                  <UserDetails user={employee} />
                </div>
                <div className={styles.col}>
                  {assigned[employee.id] ? (
                    <button
                      className={cn("button button-small", styles.button)}
                      onClick={() => handleUnAssignEmployee(employee)}
                    >
                      <Icon
                        name="check"
                        size="24"
                      />
                      Tag added to Employee
                    </button>
                  ) : (
                    <button
                      className={cn(
                        "button button-small button-stroke-blue",
                        styles.button
                      )}
                      onClick={() => handleAssignEmployee(employee)}
                    >
                      <AddCircled />
                      Add Tag to Employee
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default AddTagToEmployeeModal;
