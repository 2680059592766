import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api, { apiV2 } from "../app/api";
import { lastEntryParser } from "../utils";

const initialState = {
  status: "idle",
  loading: false,
  archiveLoading: false,
  error: null,
  employee: null,
  employeeProfile: null,
  activitySnapshot: null,
  integrationStats: null,
  employeeList: null,
  employeesByIntegrations: [],
  employeeIntegrations: null,
  lastEntries: null,
  notes: null,
  archiveInBulkEmployees: [],

  employeeListLoading: false,

  integrationStartDate: null,
  integrationEndDate: null,
};

export const employee = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setIntegrationStartDate: (state, { payload }) => {
      state.integrationStartDate = payload;
    },
    setIntegrationEndDate: (state, { payload }) => {
      state.integrationEndDate = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllEmployees.pending, (state) => {
        state.status = "loading";
        state.employeeListLoading = true;
      })
      .addCase(getAllEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employeeListLoading = false;
        state.employeeList = action.payload?.data?.employees
          ? action.payload?.data?.employees
          : null;
      })
      .addCase(getAllEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.employeeListLoading = false;
        state.error = action.error.message;
      })

      .addCase(getEmployeesByIntegrations.pending, (state) => {
        state.status = "loading";
        state.employeeListLoading = true;
      })
      .addCase(getEmployeesByIntegrations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employeeListLoading = false;
        state.employeesByIntegrations = action.payload?.data?.employees || [];
      })
      .addCase(getEmployeesByIntegrations.rejected, (state, action) => {
        state.status = "failed";
        state.employeeListLoading = false;
        state.error = action.error.message;
        state.employeesByIntegrations = [];
      })

      .addCase(getEmployee.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.employee = action.payload?.data?.employee
          ? action.payload?.data?.employee
          : null;
      })
      .addCase(getEmployee.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getEmployeeProfile.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getEmployeeProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.employeeProfile = action.payload?.data?.employee
          ? action.payload?.data?.employee
          : null;
      })
      .addCase(getEmployeeProfile.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateEmployeeProfile.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(updateEmployeeProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(updateEmployeeProfile.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getArchiveInBulkEmployees.pending, (state) => {
        state.status = "loading";
        state.archiveLoading = true;
      })
      .addCase(getArchiveInBulkEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.archiveLoading = false;
        state.archiveInBulkEmployees = action.payload?.data?.employees || null;
      })
      .addCase(getArchiveInBulkEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.archiveLoading = false;
        state.error = action.error.message;
      })

      .addCase(removeEmployee.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(removeEmployee.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(removeEmployee.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(removeIntegration.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(removeIntegration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(removeIntegration.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateEmployeeTags.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(updateEmployeeTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(updateEmployeeTags.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getEmployeeIntegrations.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getEmployeeIntegrations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.employeeIntegrations = action.payload?.data?.integrations || null;
      })
      .addCase(getEmployeeIntegrations.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLastEntries.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getLastEntries.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.lastEntries =
          lastEntryParser(action.payload?.data?.last_entries) || null;
      })
      .addCase(getLastEntries.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getNotes.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getNotes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.notes = action.payload?.data?.employee_notes || null;
      })
      .addCase(getNotes.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addNote.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.notes = action.payload?.data?.employee_notes || null;
      })
      .addCase(addNote.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(checkEmail.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(checkEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(checkEmail.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getActivitySnapshot.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getActivitySnapshot.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.activitySnapshot =
          action.payload?.data?.activity_snapshot || null;
      })
      .addCase(getActivitySnapshot.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getIntegrationStats.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getIntegrationStats.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.integrationStats = action.payload?.data?.all_stats || null;
      })
      .addCase(getIntegrationStats.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getIntegrationStat.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getIntegrationStat.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        let stats = state.integrationStats?.map((stat) =>
          action.payload?.data?.integration_stat &&
          action.payload?.data?.integration_stat.id === stat.id
            ? action.payload?.data?.integration_stat
            : stat
        );
        state.integrationStats = stats;
      })
      .addCase(getIntegrationStat.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getAllEmployees = createAsyncThunk(
  "getAllEmployees",
  async (data) => {
    // Construct the query parameters
    const queryParams = new URLSearchParams();
    if (data?.startDate && data?.endDate) {
      queryParams.append('start_date', data.startDate);
      queryParams.append('end_date', data.endDate);
    } else if (data?.period) {
      queryParams.append('period', data.period);
    }

    // Make the API request with the constructed query parameters
    const response = await apiV2.post(`/employees?${queryParams.toString()}`);
    return response;
  }
);

export const getEmployeesByIntegrations = createAsyncThunk(
  "getEmployeesByIntegrations",
  async (integrations) => {
    const response = await api.post("/integrations/employees", {
      integrations,
    });
    return response;
  }
);

export const updateEmployeeTags = createAsyncThunk(
  "updateEmployeeTags",
  async (data) => {
    const response = await api.post(`/employee/${data.id}/tags`, {
      tags: data.tags,
    });
    return response;
  }
);

export const getEmployee = createAsyncThunk("getEmployee", async (data) => {
  const response = await api.get(`/employee/${data}/overview`);
  return response;
});

export const getEmployeeProfile = createAsyncThunk(
  "getEmployeeProfile",
  async (id) => {
    const response = await api.get(`/employee/${id}`);
    return response;
  }
);

export const updateEmployeeProfile = createAsyncThunk(
  "updateEmployeeProfile",
  async ({ id, data }) => {
    const response = await api.post(`/employee/${id}/update`, data);
    return response;
  }
);

export const getArchiveInBulkEmployees = createAsyncThunk(
  "getArchiveInBulkEmployees",
  async (period) => {
    const response = await api.get(`/employee/bulk_update_people/${period}`);
    return response;
  }
);

export const updateArchiveInBulkEmployees = createAsyncThunk(
  "updateArchiveInBulkEmployees",
  async (data) => {
    const response = await api.post(
      `/employee/bulk_update_selected_people`,
      data
    );
    return response;
  }
);

export const removeEmployee = createAsyncThunk("removeEmployee", async (id) => {
  const response = await api.post(`/employee/${id}/delete`);
  return response;
});

export const getEmployeeIntegrations = createAsyncThunk(
  "getEmployeeIntegrations",
  async (data) => {
    const response = await api.get(`/employee/${data.id}/integrations`);
    return response;
  }
);

export const removeIntegration = createAsyncThunk(
  "removeIntegration",
  async ({ employeeId, integrationId }) => {
    const response = await api.post(
      `employee/${employeeId}/remove_integration/${integrationId}`
    );
    return response;
  }
);

export const getLastEntries = createAsyncThunk(
  "getLastEntries",
  async (data) => {
    const response = await api.get(
      `/employee/${data.id}/last_entries/${data.all}`
    );
    return response;
  }
);

export const getNotes = createAsyncThunk("getNotes", async (data) => {
  const response = await api.get(
    `/employee/${data.id}/employee_notes/${data.all}`
  );
  return response;
});

export const addNote = createAsyncThunk("addNote", async (data) => {
  const response = await api.post(`/employee/${data.id}/add_employee_notes`, {
    note: data.note,
  });
  return response;
});

export const checkEmail = createAsyncThunk("checkEmail", async (email) => {
  const response = await api.post(`/employee/check_email`, {
    email,
  });
  return response;
});

export const getActivitySnapshot = createAsyncThunk(
  "getActivitySnapshot",
  async (data) => {
    const response = await api.get(
      `/employee/${data.id}/activity_snapshot${
        data?.startDate && data?.endDate
          ? `?start_date=${data.startDate}&end_date=${data.endDate}`
          : `/${data.period}`
      }`
    );
    return response;
  }
);

export const getIntegrationStats = createAsyncThunk(
  "getIntegrationStats",
  async (data) => {
    console.log("data", data);
    const response = await api.get(
      `/employee/${data.id}/all_stats${
        data?.startDate && data?.endDate
          ? `?start_date=${data.startDate}&end_date=${data.endDate}`
          : ""
      }`
    );
    return response;
  }
);

export const getIntegrationStat = createAsyncThunk(
  "getIntegrationStat",
  async (data) => {
    const response = await api.get(
      `/employee/${data.id}/integartion_stat/${data.integration}/${data.period}`
    );
    return response;
  }
);

export const { setIntegrationStartDate, setIntegrationEndDate } =
  employee.actions;

export default employee.reducer;
