import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { TextInput, Tooltip } from "components";
import { getSectionTagById, editSection } from "slices/sectionSlice";

import Item from "../Item";

import styles from "./About.module.sass";

const About = ({ className }) => {
  const [name, setName] = useState();
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const params = useParams();

  const notify = () => {
    toast.success("Successfully Updated Section Details!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  useEffect(() => {
    (async () => {
      const action = await dispatch(getSectionTagById(params.id));

      if (action.payload) {
        setName(action.payload?.data?.section_tag?.name);
        setDescription(action.payload?.data?.section_tag?.description);
      }
    })();
  }, [dispatch, params.id]);

  const onSaveHandler = () => {
    if (!name) return;
    dispatch(editSection({ id: params.id, name, description }));
    notify();
  };

  // TODO: add initial values by calling getSectionById
  return (
    <Item
      className={cn(styles.card, className)}
      title="Team details and Information"
      classTitle="title-green"
    >
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Team's name"
          name="section-name"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />

        <div className={cn(styles.label)}>
          Description
          <Tooltip
            className={styles.tooltip}
            title={"Description"}
            icon="info"
            place="right"
          />
        </div>
        <textarea
          name="description"
          value={description}
          placeholder="Description"
          className={styles.description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className={styles.divider} />
        <button
          className={cn("button", styles.button)}
          onClick={onSaveHandler}
        >
          Save
        </button>
      </div>
    </Item>
  );
};

export default About;
