import { useEffect, useState, useRef } from "react";

import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { Card, Indicator, ActivityFilters } from "components";
import { getCompanyIntegrations } from "slices/homeSlice";
import { getTeamActivities } from "slices/teamSlice";
import {
  periodConverter,
  getTeamIntegrations,
  getActivitiesSumByIntegration,
} from "utils";

import styles from "./Stats.module.sass";

const Stats = ({ teamName }) => {
  const dispatch = useDispatch();

  const { selectedFilterInterval } = useSelector((state) => state.app);
  const { teamActivities } = useSelector((state) => state.team);
  const { integrationStartDate, integrationEndDate } = useSelector(
    (state) => state.employee
  );

  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const teamIntegrations = useRef([]);

  useEffect(() => {
    dispatch(getCompanyIntegrations());

    if (integrationStartDate && integrationEndDate) {
      dispatch(
        getTeamActivities({
          name: teamName,
          startDate: format(integrationStartDate, "yyyy-MM-dd"),
          endDate: format(integrationEndDate, "yyyy-MM-dd"),
        })
      );
    } else {
      dispatch(
        getTeamActivities({
          name: teamName,
          period: periodConverter(selectedFilterInterval),
        })
      );
    }
  }, [
    dispatch,
    integrationEndDate,
    integrationStartDate,
    selectedFilterInterval,
    teamName,
  ]);

  useEffect(() => {
    teamIntegrations.current = getTeamIntegrations(
      teamActivities?.data[0] || {}
    );
  }, [teamActivities]);

  const activities = getActivitiesSumByIntegration(
    teamActivities,
    selectedIntegration
  );

  return (
    <Card
      className={styles.card}
      classTitle={styles.titleContainer}
      classCardHead={styles.cardHead}
      title={
        <div className={styles.head}>
          <div className={styles.title}>
            <span>Activities ({teamActivities?.counter})</span>

            <Indicator value={teamActivities?.weeklyPercentage} />
          </div>
        </div>
      }
    >
      <ActivityFilters
        filters={teamIntegrations.current}
        onChange={(value) =>
          setSelectedIntegration(value === "All" ? null : value)
        }
      />
      <div className={styles.chart}>
        <ResponsiveContainer
          width="100%"
          height="100%"
        >
          <BarChart
            width={500}
            height={300}
            data={activities}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            barSize={32}
            barGap={8}
          >
            <CartesianGrid
              strokeDasharray="none"
              stroke={"#EFEFEF"}
              vertical={false}
            />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={{
                fontSize: 12,
                fontWeight: "500",
                fill: "#6F767E",
                whiteSpace: "wrap",
              }}
              padding={{ left: 10 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#272B30",
                borderColor: "rgba(255, 255, 255, 0.12)",
                borderRadius: 8,
                boxShadow:
                  "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
              }}
              labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
              itemStyle={{
                padding: 0,
                textTransform: "capitalize",
                fontSize: 12,
                fontWeight: "600",
                color: "#fff",
              }}
              cursor={{ fill: "#f3f2f3" }}
            />
            <Bar
              dataKey="activities"
              fill="#B5E4CA"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default Stats;
