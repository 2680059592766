import { useState } from "react";

import cn from "classnames";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "components";
import {
  setIntegrationStartDate,
  setIntegrationEndDate,
} from "slices/employeeSlice";

import styles from "./GenerateReport.module.sass";
import Item from "./Item";

const GenerateReport = ({ visible, onClose, onConfirm }) => {
  const dispatch = useDispatch();
  const [visibleDate, setVisibleDate] = useState(false);

  const { integrationStartDate, integrationEndDate } = useSelector(
    (state) => state.employee
  );

  const onChange = (dates) => {
    const [start, end] = dates;
    dispatch(setIntegrationStartDate(start));
    dispatch(setIntegrationEndDate(end));
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      outerClassName={styles.modal}
    >
      <div className={cn(styles.title)}>Date range for Employee activity</div>

      <div className={styles.head}>
        Select the range you’d like to get a report from:
      </div>
      <div>
        <Item
          className={styles.item}
          category="Date"
          icon="calendar"
          value={
            integrationStartDate && integrationEndDate
              ? `${format(integrationStartDate, "MMMM dd, yyyy")} -
                ${format(integrationEndDate, "MMMM dd, yyyy")}`
              : "-"
          }
          visible={visibleDate}
          setVisible={setVisibleDate}
        >
          <div className={styles.date}>
            <DatePicker
              selected={integrationStartDate}
              selectsRange
              startDate={integrationStartDate}
              endDate={integrationEndDate}
              onChange={onChange}
              maxDate={new Date()}
              dateFormatCalendar={"MMMM yyyy"}
              inline
            />
            <div className={styles.foot}>
              <button
                className={cn("button-small button-stroke", styles.button)}
                onClick={() => {
                  dispatch(setIntegrationStartDate(null));
                  dispatch(setIntegrationEndDate(null));
                  setVisibleDate(false);
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </Item>
      </div>

      <div className={styles.foot}>
        <button
          className={cn("button", styles.button, styles.confirm)}
          onClick={onConfirm}
          disabled={!integrationStartDate || !integrationEndDate}
        >
          Generate Report
        </button>
        <button
          className={cn("button button-stroke", styles.button)}
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default GenerateReport;
