import axios from "axios";

export const authHeader = () => {
  const token = localStorage.getItem("token");
  let header = { "Content-Type": "application/json" };
  if (token) {
    header = { ...header, Authorization: `Bearer ${token}` };
  }
  return header;
};

const logOut = () => {
  localStorage.removeItem("token");
  window.location.href = "/sign-in";
};

const BASE_URL = `${window.location.origin}/api`;
// const BASE_URL = "https://dev.metra.ai/api"; // Uncomment this line to use the app server on localhost (comment the line above)

//Change to true when testing with base URL pointing to http://localhost
export const localHTTP = false;

// Create axios instances for each version
const createAxiosInstance = (version) => {
  const baseURL = `${BASE_URL}/${version}`;

  const instance = axios.create({
    baseURL,
    headers: authHeader(),
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        instance.defaults.headers.common["Authorization"] = null;
        logOut();
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

const apiV1AI = createAxiosInstance("ai/v1");
const apiV2 = createAxiosInstance("v2");
const axiosV1 = createAxiosInstance("v1");

export { apiV2, apiV1AI };
export default axiosV1;
