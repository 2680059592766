import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { IntegrationCard } from "components";
import { getAllIntegrations } from "slices/integrationSlice";
import {
  getSectionIntegrations,
  addIntegrationsToSection,
  removeIntegrationFromSection,
} from "slices/sectionSlice";

import Item from "../Item";

import styles from "./Integrations.module.sass";

const innerTabs = ["Available in Company", "Active on Section"];

const Integrations = ({ className }) => {
  const [activeTab, setActiveTab] = useState(innerTabs[0]);
  const [sectionIntegrations, setSectionIntegrations] = useState([]);
  const [sectionIntegrationsIds, setSectionIntegrationsIds] = useState({});

  const params = useParams();

  const dispatch = useDispatch();
  const { allIntegrations } = useSelector((state) => state.integration);

  useEffect(() => {
    setActiveTab(innerTabs[1]);
  }, []);

  useEffect(() => {
    (async () => {
      await dispatch(getAllIntegrations());
      const {
        payload: {
          data: { integrations },
        },
      } = await dispatch(getSectionIntegrations(params["id"]));
      setSectionIntegrations(integrations || []);
      setSectionIntegrationsIds(
        integrations.reduce((acc, currValue) => {
          acc[currValue.id] = true;
          return acc;
        }, {})
      );
    })();
  }, [dispatch, params]);

  const onIntegrateHandler = async (integrationId) => {
    const action = await dispatch(
      addIntegrationsToSection({
        sectionTagId: params["id"],
        integrations: [integrationId],
      })
    );

    if (action?.payload?.status === 200) {
      setSectionIntegrationsIds((prevState) => ({
        ...prevState,
        [integrationId]: true,
      }));
    }
  };

  const onRemoveIntegrateHandler = async (integrationId) => {
    const action = await dispatch(
      removeIntegrationFromSection({
        sectionTagId: params["id"],
        integrationId: integrationId,
      })
    );

    if (action?.payload?.status === 200) {
      setSectionIntegrationsIds((prevState) => ({
        ...prevState,
        [integrationId]: false,
      }));
    }
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Integrations"
      classTitle="title-green"
      head={
        <div className={styles.nav}>
          {innerTabs?.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x === activeTab,
              })}
              onClick={() => setActiveTab(x)}
              key={index}
            >
              {`${x} (${
                x === "Available in Company"
                  ? allIntegrations?.length
                  : sectionIntegrations?.length
              })`}
            </button>
          ))}
        </div>
      }
    >
      {innerTabs?.map(
        (nav, index) =>
          activeTab === nav && (
            <div
              className={styles.row}
              key={index}
            >
              {/* TODO: Implement onIntegrate and onRemove. Needs Endpoints */}
              {nav === "Available in Company" &&
                allIntegrations?.map((integration) => (
                  <IntegrationCard
                    integration={integration}
                    integrated={sectionIntegrationsIds[integration.id]}
                    onIntegrate={() => onIntegrateHandler(integration.id)}
                    onRemove={() => onRemoveIntegrateHandler(integration.id)}
                  />
                ))}
              {nav === "Active on Section" &&
                sectionIntegrations?.map((integration) => (
                  <IntegrationCard
                    integration={integration}
                    integrated
                    onIntegrate={() => onIntegrateHandler(integration.id)}
                    onRemove={() => onRemoveIntegrateHandler(integration.id)}
                  />
                ))}
            </div>
          )
      )}
    </Item>
  );
};

export default Integrations;
