import cn from "classnames";

import { ReactComponent as AddCircled } from "assets/icons/add-circled.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { Avatar, Icon } from "components";

import styles from "./IntegrationCard.module.sass";

const IntegrationCard = ({
  integration,
  integrated = false,
  onIntegrate,
  onRemove,
}) => {
  return (
    <div className={styles.integration}>
      <div className={styles.head}>
        <div className={styles.avatar}>
          <Avatar
            src={`/images/integration/${(
              integration?.title || integration?.name
            )?.toLowerCase()}.png`}
            alt="Avatar"
          />
          <div className={cn("h5", styles.name)}>
            {integration?.title || integration?.name}
          </div>
        </div>
        {integration?.employees && (
          <div className={styles.stat}>
            <ProfileIcon />
            {integration?.employees} employees
          </div>
        )}
      </div>
      <p className={styles.description}>{integration.description}</p>
      <div className={styles.foot}>
        <div className={styles.metrics}>
          {"</> "}
          {integration?.metrics?.split("").length > 0
            ? integration?.metrics?.split(", ").length
            : 0}{" "}
          metrics
        </div>
        {integrated ? (
          <button
            className={cn("button button-stroke-red button-small", styles.button)}
            onClick={() => onRemove(integration)}
          >
            <Icon
              name="check"
              size="24"
            />
            Remove Integration
          </button>
        ) : (
          <button
            className={cn(
              "button button-small button-stroke-blue",
              styles.button
            )}
            onClick={() => onIntegrate(integration)}
          >
            <AddCircled />
            Add Integration to the Team
          </button>
        )}
      </div>
    </div>
  );
};

export default IntegrationCard;
