import cn from "classnames";

import { Icon, TextInput, Accordion } from "components";

import styles from "./Security.module.sass";

export const SecurityTitle = ({ onClick }) => (
  <div className={styles.subtitle}>
    <div>Security and Compliance protocols to ensure data protection.</div>
    <div
      className={cn(`button button-small button-transparent ${styles.button}`)}
      onClick={onClick}
    >
      See Privacy Policy
      <Icon name="arrow-right" />
    </div>
  </div>
);

export const SecurityBody = ({ questions, onChange, value }) => (
  <>
    <TextInput
      classInput={styles.search}
      className={styles.searchWrapper}
      placeholder="Search"
      onChange={onChange}
      value={value}
      type="text"
      name="search"
      icon="search"
    />

    {questions.map((item, index) => (
      <div key={`${index}-${item.topic}`}>
        <Accordion.Title
          title={item.topic}
          icon={item.icon}
          className={styles.accordionTitle}
        />

        <Accordion
          questions={item.questions}
          className={styles.accordion}
        />
      </div>
    ))}
  </>
);
