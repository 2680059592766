import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  integrateSalesforceIntegration, // TODO: work in this function when endpoint is ready
  validateSalesforceIntegration,
} from "slices/integrationSlice";

import Loader from "../../../../../components/Loader";

import styles from "./Integration.module.sass";

const Integration = ({ onClose, onboarding }) => {
  const { status, loading, error, testResponseSalesforce, integrating } =
    useSelector((state) => state.integration);
  const [informMessage, setInformMessage] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [values, setValues] = useState({});
  const [canProceed, setCanProceed] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleTestConnection = () => {
    dispatch(validateSalesforceIntegration(values));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setInformMessage(null);
    setFormSubmitted(true);
    const formData = new FormData();
    Object.keys(values).forEach((value) => {
      formData.append(value, values[value]);
    });
    dispatch(integrateSalesforceIntegration(formData));
  };

  const preventSubmit = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    setCanProceed(
      !Object.keys(values).some((val) => values[val] === "") &&
        Object.keys(values).length >= 3
    );
  }, [values]);

  useEffect(() => {
    if (formSubmitted) {
      if (error && integrating === "salesforce") {
        setInformMessage("Integration failed to start.");
      } else if (status === "succeeded") {
        setInformMessage(
          "Integration is in progress, we will notify you once it is complete."
        );
        setTimeout(onClose, 5000);
      }
    }
  }, [status, error, formSubmitted, onClose, integrating]);

  useEffect(() => {
    if (integrating !== "salesforce") setInformMessage(error);
    let timer = setTimeout(() => setInformMessage(null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [error, integrating]);

  useEffect(() => {
    setInformMessage(testResponseSalesforce);
    let timer = setTimeout(() => setInformMessage(null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [testResponseSalesforce]);

  return (
    <div
      className={cn(styles.integration, {
        [styles.onboarding]: onboarding,
      })}
    >
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.content}>
              <form onSubmit={preventSubmit}>
                <input
                  name="domain_name"
                  placeholder="Domain Name"
                  className={styles.input}
                  type="text"
                  onChange={handleChange}
                />
                <input
                  name="consumer_key"
                  placeholder="Consumer Key"
                  className={styles.input}
                  type="text"
                  onChange={handleChange}
                />
                <input
                  name="consumer_secret"
                  placeholder="Consumer Secret"
                  className={styles.input}
                  type="text"
                  onChange={handleChange}
                />
                {informMessage && (
                  <div className={styles.errorNote}>{informMessage}</div>
                )}
                <button
                  className={cn(
                    `button button-stroke-blue button-small`,
                    styles.buttonTest
                  )}
                  onClick={handleTestConnection}
                >
                  Test Connection
                </button>
                <button
                  type="submit"
                  className={cn(`button button-small`, styles.button, {
                    disabled: !canProceed,
                  })}
                  onClick={handleSubmit}
                >
                  {loading && integrating === "salesforce" && (
                    <Loader
                      className={styles.loader}
                      white
                    />
                  )}
                  <span>Integrate</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
