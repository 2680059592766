import { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Modal, SectionTagCard } from "components";
import { getAllEmployees } from "slices/employeeSlice";
import {
  getAllSectionTags,
  assignEmployeeToSection,
  unAssignEmployeeToSection,
} from "slices/sectionSlice";

import styles from "./EmployeeToSectionModal.module.sass";

const EmployeeToSectionModal = ({ user, visible, onClose }) => {
  const dispatch = useDispatch();
  const { sectionTags } = useSelector((state) => state.section);
  const [employeeUpdated, setEmployeeUpdated] = useState(false);

  useEffect(() => {
    dispatch(getAllSectionTags());
  }, [dispatch]);

  const onAddHandler = async (section) => {
    setEmployeeUpdated(true);
    await dispatch(
      assignEmployeeToSection({ sectionTagId: section.id, employeeId: user.id })
    );
    // TODO: avoid this by requesting new endpoint
    await dispatch(getAllSectionTags());
  };

  const onRemoveHandler = async (section) => {
    setEmployeeUpdated(true);
    await dispatch(
      unAssignEmployeeToSection({
        sectionTagId: section.id,
        employeeId: user.id,
      })
    );
    // TODO: avoid this by requesting new endpoint
    await dispatch(getAllSectionTags());
  };

  const onCloseHandler = () => {
    onClose();
    const action = dispatch(getAllEmployees());
    if (employeeUpdated) {
      toast.promise(
        action,
        {
          pending: "Updating Employees...",
          success: "Employees updated!",
          error: "Failed to update Employees!",
        },
        {
          autoClose: 1500,
        }
      );
    }
  };

  return (
    <Modal
      visible={visible}
      onClose={onCloseHandler}
      outerClassName={styles.modal}
    >
      <div className={cn("title-purple", styles.title)}>
        Add Employee to a Team
      </div>
      {sectionTags?.length > 0 ? (
        <>
          <div className={styles.head}>
            Select in which Team you'd like to add this Employee to:
          </div>
          <div className={styles.sectionTags}>
            {sectionTags.map((sectionTag) => {
              if (sectionTag?.is_default) return null;
              return (
                <SectionTagCard
                  key={sectionTag.name}
                  section={sectionTag}
                  added={sectionTag.users.some((x) => x.id === user.id)}
                  onAdd={onAddHandler}
                  onRemove={onRemoveHandler}
                />
              );
            })}
          </div>
        </>
      ) : (
        <div className={styles.head}>You need to create a Team first.</div>
      )}
    </Modal>
  );
};

export default EmployeeToSectionModal;
