import { useState, useEffect } from "react";

import cn from "classnames";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import {
  setIntegrationStartDate,
  setIntegrationEndDate,
} from "slices/employeeSlice";

import Icon from "../../Icon";
import Modal from "../../Modal";

import styles from "./DatePicker.module.sass";
import Item from "./Item";

const initialDates = {
  start: null,
  end: null,
};

const DatePickerComponent = ({ iconsOnly, wrapperClass = "" }) => {
  const dispatch = useDispatch();
  const [visibleDate, setVisibleDate] = useState(false);
  const [datePicker, setDatePricker] = useState({ ...initialDates });

  const [visibleDatePicker, setVisibleDatePicker] = useState(false);
  const toggleDatePicker = () =>
    setVisibleDatePicker((prevVisibility) => !prevVisibility);

  const { integrationStartDate, integrationEndDate } = useSelector(
    (state) => state.employee
  );

  useEffect(() => {
    setDatePricker({ start: integrationStartDate, end: integrationEndDate });
  }, [integrationStartDate, integrationEndDate]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setDatePricker({ start, end });
  };

  const onActivateDateRange = () => {
    dispatch(setIntegrationStartDate(datePicker.start));
    dispatch(setIntegrationEndDate(datePicker.end));
    setVisibleDate(false);
    toggleDatePicker();
  };

  const clearDateRangeHandler = () => {
    dispatch(setIntegrationStartDate(null));
    dispatch(setIntegrationEndDate(null));
  };

  return (
    <div className={cn(styles.container, wrapperClass)}>
      {integrationStartDate && integrationEndDate ? (
        <button
          className={cn("button button-small", styles.calendarButton)}
          onClick={toggleDatePicker}
        >
          <span>
            <CalendarIcon />
          </span>
        </button>
      ) : (
        <button
          className={cn(
            "button button-stroke button-small",
            styles.lightButton
          )}
          onClick={toggleDatePicker}
        >
          <span>
            <CalendarIcon />
          </span>
          {iconsOnly ? null : <span>Apply Date Range</span>}
        </button>
      )}

      {integrationStartDate && integrationEndDate && (
        <div className={styles.rangeText}>
          Range Active:
          <span>
            {format(integrationStartDate, "dd/MM/yyyy")} -{" "}
            {format(integrationEndDate, "dd/MM/yyyy")}
          </span>
          <div
            className={styles.close}
            onClick={clearDateRangeHandler}
          >
            <Icon
              name="close"
              size="20"
            />
          </div>
        </div>
      )}

      <Modal
        visible={visibleDatePicker}
        onClose={toggleDatePicker}
        outerClassName={styles.modal}
      >
        <div className={cn(styles.title)}>Date range for Employee activity</div>

        <div className={styles.head}>
          Select the date range you’d like to check for this employee’s activity
          throughout available services:
        </div>
        <div>
          <Item
            className={styles.item}
            category="Date"
            icon="calendar"
            value={
              datePicker.start && datePicker.end
                ? `${format(datePicker.start, "MMMM dd, yyyy")} -
                  ${format(datePicker.end, "MMMM dd, yyyy")}`
                : "-"
            }
            visible={visibleDate}
            setVisible={setVisibleDate}
          >
            <div className={styles.date}>
              <DatePicker
                selected={datePicker.start}
                selectsRange
                startDate={datePicker.start}
                endDate={datePicker.end}
                onChange={onChange}
                maxDate={new Date()}
                dateFormatCalendar={"MMMM yyyy"}
                inline
              />
              <div className={styles.foot}>
                <button
                  className={cn(
                    "button-small button-stroke",
                    styles.button,
                    styles.closeButton
                  )}
                  onClick={() => {
                    setVisibleDate(false);
                  }}
                >
                  Close window
                </button>
                {datePicker.start && datePicker.end && (
                  <button
                    className={cn("button-small button-stroke", styles.button)}
                    onClick={() => {
                      dispatch(setIntegrationStartDate(null));
                      dispatch(setIntegrationEndDate(null));
                      setDatePricker({ ...initialDates });
                      setVisibleDate(false);
                    }}
                  >
                    Clear selection
                  </button>
                )}
              </div>
            </div>
          </Item>
        </div>

        <div className={styles.foot}>
          <button
            className={cn("button", styles.button, styles.confirm)}
            onClick={onActivateDateRange}
            disabled={!datePicker.start || !datePicker.end}
          >
            Activate Date Range
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DatePickerComponent;
