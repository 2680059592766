import React from "react";

import cn from "classnames";

import { Modal } from "components";

import styles from "./DeleteModal.module.sass";

const Delete = ({ visible, onConfirm, onClose, errorDeleting }) => {
  return (
    <Modal
      visible={visible}
      onClose={onClose}
    >
      <div className={styles.delete}>
        <div className={styles.info}>Archive Confirmation</div>
        {errorDeleting ? (
          <div className={styles.text}>
            We encountered an error while attempting to delete the Employee.
            Please try again.
          </div>
        ) : (
          <div className={styles.text}>
            Are you sure you want to archive this employee?
          </div>
        )}
        {!errorDeleting && (
          <div className={styles.foot}>
            <button
              className={cn("button button-stroke", styles.button)}
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className={cn("button", styles.button, styles.confirm)}
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Delete;
