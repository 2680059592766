import styles from "./Onboarding.module.sass";

const Tooltip = ({
  continuous,
  step,
  stepIndex,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
  size,
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <div className={styles.title}>{step.title}</div>
      <span className={styles.step}>
        {stepIndex + 1}/{size}
      </span>
    </div>

    <div className={styles.body}>{step.content}</div>

    <div className={styles.footer}>
      {stepIndex !== 0 && (
        <button
          className={`button button-small button-transparent ${styles.back}`}
          {...backProps}
        >
          Back
        </button>
      )}
      {stepIndex === 0 && (
        <button
          className={`button button-small button-transparent ${styles.skip}`}
          {...skipProps}
        >
          Skip tips
        </button>
      )}
      <button
        className="button button-small"
        {...primaryProps}
      >
        {stepIndex === size - 1 ? "Done!" : "Next Tip"}
      </button>
    </div>
  </div>
);

export default Tooltip;
