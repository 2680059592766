import cn from "classnames";

import { ReactComponent as AddCircled } from "assets/icons/add-circled.svg";
import { Icon } from "components";

import styles from "./Insights.module.sass";

const EmptyState = ({ message, buttonText, action = undefined }) => (
  <div className={cn(styles.emptyState, !action && styles.withoutButton)}>
    <div className={styles.content}>
      <Icon
        className={styles.clock}
        name="dashed-clock"
        size="24"
        viewBox="0 0 20 20"
      />
      <span>{message || "Add data in order to get Insights"}</span>
    </div>
    {action && (
      <button
        className={cn("button button-small", styles.addNewButton)}
        onClick={action}
      >
        <AddCircled />
        <span>{buttonText || "Add new"}</span>
      </button>
    )}
  </div>
);

export default EmptyState;
