import React, { useState, useRef } from "react";

import cn from "classnames";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Dropdown from "../../components/Dropdown";
import {
  deleteIntegration,
  getMyIntegrations,
} from "../../slices/integrationSlice";

import IntegrationCredentials from "./IntegrationCredentials";
import IntegrationNotifications from "./IntegrationNotifications";
import IntegrationSpecifications from "./IntegrationSpecifications";
import styles from "./Settings.module.sass";

const Settings = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  let title = ["Integrations"];
  if (params) {
    Object.keys(params).forEach((param) => title.push(params[param]));
  }
  title.push("Settings");

  const scrollToSpecifications = useRef(null);
  const scrollToCredentials = useRef(null);
  const scrollToNotifications = useRef(null);

  const handleDeleteIntegration = async () => {
    if (window.confirm("Delete the integration?")) {
      const action = dispatch(deleteIntegration(params["integration"]));

      toast.promise(
        action,
        {
          pending: "Deleting Integration...",
          success: {
            render() {
              dispatch(getMyIntegrations());
              navigate("/integrations");
              return `Integration deleted!`;
            },
          },
          error: "Failed to delete Integration!",
        },
        {
          autoClose: 1500,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
    }
  };

  const navigation = [];

  if (params["integration"]) {
    navigation.push({
      title: "Specifications",
      scroll: scrollToSpecifications,
      component: <IntegrationSpecifications />,
      action: () =>
        scrollToSpecifications.current.scrollIntoView({ behavior: "smooth" }),
    });
    navigation.push({
      title: "Credentials",
      scroll: scrollToCredentials,
      component: <IntegrationCredentials />,
      action: () =>
        scrollToCredentials.current.scrollIntoView({ behavior: "smooth" }),
    });
    navigation.push({
      title: "Notifications",
      scroll: scrollToNotifications,
      component: <IntegrationNotifications />,
      action: () =>
        scrollToNotifications.current.scrollIntoView({ behavior: "smooth" }),
    });
  }

  if (params["notifications"]) {
    navigation.push({
      title: "Notifications",
      scroll: scrollToNotifications,
      component: <IntegrationNotifications />,
      action: () =>
        scrollToNotifications.current.scrollIntoView({ behavior: "smooth" }),
    });
  }
  if (params["integration"]) {
    navigation.push({
      title: "Delete Integration",
      action: () => handleDeleteIntegration(),
    });
  }

  const options = navigation.map((x) => x.title);
  
  const [activeTab, setActiveTab] = useState(options[0]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (x, index) => {
    setActiveIndex(index);
    setActiveTab(x.title);
    x.action();
  };

  const handleDropdownChange = (option) => {
    setActiveTab(option);
    const index = options.indexOf(option);
    setActiveIndex(index);
  };

  return (
    <>
      <div className={cn("h3", styles.title)}>
        {title.map((t, i) => (
          <span key={i}>
            {i === 0 ? " " : " / "}
            {t.replace("Outlook", "Exchange Online")}
          </span>
        ))}
      </div>
      <div className={styles.settings}>
        <div className={styles.menu}>
          {navigation?.map((x, index) => (
            <button
              className={cn(styles.button, {
                [styles.active]: activeIndex === index,
              })}
              key={index}
              onClick={() => handleClick(x, index)}
            >
              {x.title}
            </button>
          ))}
        </div>
        <div className={styles.wrapper}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={activeTab}
            setValue={handleDropdownChange}
            options={options}
          />

          {navigation[activeIndex]?.component}
        </div>
      </div>
    </>
  );
};

export default Settings;
