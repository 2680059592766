import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Icon, Modal, TextInput, Dropdown } from "components";
import { getCompanyRoles, updateCompany, getCompanyInformation } from "slices/companySlice";

import styles from "./AddDeleteUser.module.sass";

const AddUserModal = ({ visible, onClose, onAddUser, editor }) => {
  const [form, setForm] = useState({
    name_surname: "",
    username: "",
    email: "",
    role: "",
    is_editor: editor || false,
  });

  const dispatch = useDispatch();
  const { roles, company } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(getCompanyRoles());
  }, [dispatch]);

  useEffect(() => {
    setForm({
      name_surname: "",
      username: "",
      email: "",
      role: "",
      is_editor: editor || false,
    });
  }, [visible, editor]);


  const isFormValid = () => {
    return form.name_surname && form.username && form.email;
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleRoleChange = (value) => {
    setForm({
      ...form,
      role: value,
    });
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      outerClassName={styles.modal}
      title="Add User"
      className="modal-add-user"
    >
      <div>Add new User</div>
      <div className={styles.divider} />
      <div className={styles.addModalDescription}>
        Complete the fields below to send the invitation:
      </div>
      <TextInput
        className={styles.field}
        label="Full Name *"
        name="name_surname"
        value={form.name_surname}
        placeholder="John Doe"
        onChange={handleChange}
        type="text"
        required
      />
      <TextInput
        className={styles.field}
        label="Username *"
        name="username"
        value={form.username}
        placeholder="JohnDoe"
        onChange={handleChange}
        type="text"
        required
      />
      <TextInput
        className={styles.field}
        label="Email *"
        name="email"
        type="email"
        placeholder="john.doe@company.com"
        value={form.email}
        onChange={handleChange}
        required
      />
      {!editor && (
        <Dropdown
          classDropdownHead={styles.dropdownHead}
          value={form.role}
          setValue={handleRoleChange}
          options={roles?.split(",")}
          placeholder="Position or role here"
          label="Roles"
          name="roles"
          tooltip="-"
          enableCustomInput
          customPlaceholder="Type a new role and press Enter"
          onSubmitCustom={async (value) => {
            const formData = new FormData();
            formData.append("name", company.name || "");
            formData.append("email", company.email || "");
            formData.append("ai_key", company.aiKey || "");
            formData.append("roles", company.roles + `,${value}` || "");
            formData.append("location", company.location || "");
            formData.append("timezone", company.timezone || "");

            const updateCompanyRes = await dispatch(updateCompany(formData));
            await dispatch(getCompanyRoles());
            await dispatch(getCompanyInformation());

            setForm({
              ...form,
              role: value,
            });

            if (updateCompanyRes.payload) {
              toast.success("Role added successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else {
              toast.error("Error adding role", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }}
        />
      )}

      <div className={styles.addModalDescriptionSmall}>* Required fields</div>
      <div className={styles.sendInvitationButton}>
        <button
          className={cn("button button-small", { [styles.buttonDisabled]: !isFormValid() })}
          onClick={() => {
            if (isFormValid()) {
              onAddUser(form);
            } else {
              toast.error("Unable to send invitation. Please fill all the required fields.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }}
          disabled={!isFormValid()}
        >
          Send Invitation
          <Icon
            name="arrow-right"
            size="20"
          />
        </button>
      </div>
    </Modal>
  );
};

export default AddUserModal;
