import React from "react";

import cn from "classnames";

import Row from "./Row";
import styles from "./Table.module.sass";

const Table = ({ className, items, integrationId }) => {
  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.table)}>
        <div className={cn(styles.row)}>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Activities Pulled</div>
          <div className={styles.col}>Last Activity</div>
          <div className={styles.col}>Integrated Since</div>
          <div className={styles.col}></div>
        </div>
        {items &&
          items?.map((x, index) => (
            <Row
              item={x}
              key={index}
              integrationId={integrationId}
            />
          ))}
      </div>
      <div className={styles.foot}>
        {/* <button className={cn("button-stroke button-small", styles.button)}>
          <Loader className={styles.loader} />
          <span>Load more</span>
        </button> */}
      </div>
    </div>
  );
};

export default Table;
