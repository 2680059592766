import React, { useCallback, useEffect } from "react";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";

import { getMyIntegrations } from "slices/integrationSlice";

import Icon from "../../../../components/Icon";

import ConfluenceIntegration from "./ConfluenceIntegration";
import GithubIntegration from "./GithubIntegration";
import GoogleIntegration from "./GoogleIntegration";
import JiraIntegration from "./JiraIntegration";
import styles from "./ModalIntegration.module.sass";
import OutlookIntegration from "./OutlookIntegration";
import SalesforceIntegration from "./SalesforceIntegration";
import SlackIntegration from "./SlackIntegration";
import TeamsIntegration from "./TeamsIntegration";
import ZoomIntegration from "./ZoomIntegration";

const ModalIntegration = ({ visible, onClose, integration }) => {
  const dispatch = useDispatch();

  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [dispatch, escFunction]);

  useEffect(() => {
    return () => dispatch(getMyIntegrations());
  }, [dispatch]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal-integration");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);
  const getIntegrationModal = () => {
    if (integration === "Slack") {
      return <SlackIntegration onClose={() => onClose()} />;
    }
    if (integration === "Google") {
      return <GoogleIntegration onClose={() => onClose()} />;
    }
    if (integration === "Github") {
      return <GithubIntegration onClose={() => onClose()} />;
    }
    if (integration === "Jira") {
      return <JiraIntegration onClose={() => onClose()} />;
    }
    if (integration === "Zoom") {
      return <ZoomIntegration onClose={() => onClose()} />;
    }
    if (integration === "Confluence") {
      return <ConfluenceIntegration onClose={() => onClose()} />;
    }
    if (integration === "Teams") {
      return <TeamsIntegration onClose={() => onClose()} />;
    }
    if (integration === "Outlook") {
      return <OutlookIntegration onClose={() => onClose()} />;
    }
    if (integration === "Salesforce") {
      return <SalesforceIntegration onClose={() => onClose()} />;
    }
  };
  return createPortal(
    visible && (
      <div
        id="modal-integration"
        className={styles.modal}
      >
        <div className={styles.control}>
          <button
            className={styles.close}
            onClick={onClose}
          >
            <Icon
              name="close"
              size="20"
            />
          </button>
        </div>
        <div className={styles.outer}>{getIntegrationModal()}</div>
      </div>
    ),
    document.body
  );
};

export default ModalIntegration;
