import React from "react";

import cn from "classnames";

import { Icon, Loader } from "components";

import styles from "./NoResult.module.sass";

const NoResult = ({ loading, onCreate }) => (
  <div className={styles.noResult}>
    {loading ? (
      <div className={styles.loader}>
        <Loader />
      </div>
    ) : (
      <Icon
        className={styles.clock}
        name="dashed-clock"
        size="24"
        viewBox="0 0 20 20"
      />
    )}
    {loading && (
      <>
        <p>Your Employees are almost here!</p>
        <p>Please give us a moment to load them up.</p>
      </>
    )}

    {!loading && (
      <>
        <p>No results so far.</p>
        <p>
          Employees will be automatically added once you Integrate a service.
        </p>

        <button
          className={cn("button button-small", styles.button)}
          onClick={onCreate}
        >
          <Icon
            name="add"
            size="24"
          />
          <span>Add new Integration</span>
        </button>
      </>
    )}
  </div>
);

export default NoResult;
