import React, { useState, useEffect } from "react";

import cn from "classnames";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import Icon from "../../../../../components/Icon";
import Loader from "../../../../../components/Loader";
import { integrateSlackIntegration } from "../../../../../slices/integrationSlice";

import styles from "./Integration.module.sass";
import Schedule from "./Schedule";

const Integration = ({ onClose, onboarding }) => {
  const { status, loading, error, integrating } = useSelector(
    (state) => state.integration
  );
  const [informMessage, setInformMessage] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [canProceed, setCanProceed] = useState(false);

  const [csvFile, setCsvFile] = useState(null);
  const [integrationDate, setIntegrationDate] = useState(new Date());

  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
      if (allowedTypes.includes(file.type)) {
        setCsvFile(file);
        setInformMessage(null);
        setCanProceed(true);
      } else {
        setCsvFile(null);
        setInformMessage("Invalid file format. Please upload a CSV or Excel file.");
        setCanProceed(false);
      }
    }
  };

  const preventSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (csvFile) {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }, [csvFile]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (csvFile && canProceed) {
      setInformMessage(null);
      setFormSubmitted(true);
      const formData = new FormData();
      formData.append("csv_file", csvFile);
      formData.append("date", format(integrationDate, "yyyy-MM-dd"));

      dispatch(integrateSlackIntegration(formData));
    }
  };

  useEffect(() => {
    if (formSubmitted) {
      if (error && integrating === "slack") {
        setInformMessage("Integration failed to start.");
      } else if (status === "succeeded") {
        setInformMessage(
          "Integration is in progress, we will notify you once it is completed."
        );
        setTimeout(onClose, 5000);
      }
    }
  }, [status, error, formSubmitted, onClose, integrating]);

  return (
    <div
      className={cn(styles.integration, {
        [styles.onboarding]: onboarding,
      })}
    >
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red", styles.subtitle)}>
              Slack Integration
            </div>
            <div className={styles.content}>
              <form onSubmit={preventSubmit}>
                <div className={styles.wrap}>
                  <input
                    className={styles.input}
                    type="file"
                    onChange={handleFileChange}
                  />
                  <div className={styles.box}>
                    <Icon
                      name="upload"
                      size="24"
                    />
                    {"Slack"}
                  </div>
                  {csvFile && (
                    <div className={styles.errorNote}>{csvFile.name}</div>
                  )}
                  {informMessage && !csvFile && (
                    <div className={styles.errorNote}>{informMessage}</div>
                  )}
                </div>
                <Schedule
                  integrationDate={integrationDate}
                  setIntegrationDate={setIntegrationDate}
                  onboarding={onboarding}
                />
                {informMessage && csvFile && (
                  <div className={styles.errorNote}>{informMessage}</div>
                )}
                <button
                  type="submit"
                  className={cn(`button button-small`, styles.button, {
                    disabled: !canProceed || loading,
                  })}
                  onClick={handleSubmit}
                >
                  {loading && integrating === "slack" && (
                    <Loader className={styles.loader} white />
                  )}
                  <span>Integrate</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
