import cn from "classnames";

import { ReactComponent as SortAscIcon } from "assets/icons/sort-asc.svg";
import { ReactComponent as SortDescIcon } from "assets/icons/sort-desc.svg";
import { UserDetails } from "components";

import styles from "./Activities.module.sass";
import LineChart, { LineLegend } from "./LineChart";

const getColumns = (legend, sort, onSort) => [
  {
    title: (
      <div
        className={cn(styles.col, {
          [styles.selected]: sort.sort_by === "name",
        })}
      >
        <span onClick={() => onSort("name")}>
          {sort.sort_by === "name" && sort.sort_order === "desc" ? (
            <SortAscIcon />
          ) : (
            <SortDescIcon />
          )}
        </span>
        <span onClick={() => onSort("name")}>Employee</span>
      </div>
    ),
    key: "name",
    dataIndex: "data",
    render: (data) => (
      <>
        <UserDetails user={data} />
        <div className={styles.tags}>
          {/* {data.tags_array.map((tag) => (
            <Tag name={tag} random />
          ))} */}
        </div>
      </>
    ),
  },
  {
    title: (
      <LineLegend
        legend={legend}
        sort={sort}
        onSort={onSort}
      />
    ),
    key: "data",
    dataIndex: "data",
    render: (data) => {
      return <LineChart data={data} />;
    },
  },
];

export default getColumns;
