import React, { useState, useEffect } from "react";

import cn from "classnames";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import { File, TextInput, Loader, TextArea } from "components";

import { integrateGoogleIntegration } from "../../../../../slices/integrationSlice";

import styles from "./Integration.module.sass";
import Schedule from "./Schedule";

const FORM_FIELDS = [
  "delegated_email",
  "type",
  "project_id",
  "private_key_id",
  "private_key",
  "client_email",
  "client_id",
  "auth_uri",
  "token_uri",
  "auth_provider_x509_cert_url",
  "client_x509_cert_url",
  "access_token",
];

const Integration = ({ onClose, onboarding }) => {
  const { status, loading, error, integrating } = useSelector(
    (state) => state.integration
  );
  const [informMessage, setInformMessage] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [values, setValues] = useState(
    FORM_FIELDS.reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {})
  );
  const [canProceed, setCanProceed] = useState(false);
  const calculateStartDate = new Date();
  calculateStartDate.setDate(calculateStartDate.getDate() - 14);
  const [integrationStartDate, setIntegrationStartDate] =
    useState(calculateStartDate);
  const [integrationEndDate, setIntegrationEndDate] = useState(new Date());
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setCanProceed(
      values.access_token?.length > 0 ||
        (!Object.keys(values)
          .filter((val) => val !== "access_token")
          .some((val) => values[val] === "") &&
          Object.keys(values).length >= 10)
    );
  }, [values]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setInformMessage(null);
    setFormSubmitted(true);
    const formData = new FormData();
    Object.keys(values).forEach((value) => {
      formData.append(value, values[value]);
    });
    formData.append("start_date", format(integrationStartDate, "yyyy-MM-dd"));
    formData.append("end_date", format(integrationEndDate, "yyyy-MM-dd"));

    dispatch(integrateGoogleIntegration(formData));
  };

  const preventSubmit = (event) => {
    event.preventDefault();
  };

  const loadFromFile = (files, setValues) => {
    if (!files.length) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);
        const extractedObject = {};
        for (const key of FORM_FIELDS) {
          if (data[key]) {
            extractedObject[key] = data[key];
          }
        }
        if (extractedObject) {
          setValues((prev) => ({ ...prev, ...extractedObject }));
        }
      } catch (error) {
        console.error("Error parsing file", error);
      }
    };
    reader.readAsText(files[0]);
  };

  useEffect(() => {
    if (formSubmitted) {
      if (error && integrating === "google") {
        setInformMessage("Integration failed to start.");
      } else if (status === "succeeded") {
        setInformMessage(
          "Integration is in progress, we will notify you once it is complete."
        );
        setTimeout(onClose, 5000);
      }
    }
  }, [status, error, formSubmitted, onClose, integrating]);

  return (
    <div
      className={cn(styles.integration, {
        [styles.onboarding]: onboarding,
      })}
    >
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red", styles.subtitle)}>
              Google Integration
            </div>
            <div className={styles.content}>
              <form onSubmit={preventSubmit}>
                <Schedule
                  integrationStartDate={integrationStartDate}
                  setIntegrationStartDate={setIntegrationStartDate}
                  integrationEndDate={integrationEndDate}
                  setIntegrationEndDate={setIntegrationEndDate}
                  onboarding={onboarding}
                />
                <div className={styles.divider}>Service Account</div>
                <File
                  title="Load Credentials File"
                  className={styles.file}
                  onChange={(e) => {
                    loadFromFile(e, setValues);
                  }}
                  accept={{ "application/json": [".json"] }}
                />
                <TextInput
                  name="delegated_email"
                  placeholder="**** - **** - *****"
                  label="Delegated email"
                  className={styles.input}
                  type="text"
                  value={values.delegated_email}
                  onChange={handleChange}
                />
                <TextInput
                  name="type"
                  placeholder="**** - **** - *****"
                  label="Type"
                  className={styles.input}
                  type="text"
                  value={values.type}
                  onChange={handleChange}
                />
                <TextInput
                  name="project_id"
                  placeholder="**** - **** - *****"
                  label="Project id"
                  className={styles.input}
                  type="text"
                  value={values.project_id}
                  onChange={handleChange}
                />
                <TextInput
                  name="private_key_id"
                  placeholder="**** - **** - *****"
                  label="Private key id"
                  className={styles.input}
                  type="text"
                  value={values.private_key_id}
                  onChange={handleChange}
                />

                <TextArea
                  name="private_key"
                  placeholder="Private key"
                  label="Private key"
                  className={styles.textarea}
                  value={values.private_key}
                  onChange={handleChange}
                />
                {/* <textarea
                  name="private_key"
                  placeholder="Private key"
                  className={styles.textarea}
                  value={values.private_key}
                  onChange={handleChange}
                /> */}
                <TextInput
                  name="client_email"
                  placeholder="**** - **** - *****"
                  label="Client email"
                  className={styles.input}
                  type="text"
                  value={values.client_email}
                  onChange={handleChange}
                />
                <TextInput
                  name="client_id"
                  placeholder="**** - **** - *****"
                  label="Client id"
                  className={styles.input}
                  type="text"
                  value={values.client_id}
                  onChange={handleChange}
                />
                <TextInput
                  name="auth_uri"
                  placeholder="**** - **** - *****"
                  label="Auth uri"
                  className={styles.input}
                  type="text"
                  value={values.auth_uri}
                  onChange={handleChange}
                />
                <TextInput
                  name="token_uri"
                  placeholder="**** - **** - *****"
                  label="Token_uri"
                  className={styles.input}
                  type="text"
                  value={values.token_uri}
                  onChange={handleChange}
                />
                <TextInput
                  name="auth_provider_x509_cert_url"
                  placeholder="**** - **** - *****"
                  label="Auth provider x509 cert url"
                  className={styles.input}
                  type="text"
                  value={values.auth_provider_x509_cert_url}
                  onChange={handleChange}
                />
                <TextInput
                  name="client_x509_cert_url"
                  placeholder="**** - **** - *****"
                  label="Client x509 cert url"
                  className={styles.input}
                  type="text"
                  value={values.client_x509_cert_url}
                  onChange={handleChange}
                />
                <div className={styles.divider}> OR</div>
                <TextInput
                  name="access_token"
                  placeholder="**** - **** - *****"
                  label="Access Token"
                  className={styles.input}
                  type="text"
                  value={values.access_token}
                  onChange={handleChange}
                />

                {informMessage && (
                  <div className={styles.errorNote}>{informMessage}</div>
                )}
                <button
                  type="submit"
                  className={cn(`button button-small`, styles.button, {
                    disabled: !canProceed || loading,
                  })}
                  onClick={handleSubmit}
                >
                  {loading && integrating === "google" && (
                    <Loader
                      className={styles.loader}
                      white
                    />
                  )}
                  <span>Integrate</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
