import React from "react";

import cn from "classnames";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Icon from "../../../../Icon";
import Integration from "../../../../Integration";

import styles from "./Integrations.module.sass";

export const integrations = [
  {
    id: 0,
    integration: "Safa ERDEN",
    link: "metra.ai/integration/integration-link",
    image: "/images/content/avatar-1.jpg",
    value: 98,
    date: "Apr 3, 2021 at 3:55 PM",
    ratingValue: 4.8,
    ratingCounter: 87,
  },
  {
    id: 1,
    integration: "Diego Makarausky",
    link: "metra.ai/integration/integration-link",
    image: "/images/content/avatar-2.jpg",
    value: 123,
    date: "Apr 6, 2021 at 6:55 PM",
    ratingValue: 4.9,
    ratingCounter: 123,
  },
  {
    id: 2,
    integration: "Radoslav Panev",
    link: "metra.ai/integration/integration-link",
    image: "/images/content/avatar-3.jpg",
    value: 0,
    date: "Apr 10, 2021 at 10:55 PM",
  },
  {
    id: 3,
    integration: "Julieta Verónica Beatrice",
    link: "metra.ai/integration/integration-link",
    image: "/images/content/avatar-4.jpg",
    value: 43,
    date: "Apr 12, 2021 at 12:55 PM",
    ratingValue: 4.6,
    ratingCounter: 12,
  },
  {
    id: 4,
    integration: "Slav Galev",
    link: "metra.ai/integration/integration-link",
    image: "/images/content/avatar-5.jpg",
    value: 8,
    date: "Apr 19, 2021 at 19:55 PM",
    ratingValue: 3.2,
    ratingCounter: 159,
  },
  {
    id: 5,
    integration: "Julieta Verónica Beatrice",
    link: "metra.ai/integration/integration-link",
    image: "/images/content/avatar.jpg",
    value: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Integrations = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon
          name="arrow-right"
          size="24"
        />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon
          name="arrow-left"
          size="24"
        />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className={styles.integrations}>
      <div className={styles.head}>
        <div className={cn("title-green", styles.title)}>More like this</div>
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to="/integrations-dashboard"
        >
          <span>View all</span>
          <Icon
            name="arrow-right"
            size="24"
          />
        </Link>
      </div>
      <div className={styles.wrapper}>
        <Slider
          className="integrations-slider"
          {...settings}
        >
          {integrations.map((x, index) => (
            <Integration
              className={styles.integration}
              item={x}
              key={index}
              withoutСheckbox
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Integrations;
