import React from "react";

import { Link } from "react-router-dom";

import { Accordion } from "../../components";
import Icon from "../../components/Icon";
import Image from "../../components/Image";

import styles from "./PrivacyPolicy.module.sass";
import questions from "./questions";

const PrivacyPolicy = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <Link
          className={styles.link}
          to="/sign-up"
        >
          <Icon
            name="arrow-left"
            size="16"
          />
        </Link>{" "}
        Back to Sign Up
      </div>
      <div className={styles.head}>
        <Link
          className={styles.logo}
          to="/"
        >
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
      </div>
      <div className={styles.agreements}>
        <div className={styles.agreementsTitle}>Privacy Policy and FAQ</div>

        {questions.map((item, index) => (
          <div key={`${index}-${item.topic}`}>
            <Accordion.Title
              title={item.topic}
              icon={item.icon}
              className={styles.accordionTitle}
            />

            <Accordion
              questions={item.questions}
              className={styles.accordion}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
