import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import { onlyUnique } from "../../../utils";

import styles from "./AllIntegrations.module.sass";
import Integrations from "./Integrations";

const AllIntegrations = () => {
  const dispatch = useDispatch();
  const { allIntegrations } = useSelector((state) => state.integration);
  const [navigation, setNavigation] = useState([]);
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    if (allIntegrations) {
      const nav = allIntegrations
        ?.map((integration) => integration.status)
        .filter(onlyUnique);
      nav?.unshift("All");
      setNavigation([...nav]);
      setActiveTab(nav[0] || "");
    }
  }, [allIntegrations, dispatch]);

  return (
    <Card
      className={styles.card}
      title="All Integrations"
      classTitle={cn("title-transparent", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <div className={cn(styles.nav, "tablet-hide")}>
            {navigation?.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x === activeTab,
                })}
                onClick={() => setActiveTab(x)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
          <div className={cn(styles.dropdown, "tablet-show")}>
            <Dropdown
              classDropdownHead={styles.dropdownHead}
              value={activeTab}
              setValue={setActiveTab}
              options={navigation}
              small
            />
          </div>
          {/* {allIntegrations?.length > 0 && (
            <Filters className={styles.filters} title="Smart Filter">
              <Settings filters={navigation} />
            </Filters>
          )} */}
        </>
      }
    >
      <div className={styles.departments}>
        <div className={styles.wrapper}>
          {navigation?.map(
            (nav, index) =>
              activeTab === nav && (
                <Integrations
                  key={index}
                  items={allIntegrations.filter(
                    (integration) => integration.status === nav || nav === "All"
                  )}
                />
              )
          )}
        </div>
      </div>
    </Card>
  );
};

export default AllIntegrations;
