import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import TextInput from "../../../components/TextInput";

import styles from "./Entry.module.sass";

const Entry = ({ onConfirm, title }) => {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    username: "",
  });

  const valuesHandler = (e) => {
    const prevValues = { ...values };
    prevValues[e.target.name] = e.target.value;
    setValues({ ...prevValues });
  };

  const { isLoggedIn, confirmationToken, token, error, loading } = useSelector(
    (state) => state.app
  );

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/settings");
    }
    if (!isLoggedIn && confirmationToken && !token) {
      navigate("/reminder");
    }
  }, [isLoggedIn, confirmationToken, token, navigate]);

  return (
    <>
      <div className={cn("h2", styles.title)}>{title}</div>
      <div className={styles.entry}>
        <div className={styles.body}>
          <div className={styles.subtitle}>
            Continue with email address or username
          </div>
          {error && <div className={styles.errorNote}>{error}</div>}
          <TextInput
            className={styles.field}
            name="username"
            type="email"
            placeholder="Your email/username"
            required
            icon="mail"
            onChange={(e) => valuesHandler(e)}
          />
          <button
            className={cn("button", styles.button)}
            onClick={() => onConfirm(values)}
          >
            {loading && (
              <Loader
                className={styles.loader}
                white
              />
            )}
            <span>Request a code to login</span>
          </button>
          <div className={styles.info}>
            Don’t have an account?{" "}
            <Link
              className={styles.link}
              to="/sign-up"
            >
              Sign up
            </Link>
          </div>
          <div className={styles.info}>
            Did you remember you password?{" "}
            <Link
              className={styles.link}
              to="/sign-in"
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Entry;
