import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useSelector } from "react-redux";

import Loader from "../../../components/Loader";
import TextInput from "../../../components/TextInput";
import useDebounce from "../../../hooks/useDebounce";
import { changePasswordValidationSchema } from "../../../validations/authValidations";
import Item from "../Item";

import styles from "./Login.module.sass";

const Login = ({ className, onSubmit }) => {
  const [validationErrors, setValidationErrors] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [canProceed, setCanProceed] = useState(false);
  const [value, setValue] = useState({
    key: "",
    value: "",
  });
  const debouncedValue = useDebounce(value, 500);
  const [values, setValues] = useState({
    password: "",
    passwordConfirmation: "",
  });

  const { error, loading, confirmationToken } = useSelector(
    (state) => state.app
  );
  const handleSubmit = () => {
    onSubmit(values);
  };

  const valuesHandler = (e) => {
    const prevValues = { ...values };
    prevValues[e.target.name] = e.target.value;
    setValue({
      key: e.target.name,
      value: e.target.value,
    });
    setValues({ ...prevValues });
  };
  useEffect(() => {
    let response = null;
    if (error) {
      response = error;
    }
    if (validationErrors) {
      response = error ? `${validationErrors}, ${error}` : validationErrors;
    }
    if (!error && !validationErrors) {
      setUserInfo(null);
    } else {
      if (error === "updated") {
        setUserInfo("Password updated successfully.");
        setCanProceed(false);
        setValidationErrors(null);
      } else {
        setUserInfo(response);
      }
    }
  }, [validationErrors, error, debouncedValue]);

  useEffect(() => {
    if (error === "updated") {
      setValues({
        password: "",
        passwordConfirmation: "",
      });
    }
  }, [error]);

  useEffect(() => {
    let key = debouncedValue.key;
    let data = {};
    data[key] = debouncedValue.value;
    if (debouncedValue.value) {
      changePasswordValidationSchema
        .validate(values, { abortEarly: false })
        .then((responseData) => {
          setUserInfo(null);
          setValidationErrors(null);
          setCanProceed(true);
          setValues(responseData);
        })
        .catch((err) => {
          if (err.errors.length > 0) {
            setCanProceed(false);
            setValidationErrors(err.errors.join(", "));
          } else {
            setCanProceed(true);
            setValidationErrors(null);
          }
        });
    }
  }, [debouncedValue, values]);
  return (
    <Item
      className={cn(styles.card, className)}
      title="Login"
      classTitle="title-purple"
    >
      <div className={styles.fieldset}>
        {userInfo && <div className={styles.errorNote}>{userInfo}</div>}
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="New password"
            name="password"
            type="password"
            value={values["password"]}
            onChange={(e) => valuesHandler(e)}
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            required
          />
          <TextInput
            className={styles.field}
            label="Confirm new password"
            name="passwordConfirmation"
            type="password"
            value={values["passwordConfirmation"]}
            onChange={(e) => valuesHandler(e)}
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            required
          />
        </div>
        {(confirmationToken !== "sent" || error === "updated") && (
          <div className={styles.foot}>
            <button
              className={cn("button-stroke button-small", styles.button, {
                disabled: !canProceed,
              })}
              onClick={handleSubmit}
            >
              {loading && <Loader className={styles.loader} />}
              <span>Request code to update password</span>
            </button>
          </div>
        )}
      </div>
    </Item>
  );
};

export default Login;
