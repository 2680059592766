import React, { useCallback, useEffect } from "react";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import cn from "classnames";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";

import Icon from "../Icon";

import Integration from "./Integration";
import styles from "./ModalEmployee.module.sass";

const ModalEmployee = ({ visible, onClose }) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal-integration");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  return createPortal(
    visible && (
      <div
        id="modal-integration"
        className={styles.modal}
      >
        <div className={styles.control}>
          <Link
            className={cn("button-white", styles.button)}
            to="/departments/add"
          >
            Edit integration
          </Link>
          <button
            className={styles.close}
            onClick={onClose}
          >
            <Icon
              name="close"
              size="20"
            />
          </button>
        </div>
        <div className={styles.outer}>
          <Integration />
        </div>
      </div>
    ),
    document.body
  );
};

export default ModalEmployee;
