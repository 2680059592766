import React from "react";

import cn from "classnames";

import Icon from "../Icon";

import styles from "./Form.module.sass";

const Form = ({
  className,
  onSubmit,
  placeholder,
  value,
  setValue,
  type,
  name,
  icon,
}) => {
  return (
    <form
      className={cn(className, styles.form)}
      action=""
      onSubmit={onSubmit}
    >
      <input
        className={cn(styles.input, { [styles.inputWithIcon]: icon })}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        name={name}
        placeholder={placeholder}
        required
      />
      <button className={styles.result}>
        <Icon
          name={icon}
          size="24"
        />
      </button>
    </form>
  );
};

export default Form;
