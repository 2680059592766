import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Icon, TableComponent, Item, Modal, Loader } from "components";
import { getCompanyUsers, deleteUser, inviteUser } from "slices/companySlice";

import styles from "./AddDeleteUser.module.sass";
import AddUserModal from "./AddUserModal";

const getColumns = (onRemoveEmployee) => [
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    render: (username) => <div className={styles.strong}>{username}</div>,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (email) => email,
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    render: (role) => (typeof role === "string" ? role : ""),
  },
  {
    render: (data) =>
      data.status === "invited" ? (
        <div className={styles.invited}>
          Pending activation
          <div
            onClick={() => onRemoveEmployee(data)}
            className={styles.removeIcon}
          >
            <Icon
              name="close"
              size="20"
            />
          </div>
        </div>
      ) : (
        <button
          className={cn(
            "button-stroke button-small",
            styles.button,
            styles.remove
          )}
          onClick={() => onRemoveEmployee(data)}
        >
          <span>Remove</span>
        </button>
      ),
  },
];

const AddDeleteUser = ({ className }) => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(getCompanyUsers());
  }, [dispatch]);

  const onRemoveEmployee = async (data) => {
    const action = await dispatch(deleteUser(data.id));
    if (deleteUser.rejected.match(action)) {
      toast.error("Failed to remove the employee. Please try again.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    toast.success("Successfully Removed Employee!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    dispatch(getCompanyUsers());
  };

  const onAddUser = async (data) => {
    setLoading(true);
    const action = await dispatch(inviteUser(data));
    setLoading(false);
    if (inviteUser.rejected.match(action)) {
      toast.error("Failed to invite the employee. Please try again.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (action.payload.data.error) {
      toast.error(action.payload.data.error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    setShowModal(false);
    setShowSuccessModal(true);
    dispatch(getCompanyUsers());
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Add or delete Users"
      classTitle="title-green"
      head={
        <button
          className={cn("button button-small", styles.button)}
          onClick={() => setShowModal(true)}
        >
          <Icon
            name="add"
            size="24"
          />
          <span>Add new User</span>
        </button>
      }
    >
      <TableComponent
        columns={getColumns(onRemoveEmployee)}
        data={users}
      />

      <AddUserModal
        visible={showModal && !loading}
        onClose={() => setShowModal(false)}
        onAddUser={onAddUser}
        editor={false}
      />

      <Modal
        visible={loading}
        hideCloseBtn={true}
      >
        <Loader
          className={styles.loader}
          black
        />
      </Modal>

      <Modal
        visible={showSuccessModal && !loading}
        hideCloseBtn={true}
        onClose={() => setShowSuccessModal(false)}
      >
        <div className={styles.sucessModal}>
          <div className={styles.title}>Invitation sent by email!</div>
          <div className={styles.message}>
            To access Metra, the User has to activate the account. Don't worry,
            we will notify you when this happens.
          </div>

          <button
            className={cn("button button-stroke", styles.button)}
            onClick={() => setShowSuccessModal(false)}
          >
            Back to settings&nbsp;
            <Icon
              name="arrow-right"
              size="20"
            />
          </button>
        </div>
      </Modal>
    </Item>
  );
};

export default AddDeleteUser;
