import { useState, useEffect } from "react";

import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Card, TableComponent, ActivityFilters, TextInput } from "components";
import NoResult from "screens/Integrations/NoResult";
import { getActivities } from "slices/homeSlice";
import { getAllIntegrations } from "slices/integrationSlice";
import { getTeamEmployees } from "slices/teamSlice";
import {
  periodConverter,
  getEmployeeActivities,
  getEmployeeIntegrations,
  capitalize,
} from "utils";

import styles from "./Activities.module.sass";
import getColumns from "./getColumns";
import getIntegrationColumns from "./getIntegrationColumns";

const Activities = ({ teamName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { intervals, selectedFilterInterval } = useSelector(
    (state) => state.app
  );
  const { teamEmployees } = useSelector((state) => state.team);
  const { activities } = useSelector((state) => state.home);
  const { allIntegrations } = useSelector((state) => state.integration);
  const { integrationStartDate, integrationEndDate } = useSelector(
    (state) => state.employee
  );

  const [integrationStatuses, setIntegrationStatuses] = useState({});
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({
    sort_by: "total_activities",
    sort_order: "desc",
  });
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  useEffect(() => {
    if (selectedIntegration) return;

    if (integrationStartDate && integrationEndDate) {
      dispatch(
        getTeamEmployees({
          name: teamName,
          startDate: format(integrationStartDate, "yyyy-MM-dd"),
          endDate: format(integrationEndDate, "yyyy-MM-dd"),
          ...sort,
        })
      );
    } else {
      dispatch(
        getTeamEmployees({
          name: teamName,
          period: periodConverter(selectedFilterInterval),
          ...sort,
        })
      );
    }
  }, [
    dispatch,
    integrationStartDate,
    integrationEndDate,
    teamName,
    selectedFilterInterval,
    sort,
    selectedIntegration,
  ]);

  const [teamIntegrations, setTeamIntegrations] = useState([]);
  // Because we have bad response
  useEffect(() => {
    if (teamEmployees[0]) {
      const employeeIntegrations = getEmployeeIntegrations(teamEmployees[0]);
      const excludedArchived = employeeIntegrations.filter(
        (x) =>
          integrationStatuses[x] !== undefined &&
          integrationStatuses[x] !== "archived"
      );
      setTeamIntegrations(excludedArchived);
    }
  }, [teamEmployees]);

  const onSort = (sortBy) => {
    setSort((prevSort) => {
      if (prevSort.sort_by === sortBy) {
        return {
          sort_by: sortBy,
          sort_order: prevSort.sort_order === "asc" ? "desc" : "asc",
        };
      }
      return {
        sort_by: sortBy,
        sort_order: "desc",
      };
    });
  };

  useEffect(() => {
    if (selectedIntegration) {
      dispatch(
        getActivities({
          section: teamName,
          integration: selectedIntegration,
          page: 1,
          per: 100000,
          period: periodConverter(selectedFilterInterval),
        })
      );
    }
  }, [
    dispatch,
    selectedFilterInterval,
    intervals,
    selectedIntegration,
    teamName,
  ]);

  useEffect(() => {
    if (!allIntegrations) {
      dispatch(getAllIntegrations());
    }
  }, [allIntegrations, dispatch]);

  useEffect(() => {
    if (allIntegrations) {
      const statuses = allIntegrations.reduce((acc, integration) => {
        acc[integration.name.toLowerCase()] = integration.status;
        return acc;
      }, {});
      setIntegrationStatuses(statuses);
    }
  }, [allIntegrations]);

  const onIntegrationChange = (value) => {
    setSelectedIntegration(value === "All" ? null : value);
  };

  const employeesByIntegration = activities[
    selectedIntegration
  ]?.employees.filter((x) =>
    x.name.toLowerCase().includes(search.toLowerCase())
  );

  const allEmployees = teamEmployees.filter((employee) =>
    employee.name.toLowerCase().includes(search.toLowerCase())
  );

  const legend = Object.keys(
    getEmployeeActivities(activities[selectedIntegration]?.employees[0])
  );

  return (
    <Card
      classTitle={styles.classTitle}
      classCardHead={styles.classCardHead}
      title={
        <div className={styles.head}>
          <div className={styles.left}>
            <div className={`${styles.title} onboarding-fourth-step`}>
              Activity
            </div>
            <TextInput
              classInput={styles.search}
              className={styles.searchWrapper}
              placeholder="Search by employee"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              type="text"
              name="search"
              icon="search"
            />
          </div>
          <ActivityFilters
            filters={teamIntegrations?.map((x) => ({ name: capitalize(x) }))}
            onChange={onIntegrationChange}
          />
        </div>
      }
    >
      <TableComponent
        columns={
          selectedIntegration
            ? getIntegrationColumns(legend, sort, onSort)
            : getColumns(teamIntegrations, sort, onSort, integrationStatuses)
        }
        data={selectedIntegration ? employeesByIntegration : allEmployees}
        loading={false}
        tagsRow
      />

      {!selectedIntegration && !allEmployees.length && (
        <NoResult
          loading={false}
          onCreate={() => navigate("/integrations")}
        />
      )}
    </Card>
  );
};

export default Activities;
