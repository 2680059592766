import React from "react";

import { LineChart, Line, ResponsiveContainer } from "recharts";

import styles from "./Chart.module.sass";

const Chart = ({ item }) => {
  return (
    <div className={styles.chart}>
      <ResponsiveContainer
        width="100%"
        height="100%"
      >
        <LineChart
          width={500}
          height={300}
          data={item.data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Line
            type="monotone"
            dataKey="activities"
            dot={false}
            strokeWidth={4}
            stroke={item.chartColor}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
