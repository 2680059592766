import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { TooltipGlodal, Card, Icon, TextInput } from "components";
import { getEmployeeTags } from "slices/tagSlice";

import CreateTagModal from "./CreateTagModal";
import styles from "./EmployeeTag.module.sass";
import NoResult from "./NoResult";
import Table from "./Table";

const Tags = ({ className }) => {
  const [search, setSearch] = useState("");
  const [createTagModal, setCreateTagModal] = useState(false);

  const [sorting, setSorting] = useState({
    sort_by: "name",
    sort_order: "desc",
  });
  const [sortLoading, setSortLoading] = useState(false);

  const dispatch = useDispatch();
  const { employeeTags } = useSelector((state) => state.tag);

  useEffect(() => {
    dispatch(getEmployeeTags());
  }, [dispatch]);

  const onSortHandler = async (sort_by) => {
    setSortLoading(true);
    let sort_order = "desc";
    if (sorting?.sort_by === sort_by) {
      sort_order = sorting?.sort_order === "asc" ? "desc" : "asc";
    }

    dispatch(
      getEmployeeTags({
        sort_by: sort_by,
        sort_order: sort_order,
      })
    );

    setSorting({
      sort_by,
      sort_order,
    });

    setSortLoading(false);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={
          <div className={styles.title}>
            Tags ({employeeTags.length})
            <TextInput
              className={styles.searchWrapper}
              classInput={styles.search}
              placeholder="Search by tag name"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              type="text"
              name="search"
              icon="search"
            />
          </div>
        }
        classTitle="title-transparent"
        head={
          <div
            className={cn("button button-small", styles.button)}
            onClick={() => setCreateTagModal(true)}
          >
            <Icon
              name="add"
              size="24"
            />
            <span>Create New Tag</span>
          </div>
        }
      >
        <div className={styles.titleInfo}>
          This is how you can easily identify Employees qualities or traits, for
          efficient tracking.
        </div>

        <Table
          className={styles.table}
          items={employeeTags.filter((x) =>
            x.name.toLowerCase().includes(search.toLowerCase())
          )}
          loading={sortLoading}
          onSortHandler={onSortHandler}
          sorting={sorting}
        />

        {employeeTags.length === 0 && (
          <NoResult onCreate={() => setCreateTagModal(true)} />
        )}
      </Card>

      <TooltipGlodal />
      {createTagModal && (
        <CreateTagModal
          visible={createTagModal}
          onClose={() => setCreateTagModal(false)}
        />
      )}
    </>
  );
};

export default Tags;
