import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { CustomCarousel, Icon, Image } from "components";
import { register, confirm, logOut } from "slices/appSlice";

import Code from "./Code";
import Entry from "./Entry";
import styles from "./SignUp.module.sass";

import "react-responsive-carousel/lib/styles/carousel.min.css";


const SignUp = () => {
  const dispatch = useDispatch();

  const handleClearTokens = () => {
    dispatch(logOut());
  };

  const [visible, setVisible] = useState(true);

  const { confirmationToken } = useSelector((state) => state.app);
  useEffect(() => {
    if (confirmationToken) {
      setVisible(false);
    }
  }, [confirmationToken]);

  const handleSubmit = (values) => {
    dispatch(register(values));
  };

  const handleConfirm = (confirmer) => {
    dispatch(confirm({ confirmer, confirmationToken }));
  };
  return (
    <div className={styles.page}>
      <div
        className={styles.col}
      >
        <div
          className={styles.info}
          onClick={handleClearTokens}
        >
          <Link
            className={styles.link}
            to="/sign-in"
          >
            <Icon
              name="arrow-left"
              size="16"
            />
          </Link>{" "}
          Back to Login
        </div>
        <div className={styles.head}>
          <Link
            className={styles.logo}
            to="/"
          >
            <Image
              className={styles.pic}
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
              alt="Core"
            />
          </Link>
        </div>
        <div className={styles.wrapper}>
          {visible ? (
            <Entry
              onConfirm={(values) => handleSubmit(values)}
              title="Tell us about your business"
            />
          ) : (
            <Code
              title="Confirmation"
              onConfirm={(confirmer) => handleConfirm(confirmer)}
            />
          )}
        </div>
      </div>
      <div className={styles.col}>
        <CustomCarousel />
      </div>
    </div>
  );
};

export default SignUp;
