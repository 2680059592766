import React, { useState } from "react";

import cn from "classnames";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import api from "app/api";
import { ReactComponent as CheckAllIcon } from "assets/icons/check_all.svg";
import { ReactComponent as LoaderIcon } from "assets/icons/loader.svg";
import { Modal, UserDetails, Checkbox } from "components";
import { updateEmployeeProfile, getAllEmployees } from "slices/employeeSlice";

import styles from "./MergeBulk.module.sass";

const MergeBulk = ({ visible, onClose, selectedEmployees, onMerge }) => {
  const dispatch = useDispatch();

  const [keepEmployee, setKeepEmployee] = useState(null);
  const [proceedWithMerge, setProceedWithMerge] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleMergeEmployees = async () => {
    setLoading(true);
    const response = await api.get(`/employee/${keepEmployee}`);
    const keepEmployeeData = response.data.employee;

    const mergedEmployees = selectedEmployees.filter(
      (employee) => employee.id !== keepEmployee
    );

    const mergedEmails = mergedEmployees
      .map((employee) =>
        employee.emails_array
          .slice(1, employee.emails_array.length - 1)
          .split(",")
      )
      .flat()
      .join(",");

    const allEmails =
      keepEmployeeData.emails_array.join(",") + "," + mergedEmails;

    const formData = new FormData();
    formData.append("name", keepEmployeeData.name || "");
    formData.append("email", allEmails.split('"').join(""));
    formData.append("location", keepEmployeeData.location || "");
    formData.append("bio", keepEmployeeData.bio || "");
    formData.append("update_reason", "merge");

    const res = await dispatch(
      updateEmployeeProfile({
        id: keepEmployee,
        data: formData,
      })
    );

    if (res?.error) {
      toast.error("Unable to Update Profile Information. Try again please!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (res?.payload?.data?.message?.includes("merged")) {
      toast.success(`User got ${res.payload.data.message}!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      dispatch(getAllEmployees());
    }
    setLoading(false);
    onClose();
    onMerge();
  };

  return (
    <Modal
      visible={visible}
      onClose={() => {
        onClose();
      }}
      outerClassName={styles.modal}
    >
      <div className={cn("title-purple", styles.title)}>Merge Employees</div>
      <p className={styles.info}>
        Select the employee you’d like to keep with the merger:
      </p>
      {!proceedWithMerge && (
        <div className={styles.content}>
          {selectedEmployees.map((employee) => (
            <div
              key={employee.id}
              className={cn(styles.employee, {
                [styles.selected]: employee.id === keepEmployee,
                [styles.unselected]:
                  keepEmployee && employee.id !== keepEmployee,
              })}
              onClick={() => setKeepEmployee(employee.id)}
            >
              <Checkbox
                type="radio"
                value={employee.id === keepEmployee}
                onChange={() => setKeepEmployee(employee.id)}
              />
              <UserDetails user={employee} />
            </div>
          ))}
        </div>
      )}

      {!proceedWithMerge && (
        <div className={styles.footer}>
          <div>
            <CheckAllIcon /> &nbsp;
            {keepEmployee ? (
              <span className={styles.selectedSentence}>
                {selectedEmployees.length - 1}{" "}
                {selectedEmployees.length === 2 ? "Employee" : "Employees"} will
                be merged
              </span>
            ) : (
              "No Employee selected"
            )}
          </div>
          <button
            className={cn("button", styles.mergeBtn)}
            onClick={() => {
              setProceedWithMerge(true);
            }}
            disabled={!keepEmployee || selectedEmployees.length === 1}
          >
            Merge Employees
          </button>
        </div>
      )}

      {proceedWithMerge && (
        <div className={styles.confirmation}>
          <h3>Are you sure you’d like to proceed?</h3>

          <p>
            By creating a joint page for{" "}
            {selectedEmployees.find((emp) => emp.id === keepEmployee)?.name},
            you will no longer be able to access the individual pages from the
            merged employees. This action is irreversible.
          </p>

          <div className={styles.buttons}>
            <button
              className={cn("button-stroke")}
              onClick={() => setProceedWithMerge(false)}
            >
              Cancel and Return
            </button>
            <button
              className={cn("button")}
              onClick={() => handleMergeEmployees()}
            >
              {loading && <LoaderIcon />}
              Proceed and Finish
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default MergeBulk;
