import React from "react";

import EmployeeTag from "./EmployeeTag";
import SectionTag from "./SectionTag";

const Tags = () => (
  <>
    <SectionTag />
    <EmployeeTag />
  </>
);

export default Tags;
