import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiV2 } from "../app/api";

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  topRatedEmployees: [],
  mostUsedServices: [],
  teamsOverview: [],
};

export const home = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTopRatedEmployees.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getTopRatedEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.topRatedEmployees =
          action.payload?.data?.top_rated_employees || [];
      })
      .addCase(getTopRatedEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getMostUsedServices.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getMostUsedServices.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.mostUsedServices = action.payload?.data?.most_used_services || [];
      })
      .addCase(getMostUsedServices.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getTeamsOverview.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getTeamsOverview.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.teamsOverview = action.payload?.data?.teams_overview || [];
      })
      .addCase(getTeamsOverview.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getTopRatedEmployees = createAsyncThunk(
  "getTopRatedEmployees",
  async (data) => {
    const response = await apiV2.post(
      `/home/top_rated_employees/3${
        data?.startDate && data?.endDate
          ? `?start_date=${data.startDate}&end_date=${data.endDate}`
          : `?period=${data.period}`
      }`
    );
    return response;
  }
);

export const getMostUsedServices = createAsyncThunk(
  "mostUsedServices",
  async (data) => {
    const response = await apiV2.post(
      `/home/most_used_services/3${
        data?.startDate && data?.endDate
          ? `?start_date=${data.startDate}&end_date=${data.endDate}`
          : `?period=${data.period}`
      }`
    );
    return response;
  }
);

export const getTeamsOverview = createAsyncThunk(
  "getTeamsOverview",
  async (data) => {
    const response = await apiV2.post(
      `/home/teams_overview/${
        data?.startDate && data?.endDate
          ? `?start_date=${data.startDate}&end_date=${data.endDate}`
          : `/${data.period}`
      }`,
      data
    );
    return response;
  }
);

export const {} = home.actions;

export default home.reducer;
