import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../app/api";

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  notifications: [],
  notificationPreferences: null,
  notificationPersonalisedPreferences: null,
};

export const notification = createSlice({
  name: "notification",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAllNotifications.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.notifications = action.payload?.data?.notifications
          ? action.payload?.data?.notifications
          : state.notifications;
      })
      .addCase(getAllNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(readNotification.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(readNotification.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.notifications = action.payload?.data?.notifications
          ? action.payload?.data?.notifications
          : state.notifications;
      })
      .addCase(readNotification.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.notifications = action.payload?.data?.notifications
          ? action.payload?.data?.notifications
          : state.notifications;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getNotificationPreferences.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getNotificationPreferences.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.notificationPreferences = action.payload?.data;
      })
      .addCase(getNotificationPreferences.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateNotificationPreferences.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(updateNotificationPreferences.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.notificationPreferences = action.payload?.data;
      })
      .addCase(updateNotificationPreferences.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getNotificationPersonalisedPreferences.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(
        getNotificationPersonalisedPreferences.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.loading = false;
          state.notificationPersonalisedPreferences = action.payload?.data;
        }
      )
      .addCase(
        getNotificationPersonalisedPreferences.rejected,
        (state, action) => {
          state.status = "failed";
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

export const getAllNotifications = createAsyncThunk(
  "getAllNotifications",
  async (data) => {
    const response = await api.get(`/notifications/${data.limit}`);
    return response;
  }
);

export const readNotification = createAsyncThunk(
  "readNotification",
  async (data) => {
    const response = await api.post(
      `/notification/${data.id}/read${data.limit ? `/${data.limit}` : ""}`
    );
    return response;
  }
);

export const deleteNotification = createAsyncThunk(
  "deleteNotification",
  async (data) => {
    const response = await api.post(
      `/notification/${data.id}/delete${data.limit ? `/${data.limit}` : ""}`
    );
    return response;
  }
);

export const getNotificationPreferences = createAsyncThunk(
  "getNotificationPreferences",
  async () => {
    const response = await api.get(`/notification/preferences`);
    return response;
  }
);

export const updateNotificationPreferences = createAsyncThunk(
  "updateNotificationPreferences",
  async (data) => {
    const response = await api.put(`/notification/preferences`, data);
    return response;
  }
);

export const getNotificationPersonalisedPreferences = createAsyncThunk(
  "getNotificationPersonalisedPreferences",
  async () => {
    const response = await api.get(`/notification/personalized_preferences`);
    return response;
  }
);

export default notification.reducer;
