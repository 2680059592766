import React, { useState } from "react";

import cn from "classnames";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

import Item from "./Item";
import styles from "./Schedule.module.sass";

const Schedule = ({
  className,
  integrationStartDate,
  setIntegrationStartDate,
  integrationEndDate,
  setIntegrationEndDate,
  onboarding,
}) => {
  const [visibleDate, setVisibleDate] = useState(false);
  const onChange = (dates) => {
    const [start, end] = dates;
    setIntegrationStartDate(start);
    setIntegrationEndDate(end);
  };
  return (
    <div className={cn(styles.schedule, className)}>
      <div className={styles.note}>Choose a range to integrate Zoom data.</div>
      <div className={styles.list}>
        <Item
          className={styles.item}
          category="Date"
          icon="calendar"
          value={
            integrationStartDate &&
            integrationEndDate &&
            `${format(integrationStartDate, "MMMM dd, yyyy")} -
              ${format(integrationEndDate, "MMMM dd, yyyy")}`
          }
          visible={visibleDate}
          setVisible={setVisibleDate}
          onboarding={onboarding}
        >
          <div className={styles.date}>
            <DatePicker
              selected={integrationStartDate}
              selectsRange
              startDate={integrationStartDate}
              endDate={integrationEndDate}
              onChange={onChange}
              maxDate={new Date()}
              dateFormatCalendar={"MMMM yyyy"}
              inline
            />
            <div className={styles.foot}>
              <button
                className={cn("button-small", styles.button)}
                onClick={() => setVisibleDate(false)}
              >
                Close
              </button>
            </div>
          </div>
        </Item>
      </div>
    </div>
  );
};

export default Schedule;
