import React, { useState, useEffect } from "react";

import cn from "classnames";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Card, Dropdown, TextInput } from "components";

// import Settings from "./Settings";
// import Filters from "../../../../components/Filters";
import DatePicker from "screens/EmployeeDetails/DatePicker";
import {
  setIntegrationStartDate,
  setIntegrationEndDate,
} from "slices/employeeSlice";
import {
  getActivities,
  getCompanyIntegrations,
  clearActivities,
} from "slices/homeSlice";
import { periodConverter, getEmployeeActivities } from "utils";

import NoResult from "../NoResult";

import styles from "./Activity.module.sass";
import Table from "./Table";

const Departments = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const params = useParams();

  const { intervals } = useSelector((state) => state.app);
  const { integrationTabs, activities, activitiesLoading } = useSelector(
    (state) => state.home
  );
  const { integrationStartDate, integrationEndDate } = useSelector(
    (state) => state.employee
  );
  const [filter, setFilter] = useState(intervals[0]);
  const [sorting, setSorting] = useState(null);
  const [sortLoading, setSortLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [visibleDatePicker, setVisibleDatePicker] = useState(false);

  const toggleDatePicker = () =>
    setVisibleDatePicker((prevVisibility) => !prevVisibility);

  const handleActivityPeriod = (value) => {
    setFilter(value);

    dispatch(
      getActivities({
        section: params.department || "home",
        integration: activeTab,
        page: 1,
        per: 100000,
        period: periodConverter(value),
      })
    );
  };

  useEffect(() => {
    (async () => {
      dispatch(clearActivities());
      const res = await dispatch(
        getCompanyIntegrations(params.department || "")
      );
      const firstIntegration = res?.payload?.data?.integrations[0]?.name;
      setActiveTab(firstIntegration);

      dispatch(
        getActivities({
          section: params.department || "home",
          period: 7,
          startDate:
            integrationStartDate && format(integrationStartDate, "yyyy-MM-dd"),
          endDate:
            integrationEndDate && format(integrationEndDate, "yyyy-MM-dd"),
          per: 100000,
          integration: firstIntegration,
          sort_by: "total",
          sort_order: "desc",
        })
      );
    })();
  }, [dispatch, integrationEndDate, integrationStartDate, params.department]);

  useEffect(() => {
    return () => {
      dispatch(setIntegrationStartDate(null));
      dispatch(setIntegrationEndDate(null));
    };
  }, [dispatch]);

  const handleTabChange = (tab) => {
    setActiveTab(tab.name);
    dispatch(
      getActivities({
        section: params.department || "home",
        period: periodConverter(filter),
        startDate:
          integrationStartDate && format(integrationStartDate, "yyyy-MM-dd"),
        endDate: integrationEndDate && format(integrationEndDate, "yyyy-MM-dd"),
        per: 100000,
        integration: tab.name,
        sort_by: "total",
        sort_order: "desc",
      })
    );
  };

  const onSortHandler = async ({ sort_by }) => {
    setSortLoading(true);

    let sort_order = "desc";
    if (sorting?.sort_by === sort_by) {
      sort_order = sorting?.sort_order === "desc" ? "asc" : "desc";
    }
    await dispatch(
      getActivities({
        section: params.department || "home",
        period: periodConverter(filter),
        startDate:
          integrationStartDate && format(integrationStartDate, "yyyy-MM-dd"),
        endDate: integrationEndDate && format(integrationEndDate, "yyyy-MM-dd"),
        per: 100000,
        integration: activeTab,
        sort_by: sort_by,
        sort_order: sort_order,
      })
    );

    setSorting({
      sort_by,
      sort_order,
    });

    setSortLoading(false);
  };

  return (
    <Card
      className={styles.card}
      title="Integrations"
      classTitle={cn("title-transparent", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <div className={styles.leftOptions}>
            {!integrationStartDate && !integrationEndDate && (
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={filter}
                setValue={handleActivityPeriod}
                options={intervals}
                small
              />
            )}
            <DatePicker
              visible={visibleDatePicker}
              onClose={toggleDatePicker}
              iconsOnly
            />
            <TextInput
              classInput={styles.search}
              className={styles.searchWrapper}
              placeholder="Search by employee"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              type="text"
              name="search"
              icon="search"
            />
          </div>
          <div className={cn(styles.nav, "tablet-hide")}>
            {integrationTabs?.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x.name === activeTab,
                })}
                onClick={() => handleTabChange(x)}
                key={index}
              >
                {x.name}
              </button>
            ))}
          </div>
          <div className={cn(styles.dropdown, "tablet-show")}>
            <Dropdown
              classDropdownHead={styles.dropdownHead}
              value={activeTab}
              setValue={setActiveTab}
              options={integrationTabs?.map((x) => x.name)}
              small
            />
          </div>
          {/* {<Filters className={styles.filters} title="Smart Filter">
            <Settings filters={navigation} />
          </Filters>} */}
        </>
      }
    >
      <div className={styles.departments}>
        <div className={styles.wrapper}>
          {!activities[activeTab]?.employees?.length ? (
            <NoResult loading={activitiesLoading} />
          ) : (
            <Table
              title={activeTab}
              legend={Object.keys(
                getEmployeeActivities(activities[activeTab]?.employees[0])
              )}
              items={activities[activeTab]?.employees.filter((x) =>
                x.name.toLowerCase().includes(search.toLowerCase())
              )}
              total={activities[activeTab]?.total}
              loading={sortLoading}
              onSortHandler={onSortHandler}
              sorting={sorting}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default Departments;
