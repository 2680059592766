import { ReactComponent as ShieldTickIcon } from "assets/icons/shield-tick.svg";

export const integrationFAQ = (integration) => [
  {
    topic: "Integration Questions",
    icon: <ShieldTickIcon />,
    questions: [
      {
        question: `Q1: What is ${integration.name}'s security policy?`,
        answer:
          "Q1: Metra's security policy is to ensure that all data is protected and secure.",
      },
      {
        question: `Q2: Does Metra share my ${integration.name} data with third parties?`,
        answer:
          "Q2: Metra's security policy is to ensure that all data is protected and secure.",
      },
      {
        question: `Q3: How does Metra use my ${integration.name} data?`,
        answer:
          "Q3: Metra's security policy is to ensure that all data is protected and secure.",
      },
    ],
  },
  {
    topic: "General Questions",
    icon: <ShieldTickIcon />,
    questions: [
      {
        question: `Q1: What is ${integration.name}'s security policy?`,
        answer:
          "Q1: Metra's security policy is to ensure that all data is protected and secure.",
      },
      {
        question: `Q2: Does Metra share my ${integration.name} data with third parties?`,
        answer:
          "Q2: Metra's security policy is to ensure that all data is protected and secure.",
      },
      {
        question: `Q3: How does Metra use my ${integration.name} data?`,
        answer:
          "Q3: Metra's security policy is to ensure that all data is protected and secure.",
      },
    ],
  },
  {
    topic: "General Questions",
    icon: <ShieldTickIcon />,
    questions: [
      {
        question: `Q1: What is ${integration.name}'s security policy?`,
        answer:
          "Q1: Metra's security policy is to ensure that all data is protected and secure.",
      },
      {
        question: `Q2: Does Metra share my ${integration.name} data with third parties?`,
        answer:
          "Q2: Metra's security policy is to ensure that all data is protected and secure.",
      },
      {
        question: `Q3: How does Metra use my ${integration.name} data?`,
        answer:
          "Q3: Metra's security policy is to ensure that all data is protected and secure.",
      },
    ],
  },
];
