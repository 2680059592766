import React from "react";

import cn from "classnames";
import { useDropzone } from "react-dropzone";

import Icon from "../Icon";

import styles from "./File.module.sass";

/**

 File component

 Usage:
 ```
   <File
     label="Upload file"
     title="Drag and drop file here"
     onChange={(files) => console.log(files)}
     accept={{ "application/json": [".json"] }}
   />
 ```
 @param {string} className - additional class name
 @param {string} label - label text
 @param {string} title - title text
 @param {function} onChange - callback function when file is selected
 @param {number} maxFileCount - maximum files that can be uploaded at once
 @param {{[key: string]: string[]}} accept - file types that are allowed to be
  uploaded as key value pairs where key is the file type and value is the
  file extensions
 **/
const File = ({
  className,
  label,
  // tooltip,
  title,
  onChange,
  maxFileCount = 1,
  accept = undefined,
}) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      onDrop: onChange,
      maxFiles: maxFileCount,
      multiple: maxFileCount !== 1,
      accept,
    });
  return (
    <div className={cn(styles.file, className)}>
      {label && <div className={styles.label}>{label} </div>}
      <div
        className={cn(styles.wrap, {
          [styles.dragging]: isDragActive && isDragAccept,
        })}
        {...getRootProps()}
      >
        <input
          className={styles.input}
          type="file"
          {...getInputProps()}
        />
        <div className={styles.box}>
          {isDragActive ? (
            isDragAccept ? (
              <>
                <Icon
                  name="download"
                  size="24"
                />
                Drop here
              </>
            ) : (
              <>
                <Icon
                  name="upload"
                  size="24"
                />
                File type not allowed
              </>
            )
          ) : (
            <>
              <Icon
                name="upload"
                size="24"
              />
              {title}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default File;
