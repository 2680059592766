import React from "react";

import cn from "classnames";

import { Icon } from "components";

import styles from "./NoResult.module.sass";

const NoResult = ({ onCreate }) => (
  <div className={styles.noResult}>
    <Icon
      className={styles.clock}
      name="dashed-clock"
      size="24"
      viewBox="0 0 20 20"
    />
    <p>No results so far.</p>
    <p>Create a tag to easily qualify employees.</p>

    <button
      className={cn("button button-small", styles.button)}
      onClick={onCreate}
    >
      <Icon
        name="add"
        size="24"
      />
      <span>Create New Team</span>
    </button>
  </div>
);

export default NoResult;
