import React from "react";

import cn from "classnames";

import Item from "../Item";

import styles from "./Payment.module.sass";

const Payment = ({ className }) => {
  return (
    <Item
      className={cn(styles.card, className)}
      title="Payment"
      classTitle="title-green"
    >
      <div className={styles.line}>
        <div className={styles.title}>Paypal </div>
        <button className={cn("button-stroke button-small", styles.button)}>
          Update
        </button>
      </div>
      <div className={styles.email}>tam@metra.ai</div>
      <div className={styles.content}>
        Payout fee is 1% of the amount transferred, with a minimum of USD $0.25
        and a maximum of USD $20
      </div>
    </Item>
  );
};

export default Payment;
