import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { File } from "../../../../../components";
import Loader from "../../../../../components/Loader";
import { updateIntegration } from "../../../../../slices/integrationSlice";

import styles from "./Integration.module.sass";

const FORM_FIELDS = [
  "delegated_email",
  "type",
  "project_id",
  "private_key_id",
  "private_key",
  "client_email",
  "client_id",
  "auth_uri",
  "token_uri",
  "auth_provider_x509_cert_url",
  "client_x509_cert_url",
  "access_token",
];

const Integration = ({ account_id, integration_name, account_name }) => {
  const { status, loading, error } = useSelector((state) => state.integration);
  const [informMessage, setInformMessage] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [values, setValues] = useState({
    account_id: account_id,
    integration_name: integration_name,
    ...FORM_FIELDS.reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {}),
  });
  const [canProceed, setCanProceed] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setCanProceed(
      values.access_token?.length > 0 ||
        (!Object.keys(values)
          .filter((val) => val !== "access_token")
          .some((val) => values[val] === "") &&
          Object.keys(values).length >= 13)
    );
  }, [values]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setInformMessage(null);
    setFormSubmitted(true);
    const formData = new FormData();
    Object.keys(values).forEach((value) => {
      formData.append(value, values[value]);
    });

    dispatch(updateIntegration(formData));
  };

  const preventSubmit = (event) => {
    event.preventDefault();
  };

  // TODO: this is duplicated code. Must be refactored into some shared utility
  const loadFromFile = (files, setValues) => {
    if (!files.length) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);
        const extractedObject = {};
        for (const key of FORM_FIELDS) {
          if (data[key]) {
            extractedObject[key] = data[key];
          }
        }
        if (extractedObject) {
          setValues((prev) => ({ ...prev, ...extractedObject }));
        }
      } catch (error) {
        console.error("Error parsing file", error);
      }
    };
    reader.readAsText(files[0]);
  };

  useEffect(() => {
    if (formSubmitted) {
      if (error) {
        setInformMessage("Integration failed to update.");
      } else if (status === "succeeded") {
        setInformMessage("Integration updated.");
      }
    }
  }, [status, error, formSubmitted]);

  return (
    <div className={styles.integration}>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.content}>
              <form onSubmit={preventSubmit}>
                <div className={styles.divider}>Service Account</div>
                <File
                  title="Load Credentials File"
                  className={styles.file}
                  onChange={(e) => {
                    loadFromFile(e, setValues);
                  }}
                  accept={{ "application/json": [".json"] }}
                />
                <input
                  name="delegated_email"
                  placeholder="Delegated email"
                  className={styles.input}
                  type="text"
                  value={values.delegated_email}
                  onChange={handleChange}
                />
                <input
                  name="type"
                  placeholder="Type"
                  className={styles.input}
                  type="text"
                  value={values.type}
                  onChange={handleChange}
                />
                <input
                  name="project_id"
                  placeholder="Project id"
                  className={styles.input}
                  type="text"
                  value={values.project_id}
                  onChange={handleChange}
                />
                <input
                  name="private_key_id"
                  placeholder="Private key id"
                  className={styles.input}
                  type="text"
                  value={values.private_key_id}
                  onChange={handleChange}
                />
                <textarea
                  name="private_key"
                  placeholder="Private key"
                  className={styles.textarea}
                  value={values.private_key}
                  onChange={handleChange}
                />
                <input
                  name="client_email"
                  placeholder="Client email"
                  className={styles.input}
                  type="text"
                  value={values.client_email}
                  onChange={handleChange}
                />
                <input
                  name="client_id"
                  placeholder="Client id"
                  className={styles.input}
                  type="text"
                  value={values.client_id}
                  onChange={handleChange}
                />
                <input
                  name="auth_uri"
                  placeholder="Auth uri"
                  className={styles.input}
                  type="text"
                  value={values.auth_uri}
                  onChange={handleChange}
                />
                <input
                  name="token_uri"
                  placeholder="Token_uri"
                  className={styles.input}
                  type="text"
                  value={values.token_uri}
                  onChange={handleChange}
                />
                <input
                  name="auth_provider_x509_cert_url"
                  placeholder="Auth provider x509 cert url"
                  className={styles.input}
                  type="text"
                  value={values.auth_provider_x509_cert_url}
                  onChange={handleChange}
                />
                <input
                  name="client_x509_cert_url"
                  placeholder="Client x509 cert url"
                  className={styles.input}
                  type="text"
                  value={values.client_x509_cert_url}
                  onChange={handleChange}
                />
                <div className={styles.divider}> OR</div>
                <input
                  name="access_token"
                  placeholder="Access Token"
                  className={styles.input}
                  type="text"
                  value={values.access_token}
                  onChange={handleChange}
                />

                {informMessage && (
                  <div className={styles.errorNote}>{informMessage}</div>
                )}
                <button
                  type="submit"
                  className={cn(`button button-small`, styles.button, {
                    disabled: !canProceed,
                  })}
                  onClick={handleSubmit}
                >
                  {loading && (
                    <Loader
                      className={styles.loader}
                      white
                    />
                  )}
                  <span>Update</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
