import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Checkbox, IntegrationCard } from "components";
import { getBasicsOfMyIntegrations } from "slices/integrationSlice";

import styles from "./Integrations.module.sass";

const Integrations = ({ values, onChange }) => {
  const dispatch = useDispatch();
  const { myIntegrationsBasics } = useSelector((state) => state.integration);

  useEffect(() => {
    dispatch(getBasicsOfMyIntegrations());
  }, [dispatch]);

  const handleOnIntegrate = (integration) => {
    const integrationIds = values["integrationIds"] || [];
    onChange([...integrationIds, integration.id]);
  };

  const handleOnRemove = (integration) => {
    onChange(values["integrationIds"].filter((x) => x !== integration.id));
  };

  return (
    <>
      <div className={styles.head}>
        <p>
          Your Company currently has{" "}
          <span>{myIntegrationsBasics?.length || 0} Active Integrations.</span>{" "}
          <br />
          Select which ones you'd like to Add to this Team.
        </p>
        <Checkbox
          content="Select All Integrations"
          className={styles.checkContent}
          onChange={(e) => {
            if (e.target.checked) {
              onChange(myIntegrationsBasics?.map((x) => x.id));
            } else {
              onChange([]);
            }
          }}
        />
      </div>
      <div className={styles.integrations}>
        {myIntegrationsBasics?.map((integration) => (
          <IntegrationCard
            key={integration.id}
            integration={integration}
            onIntegrate={handleOnIntegrate}
            onRemove={handleOnRemove}
            integrated={values["integrationIds"]?.includes(integration.id)}
          />
        ))}
      </div>
    </>
  );
};

export default Integrations;
