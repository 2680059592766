import { useEffect, useState } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ChartIcon } from "assets/icons/onboarding-chart2.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/onboarding-people.svg";
import { ReactComponent as ShareIcon } from "assets/icons/onboarding-share.svg";
import { ReactComponent as ProcessLoaderIcon } from "assets/icons/process-loader.svg";
import { Icon } from "components"
import { addGoals, getCompanyGoals } from "slices/homeSlice";

import styles from "./Finalizing.module.sass";

const icons = {
  share: <ShareIcon />,
  people: <PeopleIcon />,
  chart: <ChartIcon />,
};

const messages = {
  share: "Raw data is transformed into comprehensive insights",
  people: "Employees and teams are imported in seconds",
  chart: "Finalizing your operational dashboard..",
  done: null,
};

const titles = {
  share: "Synching all services...",
  people: "Fetching employees...",
  chart: "Creating your Metra space...",
  done: <div><Icon name="check" className={styles.tick} /> You’re all set!</div>,
};

const Finalizing = ({ selectedGoals }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [process, setProcess] = useState("share");

  useEffect(() => {
    (async () => {
      if (!selectedGoals.length) return;
      const addGoalsResponse = await dispatch(
        addGoals({
          goals: selectedGoals.join(","),
        })
      );
      if (addGoalsResponse.payload.data.response) {
        dispatch(getCompanyGoals());

        Object.keys(messages).forEach((key, index) => {
          setTimeout(() => {
            setProcess(key);
          }, 3000 * index);
        });
      }
    })();
  }, [dispatch, selectedGoals]);

  return (
    <div className={styles.container}>
      {process !== "done" && (
        <div className={styles.information}>
          <span>{icons[process]}</span>
          {messages[process]}
        </div>
      )}

      <img
        src="/images/onboarding-success.png"
        alt="Finish"
      />

      {process === "done" && (
        <button
          className={cn("button button-small", styles.button)}
          onClick={() => navigate("/")}
        >
          Get into my Metra space
        </button>
      )}
    </div>
  );
};

export const FinalizingHead = () => {
  const [process, setProcess] = useState("share");

  useEffect(() => {
    Object.keys(titles).forEach((key, index) => {
      setTimeout(() => {
        setProcess(key);
      }, 3000 * index);
    });
  }, []);

  return (
    <div className={cn(styles.head, { [styles.done]: process === "done" })}>
      {process === "done" ? (
        <>{titles[process]}</>
      ) : (
        <>
          <ProcessLoaderIcon className={styles.circle} />
          {titles[process]}
        </>
      )}
      {process === "done" && (
        <>
          <div className={styles.description}>
            Get into your Metra space and start getting more insight from your
            employees.
          </div>
        </>
      )}
    </div>
  );
};

export default Finalizing;
