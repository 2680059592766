import React from "react";

import cn from "classnames";

import Icon from "../Icon";

import styles from "./Balance.module.sass";

const Balance = ({ className, value, background, showValue }) => {
  return (
    <div
      className={cn(
        {
          [styles.background]: background,
          [styles.negative]: value <= -10,
          [styles.midNegative]: value > -10 && value <= 0,
          [styles.positive]: value > 0,
        },
        className
      )}
    >
      {value && <Icon name={value >= 0 ? "arrow-top" : "arrow-bottom"} />}
      {showValue && value && `${Math.abs(Math.round(value * 10) / 10)}%`}
    </div>
  );
};

export default Balance;
