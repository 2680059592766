import React, { useEffect } from "react";

import cn from "classnames";
import { useSelector } from "react-redux";

import { Card, Balance } from "components";

import styles from "./IntegrationMetrics.module.sass";

const IntegrationMetrics = ({ integration, statPeriod, report }) => {
  const { selectedFilterInterval } = useSelector((state) => state.app);

  useEffect(() => {
    statPeriod(integration?.id, selectedFilterInterval);
  }, [integration?.id, selectedFilterInterval, statPeriod]);

  return (
    <Card
      className={styles.card}
      title={integration?.title}
      classTitle="title-green"
    >
      <div className={styles.tips}>
        <div className={styles.list}>
          {integration?.data?.map((x, index) => (
            <div
              className={styles.item}
              key={index}
              onClick={() => {}}
            >
              <div className={styles.icon}>
                {x.statusText && (
                  <div
                    className={cn(
                      { "status-purple": x.statusColor === "purple" },
                      { "status-green-dark": x.statusColor === "green" },
                      { "status-red-dark": x.statusColor === "red" },
                      styles.status
                    )}
                  >
                    {x.statusText}
                  </div>
                )}
              </div>
              <div className={styles.details}>
                <div className={styles.title}>{x.title}</div>
                <div className={styles.line}>
                  <Balance
                    className={styles.balance}
                    value={x.value}
                    background
                    showValue
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default IntegrationMetrics;
