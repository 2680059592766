import React, { useEffect, useState } from "react";

import cn from "classnames";

import { Dropdown } from "components";

import styles from "./ActivityFilters.module.sass";

const ActivityFilters = ({ filters, onChange }) => {
  const [activeTab, setActiveTab] = useState("All");

  const handleTabChange = (tab) => {
    setActiveTab(tab.name);
  };

  useEffect(() => {
    onChange(activeTab);
  }, [activeTab, onChange]);

  return (
    <div className={styles.filters}>
      <div className={cn(styles.nav, "tablet-hide")}>
        {[{ name: "All" }, ...filters?.slice(0, 2)].map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: x.name === activeTab,
            })}
            onClick={() => handleTabChange(x)}
            key={index}
          >
            {x.name}
          </button>
        ))}
        {filters?.slice(2).length > 0 && (
          <Dropdown
            classDropdownHead={styles.dropdownHead}
            value="More"
            setValue={setActiveTab}
            options={filters?.slice(2)?.map((x) => x.name)}
            small
          />
        )}
      </div>
      <div className={cn(styles.dropdown, "tablet-show")}>
        <Dropdown
          classDropdownHead={styles.dropdownHead}
          value={activeTab}
          setValue={setActiveTab}
          options={filters?.map((x) => x.name)}
          small
        />
      </div>
    </div>
  );
};

export default ActivityFilters;
