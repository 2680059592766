import React from "react";

import cn from "classnames";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { UserDetails } from "components";
import {
  getAssignedEmployeesByTag,
  unAssignEmployeeFromTag,
} from "slices/tagSlice";
import { formatDateUK } from "utils";

import styles from "./Row.module.sass";

const AssignedEmployeeRow = ({ tag, employee }) => {
  const dispatch = useDispatch();

  const notify = () => {
    toast.success("Successfully Unnassigned Employee From Tag!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleUnAssignEmployee = async () => {
    await dispatch(
      unAssignEmployeeFromTag({ tagId: tag.id, userId: employee.id })
    );
    notify();
    dispatch(getAssignedEmployeesByTag(tag));
  };
  return (
    <div className={cn(styles.row, styles.assignedEmployes)}>
      <div className={styles.col}></div>
      <div className={cn(styles.col, styles.second)}>
        <UserDetails
          user={employee}
          leftArch
        />
      </div>
      <div className={styles.col}></div>
      <div className={cn(styles.col, styles.date)}>
        {employee?.assigned_in && (
          <div>
            Assigned in
            <span>{formatDateUK(employee?.assigned_in)}</span>
          </div>
        )}
      </div>
      <div className={cn(styles.col, styles.trash)}>
        <TrashIcon
          className={styles.trash}
          onClick={handleUnAssignEmployee}
        />
      </div>
    </div>
  );
};

export default AssignedEmployeeRow;
