import cn from "classnames";

import Icon from "../../Icon";

import styles from "./Collapser.module.sass";

const Collapser = ({ collapseHandler, collapsed }) => {
  return (
    <div className={styles.container}>
      <div className={styles.line} />
      <div
        onClick={collapseHandler}
        className={cn(styles.iconWrapper, {
          [styles.opened]: !collapsed,
        })}
      >
        <div className={styles.collapser}>
          <div
            className={cn(styles.arrow, {
              [styles.collapsed]: collapsed,
            })}
          >
            {console.log("collapsed", collapsed)}
            <Icon name="arrow-next" />
          </div>
        </div>
        <div className={styles.text}>Collapse</div>
      </div>
    </div>
  );
};

export default Collapser;
