import React, { useState } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import Balance from "../../../../../components/Balance";
import Card from "../../../../../components/Card";
import Dropdown from "../../../../../components/Dropdown";
import Icon from "../../../../../components/Icon";
import Tooltip from "../../../../../components/Tooltip";
import { getInsights } from "../../../../../slices/homeSlice";
import { periodConverter } from "../../../../../utils";

import styles from "./Overview.module.sass";

const Overview = ({ className, item }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { intervals } = useSelector((state) => state.app);
  const [sorting, setSorting] = useState(intervals[0]);

  const handleActivityPeriod = (value) => {
    setSorting(value);
    dispatch(
      getInsights({
        name: params.department,
        period: periodConverter(value),
        integration: item.title,
      })
    );
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={item.title}
        classTitle={cn("title-yellow", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Dropdown
              classDropdownHead={styles.dropdown}
              value={sorting}
              setValue={handleActivityPeriod}
              options={intervals}
              small
            />
            {!params.tag && (
              <Link
                className={cn("button-small", styles.button)}
                to={`/integration/${item.title}/details`}
              >
                See all {item.title} metrics
              </Link>
            )}
          </>
        }
      >
        <div className={styles.overview}>
          <div className={styles.list}>
            {item.data.map((x, index) => (
              <div
                className={styles.item}
                key={index}
              >
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <Icon
                    name={x.icon}
                    size="24"
                  />
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>{x.title}</div>
                  <div className={styles.counter}>{x.counter}</div>
                  <div className={styles.indicator}>
                    <Balance
                      className={styles.balance}
                      value={x.value}
                      showValue
                    />
                    <span>this week</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </>
  );
};

export default Overview;
