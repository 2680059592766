import cn from "classnames";
import { Link } from "react-router-dom";

import { localHTTP } from "app/api";
import { ReactComponent as BlueArch } from "assets/icons/blue-arch.svg";
import { Checkbox } from "components";

import styles from "./UserDetails.module.sass";

const UserDetails = ({
  user,
  variant = "md",
  leftArch = false,
  selectable = false,
  onChange,
  value,
  description,
}) => {
  const email = user?.emails_array?.length
    ? typeof user?.emails_array === "string"
      ? user?.emails_array.slice(1, user?.emails_array.length - 1).split(",")[0]
      : user?.emails_array[0]
    : user?.email;
  const avatar_url = localHTTP
    ? user?.avatar_url
    : user?.avatar_url.replace("http://", "https://");

  return (
    <div className={cn(styles.user, { [styles.leftArch]: leftArch })}>
      {leftArch && <BlueArch />}
      {selectable && (
        <Checkbox
          className={styles.checkbox}
          value={value}
          onChange={(e) => onChange(e, user)}
        />
      )}
      <div
        className={cn(styles.avatar, {
          [styles.lg]: variant === "lg",
          [styles.sm]: variant === "sm",
        })}
      >
        <img
          src={avatar_url || "/generic.png"}
          alt="Avatar"
        />
      </div>
      <div className={styles.details}>
        {user?.name && (
          <Link
            to={`/employee/${user?.id}/details`}
            className={cn(styles.name, {
              [styles.sm]: variant === "sm",
            })}
          >
            {user?.name}
          </Link>
        )}
        <div className={cn(styles.email)}>{description || email || "-"}</div>
      </div>
    </div>
  );
};

export default UserDetails;
