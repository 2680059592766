import { ReactComponent as CommandSquareIcon } from "assets/icons/command-square.svg";
import { ReactComponent as KeySquareIcon } from "assets/icons/key-square.svg";
import { ReactComponent as SecurityUserIcon } from "assets/icons/security-user.svg";
import { ReactComponent as ShieldTickIcon } from "assets/icons/shield-tick.svg";
import { ReactComponent as UnionIcon } from "assets/icons/union-icon.svg";
import styles from "components/Accordion/AccordionItem/AccordionItem.module.sass";

export default [
  {
    topic: "Terms & Conditions",
    icon: <UnionIcon />,
    questions: [
      {
        question: (
          <p className={styles.termsConditions}>
            The following terms and conditions (“Website Terms of Use”) apply to
            your access and use of the website located at https://www.metra.ai
            (or any successor links) and all associated web pages, websites, and
            social media pages (“Site”). {' '}
            <span>
              By accessing or using this Site, you agree to these Website Terms
              of Use, including the Acceptable Use Policy, together which shall
              constitute a legally binding agreement between you and Xogito Inc.
              dba Metra (“Metra”, “we”, “our”, or “us”).
            </span> {' '}
            You should review these Website Terms of Use regularly as they may
            change at any time at our sole discretion. If you do not agree to
            any term or condition, you should not access or otherwise use this
            Site. In connection with your use of and/or access to Content
            contained in certain areas of this Site, it may be necessary for you
            to consent to policies or other terms and conditions in addition to
            the Terms set forth herein.
          </p>
        ),
        answer: (
          <div className={styles.termsConditions}>
            <strong>
              BY AGREEING TO THESE WEBSITE TERMS OF USE, EXCEPT FOR (I) CERTAIN
              TYPES OF DISPUTES DESCRIBED IN SECTION 18, (II) WHERE YOU EXERCISE
              YOUR RIGHT TO OPT OUT OF ARBITRATION AS DESCRIBED IN SECTION 18,
              OR (III) TO THE EXTENT PROHIBITED BY LAW, DISPUTES BETWEEN YOU AND
              ACTIVTRAK WILL BE RESOLVED SOLELY ON AN INDIVIDUAL BASIS AND NOT
              AS A CLASS ARBITRATION, CLASS ACTION, ANY OTHER KIND OF
              REPRESENTATIVE PROCEEDING, OR BY JURY TRIAL.
            </strong>
            <ol>
              <li>
                <p>
                  We Provide Our Website For Your Convenience Only Our Site is
                  provided to you without charge as a convenience and for your
                  information only. The Site, including the text, graphics,
                  images, photographs, videos, illustrations, and other content
                  contained therein, and all intellectual property rights
                  therein and thereto, are owned by Metra or our licensors and
                  are protected under both United States and foreign laws.
                  Except as explicitly stated in these Website Terms of Use, all
                  rights in and to the Site, including all intellectual property
                  rights therein and thereto, are reserved by us or our
                  licensors. Subject to your compliance with these Website Terms
                  of Use you are hereby granted a limited, nonexclusive,
                  nontransferable, non-sublicensable, revocable license to
                  access and use our Site for internal use. Any use of the Site
                  other than as specifically authorized herein, without our
                  prior written permission, is strictly prohibited and will
                  terminate the license granted herein and violate our
                  intellectual property rights.
                </p>
              </li>
              <li>
                <p>
                  Accounts You may designate authorized individuals to use our
                  Site (“Users”). Users may create accounts in order to use our
                  Site. You will ensure that users: (a) do not share their
                  account credentials, (b) provide accurate account information
                  and promptly update this information if it changes, and (c)
                  use a strong password for their account that is unique to our
                  Site and not used in any other website or online service. You
                  will maintain the security of any accounts created by your
                  Users. If you discover or suspect that someone has accessed
                  the account of one of your Users without permission, you will
                  promptly notify us. We reserve the right to reclaim usernames,
                  including on behalf of businesses or individuals that hold
                  legal title, including trademark rights, in those usernames.
                </p>
              </li>
              <li>
                <p>
                  We Provide Our Website “As Is” and Disclaim All Warranties {' '}
                  <strong>
                    YOUR USE OF OUR SITE AND ANY CONTENT OR MATERIALS PROVIDED
                    THEREIN OR THEREWITH IS AT YOUR SOLE RISK. OUR SITE,
                    INCLUDING ALL CONTENT, SOFTWARE AND FUNCTIONS MADE AVAILABLE
                    ON OR ACCESSED THROUGH OUR SITE, IS PROVIDED “AS AVAILABLE”
                    AND ON AN “AS IS, WHERE IS” BASIS. TO THE FULLEST EXTENT
                    PERMISSIBLE BY LAW, WE AND ANY SUBSIDIARIES AND AFFILIATES
                    MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND AS TO THE
                    CONTENT, SOFTWARE OR FUNCTIONS ACCESSED THROUGH OUR SITE,
                    FOR ANY PRODUCTS OR SERVICES OR LINKS TO THIRD PARTIES OR
                    FOR ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION
                    OF SENSITIVE INFORMATION THROUGH OUR SITE OR ANY LINKED
                    SITE. WE AND ANY SUBSIDIARIES AND AFFILIATES DISCLAIM ANY
                    EXPRESS OR IMPLIED WARRANTIES, INCLUDING, WITHOUT
                    LIMITATION, NON-INFRINGEMENT, QUIET ENJOYMENT, QUALITY OF
                    INFORMATION, MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                    PURPOSE. THIS INCLUDES LOSS OF DATA OR PROFIT ARISING OUT OF
                    THE USE OR THE INABILITY TO USE THE CONTENT OF THIS WEBSITE,
                    EVEN IF ONE OF OUR REPRESENTATIVES HAS BEEN ADVISED OF THE
                    POSSIBILITY OF YOUR DAMAGES. WE DO NOT WARRANT THAT ANY
                    CONTENT, SOFTWARE OR THE FUNCTIONS ACCESSED THROUGH OUR SITE
                    WILL BE UNINTERRUPTED OR ERROR FREE, ARE ACCURATE, COMPLETE,
                    RELIABLE, CURRENT, OR ERROR-FREE, THAT DEFECTS WILL BE
                    CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
                    AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR
                    THAT YOUR ACCESS WILL BE UNINTERRUPTED. WE AND ANY
                    SUBSIDIARIES AND AFFILIATES WILL NOT BE LIABLE FOR DIRECT,
                    INDIRECT, PUNITIVE OR SPECIAL DAMAGES RELATED TO YOUR USE OF
                    THE SITE. YOU ASSUME THE ENTIRE RISK AS TO THE QUALITY AND
                    PERFORMANCE OF THE SITE AND ANY CONTENT PROVIDED THEREIN OR
                    THEREWITH. ALL DISCLAIMERS OF ANY KIND (INCLUDING IN THIS
                    SECTION AND ELSEWHERE IN THESE WEBSITE TERMS OF USE) ARE
                    MADE FOR THE BENEFIT OF METRA, AND METRA’S RESPECTIVE
                    SHAREHOLDERS, AGENTS, REPRESENTATIVES, LICENSORS, SUPPLIERS,
                    AND SERVICE PROVIDERS, AS WELL AS THEIR RESPECTIVE
                    SUCCESSORS AND ASSIGNS. SOME JURISDICTIONS DO NOT ALLOW THE
                    EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                    INCIDENTAL DAMAGES. IN SUCH JURISDICTIONS, OUR LIABILITY IS
                    LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
                  </strong>
                </p>
              </li>
              <li>
                <p>
                  We Do Not Have Responsibility For Links To Third Party Content
                  Our Site may interoperate with third-party products and
                  services, including, without limitation, hyperlinks or
                  pointers to other websites maintained by third parties or
                  third party content on our Site by framing or other methods.
                  The links to third party websites are provided for your
                  convenience and information only. The content in any linked
                  websites is not under our control so we are not responsible
                  for the content, including any further links in a third party
                  site. If you decide to access any of the third party sites
                  linked to our website, you do this entirely at your own risk.
                  It is up to you to take precautions to ensure that the third
                  party you link to for your use is free of computer viruses,
                  worms, trojan horses and other items of a destructive nature.
                </p>
              </li>
              <li>
                <p>
                  If We Provide a Link, We Do Not Necessarily Endorse a Third
                  Party We reserve the right to terminate a link to a third
                  party website at any time. The fact that we provide a link to
                  a third party website does not mean that we endorse, adopt,
                  authorize or sponsor that website. It also does not mean that
                  we are affiliated with the third party website’s owners or
                  sponsors.
                </p>
              </li>
            </ol>
          </div>
        ),
      },
    ],
  },
  {
    topic: "General Security",
    icon: <ShieldTickIcon />,
    questions: [
      {
        question: "Q1: How does Metra secure API keys from multiple services?",
        answer: (
          <div className={styles.answers}>
            <strong>
              Metra employs several security measures to protect API keys:
            </strong>

            <ul>
              <li>
                <strong>Encryption:</strong> All API keys are encrypted using
                industry-standard AES-256 encryption both at rest and in
                transit.
              </li>
              <li>
                <strong>Access Control:</strong> Access to API keys is
                restricted to authorized personnel only. Role-based access
                control (RBAC) ensures that users only have access to the keys
                they need.
              </li>
              <li>
                <strong>Key Management:</strong> We implement key rotation and
                automatic expiration policies to minimize the risk of key
                compromise.
              </li>
            </ul>
          </div>
        ),
      },
      {
        question:
          "Q2: What measures are in place to prevent unauthorized access to Metra's platform?",
        answer: (
          <div className={styles.answers}>
            <strong>
              Metra uses a combination of security measures to prevent
              unauthorized access:
            </strong>

            <ul>
              <li>
                <strong>Multi-Factor Authentication (MFA): </strong> Users are
                required to enable MFA for an added layer of security.
              </li>
              <li>
                <strong>Secure Login: </strong> Login sessions are protected
                with secure protocols such as HTTPS/TLS.
              </li>
              <li>
                <strong>Activity Monitoring: </strong> Continuous monitoring and
                logging of user activities help detect and respond to suspicious
                behavior.
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
  {
    topic: "Data Security",
    icon: <KeySquareIcon />,
    questions: [
      {
        question:
          "Q1: How is user data protected while being transmitted and stored?",
        answer: (
          <div className={styles.answers}>
            <strong>
              Data protection during transmission and storage is a top priority
              for Metra:
            </strong>

            <ul>
              <li>
                <strong>In Transit:</strong>Data is encrypted using TLS 1.2 or
                higher during transmission between services and Metra&apos;s
                platform.
              </li>
              <li>
                <strong>At Rest:</strong> All user data is encrypted using
                AES-256 encryption while stored in our databases.
              </li>
            </ul>
          </div>
        ),
      },
      {
        question: "Q2: How does Metra handle data breaches?",
        answer: (
          <div className={styles.answers}>
            <strong>
              In the event of a data breach, Metra follows a comprehensive
              incident response plan:
            </strong>

            <ul>
              <li>
                <strong>Detection:</strong> Immediate detection through
                continuous monitoring and automated alerts.
              </li>
              <li>
                <strong>Containment:</strong> Swift action to contain the breach
                and prevent further damage.
              </li>
              <li>
                <strong>Investigation:</strong> Detailed investigation to
                understand the cause and impact of the breach.
              </li>
              <li>
                <strong>Notification:</strong> Timely notification to affected
                users and relevant authorities as per compliance requirements.
              </li>
              <li>
                <strong>Remediation:</strong> Implementation of corrective
                measures to prevent future breaches.
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
  {
    topic: "Privacy and Compliance",
    icon: <SecurityUserIcon />,
    questions: [
      {
        question: "Q5: How does Metra ensure user privacy?",
        answer: (
          <div className={styles.answers}>
            <strong>
              Metra is committed to user privacy and implements the following
              measures:
            </strong>

            <ul>
              <li>
                <strong>Data Minimization:</strong> Collecting only the
                necessary data required to provide our services.
              </li>
              <li>
                <strong>Anonymization:</strong> Where possible, data is
                anonymized to protect user identities.
              </li>
              <li>
                <strong>User Control:</strong> Users have control over their
                data, including access, modification, and deletion rights.
              </li>
            </ul>
          </div>
        ),
      },
      {
        question: "Q6: What compliance standards does Metra adhere to?",
        answer: (
          <div className={styles.answers}>
            <strong>
              Metra adheres to several industry standards and regulations to
              ensure data protection:
            </strong>

            <ul>
              <li>
                <strong>GDPR:</strong> Compliance with the General Data
                Protection Regulation for users in the European Union.
              </li>
              <li>
                <strong>CCPA:</strong> Adherence to the California Consumer
                Privacy Act for users in California.
              </li>
              <li>
                <strong>ISO/IEC 27001:</strong> Certification for information
                security management.
              </li>
              <li>
                <strong>SOC 2:</strong> Compliance with Service Organization
                Control standards for data security, availability, and
                confidentiality.
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
  {
    topic: "Technical Details",
    icon: <CommandSquareIcon />,
    questions: [
      {
        question:
          "Q7: What architectural measures are in place to secure Metra's infrastructure?",
        answer: (
          <div className={styles.answers}>
            <strong>
              Metra&apos;s infrastructure is designed with security in mind:
            </strong>

            <ul>
              <li>
                <strong>Microservices Architecture:</strong> Isolated services
                minimize the impact of potential vulnerabilities.
              </li>
              <li>
                <strong>Containerization:</strong> Use of Docker containers
                ensures consistent environments and enhances security.
              </li>
              <li>
                <strong>Cloud Security:</strong> Leveraging cloud providers with
                robust security practices, such as AWS, for infrastructure
                management.
              </li>
            </ul>
          </div>
        ),
      },
      {
        question: "Q8: How are software updates and patches managed?",
        answer: (
          <div className={styles.answers}>
            <strong>
              Metra follows a rigorous process for managing software updates and
              patches:
            </strong>

            <ul>
              <li>
                <strong>Regular Updates:</strong> Regularly scheduled updates to
                ensure all software components are up-to-date.
              </li>
              <li>
                <strong>Automated Patching:</strong> Automated patch management
                systems apply critical security patches promptly.
              </li>
              <li>
                <strong>Testing:</strong> Thorough testing of updates in staging
                environments before deployment to production.
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
];
