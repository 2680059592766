import React, { useState } from "react";

import cn from "classnames";

import Icon from "../../Icon";

import Details from "./Details";
import styles from "./Integration.module.sass";
import Panel from "./Panel";

const Integration = () => {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={cn(styles.integration, { [styles.active]: visible })}>
      <Details
        className={styles.details}
        setValue={setVisible}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <Panel className={styles.panel} />
      <button className={styles.next}>
        <Icon
          name="arrow-right"
          size="24"
        />
      </button>
    </div>
  );
};

export default Integration;
