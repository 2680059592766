import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiV2 } from "../app/api";

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  teamAbout: null,
  teamActivities: null,
  teamEmployees: [],
};

export const home = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTeamAbout.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getTeamAbout.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.teamAbout = action.payload?.data?.team_about || null;
      })
      .addCase(getTeamAbout.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getTeamActivities.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getTeamActivities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.teamActivities = action.payload?.data?.team_activities || null;
      })
      .addCase(getTeamActivities.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getTeamEmployees.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getTeamEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.teamEmployees = action.payload?.data?.team_employees || null;
      })
      .addCase(getTeamEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getTeamAbout = createAsyncThunk("getTeamAbout", async (name) => {
  const response = await apiV2.post(`/home/team_about/${name}`);
  return response;
});

export const getTeamActivities = createAsyncThunk(
  "getTeamActivities",
  async (data) => {
    const response = await apiV2.post(
      `/home/team_activities/${data.name}${
        data?.startDate && data?.endDate
          ? `?start_date=${data.startDate}&end_date=${data.endDate}`
          : `/${data.period}`
      }`
    );
    return response;
  }
);

export const getTeamEmployees = createAsyncThunk(
  "getTeamEmployees",
  async (data) => {
    const response = await apiV2.post(
      `/home/team_employees/${data.name}${
        data?.startDate && data?.endDate
          ? `?start_date=${data.startDate}&end_date=${data.endDate}`
          : `/${data.period}`
      }`,
      data
    );
    return response;
  }
);

export const {} = home.actions;

export default home.reducer;
