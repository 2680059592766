import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getEmployeeIntegrations,
  removeIntegration,
} from "slices/employeeSlice";

import Avatar from "../../../components/Avatar";
import Icon from "../../../components/Icon";
import Integration from "../Integration";

import AddEmailModal from "./AddEmailModal";
import styles from "./ProfileIntegration.module.sass";

const ProfileIntegration = ({ className }) => {
  const [emailModal, setEmailModal] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const { employeeIntegrations, employeeProfile } = useSelector(
    (state) => state.employee
  );
  useEffect(() => {
    dispatch(getEmployeeIntegrations({ id: params.employee }));
  }, [dispatch, params.employee]);

  const handleRemoveIntegration = (integration) => {
    // TODO: continue after Safa's changes on endpoint to get real integration id

    dispatch(
      removeIntegration({
        employeeId: params.employee,
        integrationId: integration.integration_id,
      })
    );
  };

  return (
    <Integration
      className={cn(styles.card, className)}
      title="Integrations"
      classTitle="title-blue"
    >
      {employeeIntegrations?.map((integration, index) => {
        let temp_emails = "";
        if (Array.isArray(integration?.emails_array)) {
          temp_emails =
            integration?.emails_array.length > 0
              ? integration?.emails_array?.join(",")
              : "";
        } else {
          temp_emails =
            integration?.emails_array?.replace(
              /[^a-zA-Z0-9\s,@.(),:;<>[\]]+/g,
              ""
            ) || "";
        }
        return (
          <div
            className={styles.profile}
            key={index}
          >
            <div className={styles.profileHeader}>
              <div className={styles.details}>
                <div className={styles.avatar}>
                  <Avatar
                    src={`/images/integration/${integration?.avatar_url?.toLowerCase()}.png`}
                    alt="Avatar"
                  />
                </div>
                <div className={styles.wrap}>
                  <div className={cn("h5", styles.man)}>
                    {integration.title}
                  </div>
                </div>
              </div>
              <div className={styles.contacts}>
                <div className={styles.socials}></div>
                <button
                  className={cn(
                    "button button-stroke button-small",
                    styles.button
                  )}
                  onClick={() => handleRemoveIntegration(integration)}
                >
                  Remove Integration
                </button>
              </div>
            </div>
            <div className={styles.info}>{integration.info}</div>
            <div className={styles.profileFooter}>
              <div>
                {temp_emails?.split(",")?.map((email, index) => (
                  <div
                    key={index}
                    className={styles.email}
                  >
                    {email}
                  </div>
                ))}
              </div>

              <button
                className={cn("button button-small", styles.button)}
                onClick={() => setEmailModal(true)}
              >
                <Icon
                  name="add"
                  size="20"
                />
                Add New Email
              </button>
            </div>
          </div>
        );
      })}

      <AddEmailModal
        visible={emailModal}
        onClose={() => setEmailModal(false)}
        employee={employeeProfile}
        onAddEmail={() =>
          dispatch(getEmployeeIntegrations({ id: params.employee }))
        }
      />
    </Integration>
  );
};

export default ProfileIntegration;
