import React, { useState } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";

import Checkbox from "components/Checkbox";
import Icon from "components/Icon";
import NotificationSetting from "components/Notification/NotificationSetting";
import Switch from "components/SwitchSecond";
import { updateNotificationPreferences } from "slices/notificationSlice";

import styles from "../Notifications.module.sass";

const settings2 = [
  {
    title: "Notify me for Integrations",
    type: "radio",
    key: "general_integration",
    optionsKey: "integration",
    defaultOptions: [
      {
        title: "Push Notifications",
        type: "checkbox",
        key: "is_push",
      },
      {
        title: "Email Notifications",
        type: "checkbox",
        key: "is_email",
      },
    ],
    options: [
      {
        title: "Notify me when an Integration stops working",
        type: "radio",
        key: "stopped",
      },
      {
        title: "Notify me when an Integration turns operational (online)",
        type: "radio",
        key: "online",
      },
      {
        title: "Notify me whenever an Employee's ID becomes unavailable",
        type: "radio",
        key: "integrating",
      },
    ],
  },
  {
    title: "Notify me for Employees",
    type: "radio",
    key: "general_employee",
    optionsKey: "employee",
    defaultOptions: [
      {
        title: "Push Notifications",
        type: "checkbox",
        key: "is_push",
      },
      {
        title: "Email Notifications",
        type: "checkbox",
        key: "is_email",
      },
    ],
    options: [
      {
        title: "Notify me when an Integration stops working",
        type: "radio",
      },
    ],
  },
  {
    title: "Notify me for Teams & Tags",
    type: "radio",
    key: "general_team",
    optionsKey: "team",
    defaultOptions: [
      {
        title: "Push Notifications",
        type: "checkbox",
        key: "is_push",
      },
      {
        title: "Email Notifications",
        type: "checkbox",
        key: "is_email",
      },
    ],
    options: [
      {
        title: "Notify me when an Integration stops working",
        type: "radio",
      },
    ],
  },
  {
    title: "Notify me for updates",
    type: "radio",
    key: "general_updates",
    optionsKey: "updates",
    defaultOptions: [
      {
        title: "Push Notifications",
        type: "checkbox",
        key: "is_push",
      },
      {
        title: "Email Notifications",
        type: "checkbox",
        key: "is_email",
      },
    ],
  },
];

const General = ({disabled}) => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const { notificationPreferences } = useSelector(
    (state) => state.notification
  );
  return (
    <div>
      {settings2.map((x, index) => (
        <div key={index}>
          <div
            className={styles.line}
            key={index}
          >
            <div className={styles.title}>{x.title}</div>
            <div className={styles.control}>
              <Switch
                className={styles.switch}
                value={notificationPreferences?.preferences[x.key].is_enabled}
                onChange={() => {
                  dispatch(
                    updateNotificationPreferences({
                      ...notificationPreferences,
                      preferences: {
                        ...notificationPreferences.preferences,
                        [x.key]: {
                          ...notificationPreferences.preferences[x.key],
                          is_enabled:
                            !notificationPreferences?.preferences[x.key]
                              .is_enabled,
                        },
                      },
                    })
                  );
                }}
                disabled={disabled}
              />
              <span
                style={{
                  cursor: "pointer",
                  transform: `rotate(${expanded === x.title ? 180 : 0}deg)`,
                }}
                disabled={disabled}
              >
                <Icon
                  name="arrow-down"
                  size="24"
                  onClick={() =>
                    setExpanded(expanded === x.title ? false : x.title)
                  }
                />
              </span>
            </div>
          </div>
          {x.defaultOptions?.map((y, index) => (
            <div
              className={cn(styles.subline, styles.checkbox)}
              key={index}
            >
              <div className={styles.title}>{y.title}</div>
              <Checkbox
                disabled={disabled}
                value={notificationPreferences?.preferences[x.key][y.key]}
                onChange={() => {
                  dispatch(
                    updateNotificationPreferences({
                      ...notificationPreferences,
                      preferences: {
                        ...notificationPreferences.preferences,
                        [x.key]: {
                          ...notificationPreferences.preferences[x.key],
                          [y.key]:
                            !notificationPreferences?.preferences[x.key][y.key],
                        },
                      },
                    })
                  );
                }}
              />
            </div>
          ))}
          {expanded === x.title && (
            <div>
              {Object.keys(
                notificationPreferences?.friendly_names[x.optionsKey]
              ).map((key, index) => (
                <NotificationSetting
                  key={index}
                  title={notificationPreferences.friendly_names[x.optionsKey][
                    key
                  ].replace("this", "an")}
                  value={
                    notificationPreferences?.preferences[x.key][x.optionsKey][
                      key
                    ]
                  }
                  onChange={(e) => {
                    dispatch(
                      updateNotificationPreferences({
                        ...notificationPreferences,
                        preferences: {
                          ...notificationPreferences.preferences,
                          [x.key]: {
                            ...notificationPreferences.preferences[x.key],
                            [x.optionsKey]: {
                              ...notificationPreferences.preferences[x.key][
                                x.optionsKey
                              ],
                              [key]: e.target.checked,
                            },
                          },
                        },
                      })
                    );
                  }}
                  disabled={disabled}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default General;
