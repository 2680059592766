import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Card from "../../../components/Card";
import { getIntegrationLogs } from "../../../slices/integrationSlice";

import styles from "./IntegrationLogs.module.sass";
import Table from "./Table";

const IntegrationLogs = ({ viewAll }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { integrationLogs } = useSelector((state) => state.integration);
  const [visible, setVisible] = useState(false);
  const navigation = ["All", "Today"];
  const [entries, setEntries] = useState(
    viewAll && integrationLogs?.length > 3
      ? integrationLogs.slice(0, 3)
      : integrationLogs
  );
  const [activeTab, setActiveTab] = useState(navigation ? navigation[0] : "");

  useEffect(() => {
    dispatch(
      getIntegrationLogs({
        integration: params.integration,
        all: !viewAll,
        period: 365,
      })
    );
  }, [dispatch, params.integration, viewAll]);

  useEffect(() => {
    setEntries(
      viewAll && integrationLogs?.length > 3
        ? integrationLogs.slice(0, 3)
        : integrationLogs
    );
  }, [integrationLogs, setEntries, viewAll]);

  return (
    <Card
      className={styles.card}
      title="Last entries"
      classTitle={cn("title-transparent", styles.title)}
      classCardHead={cn(styles.head, { [styles.hidden]: visible })}
      head={
        <>
          <div className={styles.nav}>
            {navigation?.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x === activeTab,
                })}
                onClick={() => setActiveTab(x)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
        </>
      }
    >
      <div className={cn(styles.row, { [styles.flex]: visible })}>
        {navigation?.map(
          (nav, index) =>
            activeTab === nav && (
              <Table
                key={index}
                className={styles.table}
                activeTable={visible}
                setActiveTable={setVisible}
                viewAll={viewAll}
                id={params.integration}
                items={entries?.filter(
                  (employee) => employee.isToday === nav || "All" === nav
                )}
              />
            )
        )}
      </div>
    </Card>
  );
};

export default IntegrationLogs;
