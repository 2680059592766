import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";

import { Modal, Loader } from "components";
import { addNote } from "slices/employeeSlice";

import styles from "./AddNoteModal.module.sass";

const AddNoteModal = ({ visible, onClose, id, onNoteAdded }) => {
  const [note, setNote] = useState("");

  const dispatch = useDispatch();

  const { notes, loading } = useSelector((state) => state.employee);

  const addNoteHandler = async () => {
    if (note.length === 0) return;
    await dispatch(addNote({ id: id, note: note }));
    onNoteAdded();
    setNote("");
    onClose();
  };

  useEffect(() => {
    setNote("");
  }, [notes]);

  useEffect(() => {}, [visible]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      addNoteHandler();
    }
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      outerClassName={styles.modal}
    >
      <p className={cn("title-purple")}>Add new note</p>
      <div className={styles.head}>
        <div className={styles.addNote}>
          <textarea
            name="note"
            placeholder="Note"
            className={styles.description}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button
            className={cn(
              "button",
              { "button-stroke-blue": note.length > 0 },
              styles.button
            )}
            disabled={note.length <= 0}
            onClick={addNoteHandler}
          >
            {loading && (
              <Loader
                className={styles.loader}
                white
              />
            )}
            <span>Add note</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNoteModal;
