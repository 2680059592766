import React, { useState } from "react";

import cn from "classnames";

import Avatar from "../../../Avatar";
import Icon from "../../../Icon";
import Modal from "../../../Modal";

import styles from "./Panel.module.sass";
import Share from "./Share";

const Panel = ({ className }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <div className={cn(styles.panel, className)}>
        <div className={styles.avatar}>
          <Avatar
            src="/images/content/avatar.jpg"
            alt="Avatar"
          />
        </div>
        <div className={styles.brand}>
          <Avatar
            src="/images/content/figma.png"
            alt="Figma"
          />
          <div className={styles.counter}>3</div>
        </div>
        <button
          className={styles.share}
          onClick={() => setVisibleModal(true)}
        >
          <Icon
            name="share"
            size="24"
          />
        </button>
      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Share />
      </Modal>
    </>
  );
};

export default Panel;
