import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { setFilterInterval } from "slices/appSlice";
import { getAllNotifications } from "slices/notificationSlice";

import Dropdown from "../Dropdown";

import { breadcrumbs, breadcrumbRoutes } from "./breadcrumbs";
import DatePicker from "./DatePicker";
import styles from "./Header.module.sass";
import Notification from "./Notification";
import User from "./User";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const decodePathname = (path) => {
  return decodeURIComponent(path);
};

const getBreadcrumbLabel = (path) => {
  const decodedPath = decodePathname(path);
  const staticLabel = breadcrumbs[decodedPath];
  if (staticLabel) {
    return staticLabel;
  }

  return decodedPath
    .split("/")
    .filter((part) => isNaN(part))
    .map((part) => capitalizeFirstLetter(part))
    .join("/");
};

const Header = ({ onOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { intervals, selectedFilterInterval, collapsed } = useSelector(
    (state) => state.app
  );
  const { integrationStartDate, integrationEndDate } = useSelector(
    (state) => state.employee
  );

  const [visibleDatePicker, setVisibleDatePicker] = useState(false);

  const toggleDatePicker = () =>
    setVisibleDatePicker((prevVisibility) => !prevVisibility);

  const handleClick = () => onOpen();

  useEffect(() => {
    dispatch(getAllNotifications({ limit: 5 }));
  }, [dispatch, location]);

  const path = window.location.pathname;
  const title = getBreadcrumbLabel(path);
  const parts = title.split("/").map((part) => part.trim());

  const generatePath = (label) => {
    return breadcrumbRoutes[label] || `/${label.toLowerCase()}`;
  };

  const renderDatePicker =
    path !== "/settings" && path !== "/chat" && path !== "/teams-tags";

  return (
    <header className={cn(styles.header, { [styles.collapsed]: collapsed })}>
      <div className={cn("h3", styles.title)}>
        <span className={styles.breadcrumb}>
          {parts.length > 1 ? (
            <>
              {parts.slice(0, -1).map((part, i) => {
                const pathToNavigate = generatePath(part);
                return (
                  <React.Fragment key={i}>
                    <span
                      onClick={() => navigate(pathToNavigate)}
                      className={styles.breadcrumbFirstPart}
                    >
                      {i === 0 ? part : ` / ${part}`}
                    </span>
                  </React.Fragment>
                );
              })}
              {` / `}
              <span className={styles.breadcrumbLastPart}>
                {parts[parts.length - 1]}
              </span>
            </>
          ) : (
            <span className={styles.breadcrumbLastPart}>{title}</span>
          )}
        </span>
      </div>

      {renderDatePicker && (
        <div className={styles.datepicker}>
          {!integrationStartDate && !integrationEndDate && (
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={selectedFilterInterval}
              setValue={(value) => dispatch(setFilterInterval(value))}
              options={intervals}
              small
            />
          )}
          <DatePicker
            visible={visibleDatePicker}
            onClose={toggleDatePicker}
            iconsOnly
          />
        </div>
      )}

      <button
        className={styles.burger}
        onClick={() => handleClick()}
      ></button>
      <div className={styles.control}>
        <Notification className={styles.notification} />
        <User className={styles.user} />
      </div>
    </header>
  );
};

export default Header;
