import React, { useEffect } from "react";

import cn from "classnames";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import Card from "../../../components/Card";
import useDarkMode from "../../../hooks/useDarkMode";

import styles from "./Overview.module.sass";

const IntegrationViews = ({ className, snapshotPeriod, report }) => {
  const { selectedFilterInterval } = useSelector((state) => state.app);
  const { activitySnapshot } = useSelector((state) => state.employee);
  const darkMode = useDarkMode(false);

  useEffect(() => {
    snapshotPeriod(selectedFilterInterval);
  }, [selectedFilterInterval, snapshotPeriod]);

  return (
    <Card
      className={cn(styles.card, className)}
      title="Total daily activities"
      classTitle={cn("title-transparent", styles.title)}
      classCardHead={styles.head}
    >
      <div className={styles.chart}>
        <ResponsiveContainer
          width="100%"
          height="100%"
        >
          <BarChart
            width={500}
            height={300}
            data={activitySnapshot}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            barSize={32}
            barGap={8}
          >
            <CartesianGrid
              strokeDasharray="none"
              stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
              vertical={false}
            />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
              padding={{ left: 10 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#272B30",
                borderColor: "rgba(255, 255, 255, 0.12)",
                borderRadius: 8,
                boxShadow:
                  "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
              }}
              labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
              itemStyle={{
                padding: 0,
                textTransform: "capitalize",
                fontSize: 12,
                fontWeight: "600",
                color: "#fff",
              }}
              cursor={{ fill: "#f3f2f3" }}
            />
            <Bar
              dataKey="activities"
              fill="#B5E4CA"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default IntegrationViews;
