import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as AddCircled } from "assets/icons/add-circled.svg";
import { TextInput, UserDetails, Icon, TableComponent } from "components";
import { getEmployeesByIntegrations } from "slices/employeeSlice";

import styles from "./AddEmployee.module.sass";

const AddEmployee = ({ values, onChange }) => {
  const { employeesByIntegrations } = useSelector((state) => state.employee);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (values["integrationIds"]) {
      dispatch(getEmployeesByIntegrations(values["integrationIds"]));
    }
  }, [dispatch, values]);

  const handleAddEmployee = (employee) => {
    const employeeIds = values["employeeIds"] || [];
    onChange([...employeeIds, employee.id]);
  };

  const handleRemoveEmployee = (employee) => {
    onChange(values["employeeIds"].filter((x) => x !== employee.id));
  };

  const getColumns = (employee) => [
    {
      title: "Name",
      key: "data",
      sort: true,
      dataIndex: "data",
      render: (data) => <UserDetails user={data} />,
    },
    {
      title: (
        <TextInput
          className={styles.search}
          placeholder="Search for employees"
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
          value={search}
          type="text"
          name="search"
          icon="search"
        />
      ),
      key: "data",
      dataIndex: "data",
      render: (data) =>
        (values["employeeIds"] || []).includes(data.id) ? (
          <button
            className={cn("button button-small", styles.button)}
            onClick={() => handleRemoveEmployee(data)}
          >
            <Icon
              name="check"
              size="24"
            />
            Employee Added
          </button>
        ) : (
          <button
            className={cn(
              "button button-small button-stroke-blue",
              styles.button
            )}
            onClick={() => handleAddEmployee(data)}
          >
            <AddCircled />
            Add Employee to the Team
          </button>
        ),
    },
  ];

  return (
    <>
      <div className={cn(styles.wrapper)}>
        <TableComponent
          columns={getColumns()}
          data={employeesByIntegrations?.filter(({ name }) =>
            name.toLowerCase().includes(search)
          )}
          className={styles.table}
        />
      </div>
    </>
  );
};

export default AddEmployee;
