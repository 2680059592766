import { useState, useEffect } from "react";

import cn from "classnames";
import { Link } from "react-router-dom";

import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { Indicator, Icon } from "components";
import { fillArray } from "utils";

import styles from "./TeamCard.module.sass";

const integrationIcons = {
  slack: { img: "/images/integration/slack.png" },
  github: { img: "/images/integration/github.png", full: true },
  google: { img: "/images/integration/google.png" },
  confluence: { img: "/images/integration/confluence.png" },
  jira: { img: "/images/integration/jira.png" },
  zoom: { img: "/images/integration/zoom.png", full: true },
  teams: { img: "/images/integration/teams.png" },
  outlook: { img: "/images/integration/exchangeonline.png" },
  salesforce: { img: "/images/integration/salesforce.png" },
};

const Card = ({ team }) => {
  const performance = team?.weeklyPercentage || 0;
  const [services, setServices] = useState([]);

  useEffect(() => {
    let newServices = Object.keys(team.data)
      .map((service) => {
        return {
          name: service.slice(0, -4),
          value: team.data[service],
        };
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, 5);
    if (newServices.length < 5) newServices = fillArray(newServices, 5);
    setServices(newServices);
  }, [team]);

  return (
    <Link
      to={`/team/${team.title}`}
      className={cn(styles.card, {
        [styles.positive]: performance > 0,
        [styles.midNegative]: performance <= 0 && performance > -10,
        [styles.negative]: performance <= -10,
      })}
    >
      <div className={styles.info}>
        <div className={styles.title}>
          {team.title}
          <Icon
            name="arrow-right"
            size={24}
          />
        </div>
        <div className={styles.stats}>
          <div className={styles.row}>
            <div>
              <Indicator value={performance} />
            </div>
            <div className={styles.stat}>
              Total activities: <span>{team.counter}</span>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.stat}>
              <ProfileIcon /> {team.employees} employees
            </div>
            <div className={styles.stat}>
              Employee avg: <span>{team.avg}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.chart}>
        <span>Top 5 services</span>
        {services.map((service) => {
          if (!service)
            return (
              <div className={styles.emptyLine}>
                <div className={styles.line}></div>
              </div>
            );

          const serviceIcon = integrationIcons[service.name.toLowerCase()];
          const servicePercentage =
            team.counter > 0
              ? ((service.value / team.counter) * 100).toFixed(1)
              : 0;

          return (
            <div className={styles.integrationRow}>
              <div>
                <div className={styles.integrationIcon}>
                  <img
                    className={cn({
                      [styles.fullIcon]: serviceIcon.full,
                    })}
                    src={serviceIcon.img}
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.progressContainer}>
                <div className={styles.progressData}>
                  <div
                    className={styles.activitiesValue}
                    style={{
                      minWidth: "fit-content",
                      width: `${servicePercentage}%`,
                    }}
                  >
                    <span className={cn(styles.activitiesNumber)}>
                      {service.value}
                    </span>
                  </div>
                  <span className={styles.percentage}>
                    {servicePercentage}%
                  </span>
                </div>

                <div className={styles.progressBar}>
                  <span
                    className={styles.progress}
                    style={{ width: `${servicePercentage}%` }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Link>
  );
};

export default Card;
