import React, { forwardRef } from "react";

import { format } from "date-fns";
import { useSelector } from "react-redux";

import { Image } from "components";

import IntegrationMetrics from "../IntegrationMetrics";
import Overview from "../Overview";
import Profile from "../Profile";

import styles from "./Report.module.sass";

const Report = forwardRef((props, ref) => {
  const { modifiedIntegrationStats } = props;
  const { integrationStartDate, integrationEndDate } = useSelector(
    (state) => state.employee
  );

  return (
    <div
      className={styles.container}
      ref={ref}
    >
      <div className={styles.titleDetails}>
        <span className={styles.title}>Employee Report</span>
        {integrationStartDate && integrationEndDate && (
          <div className={styles.rangeText}>
            Data from:
            <span>
              {format(integrationStartDate, "dd/MM/yyyy")} -{" "}
              {format(integrationEndDate, "dd/MM/yyyy")}
            </span>
          </div>
        )}

        <Image
          className={styles.logo}
          src="/images/logo-dark.png"
          srcDark="/images/logo-light.png"
          alt="Core"
        />
      </div>
      <div>
        <div className={styles.row}>
          <Profile report />
        </div>
        <div className={styles.row}>
          <Overview
            className={styles.card}
            snapshotPeriod={() => {}}
            report
          />
        </div>
        {modifiedIntegrationStats?.map((integration, index) =>
          index === 0 ? (
            <div
              className={styles.row}
              key={integration.id}
            >
              <IntegrationMetrics
                integration={integration}
                statPeriod={() => {}}
                report
              />
            </div>
          ) : (
            <div className={styles.row}>
              <IntegrationMetrics
                integration={integration}
                statPeriod={() => {}}
                report
              />
            </div>
          )
        )}
      </div>
    </div>
  );
});

export default Report;
