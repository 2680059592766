import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getCompanyGoals } from "slices/homeSlice";
import { getBasicsOfMyIntegrations } from "slices/integrationSlice";

import Finalizing, { FinalizingHead } from "./Finalizing";
import Goals from "./Goals";
import Integration, { IntegrateHeader } from "./Integrations/Integration";
import Selection from "./Integrations/Selection";
import Starter, { StarterHead } from "./Starter";
import Stepper from "./Stepper";

const Onboarding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [done, setDone] = useState(false);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [selectedIntegrations, setSelectedIntegrations] = useState([]);

  const { isLoggedIn, currentUser } = useSelector((state) => state.app);
  const onboardedOnce = window.localStorage.getItem("onboardedOnce");

  if (!onboardedOnce) {
    window.localStorage.setItem("onboardedOnce", true);
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/sign-in");
    }
  }, [isLoggedIn, navigate]);
  
  useEffect(() => {
    if (!currentUser?.is_creator && !currentUser?.is_editor) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    dispatch(getCompanyGoals());
    dispatch(getBasicsOfMyIntegrations());
  }, [dispatch]);

  const steps = [
    {
      name: "Integrate",
      title: <StarterHead />,
      component: <Starter onClick={() => setStep((step) => step + 1)} />,
      hideFooter: true,
      fullScreen: true,
    },
    {
      name: "Goals",
      title: "Hi, what are you hoping to achieve with Metra?",
      subtitle:
        "Select up to 3 goals that align the most with your business needs:",
      component: (
        <Goals
          onSelection={(selectedGoals) => setSelectedGoals(selectedGoals)}
          selection={selectedGoals}
        />
      ),
    },
    {
      name: "Select Integrations",
      title: "Select the services your company uses",
      subtitle: (
        <div style={{ textAlign: "center" }}>
          Pick one or more Integrations, so that Metra can start delivering
          insights about your operation. <br />
          Don’t worry, you can add or remove services at any time after the
          onboarding.
        </div>
      ),
      component: (
        <Selection
          onIntegrationSelection={(selectedIntegrations) =>
            setSelectedIntegrations(selectedIntegrations)
          }
          selection={selectedIntegrations}
        />
      ),
    },
    {
      name: "Integrate",
      title: "Input the activation code for the chosen services",
      subtitle: <IntegrateHeader />,
      component: <Integration selection={selectedIntegrations} />,
      fullComponent: true,
    },
    {
      name: "Integrate",
      title: <FinalizingHead />,
      component: <Finalizing selectedGoals={selectedGoals} />,
      hideFooter: true,
      fullScreen: true,
    },
  ];

  const handleOnFinish = () => {
    setDone(true);
  };

  return (
    <div>
      <Stepper
        steps={steps}
        step={step}
        onNext={() => {
          if (step === 2 && selectedGoals.length === 0) return;
          if (step < steps.length) setStep((step) => step + 1);
        }}
        onBack={() => {
          if (step > 2) setStep((step) => step - 1);
        }}
        onFinish={handleOnFinish}
        disableNext={
          (step === 2 && selectedGoals.length === 0) ||
          (step === 3 && selectedIntegrations.length === 0)
        }
        onSkip={() => navigate("/")}
        done={done}
      />
    </div>
  );
};

export default Onboarding;
