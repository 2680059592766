import { useState, useEffect } from "react";

import cn from "classnames";
import { WithContext as ReactTags } from "react-tag-input";

import styles from "./TagInput.module.sass";

import "./reactTags.sass";
import { Tooltip } from "components";

const TagInput = ({
  tags: initialTags,
  onAdd,
  onDelete,
  onChange,
  label,
  tooltip,
  classLabel,
  ...rest
}) => {
  const [tags, setTags] = useState(initialTags || []);

  useEffect(() => {
    setTags(initialTags);
  }, [initialTags]);

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const handleDelete = (i) => {
    setTags(tags?.filter((tag, index) => index !== i));
    onChange && onChange(tags?.filter((tag, index) => index !== i));
    onDelete(
      tags?.find((tag, index) => index === i),
      tags?.filter((tag, index) => index !== i)
    );
  };

  const handleAddition = (tag) => {
    if (tags.length < 12) {
      setTags([...tags, tag]);
      onChange && onChange([...tags, tag]);
      onAdd([...tags, tag]);
    }
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...tags].slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
    onChange && onChange(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const onClearAll = () => {
    setTags([]);
    onChange && onChange([]);
  };

  const onTagUpdate = (i, newTag) => {
    const updatedTags = tags.slice();
    updatedTags.splice(i, 1, newTag);
    setTags(updatedTags);
    onChange && onChange(updatedTags);
  };

  return (
    <>
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <ReactTags
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        delimiters={delimiters}
        handleTagClick={handleTagClick}
        onClearAll={onClearAll}
        onTagUpdate={onTagUpdate}
        suggestions={[{ id: "1", text: "Engineering" }]}
        placeholder="Enter employee emails"
        minQueryLength={2}
        maxLength={35}
        autofocus={false}
        allowDeleteFromEmptyInput={true}
        autocomplete={true}
        readOnly={false}
        allowUnique={true}
        allowDragDrop={true}
        inline={true}
        inputFieldPosition="inline"
        allowAdditionFromPaste={true}
        editable={true}
        clearAll={true}
        tags={tags}
        {...rest}
      />
    </>
  );
};

export default TagInput;
