import React from "react";

import cn from "classnames";

import { Modal } from "components";

import styles from "./ArchiveBulkConfirmation.module.sass";

const ArchiveBulkConfirmation = ({
  visible,
  onClose,
  onConfirm,
  selectedEmployees,
}) => {
  return (
    <Modal
      visible={visible}
      onClose={() => {
        onClose();
      }}
      outerClassName={styles.modal}
    >
      <div className={styles.confirmation}>
        <h3>
          Are you sure you’d like to <br /> archive the selected Employee(s)?
        </h3>

        <ul className={styles.list}>
          <div>
            {selectedEmployees.map((employee) => (
              <li key={employee.id}>{employee.name}</li>
            ))}
          </div>
        </ul>

        <p>
          The archived employees will no longer appear in the personnel list for
          your company. This action is irreversible.
        </p>

        <div className={styles.buttons}>
          <button
            className={cn("button-stroke")}
            onClick={onClose}
          >
            Cancel and Return
          </button>
          <button
            className={cn("button")}
            onClick={onConfirm}
          >
            Proceed and Finish
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ArchiveBulkConfirmation;
