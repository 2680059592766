export const breadcrumbs = {
  "/": "Home",
  "/employees": "Employees",
  "/integrations": "Integrations",
  "/teams-tags": "Teams & Tags",
  "/settings": "Settings",
  "/chat": "Ask Metra A.I.",
  "/employee/details": "Employees/Details",
  "/integration/details": "Integration/Details",
};

export const breadcrumbRoutes = {
  "Home": "/",
  "Employee": "/employees",
  "Teams & Tags": "/teams-tags",
  "Team": "/teams-tags", 
  "Integration": "/integrations",
  "Teams": "/integrations",
  "Confluence": "/integrations",
  "Jira": "/integrations",
  "Slack": "/integrations",
  "Zoom": "/integrations",
  "Google": "/integrations",
  "Outlook": "/integrations",
  "Github": "/integrations",
  "Salesforce": "/integrations",
  "Settings": "/settings",
  "Section": "/",
  "Logs": "/integrations",
};
