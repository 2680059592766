import cn from "classnames";

import { Image } from "components";

import styles from "./Stepper.module.sass";

const Stepper = ({
  steps,
  step = 1,
  onNext,
  onBack,
  disableNext,
  onSkip,
  done,
}) => (
  <>
    <div className={styles.header}>
      <Image
        className={styles.pic}
        src="/images/logo-dark.png"
        srcDark="/images/logo-light.png"
        alt="Core"
      />
      <div className={styles.progress}>
        <div
          className={cn(styles.line, {
            [styles.step1]: step === 1,
            [styles.step2]: step === 2,
            [styles.step3]: step === 3,
            [styles.step4]: step === 4,
            [styles.done]: step === 5 || done,
          })}
        ></div>
        <div className={styles.dots}>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
        </div>
      </div>
    </div>
    <div className={styles.title}>
      <h1>{steps[step - 1].title}</h1>
      <p>{steps[step - 1].subtitle}</p>
    </div>
    <div className={cn(styles.component, { 
      [styles.fullScreen]: steps[step - 1].fullScreen,
      [styles.fullComponent]: steps[step - 1].fullComponent,
    })}>{steps[step - 1].component || null}</div>
    {!steps[step - 1].hideFooter && (
      <div className={styles.footer}>
        <div>
          <button
            className={cn("button button-small button-stroke", styles.back, {
              [styles.hide]: step === 1 || step === 2,
            })}
            onClick={onBack}
          >
            Back
          </button>
        </div>

        <div>
          <button
            className={cn("button button-small button-transparent", styles.skip)}
            onClick={onSkip}
          >
            Skip
          </button>
          <button
            className={cn("button button-small", styles.next)}
            onClick={onNext}
            disabled={disableNext}
          >
            Next
          </button>
        </div>
      </div>
    )}
  </>
);

export default Stepper;
