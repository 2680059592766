export const colors = [
  "#CABDFF",
  "#B5E4CA",
  "#FFBC99",
  "#B1E5FC",
  "#FFD88D",
  "#80BFA0",
  "#DD8680",
  "#706FBB",
  "#836853",
];
