import { useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar-2.svg";
import { ReactComponent as FlashIcon } from "assets/icons/flash.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as StoreIcon } from "assets/icons/store.svg";
import { Card } from "components";
import { getTeamAbout } from "slices/teamSlice";
import { formatDateUK } from "utils";

import styles from "./About.module.sass";

const About = ({ teamName }) => {
  const dispatch = useDispatch();
  const { teamAbout } = useSelector((state) => state.team);

  useEffect(() => {
    dispatch(getTeamAbout(teamName));
  }, [dispatch, teamName]);

  const onlineIntegrations = teamAbout?.integrations.filter(
    (integration) => integration.status === "active"
  );

  return (
    <Card className={styles.card}>
      <div className={styles.title}>
        <span>About</span>
        {!teamAbout?.is_default && (
          <Link
            className={cn("button button-stroke button-small", styles.button)}
            to={`/team/${teamAbout?.title || 'details'}/${teamAbout?.id}`}
          >
            <span>
              <SettingsIcon />
            </span>
            <span>Team Settings</span>
          </Link>
        )}
      </div>

      <div className={styles.row}>
        <PersonIcon /> {teamAbout?.employees} Employees
      </div>
      <div className={styles.row}>
        <FlashIcon />
        Activity average: &nbsp;
        <span>{teamAbout?.avg} per Employee (last 30 days)</span>
      </div>
      <div className={styles.row}>
        <StoreIcon /> {teamAbout?.integrations.length} Integrations
        <div className={styles.online}>{onlineIntegrations?.length} Online</div>
      </div>
      <div className={styles.row}>
        <CalendarIcon />
        Created in: &nbsp;<span>{formatDateUK(teamAbout?.created_at)}</span>
      </div>

      <div className={styles.description}>
        <span>Description</span>
        <p>{teamAbout?.description}</p>
      </div>
    </Card>
  );
};

export default About;
