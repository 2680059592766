import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Card, Dropdown, TextInput } from "components";

import {
  getMyIntegrations,
  getMyIntegrationsEmployees,
  getMyIntegrationsDifferenceByDate,
  getMyIntegrationsInteractionCountByDate,
} from "../../../slices/integrationSlice";
import { onlyUnique } from "../../../utils";
import NoResult from "../NoResult";

import styles from "./MyIntegrations.module.sass";
import Row from "./Row";

const MyIntegrations = ({ noResultHandler }) => {
  const dispatch = useDispatch();
  const { myIntegrations, myIntegrationsLoading } = useSelector(
    (state) => state.integration
  );
  const [navigation, setNavigation] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [askedForIntegrationDetails, setAskedForIntegrationDetails] =
    useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (myIntegrations) {
      if (myIntegrations.length > 0) {
        const nav = myIntegrations
          ?.map((integration) => integration.status)
          .filter(onlyUnique);
        nav?.unshift("All");
        setNavigation([...nav]);
        if (!activeTab) setActiveTab(nav[0] || "");
        if (!askedForIntegrationDetails) {
          myIntegrations.forEach((myIntegration) => {
            dispatch(
              getMyIntegrationsEmployees({
                company_integration_id: myIntegration.company_integration_id,
              })
            );
            dispatch(
              getMyIntegrationsDifferenceByDate({
                company_integration_id: myIntegration.company_integration_id,
              })
            );
            dispatch(
              getMyIntegrationsInteractionCountByDate({
                company_integration_id: myIntegration.company_integration_id,
              })
            );
          });
          setAskedForIntegrationDetails(true);
        }
      }
    }
  }, [activeTab, askedForIntegrationDetails, dispatch, myIntegrations]);

  const filterByIntegrationsSearch = (myIntegrations || [])?.filter(
    ({ status, title }) =>
      status?.toLowerCase().includes(search) ||
      title?.toLowerCase().includes(search)
  );

  return (
    <>
      <Card
        className={styles.card}
        title={
          <span>
            My Integrations{" "}
            <span className={styles.size}>({myIntegrations?.length || 0})</span>
          </span>
        }
        classTitle={cn("title-transparent", styles.title)}
        classCardHead={styles.head}
        head={
          <div className={styles.headOptions}>
            <TextInput
              className={styles.search}
              placeholder="Search by integration name or status"
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
              value={search}
              type="text"
              name="search"
              icon="search"
            />
            <div className={cn(styles.nav, "tablet-hide")}>
              {navigation?.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: x === activeTab,
                  })}
                  onClick={() => setActiveTab(x)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            <div className={cn(styles.dropdown, "tablet-show")}>
              <Dropdown
                classDropdownHead={styles.dropdownHead}
                value={activeTab}
                setValue={setActiveTab}
                options={navigation}
                small
              />
            </div>
            {/* {myIntegrations?.length > 0 && (
              <Filters className={styles.filters} title="Smart Filter">
                <Settings filters={navigation} />
              </Filters>
            )} */}
          </div>
        }
      >
        {filterByIntegrationsSearch?.length > 0 && (
          <div className={styles.table}>
            {(!filterByIntegrationsSearch ||
              !filterByIntegrationsSearch.length) && (
              <NoResult
                loading={myIntegrationsLoading}
                onCreate={noResultHandler}
              />
            )}
            {filterByIntegrationsSearch?.length > 0 && (
              <div className={styles.row}>
                <div className={styles.col}>Name</div>
                <div className={styles.col}>Status</div>
                <div className={styles.col}>Pulling to</div>
                <div className={styles.col}>Activity</div>
              </div>
            )}
            {navigation?.map(
              (nav) =>
                activeTab === nav &&
                filterByIntegrationsSearch
                  .filter(
                    (integration) => integration.status === nav || nav === "All"
                  )
                  .map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                    />
                  ))
            )}
          </div>
        )}
        {/* <div className={styles.foot}>
          <button className={cn("button-stroke button-small", styles.button)}>
            <Loader className={styles.loader} />
            <span>Load more</span>
          </button>
        </div> */}
      </Card>
    </>
  );
};

export default MyIntegrations;
