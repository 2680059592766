import React from "react";

import cn from "classnames";

// import Loader from "../Loader";
import { ReactComponent as SortAscIcon } from "assets/icons/sort-asc.svg";

import Row from "./Row";
import styles from "./Table.module.sass";

const Table = ({ columns, data, className, tagsRow }) => (
  <div className={cn(styles.wrapper, className)}>
    <table>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>
              {column.sort && (
                <>
                  &nbsp; <SortAscIcon />
                </>
              )}
              {column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((x, index) => (
          <Row
            item={x}
            key={index}
            columns={columns}
            tagsRow={tagsRow}
          />
        ))}
      </tbody>
      {/* <tfoot className={styles.foot}>
        <tr>
          <td colSpan={columns.length}>
            <button className={cn("button-stroke button-small", styles.button)}>
              <Loader className={styles.loader} />
              <span>Load more</span>
            </button>
          </td>
        </tr>
      </tfoot> */}
    </table>
  </div>
);

export default Table;
