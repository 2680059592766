import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../app/api";

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  sectionTags: [],
  createSectionOnProcess: false,
  createSectionData: {},
  createdSection: null,
  sectionIntegrations: [],

  assignedEmployees: [],
  unAssignedEmployees: [],

  sectionTagById: null,
};

export const section = createSlice({
  name: "tag",
  initialState,
  reducers: {
    startCreateSectionProcess: (state) => {
      state.createSectionOnProcess = true;
    },
    endCreateSectionProcess: (state) => {
      state.createSectionOnProcess = false;
    },
    onCreateSectionDataChange: (state, action) => {
      state.createSectionData = {
        ...state.createSectionData,
        ...action.payload,
      };
    },
    clearCreateSectionData: (state) => {
      state.createSectionData = {};
      state.createdSection = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSectionTagById.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getSectionTagById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.sectionTagById = action.payload?.data?.section_tag || null;
      })
      .addCase(getSectionTagById.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(createNewSection.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(createNewSection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.createdSection = action.payload?.data?.section_tag || null;
      })
      .addCase(createNewSection.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(editSection.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(editSection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(editSection.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(deleteSectionTag.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(deleteSectionTag.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(deleteSectionTag.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getSectionTags.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getSectionTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.sectionTags = action.payload?.data?.section_tags || [];
      })
      .addCase(getSectionTags.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getAllSectionTags.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getAllSectionTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.sectionTags = action.payload?.data?.section_tags || [];
      })
      .addCase(getAllSectionTags.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getAssignedBySectionTag.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getAssignedBySectionTag.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.assignedEmployees =
          action.payload?.data?.assigned_employees || [];
      })
      .addCase(getAssignedBySectionTag.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getUnAssignedBySectionTag.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getUnAssignedBySectionTag.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.unAssignedEmployees =
          action.payload?.data?.unassigned_employees || [];
      })
      .addCase(getUnAssignedBySectionTag.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(assignEmployeesToSection.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(assignEmployeesToSection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(assignEmployeesToSection.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(unAssignEmployeeToSection.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(unAssignEmployeeToSection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(unAssignEmployeeToSection.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(addIntegrationsToSection.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(addIntegrationsToSection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(addIntegrationsToSection.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(removeIntegrationFromSection.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(removeIntegrationFromSection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(removeIntegrationFromSection.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      // Section Integration Endpoints
      .addCase(getSectionIntegrations.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getSectionIntegrations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.sectionIntegrations = action.payload?.data?.integrations || [];
      })
      .addCase(getSectionIntegrations.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Section Tags Endpoints
export const getSectionTagById = createAsyncThunk(
  "getSectionTagById",
  async (id) => {
    const response = await api.get(`/tags/section_tags/${id}`);
    return response;
  }
);

export const createNewSection = createAsyncThunk(
  "createNewSection",
  async ({ name, description }) => {
    const response = await api.post(`/tag/new_section`, { name, description });
    return response;
  }
);

export const editSection = createAsyncThunk("editSection", async (data) => {
  const response = await api.put(`/tag/edit_section`, data);
  return response;
});

export const deleteSectionTag = createAsyncThunk(
  "deleteSectionTag",
  async (id) => {
    const response = await api.post(`/tag/delete_section_tag/${id}`);
    return response;
  }
);

export const getSectionTags = createAsyncThunk("getSectionTags", async () => {
  const response = await api.get(`/tags/sections`);
  return response;
});

export const getAllSectionTags = createAsyncThunk(
  "getAllSectionTags",
  async () => {
    const response = await api.get(`/tags/sections/show_more`);
    return response;
  }
);

export const getAssignedBySectionTag = createAsyncThunk(
  "getAssignedBySectionTags",
  async (sectionTagId) => {
    const response = await api.get(
      `/tags/section_tags/${sectionTagId}/assigned`
    );
    return response;
  }
);

export const getUnAssignedBySectionTag = createAsyncThunk(
  "getUnAssignedBySectionTag",
  async (sectionTagId) => {
    const response = await api.get(
      `/tags/section_tags/${sectionTagId}/unassigned`
    );
    return response;
  }
);

export const assignEmployeeToSection = createAsyncThunk(
  "assignEmployeeToSection",
  async ({ sectionTagId, employeeId }) => {
    const response = await api.post(
      `/tags/section_tags/${sectionTagId}/assign/${employeeId}`
    );
    return response;
  }
);

export const unAssignEmployeeToSection = createAsyncThunk(
  "unAssignEmployeeToSection",
  async ({ sectionTagId, employeeId }) => {
    const response = await api.post(
      `/tags/section_tags/${sectionTagId}/unassign/${employeeId}`
    );
    return response;
  }
);

export const assignEmployeesToSection = createAsyncThunk(
  "assignEmployeesToSection",
  async ({ sectionTagId, employeeIds }) => {
    const response = await api.post(
      `/tags/section_tags/${sectionTagId}/assign_employees`,
      {
        users: employeeIds,
      }
    );
    return response;
  }
);

// Section Integration Endpoints
export const getSectionIntegrations = createAsyncThunk(
  "getSectionIntegrations",
  async (sectionId) => {
    const response = await api.get(
      `/tags/section_tags/${sectionId}/integrations`
    );
    return response;
  }
);

export const addIntegrationsToSection = createAsyncThunk(
  "addIntegrationsToSection",
  async ({ sectionTagId, integrations }) => {
    const response = await api.put(
      `/tags/section_tags/${sectionTagId}/integrations`,
      {
        integrations,
      }
    );
    return response;
  }
);

export const removeIntegrationFromSection = createAsyncThunk(
  "removeIntegrationFromSection",
  async ({ sectionTagId, integrationId }) => {
    const response = await api.put(
      `/tags/section_tags/${sectionTagId}/remove/${integrationId}`
    );
    return response;
  }
);

export const {
  startCreateSectionProcess,
  endCreateSectionProcess,
  onCreateSectionDataChange,
  clearCreateSectionData,
} = section.actions;

export default section.reducer;
