import { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import { getCompanyGoals } from "slices/homeSlice";
import { getBasicsOfMyIntegrations } from "slices/integrationSlice";

import styles from "./OnboardWarning.module.sass";

const OnboardWarning = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showWarning, setShowWarning] = useState(false);
  const onboardedOnce = window.localStorage.getItem("onboardedOnce");
  const { currentUser } = useSelector((state) => state.app);

  const { myIntegrationsBasics } = useSelector((state) => state.integration);
  const { companyGoals } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(getBasicsOfMyIntegrations());
    dispatch(getCompanyGoals());
  }, [dispatch]);

  if (
    myIntegrationsBasics?.length ||
    companyGoals?.length ||
    (!currentUser?.is_creator && !currentUser?.is_editor)
  )
    return null;

  if (!onboardedOnce && (currentUser?.is_creator || currentUser?.is_editor)) {
    navigate("/onboarding");
  }

  setTimeout(() => {
    setShowWarning(true);
  }, 1000);

  if (!showWarning) return null;

  return (
    <div className={styles.warning}>
      <div className={styles.text}>
        <WarningIcon />
        Finish setting up the Onboarding to proceed with Employees
      </div>

      <button
        className={styles.button}
        onClick={() => navigate("/onboarding")}
      >
        Finish Onboarding
      </button>
    </div>
  );
};

export default OnboardWarning;
