import React from "react";

import cn from "classnames";
import { Link } from "react-router-dom";

import Card from "../../../components/Card";
import Icon from "../../../components/Icon";

import styles from "./TopButtons.module.sass";

const TopButtons = ({ className, title }) => {
  return (
    <Card
      className={cn(styles.card, className)}
      title=""
      classTitle="title-purple"
    >
      <div className={styles.buttons}>
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to={`/integration/settings/${title}`}
        >
          <Icon
            name="setting"
            size="20"
          />
          <span>Settings</span>
        </Link>
        <Link
          className={cn("button button-small", styles.button)}
          to={`/integration/logs/${title}`}
        >
          <Icon
            name="activity"
            size="20"
          />
          <span>See activity log</span>
        </Link>
      </div>
    </Card>
  );
};

export default TopButtons;
