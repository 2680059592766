import React, { useState } from "react";

import cn from "classnames";
import { Link } from "react-router-dom";

import Avatar from "../Avatar";
import Checkbox from "../Checkbox";
import Icon from "../Icon";

import Control from "./Control";
import styles from "./Integration.module.sass";

const Integration = ({
  className,
  item,
  value,
  onChange,
  released,
  withoutСheckbox,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    onChange();
    setVisible(!visible);
  };

  return (
    <div
      className={cn(styles.integration, className, {
        [styles.active]: visible,
      })}
    >
      <div className={styles.preview}>
        {!withoutСheckbox && (
          <Checkbox
            className={styles.checkbox}
            classCheckboxTick={styles.checkboxTick}
            value={value}
            onChange={() => handleClick()}
          />
        )}
        <Control className={styles.control} />
        <Avatar
          src={item.avatar_url}
          alt="Integration"
        />
      </div>
      <div className={styles.date}>{item.description}</div>
      <div className={styles.line}>
        <div className={styles.title}>{item.name}</div>
        <Link
          className={cn("button button-small", styles.button)}
          to="/departments/add"
        >
          <Icon
            name="add"
            size="22"
          />
          <span>Integrate</span>
        </Link>
      </div>
    </div>
  );
};

export default Integration;
