import React, { useState, useEffect, useCallback } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import { getNotes } from "../../../slices/employeeSlice";
import AddNoteModal from "../Notes/AddNoteModal";

import styles from "./Notes.module.sass";
import Table from "./Table";

const Note = ({ viewAll }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { notes } = useSelector((state) => state.employee);

  const [addNoteModal, setAddNoteModal] = useState(false);
  
  const handleNoteAdded = useCallback(() => {
    dispatch(getNotes({ id: params.id, all: !viewAll }));
  }, [dispatch, params.id, viewAll]);

  useEffect(() => {
    setAddNoteModal(false);
  }, [notes]);

  useEffect(() => {
    handleNoteAdded();
  }, [handleNoteAdded]);

  return (
    <>
      <Card
        className={styles.card}
        title="Notes"
        classTitle={cn("title-transparent", styles.title)}
        classCardHead={cn(styles.head)}
        head={
          <button
            onClick={() => setAddNoteModal(true)}
            className={cn("button-small", styles.button)}
          >
            <Icon
              name="plus"
              size="14"
            />
            Add new note
          </button>
        }
      >
        <div className={cn(styles.row)}>
          <Table
            className={styles.table}
            items={notes}
            viewAll={viewAll}
            id={params.id}
          />
        </div>
      </Card>
      {addNoteModal && (
        <AddNoteModal
          id={params.id}
          visible={addNoteModal}
          onClose={() => setAddNoteModal(false)}
          onNoteAdded={handleNoteAdded}
        />
      )}
    </>
  );
};

export default Note;
