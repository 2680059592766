import React from "react";

import cn from "classnames";

import { ReactComponent as SortAscIcon } from "assets/icons/sort-asc.svg";
import { ReactComponent as SortDescIcon } from "assets/icons/sort-desc.svg";

import Row from "./Row";
import styles from "./Table.module.sass";

const columns = [
  {
    name: "Tag",
    sort: "name",
  },
  {
    name: "Assigned to",
    sort: "count",
  },
  {
    name: "Created in",
    sort: "created_at",
  },
  {
    name: "Last Assigned",
    sort: "last_assigned",
  },
  {
    name: "Status",
    sort: "status",
  },
];

const Table = ({
  className,
  activeTable,
  items,
  loading,
  onSortHandler,
  sorting,
}) => (
  <div className={cn(styles.wrapper, className)}>
    <div className={cn(styles.table)}>
      <div className={cn(styles.row, { [styles.active]: activeTable })}>
        {columns.map((column, index) => (
          <div
            className={cn(styles.col, {
              [styles.selected]: sorting.sort_by === column.sort,
            })}
            key={index}
            onClick={() => onSortHandler(column.sort)}
          >
            {column.sort &&
              (sorting.sort_by === column.sort &&
              sorting.sort_order === "desc" ? (
                <SortDescIcon />
              ) : (
                <SortAscIcon />
              ))}
            &nbsp; {column.name}
          </div>
        ))}
      </div>

      {items?.map((x, index) => (
          <Row
            item={x}
            key={index}
            loading={loading}
          />
        ))}
    </div>
    {items?.length > 0 && (
      <div className={styles.foot}>
        {/* <button className={cn("button-stroke button-small", styles.button)}>
          {loading && <Loader className={styles.loader} />}
          <span>Load more</span>
        </button> */}
      </div>
    )}
  </div>
);

export default Table;
