import { configureStore } from "@reduxjs/toolkit";

import aiReducer from "../slices/aiSlice";
import appReducer from "../slices/appSlice";
import companySlice from "../slices/companySlice";
import employeeReducer from "../slices/employeeSlice";
import homeReducer from "../slices/homeSlice";
import homeV2Reducer from "../slices/homeV2Slice";
import integrationReducer from "../slices/integrationSlice";
import notificationReducer from "../slices/notificationSlice";
import sectionReducer from "../slices/sectionSlice";
import tagReducer from "../slices/tagSlice";
import teamReducer from "../slices/teamSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    ai: aiReducer,
    company: companySlice,
    home: homeReducer,
    employee: employeeReducer,
    integration: integrationReducer,
    tag: tagReducer,
    section: sectionReducer,
    notification: notificationReducer,
    homeV2: homeV2Reducer,
    team: teamReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
