import { useState } from "react";

import { ReactComponent as TaskIcon } from "assets/icons/task.svg";
import ConfluenceIntegrationCredentials from
  "screens/Integrations/AllIntegrations/IntegrationModals/ConfluenceIntegration";
import GithubIntegrationCredentials from "screens/Integrations/AllIntegrations/IntegrationModals/GithubIntegration";
import GoogleIntegrationCredentials from "screens/Integrations/AllIntegrations/IntegrationModals/GoogleIntegration";
import JiraIntegrationCredentials from "screens/Integrations/AllIntegrations/IntegrationModals/JiraIntegration";
import OutlookIntegrationCredentials from "screens/Integrations/AllIntegrations/IntegrationModals/OutlookIntegration";
import SalesforceIntegrationCredentials from
  "screens/Integrations/AllIntegrations/IntegrationModals/SalesforceIntegration";
import SlackIntegrationCredentials from "screens/Integrations/AllIntegrations/IntegrationModals/SlackIntegration";
import TeamsIntegrationCredentials from "screens/Integrations/AllIntegrations/IntegrationModals/TeamsIntegration";
import ZoomIntegrationCredentials from "screens/Integrations/AllIntegrations/IntegrationModals/ZoomIntegration";

import MetricsModal from "../MetricsModal";
import TutorialModal from "../TutorialModal";

import styles from "./Credentials.module.sass";

const integrationIcons = {
  Slack: {
    img: "/images/integration/slack.png",
    integration: <SlackIntegrationCredentials onboarding />,
  },
  Github: {
    img: "/images/integration/github.png",
    full: true,
    integration: <GithubIntegrationCredentials onboarding />,
  },
  Google: {
    img: "/images/integration/google.png",
    integration: <GoogleIntegrationCredentials onboarding />,
  },
  Confluence: {
    img: "/images/integration/confluence.png",
    integration: <ConfluenceIntegrationCredentials onboarding />,
    videoUrl: "/videos/tutorials/confluence.mp4",
  },
  Jira: {
    img: "/images/integration/jira.png",
    integration: <JiraIntegrationCredentials onboarding />,
  },
  Zoom: {
    img: "/images/integration/zoom.png",
    full: true,
    integration: <ZoomIntegrationCredentials onboarding />,
  },
  Teams: {
    img: "/images/integration/teams.png",
    integration: <TeamsIntegrationCredentials onboarding />,
  },
  Outlook: {
    img: "/images/integration/exchangeonline.png",
    integration: <OutlookIntegrationCredentials onboarding />,
  },
  Salesforce: {
    img: "/images/integration/salesforce.png",
    integration: <SalesforceIntegrationCredentials onboarding />,
    videoUrl: "/videos/tutorials/salesforce.mp4",
  },
};

const Credentials = ({ integration }) => {
  const [isMetricsModalOpen, setMetricsModalOpen] = useState(false);
  const [isTutorialModalOpen, setTutorialModalOpen] = useState(false);

  return (
    <div className={styles.card}>
      <div className={styles.head}>
        <div className={styles.title}>
          <img
            width={36}
            src={integrationIcons[integration.name]?.img}
            alt={integration.name}
          />
          <h1 className={styles.name}>{integration.name}</h1>
        </div>

        <div
          className={styles.viewAll}
          onClick={() => setMetricsModalOpen(true)}
        >
          View all
          <TaskIcon />
        </div>
      </div>
      <div className={styles.subhead}>
        {integrationIcons[integration.name]?.videoUrl ? (
          <div
            className={styles.integrationTutorial}
            onClick={() => setTutorialModalOpen(true)}
          >
            Watch Integration tutorial
          </div>
        ) : <div />}

        <div className={styles.metricsCount}>
          Pulling {integration.metrics.split(", ").length} Available Metrics
        </div>
      </div>

      <div className={styles.credentials}>
        {integrationIcons[integration.name]?.integration}
      </div>

      <MetricsModal
        isOpen={isMetricsModalOpen}
        onClose={() => setMetricsModalOpen(false)}
        onOpen={() => setMetricsModalOpen(true)}
        integration={integration}
        icon={integrationIcons[integration.name]?.img}
      />

      {integrationIcons[integration.name]?.videoUrl && (
        <TutorialModal
          isOpen={isTutorialModalOpen}
          onClose={() => setTutorialModalOpen(false)}
          onOpen={() => setTutorialModalOpen(true)}
          integration={integration}
          icon={integrationIcons[integration.name]?.img}
          videoUrl={integrationIcons[integration.name]?.videoUrl}
        />
      )}
    </div>
  );
};

export default Credentials;
