import React, { useState, useRef, useEffect } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { TooltipGlodal, Dropdown } from "components";
import { removeEmployee, getAllEmployees } from "slices/employeeSlice";

import {
  requestPasswordChange,
  confirmPasswordChange,
} from "../../slices/appSlice";

import AddDeleteUser from "./AddDeleteUser";
import AdminInformation from "./AdminInformation";
import Code from "./Code";
import CompanySettings from "./CompanySettings";
import DeleteModal from "./DeleteModal";
import EmployeeNotifications from "./EmployeeNotifications";
import Login from "./Login";
import Notifications from "./Notifications";
import Payment from "./Payment";
import ProfileInformation from "./ProfileInformation";
import ProfileIntegration from "./ProfileIntegration";
import styles from "./Settings.module.sass";

const Settings = ({ filters }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [values, setValues] = useState({
    password: "",
    passwordConfirmation: "",
  });
  const { error, confirmationToken } = useSelector((state) => state.app);

  useEffect(() => {
    if (confirmationToken && error !== "updated") {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [confirmationToken, error]);

  const handleSubmit = (values) => {
    setValues(values);
    dispatch(requestPasswordChange(values));
  };

  const handleConfirm = (confirmer) => {
    dispatch(confirmPasswordChange({ ...values, confirmer }));
  };
  const scrollToNotifications = useRef(null);
  const scrollToPayment = useRef(null);

  const navigation = [];
  if (!params["employee"]) {
    navigation.push({
      title: "Company Information",
      component: <CompanySettings />,
    });
    navigation.push({
      title: "User Information",
      component: (
        <div>
          <AdminInformation onSubmit={(values) => handleSubmit(values)}/>
        </div>
      ),
    });
    navigation.push({
      title: "Add or delete Users",
      component: <AddDeleteUser />,
    });
    navigation.push({
      title: "Notifications",
      component: <Notifications />,
    });
  }

  if (params["employee"]) {
    navigation.push({
      title: "Basics",
      component: <ProfileInformation />,
    });
    navigation.push({
      title: "Integrations",
      component: <ProfileIntegration />,
    });
    navigation.push({
      title: "Notifications",
      component: <EmployeeNotifications />,
    });
    navigation.push({
      title: <div className={styles.deleteSection}>Delete Employee</div>,
      action: () => setIsModalOpen(true),
    });
  }

  if (params["notifications"]) {
    navigation.push({
      title: "Notifications",
      scroll: scrollToNotifications,
      component: <Notifications />,
    });
    navigation.push({
      title: "Payment",
      scroll: scrollToPayment,
      component: <Payment />,
    });
  }

  const options = [];
  navigation?.map((x) => options.push(x.title));
  const [activeTab, setActiveTab] = useState(options[0]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (x, index) => {
    x.action ? x.action() : setActiveIndex(index);
  };

  const handleDelete = async () => {
    const res = await dispatch(removeEmployee(params["employee"]));
    if (res.error) setErrorDeleting(true);
    else {
      dispatch(getAllEmployees());
      setIsModalOpen(false);
      toast.success("Successfully Deleted Employee!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      navigate("/employees");
    }
  };

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.menu}>
          {navigation?.map((x, index) => (
            <button
              className={cn(styles.button, {
                [styles.active]: activeIndex === index,
              })}
              key={index}
              onClick={() => handleClick(x, index)}
            >
              {x.title}
            </button>
          ))}
        </div>
        <div className={styles.wrapper}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={activeTab}
            setValue={setActiveTab}
            options={options}
          />

          {navigation[activeIndex]?.component}

          {/* <button className={cn("button", styles.button)}>Save</button> */}

          {visible && (
            <Code onConfirm={(confirmer) => handleConfirm(confirmer)} />
          )}
        </div>
      </div>
      <TooltipGlodal />

      <DeleteModal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDelete}
        errorDeleting={errorDeleting}
      />
    </>
  );
};

export default Settings;
