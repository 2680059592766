import React, { useState } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { TooltipGlodal, Dropdown } from "components";
import { deleteSectionTag } from "slices/sectionSlice";

import About from "./About";
import DeleteModal from "./DeleteModal";
import Employees from "./Employees";
import Integrations from "./Integrations";
import styles from "./SectionDetails.module.sass";

const SectionDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState(false);

  const { assignedEmployees, sectionIntegrations } = useSelector(
    (state) => state.section
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const handleDelete = async () => {
    const action = await dispatch(deleteSectionTag(params["id"]));
    if (action?.payload?.status === 200) {
      setIsModalOpen(false);
      navigate("/tags");
    }

    if (action?.error) setErrorDeleting(true);
  };

  let navigation = [
    {
      title: `Employees ${assignedEmployees?.length ? `(${assignedEmployees.length})` : ""}`,
      component: <Employees />,
    },
    {
      title: `Integrations ${sectionIntegrations?.length ? `(${sectionIntegrations.length})` : ""}`,
      component: <Integrations />,
    },
    {
      title: "About",
      component: <About />,
    },
    {
      title: <div className={styles.deleteSection}>Delete Team</div>,
      action: () => setIsModalOpen(true),
    },
  ];

  if (assignedEmployees[0]?.is_default) {
    navigation = navigation.slice(0, 1);
  }

  const options = [];
  navigation?.map((x) => options.push(x.title));
  const [activeTab, setActiveTab] = useState(options[0]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.menu}>
          {navigation?.map((x, index) => (
            <button
              className={cn(styles.button, {
                [styles.active]: activeIndex === index,
              })}
              key={index}
              onClick={x.action ? () => x.action() : () => handleClick(index)}
            >
              {x.title}
            </button>
          ))}
        </div>
        <div className={styles.wrapper}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={activeTab}
            setValue={setActiveTab}
            options={options}
          />

          {navigation[activeIndex]?.component}
        </div>
      </div>
      <TooltipGlodal />

      <DeleteModal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDelete}
        errorDeleting={errorDeleting}
      />
    </>
  );
};

export default SectionDetails;
