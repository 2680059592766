import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { Avatar } from "components";

import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Filters from "../../components/Filters";
import Loader from "../../components/Loader";
import { getIntegrationLogs } from "../../slices/integrationSlice";
import { onlyUnique, periodConverter } from "../../utils";

import styles from "./IntegrationLog.module.sass";
import Settings from "./Settings";

const IntegrationLog = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { intervals } = useSelector((state) => state.app);
  const { integrationLogs } = useSelector((state) => state.integration);
  const [sorting, setSorting] = useState(intervals[0]);
  const navigation = integrationLogs
    ?.map((log) => log.isToday)
    ?.filter(onlyUnique);

  const [activeTab, setActiveTab] = useState(navigation ? navigation[0] : "");
  let title = ["Integrations"];
  if (params) {
    Object.keys(params).forEach((param) => title.push(params[param]));
  }
  title.push("Logs");
  useEffect(() => {
    dispatch(
      getIntegrationLogs({
        integration: params.integration,
        all: true,
        period: 7,
      })
    );
  }, [dispatch, params.integration]);

  useEffect(() => {
    if (!navigation?.includes(activeTab)) {
      setActiveTab(navigation ? navigation[0] : "");
    }
  }, [activeTab, navigation]);

  const handleActivityPeriod = (value) => {
    setSorting(value);
    dispatch(
      getIntegrationLogs({
        integration: params.integration,
        all: true,
        period: periodConverter(value),
      })
    );
  };

  return (
    <>
      <div className={cn("h3", styles.title)}>
        {title.map((t, i) => (
          <span key={i}>
            {i === 0 ? " " : " / "}
            {t}
          </span>
        ))}
      </div>
      <Card
        className={cn(styles.card)}
        title="Log"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.nav}>
              {navigation?.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: x === activeTab,
                  })}
                  onClick={() => setActiveTab(x)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            {/* <Filters className={styles.filters} title="Smart Filter">
              <Settings filters={navigation} />
            </Filters> */}
            <div className={styles.divider} />
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={sorting}
              setValue={handleActivityPeriod}
              options={intervals}
              small
            />
          </>
        }
      >
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Date</div>
              <div className={styles.col}>Employee</div>
              <div className={styles.col}>Description</div>
              <div className={styles.col}>Duration</div>
            </div>
            {navigation?.map(
              (nav) =>
                activeTab === nav &&
                integrationLogs
                  .filter((employee) => employee.isToday === nav)
                  .map((x, index) => {
                    let temp_emails = "";
                    if (Array.isArray(x?.emails)) {
                      temp_emails =
                        x?.emails.length > 0 ? x?.emails?.join(",") : "";
                    } else {
                      temp_emails =
                        x?.emails?.replace(
                          /[^a-zA-Z0-9\s,@.(),:;<>[\]]+/g,
                          ""
                        ) || "";
                    }
                    return (
                      <div
                        className={styles.row}
                        key={index}
                      >
                        <div className={styles.col}>
                          <div className={styles.label}>Date</div>
                          {x.time}
                        </div>
                        <div className={styles.col}>
                          <div className={styles.details}>
                            <Link
                              to={`/employee/${x.id}/details`}
                              className={styles.item}
                            >
                              <div className={styles.avatar}>
                                <Avatar
                                  src={x.avatar_url}
                                  alt="Avatar"
                                />
                              </div>
                              <div className={styles.details}>
                                <div className={styles.user}>{x.name}</div>
                                <div className={styles.email}>
                                  {temp_emails
                                    ?.split(",")
                                    ?.map((email, index) => (
                                      <div key={index}>{email}</div>
                                    ))}
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className={styles.col}>
                          <div className={styles.integration}>{x.action}</div>
                        </div>
                        <div className={styles.col}>
                          <div className={styles.label}>Duration</div>
                          {x.isToday === "Today" ? (
                            <div
                              className={cn(
                                { "status-green-dark": x.isToday === "Today" },
                                styles.status
                              )}
                            >
                              {x.isToday}
                            </div>
                          ) : (
                            <div
                              className={cn(
                                { "status-red-dark": x.isToday === "All" },
                                styles.status
                              )}
                            >
                              {x.isToday}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
            )}
          </div>
        </div>
        {/* <div className={styles.foot}>
          <button className={cn("button-stroke button-small", styles.button)}>
            <Loader className={styles.loader} />
            <span>Load more</span>
          </button>
        </div> */}
      </Card>
    </>
  );
};

export default IntegrationLog;
