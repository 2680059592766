import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Icon, TableComponent, UserDetails, TextInput } from "components";
import {
  getAssignedBySectionTag,
  unAssignEmployeeToSection,
} from "slices/sectionSlice";
import { formatDateUK } from "utils";

import Item from "../Item";

import AddEmployeeToSectionModal from "./AddEmployeeToSectionModal";
import DeleteEmployeeModal from "./DeleteEmployeeModal";
import styles from "./Employees.module.sass";

const getColumns = (onRemoveEmployee, is_default) => [
  {
    title: "Name",
    key: "name",
    sort: true,
    dataIndex: "data",
    render: (data) => <UserDetails user={data} />,
  },
  {
    title: "Associated since",
    key: "updated_at",
    sort: true,
    dataIndex: "updated_at",
    render: (date) => <div>{formatDateUK(date)}</div>,
  },
  {
    render: (data) =>
      is_default ? null : (
        <button
          className={cn(
            "button-stroke button-small",
            styles.button,
            styles.remove
          )}
          onClick={() => onRemoveEmployee(data)}
        >
          <Icon
            name="dash"
            size="20"
            viewBox="0 0 24 24"
          />
          <span>Remove Employee</span>
        </button>
      ),
  },
];

const Integrations = ({ className }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { assignedEmployees } = useSelector((state) => state.section);
  const [search, setSearch] = useState("");
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);

  const [showDeleteEmployeeModal, setShowDeleteEmployeeModal] = useState(null);

  useEffect(() => {
    dispatch(getAssignedBySectionTag(params.id));
  }, [dispatch, params.id]);

  const onRemoveEmployee = async (user) => {
    setShowDeleteEmployeeModal(user);
  };

  const confirmRemoveEmployee = async (user) => {
    const action = await dispatch(
      unAssignEmployeeToSection({
        sectionTagId: params.id,
        employeeId: user.id,
      })
    );

    if (!action.error)
      toast.success("Successfully Removed Employee!", {
        position: toast.POSITION.TOP_RIGHT,
      });

    dispatch(getAssignedBySectionTag(params.id));
    setShowDeleteEmployeeModal(null);
  };

  const employeesBySearch = assignedEmployees?.filter((employee) =>
    employee.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Item
      className={cn(styles.card, className)}
      title="Employees"
      classTitle="title-green"
      head={
        <div className={styles.head}>
          <TextInput
            classInput={styles.search}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search or type a command"
            type="text"
            name="search"
            icon="search"
          />
          <div
            className={cn("button button-small", styles.button)}
            onClick={() => setShowAddEmployeeModal(true)}
          >
            <Icon
              name="add"
              size="24"
            />
            <span>Add new Employee</span>
          </div>
        </div>
      }
    >
      <TableComponent
        columns={getColumns(onRemoveEmployee, assignedEmployees[0]?.is_default)}
        data={employeesBySearch}
        className={styles.table}
      />

      <AddEmployeeToSectionModal
        sectionId={params.id}
        visible={showAddEmployeeModal}
        onClose={() => setShowAddEmployeeModal(false)}
      />

      <DeleteEmployeeModal
        visible={showDeleteEmployeeModal}
        onConfirm={() => confirmRemoveEmployee(showDeleteEmployeeModal)}
        onClose={() => setShowDeleteEmployeeModal(null)}
        name={showDeleteEmployeeModal?.name}
      />
    </Item>
  );
};

export default Integrations;
