import { colors } from "constants";

import React from "react";

import cn from "classnames";
import { Link } from "react-router-dom";

import { ReactComponent as TimerIcon } from "assets/icons/timer.svg";
import { getEmployeeActivities, refactorName } from "utils";

import Avatar from "../../../../../../components/Avatar";

import styles from "./Row.module.sass";

const Row = ({ item, loading }) => {
  const activities = getEmployeeActivities(item);

  const getWidth = (value) => {
    let total = item?.total;
    if (item?.sum_minutes_spent) total -= item?.sum_minutes_spent;
    if (item?.sum_hours_logged) total -= item?.sum_hours_logged;
    return `${Math.ceil((parseInt(value) / parseInt(total)) * 100)}%`;
  };

  let temp_tags = "";
  if (Array.isArray(item?.tags_array)) {
    temp_tags = item?.tags_array.length > 0 ? item?.tags_array?.join(",") : "";
  } else {
    temp_tags =
      item?.tags_array?.replace(/[^a-zA-Z0-9\s,@.(),:;<>[\]]+/g, "") || "";
  }
  return (
    <div className={cn(styles.row, { [styles.loading]: loading })}>
      <div className={styles.col}>
        <div className={styles.item}>
          <Link
            className={styles.preview}
            to={`/employee/${item.id}/details`}
          >
            <Avatar
              src={item.avatar_url}
              alt="Employee"
            />
          </Link>
          <Link
            className={styles.details}
            to={`/employee/${item.id}/details`}
          >
            <div className={styles.integration}>{item?.name}</div>
          </Link>
          <div className={styles.tableTags}>
            {temp_tags &&
              temp_tags?.split(",")?.map((tag) => {
                if (tag.length < 3) {
                  return (
                    <Link
                      key={tag}
                      to={`/tags/${tag}`}
                      className={styles.tag3}
                    >
                      {tag}
                    </Link>
                  );
                } else if (tag.length >= 3 && tag.length < 6) {
                  return (
                    <Link
                      key={tag}
                      to={`/tags/${tag}`}
                      className={styles.tag6}
                    >
                      {tag}
                    </Link>
                  );
                } else if (tag.length >= 6 && tag.length < 9) {
                  return (
                    <Link
                      key={tag}
                      to={`/tags/${tag}`}
                      className={styles.tag9}
                    >
                      {tag}
                    </Link>
                  );
                } else if (tag.length >= 9 && tag.length < 12) {
                  return (
                    <Link
                      key={tag}
                      to={`/tags/${tag}`}
                      className={styles.tag12}
                    >
                      {tag}
                    </Link>
                  );
                } else if (tag.length >= 12 && tag.length < 15) {
                  return (
                    <Link
                      key={tag}
                      to={`/tags/${tag}`}
                      className={styles.tag15}
                    >
                      {tag}
                    </Link>
                  );
                } else if (tag.length >= 15 && tag.length < 18) {
                  return (
                    <Link
                      key={tag}
                      to={`/tags/${tag}`}
                      className={styles.tag18}
                    >
                      {tag}
                    </Link>
                  );
                } else {
                  return (
                    <Link
                      key={tag}
                      to={`/tags/${tag}`}
                      className={styles.tag21}
                    >
                      {tag}
                    </Link>
                  );
                }
              })}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div
          className={styles.line}
          style={{ width: item.progress }}
        >
          <div className={styles.number}>{item.total}</div>
          {Object.keys(activities)?.map(
            (key, index) =>
              key !== "sum_minutes_spent" && (
                <div
                  className={styles.box}
                  style={{
                    backgroundColor: colors[index],
                    width: getWidth(activities[key]),
                  }}
                  key={index}
                >
                  <div className={styles.tooltip}>
                    <div className={styles.subtitle}>{refactorName(key)}</div>
                    <div className={styles.legend}>
                      <div
                        className={styles.color}
                        style={{ backgroundColor: colors[index] }}
                      ></div>
                      <div className={styles.counter}>{activities[key]}</div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      {(item?.hasOwnProperty("sum_minutes_spent") ||
        item?.hasOwnProperty("sum_hours_logged")) && (
        <div className={styles.col}>
          <div className={styles.timer}>
            <TimerIcon />
            {item?.sum_minutes_spent
              ? `${item?.sum_minutes_spent} minutes`
              : `${item?.sum_hours_logged} hours`}
          </div>
        </div>
      )}
    </div>
  );
};

export default Row;
