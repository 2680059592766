import { Modal } from "components";

import styles from "./TutorialModal.module.sass";

const TutorialModal = ({ isOpen, onClose, videoUrl }) => {
  return (
    <Modal
      visible={isOpen}
      onClose={onClose}
      modalClassName={styles.modal}
      outerClassName={styles.outer}
      title="Metrics"
      hideCloseBtn={true}
    >
      <video
        src={videoUrl}
        loop={true}
        autoPlay={true}
        muted={true}
        controls={true}
        className={styles.video}
      />
    </Modal>
  );
};

export default TutorialModal;
