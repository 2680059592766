import { useState } from "react";

import cn from "classnames";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { Modal, TagInput, UserDetails } from "components";
import { checkEmail, updateEmployeeProfile } from "slices/employeeSlice";

import ConfirmModal from "../ProfileInformation/ConfirmModal";

import styles from "./ProfileIntegration.module.sass";

const AddEmailModal = ({ visible, onClose, onAddEmail, employee }) => {
  const [emails, setEmails] = useState(
    employee?.emails_array.map((email) => ({
      id: email,
      text: email,
    }))
  );
  const [intersectingEmails, setIntersectingEmails] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dispatch = useDispatch();

  const handleAddEmail = async (tags) => {
    const res = await dispatch(checkEmail(tags[tags.length - 1].text));
    if (res.payload?.data?.does_exist) {
      setIntersectingEmails((emails) => [
        ...emails,
        tags[tags.length - 1].text,
      ]);
    }

    setEmails(tags);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", emails.map((email) => email.text).join(","));

    formData.append("name", employee.name);
    formData.append("location", employee.location);
    formData.append("bio", employee.bio);

    const res = await dispatch(
      updateEmployeeProfile({
        id: employee.id,
        data: formData,
      })
    );

    if (res.error) {
      toast.error("Unable to Update Profile Information. Try again please!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    toast.success("Successfully Updated Profile Information!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });

    onAddEmail();

    onClose();
  };

  const handleDeleteEmail = (deletedTag, tags) => {
    setIntersectingEmails((emails) =>
      emails.filter((e) => e !== deletedTag.text)
    );

    setEmails(tags);
  };

  return (
    <Modal
      visible={visible}
      title="Add Email"
      onClose={onClose}
      onConfirm={handleAddEmail}
      outerClassName={styles.emailModal}
    >
      <UserDetails user={employee} />

      <div className={styles.emailModalTitle}>Add New Email</div>
      <p className={styles.emailModalDescription}>
        Log in your credentials to access all the available metrics.{" "}
      </p>

      <TagInput
        tags={emails}
        onAdd={(tags) => handleAddEmail(tags)}
        onDelete={(deletedTag, tags) => handleDeleteEmail(deletedTag, tags)}
      />
      {intersectingEmails.length > 0 && (
        <div className={styles.warnEmailIntersection}>
          Warning: One of these emails already exists.
        </div>
      )}

      <button
        className={cn("button", styles.saveEmailButton)}
        onClick={(e) => {
          if (intersectingEmails.length > 0) {
            setShowConfirmModal(true);
            return;
          }
          handleSubmit(e);
        }}
      >
        Save
      </button>

      <ConfirmModal
        visible={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={handleSubmit}
        onClose={() => setShowConfirmModal(false)}
      />
    </Modal>
  );
};

export default AddEmailModal;
