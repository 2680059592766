import cn from "classnames";

import Switch from "../SwitchSecond";

import styles from "./Notification.module.sass";

const NotificationSetting = ({ title, value, onChange, disabled }) => {
  return (
    <div className={cn(styles.subline)}>
      <div className={styles.title}>{title}</div>
      <Switch
        className={styles.switch}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default NotificationSetting;
