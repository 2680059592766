import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { TooltipGlodal, Card, Icon, NewSectionCard } from "components";
import { getAllSectionTags } from "slices/sectionSlice";

import CreateSection from "./CreateSection";
import SectionCard from "./SectionCard";
import styles from "./SectionTag.module.sass";

const Tags = ({ className }) => {
  const [expanded, setExpanded] = useState(false);
  const [createSectionModal, setCreateSectionModal] = useState(false);

  const dispatch = useDispatch();
  const { sectionTags } = useSelector((state) => state.section);

  useEffect(() => {
    dispatch(getAllSectionTags());
  }, [dispatch]);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={
          <div>
            Teams ({sectionTags.length})
            <div className={styles.titleInfo}>
              This is how you can set up and organize your employees into
              groups, for better tracking.
            </div>
          </div>
        }
        classTitle="title-transparent"
        head={
          <div
            className={cn("button button-small", styles.button)}
            onClick={() => setCreateSectionModal(true)}
          >
            <Icon
              name="add"
              size="24"
            />
            <span>Create New Team</span>
          </div>
        }
      >
        {sectionTags.length ? (
          <div className={styles.sectionTags}>
            {sectionTags
              .slice(0, expanded ? sectionTags.length : 3)
              .map((section, index) => (
                <SectionCard
                  key={index}
                  section={section}
                />
              ))}
          </div>
        ) : (
          <NewSectionCard onClick={() => setCreateSectionModal(true)} />
        )}

        <button
          className={cn("button-transparent", styles.seeMore, {
            [styles.hide]: sectionTags.length < 4,
          })}
          onClick={() => setExpanded((expanded) => !expanded)}
        >
          {expanded ? "See less" : "See more"}
          <div className={cn(styles.arrow, { [styles.up]: expanded })}>
            <Icon
              name="arrow-down"
              size="24"
            />
          </div>
        </button>
      </Card>
      <TooltipGlodal />

      <CreateSection
        visible={createSectionModal}
        onClose={() => setCreateSectionModal(false)}
      />
    </>
  );
};

export default Tags;
