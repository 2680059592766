import React from "react";

import cn from "classnames";

import { Modal } from "components";

import styles from "./MuteModal.module.sass";

const MuteModal = ({ visible, loading, onConfirm, onClose }) => {
  return (
    <Modal
      visible={visible}
      onClose={onClose}
    >
      <div className={styles.mute}>
        <div className={styles.info}>Mute all notification</div>

        <div className={styles.text}>
          Are you sure you want to mute all notifications?
        </div>

        <div className={styles.foot}>
          <button
            className={cn("button button-stroke", styles.button)}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className={cn(
              `button ${loading ? styles.loading : ""}`,
              styles.button,
              styles.confirm
            )}
            onClick={onConfirm}
          >
            Mute All Notifications
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MuteModal;
