import React, { useEffect, useState, useRef } from "react";

import cn from "classnames";
import { useSelector } from "react-redux";

import Loader from "../../../components/Loader";

import styles from "./Code.module.sass";

const Code = ({ title, onConfirm }) => {
  const [confirmer, setConfirmer] = useState("");
  const { loading } = useSelector((state) => state.app);
  const [values, setValues] = useState({
    number1: "",
    number2: "",
    number3: "",
    number4: "",
  });
  const inputRefs = {
    number1: useRef(null),
    number2: useRef(null),
    number3: useRef(null),
    number4: useRef(null),
  };

  const valuesHandler = (e, fieldName) => {
    const inputValue = e.target.value;

    // Check if the input value is exactly one digit
    if (/^\d$/.test(inputValue)) {
      console.log(inputValue);
      const prevValues = { ...values };
      prevValues[fieldName] = inputValue;
      setValues({ ...prevValues });

      // Focus on the next input field if available
      if (fieldName !== "number4") {
        inputRefs[
          `number${parseInt(fieldName.charAt(fieldName.length - 1)) + 1}`
        ].current.focus();
      }
    } else if (inputValue === "") {
      console.log(inputValue);
      // Handle input value being empty (deletion)
      const prevValues = { ...values };
      prevValues[fieldName] = "";
      setValues({ ...prevValues });

      // Focus on the previous input field if available
      if (fieldName !== "number1") {
        inputRefs[
          `number${parseInt(fieldName.charAt(fieldName.length - 1)) - 1}`
        ].current.focus();
      }
    } else if (/^\d{4}$/.test(inputValue)) {
      console.log(inputValue);
      const pastedDigits = inputValue.split("");
      for (let i = 0; i < 4; i++) {
        const field = `number${i + 1}`;
        if (inputRefs[field].current) {
          inputRefs[field].current.value = pastedDigits[i];
        }
      }
      setValues({
        ...values,
        ...{
          number1: pastedDigits[0],
          number2: pastedDigits[1],
          number3: pastedDigits[2],
          number4: pastedDigits[3],
        },
      });
    }

    // Prevent default paste behavior
    e.preventDefault();
  };

  const newCodeHandler = () => {
    setValues({
      number1: "",
      number2: "",
      number3: "",
      number4: "",
    });
    inputRefs["number1"].current.focus();
  };

  const submitHandler = () => {
    newCodeHandler();
    onConfirm(confirmer);
  };

  useEffect(() => {
    setConfirmer(
      Object.keys(values)
        .map((val) => values[val])
        .join("")
    );
  }, [values]);
  return (
    <>
      <div className={cn("h2", styles.title)}>{title}</div>
      <div className={styles.code}>
        <div className={styles.body}>
          <div className={styles.infoTop}>
            We send you a confirmation code. Check your inbox to get them.
          </div>
          <div className={styles.fieldset}>
            {Object.keys(values).map((fieldName) => (
              <div
                className={styles.field}
                key={fieldName}
              >
                <input
                  className={styles.input}
                  name={fieldName}
                  type="tel"
                  autoComplete="off"
                  required
                  value={values[fieldName]}
                  onChange={(e) => valuesHandler(e, fieldName)}
                  ref={inputRefs[fieldName]}
                />
              </div>
            ))}
          </div>
          <button
            onClick={submitHandler}
            className={cn("button", styles.button)}
          >
            {loading && <Loader className={styles.loader} />}
            <span>Confirm your request</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Code;
