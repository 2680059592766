import cn from "classnames";

import styles from "./HeaderSwitch.module.sass";

const HeaderSwitch = ({ value, setValue }) => (
  <div className={styles.header}>
    <span>Notifications</span>
    <div className={styles.toggler}>
      <div
        className={cn({ [styles.selected]: value === "general" })}
        onClick={() => setValue("general")}
      >
        General
      </div>
      <div
        className={cn({ [styles.selected]: value === "personalised" })}
        onClick={() => setValue("personalised")}
      >
        Personalized
      </div>
    </div>
  </div>
);

export default HeaderSwitch;
