import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import NotificationSetting from "components/Notification/NotificationSetting";
import {
  getNotificationPreferences,
  updateNotificationPreferences,
} from "slices/notificationSlice";

import styles from "./EmployeeNotifications.module.sass";

const EmployeeNotifications = ({ className }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const employeeId = parseInt(params.employee);

  const { notificationPreferences } = useSelector(
    (state) => state.notification
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getNotificationPreferences());
  }, [dispatch]);

  const employeeNotifications =
    notificationPreferences?.preferences?.personalized_employee;

  const employeeFriendlyNames =
    notificationPreferences?.friendly_names?.employee;

  const handleSwitchChange = async (key, checked) => {
    setLoading(true);

    const newEmployeeNotifications = {
      ...employeeNotifications,
      [key]: checked
        ? [...employeeNotifications[key], employeeId]
        : employeeNotifications[key].filter((x) => x !== employeeId),
    };

    try {
      await dispatch(
        updateNotificationPreferences({
          ...notificationPreferences,
          preferences: {
            ...notificationPreferences.preferences,
            personalized_employee: newEmployeeNotifications,
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cn(styles.item, className)}>
      <div className={cn("title-red", styles.title)}>{"Notifications"}</div>
      <div className={styles.list}>
        {notificationPreferences &&
          Object.keys(employeeFriendlyNames)?.map((key, index) => (
            // key can be: no_activity, daily_highest, daily_lowest, weekly_highest, weekly_lowest
            <NotificationSetting
              key={index}
              title={employeeFriendlyNames[key] || key}
              value={employeeNotifications[key].includes(employeeId) || false}
              onChange={(e) => handleSwitchChange(key, e.target.checked)}
              disabled={loading}
            />
          ))}
      </div>
    </div>
  );
};

export default EmployeeNotifications;
