import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { restructuringConversation } from "utils";

import { apiV1AI } from "../app/api";

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  conversations: [],
  chat: [],
  chatId: null,
  lastConversation: null,
};

export const ai = createSlice({
  name: "ai",
  initialState,
  reducers: {
    setChatMessages: (state, { payload }) => {
      state.loading = payload;
      state.chat = payload;
    },
    setChatId: (state, { payload }) => {
      state.loading = payload;
      state.chatId = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getConversations.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getConversations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.conversations = action.payload?.data || [];
      })
      .addCase(getConversations.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getLastConversation.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getLastConversation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.lastConversation = action.payload?.data || null;
      })
      .addCase(getLastConversation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getConversation.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getConversation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.conversation =
          restructuringConversation(action.payload?.data) || [];
      })
      .addCase(getConversation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getConversations = createAsyncThunk(
  "getConversations",
  async () => {
    const response = await apiV1AI.get("/conversations");
    return response;
  }
);

export const getLastConversation = createAsyncThunk(
  "getLastConversation",
  async () => {
    const response = await apiV1AI.get("/last-conversation");
    return response;
  }
);

export const getConversation = createAsyncThunk(
  "getConversation",
  async ({ conversationId }) => {
    const response = await apiV1AI.get("/conversation", {
      params: { conversationId },
    });
    return response;
  }
);

export const deleteConversation = createAsyncThunk(
  "deleteConversation",
  async ({ conversationId }) => {
    const response = await apiV1AI.delete("/conversation", {
      params: { conversationId },
    });
    return response;
  }
);

export const { setChatMessages, setChatId } = ai.actions;

export default ai.reducer;
