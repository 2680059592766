import { colors } from "constants";

import cn from "classnames";

import { ReactComponent as SortAscIcon } from "assets/icons/sort-asc.svg";
import { ReactComponent as SortDescIcon } from "assets/icons/sort-desc.svg";
import { refactorName, getEmployeeActivities } from "utils";

import styles from "./LineChart.module.sass";

const LineChart = ({ data }) => {
  const getWidth = (value) => {
    let total = data?.total;
    if (data?.sum_minutes_spent) total -= data?.sum_minutes_spent;
    if (data?.sum_hours_logged) total -= data?.sum_hours_logged;
    return `${Math.ceil((parseInt(value) / parseInt(total)) * 100)}%`;
  };

  const activities = getEmployeeActivities(data);

  return (
    <div
      className={styles.line}
      style={{ width: data.progress }}
    >
      <div className={styles.number}>{data.total}</div>
      {Object.keys(activities)?.map(
        (key, index) =>
          key !== "sum_minutes_spent" && (
            <div
              className={styles.box}
              style={{
                backgroundColor: colors[index],
                width: getWidth(activities[key]),
              }}
              key={index}
            >
              <div className={styles.tooltip}>
                <div className={styles.subtitle}>{refactorName(key)}</div>
                <div className={styles.legend}>
                  <div
                    className={styles.color}
                    style={{ backgroundColor: colors[index] }}
                  ></div>
                  <div className={styles.counter}>{activities[key]}</div>
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default LineChart;

export const LineLegend = ({ legend, sort, onSort }) => {
  return (
    <div className={styles.lineHead}>
      <div
        className={cn(styles.info, {
          [styles.selected]: sort?.sort_by === "total",
        })}
        onClick={() =>
          onSort({
            sort_by: "total",
          })
        }
      >
        {sort?.sort_by === "total" && sort?.sort_order === "desc" ? (
          <SortDescIcon />
        ) : (
          <SortAscIcon />
        )}
        Activities
      </div>
      <div className={styles.indicators}>
        {legend.map((x, index) => (
          <div
            className={styles.legendHead}
            key={index}
          >
            <div
              className={styles.colorHead}
              style={{ backgroundColor: colors[index] }}
            ></div>
            {refactorName(x)}
          </div>
        ))}
      </div>
    </div>
  );
};
