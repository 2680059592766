import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api, { apiV2 } from "../app/api";

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  employeeTags: [],
  integrationTabs: [],
  assignedEmployee: {},
  createdNewTag: false,
  activities: {},
  insights: null,
  unAssignedEmployees: [],

  insightsLoading: false,
  activitiesLoading: false,
};

export const tag = createSlice({
  name: "tag",
  initialState,
  reducers: {
    clearCreatedNewTag: (state) => {
      state.createdNewTag = false;
    },
    clearActivities: (state) => {
      state.activities = {};
    },
  },
  extraReducers(builder) {
    builder
      // Employee Tags Endpoints
      .addCase(createNewTag.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(createNewTag.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        // TODO: check if I can use status code
        state.createdNewTag = action.payload?.data?.tag || false;
      })
      .addCase(createNewTag.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getEmployeeTags.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getEmployeeTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.employeeTags = action.payload?.data?.employee_tags || [];
      })
      .addCase(getEmployeeTags.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(assignEmployeeToTag.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(assignEmployeeToTag.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(assignEmployeeToTag.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(unAssignEmployeeFromTag.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(unAssignEmployeeFromTag.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(unAssignEmployeeFromTag.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateEmployeeTag.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(updateEmployeeTag.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(updateEmployeeTag.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getAssignedEmployeesByTag.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getAssignedEmployeesByTag.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.assignedEmployee = {
          ...state.assignedEmployee,
          [action.meta.arg.id]: action.payload?.data?.assigned_employees || [],
        };
      })
      .addCase(getAssignedEmployeesByTag.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getUnAssignedEmployeesByTag.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getUnAssignedEmployeesByTag.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.unAssignedEmployees =
          action.payload?.data?.unassigned_employees || [];
      })
      .addCase(getUnAssignedEmployeesByTag.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getInsights.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getInsights.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.insights = action.payload?.data?.insights
          ? action.payload?.data?.insights
          : null;
      })
      .addCase(getInsights.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getActivities.pending, (state) => {
        state.status = "loading";
        state.activitiesLoading = true;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.activitiesLoading = false;

        if (action?.payload?.data?.page > 1) {
          const updatedEmployees = state.activities[
            action?.payload?.data?.integration
          ].employees.concat(action.payload?.data?.employees);

          state.activities[action?.payload?.data?.integration] = {
            ...action?.payload?.data,
            employees: updatedEmployees,
            end: action?.payload?.data?.employees?.length === 0,
          };
        } else {
          state.activities[action?.payload?.data?.integration] =
            action.payload?.data;
        }
      })
      .addCase(getActivities.rejected, (state, action) => {
        state.status = "failed";
        state.activitiesLoading = false;
        state.error = action.error.message;
      })

      .addCase(getTagIntegrations.pending, (state) => {
        state.status = "loading";
        state.activitiesLoading = true;
      })
      .addCase(getTagIntegrations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.activitiesLoading = false;
        state.integrationTabs = action.payload?.data?.integrations || [];
      })
      .addCase(getTagIntegrations.rejected, (state, action) => {
        state.status = "failed";
        state.activitiesLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteTag.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        const tagId = action.payload;
        state.employeeTags = state.employeeTags.filter(
          (tag) => tag.id !== tagId
        );
      })
      .addCase(deleteTag.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Employee Tags Endpoints
export const createNewTag = createAsyncThunk("createNewTag", async (data) => {
  const response = await api.post(`/tag/new`, data);
  return response;
});

export const getEmployeeTags = createAsyncThunk(
  "getEmployeeTags",
  async (data) => {
    const response = await apiV2.post(`/tags/employee_tags`, data);
    return response;
  }
);

export const assignEmployeeToTag = createAsyncThunk(
  "assignEmployeeToTag",
  async ({ tagId, userId }) => {
    const response = await api.post(
      `/tags/employee_tags/${tagId}/assign/${userId}`
    );
    return response;
  }
);

export const unAssignEmployeeFromTag = createAsyncThunk(
  "unAssignEmployeeFromTag",
  async ({ tagId, userId }) => {
    const response = await api.post(
      `/tags/employee_tags/${tagId}/unassign/${userId}`
    );
    return response;
  }
);

export const updateEmployeeTag = createAsyncThunk(
  "updateEmployeeTag",
  async ({ id, data }) => {
    const response = await api.put(`/tags/employee_tags/${id}`, data);
    return response;
  }
);

export const getAssignedEmployeesByTag = createAsyncThunk(
  "getAssignedEmployeesByTag",
  async ({ id }) => {
    const response = await api.get(`/tags/employee_tags/${id}/assigned`);
    return response;
  }
);

export const getUnAssignedEmployeesByTag = createAsyncThunk(
  "getUnAssignedEmployeesByTag",
  async ({ id }) => {
    const response = await api.get(`/tags/employee_tags/${id}/unassigned`);
    return response;
  }
);

export const getInsights = createAsyncThunk("getInsights", async (data) => {
  const response = await api.get(
    `/tags/employee_tag/insights/${data.period}/${data.name}`
  );
  return response;
});

export const getActivities = createAsyncThunk("getActivities", async (data) => {
  const response = await api.post(
    `/tags/activities/${data.period || 7}/${data.tag}`,
    data
  );
  return response;
});

export const getTagIntegrations = createAsyncThunk(
  "getTagIntegrations",
  async (name) => {
    const response = await api.get(`/tags/integrations/${name}`);
    return response;
  }
);

export const deleteTag = createAsyncThunk("deleteTag", async (id) => {
  await api.post(`/tag/delete_tag/${id}`);
  return id;
});

export const { clearCreatedNewTag, clearActivities } = tag.actions;

export default tag.reducer;
