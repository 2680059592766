import React from "react";

import cn from "classnames";
import { Link } from "react-router-dom";

import { ReactComponent as TagIcon } from "assets/icons/tag.svg";

import Avatar from "../../../Avatar";
import Icon from "../../../Icon";

import styles from "./Item.module.sass";

const extractName = (content) => {
  // we sometime get's name.lastname user name type, so we replace . with space
  const cleanString = content.replace(/\./g, " ");
  const parts = cleanString.split(" ").filter(Boolean);

  return parts?.length >= 2 ? parts?.slice(0, 2).join(" ") : content;
};

const Item = ({ className, item, onClose }) => {
  return (
    <Link
      className={cn(styles.item, { [styles.unread]: item.unread }, className)}
      to="/notifications"
      onClick={onClose}
    >
      <div className={styles.avatar}>
        {(item.category === "employee" || !item.category) && (
          <Avatar
            src={item.avatar_url}
            alt="Avatar"
          />
        )}
        {item.category === "integration" && (
          <div className={styles.integrationIcon}>
            <Icon
              name="store"
              size="24"
            />
          </div>
        )}
        {item.category === "team" && (
          <div className={styles.teamIcon}>
            <Icon
              name="activity"
              size="24"
            />
          </div>
        )}
        {item.category === "tag" && (
          <div className={styles.tagIcon}>
            <TagIcon />
          </div>
        )}
      </div>
      <div className={styles.details}>
        <div className={styles.line}>
          <div className={styles.subtitle}>
            {item.category === "employee"
              ? extractName(item.content)
              : item.title}
          </div>
          <div className={styles.login}></div>
          <div className={styles.time}>{item.time}</div>
        </div>
        <div className={styles.content}>
          {item.content?.slice(0, 65)} {item.content?.length > 65 && "..."}
        </div>
      </div>
    </Link>
  );
};

export default Item;
