import React, { useState } from "react";

import cn from "classnames";

import { ReactComponent as SortAscIcon } from "assets/icons/sort-asc.svg";
import { ReactComponent as SortDescIcon } from "assets/icons/sort-desc.svg";
import { Loader } from "components";

import Row from "./Row";
import styles from "./Table.module.sass";

const Table = ({
  items,
  legend,
  total,
  onLoadMore,
  loading,
  onSortHandler,
  sorting,
}) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div
              className={cn(styles.info, {
                [styles.selected]: sorting?.sort_by === "name",
              })}
              onClick={() =>
                onSortHandler({
                  sort_by: "name",
                })
              }
            >
              {sorting?.sort_by === "name" && sorting?.sort_order === "desc" ? (
                <SortDescIcon />
              ) : (
                <SortAscIcon />
              )}{" "}
              Employee
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.line}>
              <div
                className={cn(styles.info, {
                  [styles.selected]: sorting?.sort_by === "activity",
                })}
                onClick={() =>
                  onSortHandler({
                    sort_by: "activity",
                  })
                }
              >
                {/* <Icon name="activity" size="24" /> */}
                {sorting?.sort_by === "activity" &&
                sorting?.sort_order === "desc" ? (
                  <SortDescIcon />
                ) : (
                  <SortAscIcon />
                )}
                Activities
              </div>
              <div className={styles.indicators}>
                {legend.map((x, index) => (
                  <div
                    className={styles.legend}
                    key={index}
                  >
                    <div
                      className={styles.color}
                      style={{ backgroundColor: x.color }}
                    ></div>
                    {x.title}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {items?.map((x, index) => (
          <Row
            item={x}
            key={index}
            up={items.length - index <= 2}
            value={selectedFilters.includes(x.id)}
            onChange={() => handleChange(x.id)}
            loading={loading}
          />
        ))}
      </div>
      {/* {total > items.length && (
        <div className={styles.foot}>
          <button
            className={cn("button-stroke", styles.button)}
            onClick={onLoadMore}
          >
            {loading ? "Loading" : "Load more"}
            {loading && (
              <div className={styles.loader}>
                <Loader />
              </div>
            )}
          </button>
        </div>
      )} */}
    </div>
  );
};

export default Table;
