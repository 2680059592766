import React, { useState, useEffect } from "react";

import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { logOut } from "../../../slices/appSlice";
import Avatar from "../../Avatar";
import Icon from "../../Icon";

import styles from "./User.module.sass";

const User = ({ className }) => {
  const dispatch = useDispatch();
  let { company } = useSelector((state) => state.company);
  let [img, setImg] = useState("/images/content/avatar.jpg");

  useEffect(() => {
    if (company?.avatar_url && typeof company?.avatar_url == "string") {
      setImg(company?.avatar_url);
    } else if (company?.avatar_url && typeof company?.avatar_url == "object") {
      setImg(URL.createObjectURL(company?.avatar_url));
    } else {
      setImg("/images/content/avatar.jpg");
    }
  }, [company]);

  const killSession = () => {
    setVisible(false);
    dispatch(logOut());
  };
  const items = [
    {
      menu: [
        {
          title: "Edit profile",
          url: "/settings",
        },
      ],
    },
    {
      menu: [
        {
          title: "Account settings",
          url: "/settings",
        },
        {
          title: "Log out",
          action: () => killSession(),
        },
      ],
    },
  ];
  const [visible, setVisible] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className, { [styles.active]: visible })}>
        <button
          className={styles.head}
          onClick={() => setVisible(!visible)}
        >
          <Avatar
            src={img}
            alt="Avatar"
          />
        </button>
        <div className={styles.body}>
          {items?.map((item, index) => (
            <div
              className={styles.menu}
              key={index}
            >
              {item.menu.map((x, i) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, { [styles.color]: x.color })}
                    // activeClassName={styles.active}
                    to={x.url}
                    onClick={() => setVisible(false)}
                    key={i}
                  >
                    {x.icon && (
                      <Icon
                        name={x.icon}
                        size="24"
                      />
                    )}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={x.action}
                    key={i}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
