import React, { useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Balance from "../../../components/Balance";
import Card from "../../../components/Card";
import {
  getIntegrationOverview,
  getIntegrationEmployees,
} from "../../../slices/integrationSlice";
import { formatDate, periodConverter } from "../../../utils";

import Chart from "./Chart";
import styles from "./Overview.module.sass";

const Overview = ({ className }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { selectedFilterInterval } = useSelector((state) => state.app);
  const { integrationOverview } = useSelector((state) => state.integration);

  useEffect(() => {
    dispatch(
      getIntegrationOverview({
        period: periodConverter(selectedFilterInterval),
        integration: params.integration,
      })
    );
    dispatch(
      getIntegrationEmployees({
        period: periodConverter(selectedFilterInterval),
        integration: params.integration,
      })
    );
  }, [dispatch, params.integration, selectedFilterInterval]);

  return (
    <Card
      className={cn(styles.cardFull, className)}
      title="Availability"
      classTitle={cn("title-red", styles.cardTitle)}
      classCardHead={styles.cardHead}
      head={
        <>
          {integrationOverview?.status === "online" && (
            <div className={cn("status-green", styles.status)}>Online</div>
          )}
          {integrationOverview?.status === "offline" && (
            <div className={cn("status-red", styles.status)}>Offline</div>
          )}
        </>
      }
    >
      <div className={styles.overview}>
        <div className={styles.details}>
          <div className={cn("h4", styles.title)}>
            {integrationOverview?.activities} activities
          </div>
          <div className={styles.line}>
            <Balance
              className={styles.balance}
              value={integrationOverview?.value}
              background
              showValue
            />
            {" vs. "}
            {formatDate(integrationOverview?.date)}
          </div>
        </div>
        <Chart data={integrationOverview?.data} />
      </div>
    </Card>
  );
};

export default Overview;
