import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import Icon from "../../../../../components/Icon";
import Loader from "../../../../../components/Loader";
import { updateIntegration } from "../../../../../slices/integrationSlice";

import styles from "./Integration.module.sass";

const Integration = ({ account_id, integration_name }) => {
  const { status, loading, error } = useSelector((state) => state.integration);
  const [informMessage, setInformMessage] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [canProceed, setCanProceed] = useState(false);

  const [csvFile, setCsvFile] = useState(null);

  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      setCsvFile(event.target.files[0]);
    }
  };

  const preventSubmit = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (csvFile) {
      setCanProceed(true);
    }
  }, [csvFile]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setInformMessage(null);
    setFormSubmitted(true);
    const formData = new FormData();
    formData.append("csv_file", csvFile);
    formData.append("account_id", account_id);
    formData.append("integration_name", integration_name);

    dispatch(updateIntegration(formData));
  };

  useEffect(() => {
    if (formSubmitted) {
      if (error) {
        setInformMessage("Integration failed to update.");
      } else if (status === "succeeded") {
        setInformMessage("Integration updated.");
      }
    }
  }, [status, error, formSubmitted]);

  return (
    <div className={styles.integration}>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.content}>
              <form onSubmit={preventSubmit}>
                <div className={styles.wrap}>
                  <input
                    className={styles.input}
                    type="file"
                    onChange={handleFileChange}
                  />
                  <div className={styles.box}>
                    <Icon
                      name="upload"
                      size="24"
                    />
                    {"Slack"}
                  </div>
                  {csvFile && (
                    <div className={styles.errorNote}>{csvFile.name}</div>
                  )}
                </div>
                {informMessage && (
                  <div className={styles.errorNote}>{informMessage}</div>
                )}
                <button
                  type="submit"
                  className={cn(`button button-small`, styles.button, {
                    disabled: !canProceed,
                  })}
                  onClick={handleSubmit}
                >
                  {loading && (
                    <Loader
                      className={styles.loader}
                      white
                    />
                  )}
                  <span>Integrate</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
