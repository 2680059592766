import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useSelector } from "react-redux";

import styles from "./IntegrationCredentials.module.sass";
import ConfluenceIntegration from "./IntegrationInputs/ConfluenceIntegration";
import GithubIntegration from "./IntegrationInputs/GithubIntegration";
import GoogleIntegration from "./IntegrationInputs/GoogleIntegration";
import JiraIntegration from "./IntegrationInputs/JiraIntegration";
import OutlookIntegration from "./IntegrationInputs/OutlookIntegration";
import SalesforceIntegration from "./IntegrationInputs/SalesforceIntegration";
import SlackIntegration from "./IntegrationInputs/SlackIntegration";
import TeamsIntegration from "./IntegrationInputs/TeamsIntegration";
import ZoomIntegration from "./IntegrationInputs/ZoomIntegration";

const IntegrationCredentials = () => {
  const [accounts, setAccounts] = useState([]);
  const { integration } = useSelector((state) => state.integration);

  useEffect(() => {
    setAccounts(integration?.accounts || []);
  }, [integration]);

  const getIntegrationInput = (account) => {
    if (integration.name === "Slack") {
      return (
        <SlackIntegration
          account_id={account.id}
          integration_name="Slack"
        />
      );
    }
    if (integration.name === "Google") {
      return (
        <GoogleIntegration
          account_id={account.id}
          integration_name="Google"
          account_name={account.account_name}
        />
      );
    }
    if (integration.name === "Github") {
      return (
        <GithubIntegration
          account_id={account.id}
          integration_name="Github"
          repository_url={account.repository_url}
        />
      );
    }
    if (integration.name === "Jira") {
      return (
        <JiraIntegration
          account_id={account.id}
          integration_name="Jira"
          username={account.username}
          account_url={account.account_url}
        />
      );
    }
    if (integration.name === "Zoom") {
      return (
        <ZoomIntegration
          account_id={account.id}
          integration_name="Zoom"
          client_id={account.client_id}
        />
      );
    }
    if (integration.name === "Confluence") {
      return (
        <ConfluenceIntegration
          account_id={account.id}
          integration_name="Confluence"
          username={account.username}
          account_url={account.account_url}
        />
      );
    }
    if (integration.name === "Teams") {
      return (
        <TeamsIntegration
          account_id={account.id}
          integration_name="Teams"
          tenant_id={account.tenant_id}
          application_id={account.application_id}
          client_secret={account.client_secret}
        />
      );
    }
    if (integration.name === "Outlook") {
      return (
        <OutlookIntegration
          account_id={account.id}
          integration_name="Exchange Online"
          tenant_id={account.tenant_id}
          application_id={account.application_id}
          client_secret={account.client_secret}
        />
      );
    }
    if (integration.name === "Salesforce") {
      return (
        <SalesforceIntegration
          account_id={account.id}
          integration_name="Salesforce"
          tenant_id={account.tenant_id}
          application_id={account.application_id}
          client_secret={account.client_secret}
        />
      );
    }
  };

  return (
    <div className={cn(styles.item)}>
      <div className={cn("title-blue", styles.title)}>{"Credentials"}</div>
      {accounts?.map((account, index) => {
        return (
          <div
            className={styles.profile}
            key={index}
          >
            <div className={styles.profileHeader}>
              <div className={styles.details}>
                {getIntegrationInput(account)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default IntegrationCredentials;
