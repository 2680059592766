import React, { useState, useEffect, useCallback } from "react";

import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { readNotification, deleteNotification } from "slices/notificationSlice";

import Actions from "../../Actions";
import Icon from "../../Icon";

import Item from "./Item";
import styles from "./Notification.module.sass";

const Notification = ({ className }) => {
  const dispatch = useDispatch();

  const handleMarkAsRead = useCallback(
    (id = "all") => {
      dispatch(readNotification({ id: id }));
    },
    [dispatch]
  );

  const handleDeleteNotifications = useCallback(
    (id = "all") => {
      dispatch(deleteNotification({ id: id }));
    },
    [dispatch]
  );

  const { notifications } = useSelector((state) => state.notification);

  const [actions, setActions] = useState([]);
  const [visible, setVisible] = useState(false);
  const [notificationsFetched, setNotificationsFetched] = useState(false);

  useEffect(() => {
    if (notifications.length > 0) {
      setActions([
        {
          title: "Mark as read",
          icon: "check",
          action: () => handleMarkAsRead(),
        },
        {
          title: "Delete notifications",
          icon: "trash",
          action: () => handleDeleteNotifications(),
        },
      ]);
    }
  }, [handleDeleteNotifications, handleMarkAsRead, notifications]);

  useEffect(() => {
    if (!notificationsFetched) {
      setNotificationsFetched(true);
    }
  }, [dispatch, notificationsFetched]);
  const handleReadNotification = (id) => {
    dispatch(readNotification({ id: id }));
    setVisible(false);
  };
  const anyUnreadNotification = notifications.some((n) => n.unread);
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.notification, className, {
          [styles.active]: visible,
        })}
      >
        <button
          className={cn(styles.head, {
            [styles.active]: anyUnreadNotification,
          })}
          onClick={() => setVisible(!visible)}
        >
          <Icon
            name="notification"
            size="24"
          />
        </button>
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.title}>Notification</div>
            {notifications.length > 0 && (
              <Actions
                className={styles.actions}
                classActionsHead={styles.actionsHead}
                items={actions}
                small
              />
            )}
          </div>
          <div className={styles.list}>
            {notifications.length ? (
              notifications.slice(0, 5).map((x, index) => (
                <Item
                  className={cn(styles.item, className)}
                  item={x}
                  key={index}
                  onClose={() => handleReadNotification(x.id)}
                />
              ))
            ) : (
              <>
                <hr className={styles.line} />
                <div className={styles.empty}>
                  <Icon
                    className={styles.clock}
                    name="dashed-clock"
                    size="24"
                    viewBox="0 0 20 20"
                  />
                  <div className={styles.emptyTitle}>
                    No results so far. <br />
                    Click the button below to see all previous notifications
                  </div>
                </div>
              </>
            )}
          </div>
          <Link
            className={cn("button", styles.button)}
            to="/notifications"
            onClick={() => setVisible(false)}
          >
            See all notifications
          </Link>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Notification;
