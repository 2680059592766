import React from "react";

import cn from "classnames";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  integrationRemoveEmployee,
  getIntegrationEmployees,
} from "slices/integrationSlice";

import Avatar from "../../../../../components/Avatar";
import Icon from "../../../../../components/Icon";
import { normalizeDate } from "../../../../../utils";

import styles from "./Row.module.sass";

const Row = ({ item, integrationId }) => {
  const dispatch = useDispatch();
  const params = useParams();

  let temp_emails = "";
  if (Array.isArray(item?.emails_array)) {
    temp_emails =
      item?.emails_array.length > 0 ? item?.emails_array?.join(",") : "";
  } else {
    temp_emails =
      item?.emails_array?.replace(/[^a-zA-Z0-9\s,@.(),:;<>[\]]+/g, "") || "";
  }

  const onDeactivateEmployee = async () => {
    const action = await dispatch(
      integrationRemoveEmployee({ integrationId, employeeId: item.id })
    );

    if (action.error) {
      toast.error("Something went wrong deactivating employee");
      return;
    }

    toast.success("Employee was deactivated successfully");
    dispatch(
      getIntegrationEmployees({ integration: params.integration, period: 7 })
    );
  };

  return (
    <>
      <div className={cn(styles.row)}>
        <div className={styles.col}>
          <Link
            to={`/employee/${item.id}/details`}
            className={styles.item}
          >
            <div className={styles.avatar}>
              <Avatar
                src={item.avatar_url}
                alt="Avatar"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>{item.name}</div>
              <div className={styles.email}>
                {temp_emails?.split(",")?.map((email, index) => (
                  <div key={index}>{email}</div>
                ))}
              </div>
            </div>
          </Link>
        </div>
        <div className={styles.col}>
          <div className={styles.number}>{item.activities_pulled}</div>
        </div>
        <div className={styles.col}>{normalizeDate(item.last_activity)}</div>
        <div className={styles.col}>{normalizeDate(item.integrated_since)}</div>
        <div className={styles.col}>
          <button
            className={cn("button-stroke-red button-small", styles.button)}
            onClick={onDeactivateEmployee}
          >
            <Icon
              name="trash"
              size="20"
            />
            Deactivate Employee
          </button>
        </div>
      </div>
    </>
  );
};

export default Row;
