import { useEffect } from "react";

import cn from "classnames";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Icon } from "components";

import styles from "./Starter.module.sass";

const Starter = ({ onClick }) => {
  const navigate = useNavigate();
  const { companyGoals } = useSelector((state) => state.home);
  const { myIntegrationsBasics } = useSelector((state) => state.integration);

  useEffect(() => {
    if (companyGoals?.length || myIntegrationsBasics?.length) navigate("/");
  }, [companyGoals]);

  return (
    <div className={styles.container}>
      <img src="/images/onboarding-start.png" alt="Finish" />

      <div className={styles.shadow} />
      <button
        className={cn("button button-small", styles.button)}
        onClick={onClick}
      >
        Start by selecting services
        <Icon name="arrow-right" size="20" />
      </button>
    </div>
  );
};

export const StarterHead = () => {
  return (
    <div>
      <div className={styles.head}>
        Start feeling in control of your operation now
      </div>
      <div className={styles.description}>
        Users who connect their accounts within 5 minutes report a greater sense
        of <br /> operational visibility
      </div>
    </div>
  );
};

export default Starter;
