import { useEffect } from "react";

import cn from "classnames";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Card, Icon, UserDetails } from "components";
import {
  getTopRatedEmployees,
  getMostUsedServices,
} from "slices/homeV2Slice.js";
import { countActivities, periodConverter } from "utils";

import EmptyState from "./EmptyState.js";
import styles from "./Insights.module.sass";

const integrationIcons = {
  Slack: { img: "/images/integration/slack.png" },
  Github: { img: "/images/integration/github.png", full: true },
  Google: { img: "/images/integration/google.png" },
  Confluence: { img: "/images/integration/confluence.png" },
  Jira: { img: "/images/integration/jira.png" },
  Zoom: { img: "/images/integration/zoom.png", full: true },
  Teams: { img: "/images/integration/teams.png" },
  Outlook: { img: "/images/integration/exchangeonline.png" },
  Salesforce: { img: "/images/integration/salesforce.png" },
};

const Insights = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { topRatedEmployees, mostUsedServices } = useSelector(
    (state) => state.homeV2
  );
  const { selectedFilterInterval } = useSelector((state) => state.app);
  const { integrationStartDate, integrationEndDate } = useSelector(
    (state) => state.employee
  );

  useEffect(() => {
    if (integrationStartDate && integrationEndDate) {
      dispatch(
        getTopRatedEmployees({
          startDate: format(integrationStartDate, "yyyy-MM-dd"),
          endDate: format(integrationEndDate, "yyyy-MM-dd"),
        })
      );
      dispatch(
        getMostUsedServices({
          startDate: format(integrationStartDate, "yyyy-MM-dd"),
          endDate: format(integrationEndDate, "yyyy-MM-dd"),
        })
      );
    } else {
      dispatch(
        getTopRatedEmployees({
          period: periodConverter(selectedFilterInterval),
        })
      );
      dispatch(
        getMostUsedServices({
          period: periodConverter(selectedFilterInterval),
        })
      );
    }
  }, [
    dispatch,
    integrationEndDate,
    integrationStartDate,
    selectedFilterInterval,
  ]);
  return (
    <div className={styles.insights}>
      <Card
        className={`${styles.card} onboarding-second-step`}
        title="Top rated employees"
        classTitle={styles.cardTitle}
        head={
          <Link
            to="/employees"
            className={cn(
              `button button-small button-transparent ${styles.button}`
            )}
          >
            See Employees
            <Icon name="arrow-right" />
          </Link>
        }
        classCardHead={styles.cardHead}
      >
        <div className={styles.users}>
          {!topRatedEmployees.length ? (
            <EmptyState
              message="Employees will be automatically added once you Integrate a service."
              buttonText="Add new Employee"
            />
          ) : (
            topRatedEmployees.slice(0, 3)?.map((employee, index) => (
              <>
                <UserDetails
                  key={`${employee.id}-${index}`}
                  user={employee}
                  description={`${countActivities(employee)} Activities`}
                  variant="sm"
                />
                {index !== topRatedEmployees.length - 1 && (
                  <div className={styles.divider}></div>
                )}
              </>
            ))
          )}
        </div>
      </Card>

      <Card
        className={`${styles.card} onboarding-first-step`}
        title="Most used services"
        classTitle={styles.cardTitle}
        head={
          <Link
            to="/integrations"
            className={cn(
              `button button-small button-transparent ${styles.button}`
            )}
          >
            See Integrations
            <Icon name="arrow-right" />
          </Link>
        }
        classCardHead={styles.cardHead}
      >
        <div className={styles.integrations}>
          {!mostUsedServices.length ? (
            <EmptyState
              message="Add Integrations in order to get Insights."
              buttonText="Add new Integration"
              action={() => navigate("/integrations")}
            />
          ) : (
            mostUsedServices?.map((integration, index) => (
              <>
                <div
                  className={styles.integration}
                  key={index}
                >
                  <div className={styles.integrationIcon}>
                    <img
                      className={cn({
                        [styles.fullIcon]:
                          integrationIcons[integration.name]?.full,
                      })}
                      src={integrationIcons[integration.name]?.img}
                      alt=""
                    />
                  </div>
                  <div className={styles.details}>
                    <Link
                      to={`/`}
                      className={cn(styles.name)}
                    >
                      {integration.name}
                    </Link>
                    <div className={cn(styles.description)}>
                      {integration.total_activities
                        ? `${integration.total_activities} Activities`
                        : "-"}
                    </div>
                  </div>
                </div>
                {index !== mostUsedServices.length - 1 && (
                  <div className={styles.divider}></div>
                )}
              </>
            ))
          )}
        </div>
      </Card>
    </div>
  );
};

export default Insights;
