import { useState } from "react";

import cn from "classnames";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { Form, UserDetails } from "components";
import { unAssignEmployeeToSection, getSectionTags } from "slices/sectionSlice";

import styles from "./SectionCard.module.sass";

const SectionTag = ({ section }) => {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  const handleUnAssignEmployee = async (employee) => {
    await dispatch(
      unAssignEmployeeToSection({
        sectionTagId: section.id,
        employeeId: employee.id,
      })
    );
    await dispatch(getSectionTags());
  };

  const usersBySearch = section.users?.filter((user) =>
    user.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.cardTitle}>
          <Link
            to={`/team/${section.name}`}
            className={styles.sectionName}
          >
            {section.name} <span>({section.count})</span>
          </Link>
          {!section?.is_default && (
            <Link
              to={`/team/${section?.name || 'details'}/${section?.id}`}
              className={cn(styles.button)}
            >
              Go to Settings
            </Link>
          )}
        </div>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.cardRow}>
          <Form
            className={styles.search}
            value={search}
            setValue={setSearch}
            onSubmit={() => {}}
            placeholder="Search for employees"
            type="text"
            name="search"
            icon="search"
          />
        </div>
        {usersBySearch?.map((user, index) => (
          <div
            key={`${index}-${user.id}`}
            className={cn(styles.cardRow, styles.user)}
          >
            <UserDetails
              user={user}
              variant="lg"
            />
            {!section?.is_default && (
              <TrashIcon onClick={() => handleUnAssignEmployee(user)} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionTag;
