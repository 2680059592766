import React, { useEffect, useState } from "react";

import cn from "classnames";

import Card from "../../../components/Card";

import Activity from "./Activity";
import styles from "./Discrepancy.module.sass";
import Insights from "./Insights";
import TagsActivity from "./TagsActivity";

const Departments = ({ departmentView, tagView }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navigation = ["Activity"];

  const [activeTab, setActiveTab] = useState(navigation ? navigation[0] : "");
  const components = [
    tagView ? <TagsActivity key={1} /> : <Activity key={1} />,
  ];

  (departmentView || tagView) && components.unshift(<Insights key={2} />);
  (departmentView || tagView) && navigation.unshift("Insights");

  useEffect(() => {
    if (!navigation?.includes(activeTab)) {
      setActiveTab(navigation ? navigation[0] : "");
    }
  }, [activeTab, navigation]);

  // We are hiding tabs and title for now
  return (
    <Card
      className={styles.card}
      // title={departmentView || tagView ? null : "Attention Required"}
      classTitle={cn("title-blue", styles.title)}
      classCardHead={styles.head}
      // showWithoutTitle
      // head={
      //   <>
      //     <div className={cn(styles.nav, "tablet-hide")}>
      //       {navigation?.map((x, index) => (
      //         <button
      //           key={`${x}-${index}`}
      //           className={cn(styles.link, {
      //             [styles.active]: x === activeTab,
      //           })}
      //           onClick={() => setActiveTab(x)}
      //         >
      //           {x}
      //         </button>
      //       ))}
      //     </div>
      //     <div className={cn(styles.dropdown, "tablet-show")}>
      //       <Dropdown
      //         classDropdownHead={styles.dropdownHead}
      //         value={activeTab}
      //         setValue={setActiveTab}
      //         options={navigation}
      //         small
      //       />
      //     </div>
      //   </>
      // }
    >
      {/* {(!departmentView && !tagView) && (
        <div className={styles.titleInfo}>Most and least active personnel</div>
      )} */}
      <div className={styles.departments}>
        <div className={styles.wrapper}>
          {components.filter((comp, index) => activeTab === navigation[index])}
        </div>
      </div>
    </Card>
  );
};

export default Departments;
