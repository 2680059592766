import React from "react";

import { Link } from "react-router-dom";

import { Icon, Tooltip, Balance } from "components";

import Chart from "../Chart";

import styles from "./DepartmentCard.module.sass";

const DepartmentCard = ({ department }) => (
  <Link
    className={styles.item}
    key={department.id}
    style={{ backgroundColor: department.background }}
    to={`/departments/${department.title}`}
  >
    <div className={styles.headLine}>
      <span className={styles.title}>{department.title}</span>
      <span className={styles.arrow}>
        <Icon
          name="arrow-right"
          size="24"
        />
      </span>
    </div>
    <div className={styles.line}>
      <div className={styles.details}>
        <div className={styles.category}>
          Activities
          <Tooltip
            className={styles.tooltip}
            title="Small description"
            icon="info"
            place="right"
          />
        </div>
        <div className={styles.counter}>{department.counter}</div>
      </div>
      <Chart
        className={styles.chart}
        item={department}
      />
    </div>
    <div className={styles.footer}>
      <div className={styles.footerLeft}>
        <span>
          <Icon
            name="profile-circle"
            size="14"
          />
          {department.employees} employees
        </span>
      </div>
      <div className={styles.indicator}>
        <Balance
          className={styles.balance}
          value={department.weeklyPercentage}
          showValue
        />
        <span>this week</span>
      </div>
    </div>
  </Link>
);

export default DepartmentCard;
