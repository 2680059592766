import React from "react";

import cn from "classnames";
import PropTypes from "prop-types";

import { localHTTP } from "app/api";

const Avatar = ({ src, alt, className, srcSet }) => {
  const handleAvatarError = (e) => {
    document.getElementById(e.target.id).src = "/generic.png";
  };
  let source = src;
  let alternative = alt;
  if (!src) {
    source = "/generic.png";
  }
  if (!alt) {
    alternative = "noInfo";
  }
  return (
    <img
      src={localHTTP ? source : source.replace("http://", "https://")}
      alt={alternative}
      className={cn(className)}
      id={(Math.random() + 1).toString(36).substring(7)}
      onError={handleAvatarError}
    />
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

Avatar.defaultProps = {
  src: "/reader.png",
  alt: "noInfo",
};

export default Avatar;
