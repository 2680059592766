import React, { useState, useEffect, useCallback, useRef } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Icon, Card, Dropdown, TextInput } from "components";
import AddTagToEmployeeModal from "screens/Tags/EmployeeTag/AddTagToEmployeeModal";
import {
  getEmployeeTags,
  getActivities,
  getTagIntegrations,
  clearActivities,
} from "slices/tagSlice";
import { periodConverter, onlyUniqueByObject, isBottomReached } from "utils";

import NoResult from "../NoResult";

import styles from "./Activity.module.sass";
import Table from "./Table";

const Departments = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [search, setSearch] = useState("");
  const { integrationTabs, activities, activitiesLoading, employeeTags } =
    useSelector((state) => state.tag);
  const [sorting, setSorting] = useState(null);
  const [sortLoading, setSortLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [showModal, setShowModal] = useState(false);

  const currentTag = employeeTags.find((tag) => tag.name === params.tag);

  const loading = useRef(false);

  const { selectedFilterInterval } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(getEmployeeTags());
  }, [dispatch]);

  const fetchTagIntegrations = useCallback(async () => {
    const res = await dispatch(getTagIntegrations(params.tag || ""));
    dispatch(clearActivities());
    setActiveTab(res?.payload?.data?.integrations[0]?.name);
  }, [dispatch, params.tag]);

  useEffect(() => {
    fetchTagIntegrations();
  }, [dispatch, fetchTagIntegrations, params.tag]);

  useEffect(() => {
    if (integrationTabs.length)
      dispatch(
        getActivities({
          tag: params.tag,
          per: 100000,
          page: 1,
          period: periodConverter(selectedFilterInterval),
          integration: integrationTabs[0]?.name,
        })
      );
  }, [dispatch, integrationTabs, params.tag, selectedFilterInterval]);

  const handleTabChange = (tab) => {
    setActiveTab(tab.name);
    dispatch(
      getActivities({
        tag: params.tag,
        page: 1,
        per: 100000,
        period: periodConverter(selectedFilterInterval),
        integration: tab.name,
      })
    );
  };

  const handleLoadMore = useCallback(async () => {
    if (activities[activeTab]?.end) {
      loading.current = false;
      return;
    }
    await dispatch(
      getActivities({
        tag: params.tag,
        per: 100000,
        period: periodConverter(selectedFilterInterval),
        integration: activeTab,
        page: activities[activeTab]?.page + 1,
        sort_by: sorting?.sort_by || "activity",
        sort_order: sorting?.sort_order || "desc",
      })
    );
    loading.current = false;
  }, [
    dispatch,
    params.tag,
    selectedFilterInterval,
    activeTab,
    activities,
    sorting?.sort_by,
    sorting?.sort_order,
  ]);

  const handleScroll = useCallback(() => {
    if (!isBottomReached() || activitiesLoading || loading.current) return;

    loading.current = true;
    handleLoadMore();
  }, [activitiesLoading, handleLoadMore, loading]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const onSortHandler = ({ sort_by }) => {
    if (sorting?.sort_by === sort_by) {
      setSorting((prev) => ({
        ...prev,
        sort_order: prev.sort_order === "asc" ? "desc" : "asc",
      }));
    } else {
      setSorting({ sort_by, sort_order: "asc" });
    }
  };

  useEffect(() => {
    if (sorting) {
      setSortLoading(true);
      (() => {
        dispatch(
          getActivities({
            tag: params.tag,
            per: 100000,
            period: periodConverter(selectedFilterInterval),
            integration: activeTab,
            sort_by: sorting.sort_by,
            sort_order: sorting.sort_order,
          })
        );
        setSortLoading(false);
      })();
    }
  }, [dispatch, params.tag, selectedFilterInterval, activeTab, sorting]);

  const handleAddNewEmployee = () => {
    setShowModal(true);
  };

  const searchedEmployees = activities[activeTab]?.employees.filter((x) =>
    x.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Card
      className={styles.card}
      title="Integrations"
      classTitle={cn("title-transparent", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          {activities[activeTab]?.employees.length > 0 && (
            <>
              <TextInput
                className={styles.search}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search by employee"
                type="text"
                name="search"
                icon="search"
              />
              <button
                className={cn("button button-small", styles.addEmployeeButton)}
                onClick={handleAddNewEmployee}
              >
                <Icon
                  name="add"
                  size="24"
                />
                <span>Add new Employee</span>
              </button>
            </>
          )}
          <div className={cn(styles.nav, "tablet-hide")}>
            {integrationTabs?.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x.name === activeTab,
                })}
                onClick={() => handleTabChange(x)}
                key={index}
              >
                {x.name}
              </button>
            ))}
          </div>
          <div className={cn(styles.dropdown, "tablet-show")}>
            <Dropdown
              classDropdownHead={styles.dropdownHead}
              value={activeTab}
              setValue={setActiveTab}
              options={integrationTabs?.map((x) => x.name)}
              small
            />
          </div>
          {/* {<Filters className={styles.filters} title="Smart Filter">
            <Settings filters={navigation} />
          </Filters>} */}
        </>
      }
    >
      <div className={styles.departments}>
        <div className={styles.wrapper}>
          {!activities[activeTab]?.employees.length > 0 && (
            <NoResult
              loading={activitiesLoading}
              onCreate={handleAddNewEmployee}
            />
          )}
          {activities[activeTab]?.employees.length > 0 && (
            <Table
              title={activeTab}
              legend={onlyUniqueByObject(
                activities[activeTab]?.employees
                  ?.map((employee) => employee?.activities)
                  ?.flat(),
                "title"
              )}
              items={searchedEmployees}
              onLoadMore={() => handleLoadMore()}
              total={activities[activeTab]?.total}
              loading={sortLoading}
              onSortHandler={onSortHandler}
              sorting={sorting}
            />
          )}
        </div>
      </div>

      {showModal && (
        <AddTagToEmployeeModal
          item={currentTag}
          visible={showModal}
          onClose={() => {
            fetchTagIntegrations();

            dispatch(
              getActivities({
                tag: params.tag,
                per: 100000,
                period: periodConverter(selectedFilterInterval),
                integration: activeTab,
              })
            );

            setShowModal(false);
          }}
        />
      )}
    </Card>
  );
};

export default Departments;
