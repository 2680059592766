export const timezones = [
  {
    countryCode: "CI",
    countryName: "Ivory Coast",
    zoneName: "Africa/Abidjan",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "GH",
    countryName: "Ghana",
    zoneName: "Africa/Accra",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "ET",
    countryName: "Ethiopia",
    zoneName: "Africa/Addis_Ababa",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "DZ",
    countryName: "Algeria",
    zoneName: "Africa/Algiers",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "ER",
    countryName: "Eritrea",
    zoneName: "Africa/Asmara",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "ML",
    countryName: "Mali",
    zoneName: "Africa/Bamako",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "CF",
    countryName: "Central African Republic",
    zoneName: "Africa/Bangui",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "GM",
    countryName: "Gambia",
    zoneName: "Africa/Banjul",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "GW",
    countryName: "Guinea-Bissau",
    zoneName: "Africa/Bissau",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "MW",
    countryName: "Malawi",
    zoneName: "Africa/Blantyre",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "CG",
    countryName: "Republic of the Congo",
    zoneName: "Africa/Brazzaville",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "BI",
    countryName: "Burundi",
    zoneName: "Africa/Bujumbura",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "EG",
    countryName: "Egypt",
    zoneName: "Africa/Cairo",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "MA",
    countryName: "Morocco",
    zoneName: "Africa/Casablanca",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "ES",
    countryName: "Spain",
    zoneName: "Africa/Ceuta",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "GN",
    countryName: "Guinea",
    zoneName: "Africa/Conakry",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "SN",
    countryName: "Senegal",
    zoneName: "Africa/Dakar",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "TZ",
    countryName: "Tanzania",
    zoneName: "Africa/Dar_es_Salaam",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "DJ",
    countryName: "Djibouti",
    zoneName: "Africa/Djibouti",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "CM",
    countryName: "Cameroon",
    zoneName: "Africa/Douala",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "MA",
    countryName: "Morocco",
    zoneName: "Africa/El_Aaiun",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "SL",
    countryName: "Sierra Leone",
    zoneName: "Africa/Freetown",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "BW",
    countryName: "Botswana",
    zoneName: "Africa/Gaborone",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "ZW",
    countryName: "Zimbabwe",
    zoneName: "Africa/Harare",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "ZA",
    countryName: "South Africa",
    zoneName: "Africa/Johannesburg",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "SS",
    countryName: "South Sudan",
    zoneName: "Africa/Juba",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "UG",
    countryName: "Uganda",
    zoneName: "Africa/Kampala",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "SD",
    countryName: "Sudan",
    zoneName: "Africa/Khartoum",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "RW",
    countryName: "Rwanda",
    zoneName: "Africa/Kigali",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    zoneName: "Africa/Kinshasa",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "NG",
    countryName: "Nigeria",
    zoneName: "Africa/Lagos",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "GA",
    countryName: "Gabon",
    zoneName: "Africa/Libreville",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "TG",
    countryName: "Togo",
    zoneName: "Africa/Lome",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "AO",
    countryName: "Angola",
    zoneName: "Africa/Luanda",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    zoneName: "Africa/Lubumbashi",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "ZM",
    countryName: "Zambia",
    zoneName: "Africa/Lusaka",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "GQ",
    countryName: "Equatorial Guinea",
    zoneName: "Africa/Malabo",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "MZ",
    countryName: "Mozambique",
    zoneName: "Africa/Maputo",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "LS",
    countryName: "Lesotho",
    zoneName: "Africa/Maseru",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "SZ",
    countryName: "Eswatini",
    zoneName: "Africa/Mbabane",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "SO",
    countryName: "Somalia",
    zoneName: "Africa/Mogadishu",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "LR",
    countryName: "Liberia",
    zoneName: "Africa/Monrovia",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "KE",
    countryName: "Kenya",
    zoneName: "Africa/Nairobi",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "TD",
    countryName: "Chad",
    zoneName: "Africa/Ndjamena",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "NE",
    countryName: "Niger",
    zoneName: "Africa/Niamey",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "MR",
    countryName: "Mauritania",
    zoneName: "Africa/Nouakchott",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "BF",
    countryName: "Burkina Faso",
    zoneName: "Africa/Ouagadougou",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "BJ",
    countryName: "Benin",
    zoneName: "Africa/Porto-Novo",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "ST",
    countryName: "Sao Tome and Principe",
    zoneName: "Africa/Sao_Tome",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "LY",
    countryName: "Libya",
    zoneName: "Africa/Tripoli",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "TN",
    countryName: "Tunisia",
    zoneName: "Africa/Tunis",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "NA",
    countryName: "Namibia",
    zoneName: "Africa/Windhoek",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Adak",
    gmtOffset: -32400,
    timestamp: 1725438827,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Anchorage",
    gmtOffset: -28800,
    timestamp: 1725442427,
  },
  {
    countryCode: "AI",
    countryName: "Anguilla",
    zoneName: "America/Anguilla",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "AG",
    countryName: "Antigua and Barbuda",
    zoneName: "America/Antigua",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Araguaina",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/Buenos_Aires",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/Catamarca",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/Cordoba",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/Jujuy",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/La_Rioja",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/Mendoza",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/Rio_Gallegos",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/Salta",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/San_Juan",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/San_Luis",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/Tucuman",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    zoneName: "America/Argentina/Ushuaia",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AW",
    countryName: "Aruba",
    zoneName: "America/Aruba",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "PY",
    countryName: "Paraguay",
    zoneName: "America/Asuncion",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Atikokan",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Bahia",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Bahia_Banderas",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "BB",
    countryName: "Barbados",
    zoneName: "America/Barbados",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Belem",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "BZ",
    countryName: "Belize",
    zoneName: "America/Belize",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Blanc-Sablon",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Boa_Vista",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "CO",
    countryName: "Colombia",
    zoneName: "America/Bogota",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Boise",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Cambridge_Bay",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Campo_Grande",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Cancun",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "VE",
    countryName: "Venezuela",
    zoneName: "America/Caracas",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "GF",
    countryName: "French Guiana",
    zoneName: "America/Cayenne",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "KY",
    countryName: "Cayman Islands",
    zoneName: "America/Cayman",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Chicago",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Chihuahua",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Ciudad_Juarez",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "CR",
    countryName: "Costa Rica",
    zoneName: "America/Costa_Rica",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Creston",
    gmtOffset: -25200,
    timestamp: 1725446027,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Cuiaba",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "CW",
    countryName: "Curacao",
    zoneName: "America/Curacao",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "GL",
    countryName: "Greenland",
    zoneName: "America/Danmarkshavn",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Dawson",
    gmtOffset: -25200,
    timestamp: 1725446027,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Dawson_Creek",
    gmtOffset: -25200,
    timestamp: 1725446027,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Denver",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Detroit",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "DM",
    countryName: "Dominica",
    zoneName: "America/Dominica",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Edmonton",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Eirunepe",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "SV",
    countryName: "El Salvador",
    zoneName: "America/El_Salvador",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Fort_Nelson",
    gmtOffset: -25200,
    timestamp: 1725446027,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Fortaleza",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Glace_Bay",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Goose_Bay",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "TC",
    countryName: "Turks and Caicos Islands",
    zoneName: "America/Grand_Turk",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "GD",
    countryName: "Grenada",
    zoneName: "America/Grenada",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "GP",
    countryName: "Guadeloupe",
    zoneName: "America/Guadeloupe",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "GT",
    countryName: "Guatemala",
    zoneName: "America/Guatemala",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "EC",
    countryName: "Ecuador",
    zoneName: "America/Guayaquil",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "GY",
    countryName: "Guyana",
    zoneName: "America/Guyana",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Halifax",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "CU",
    countryName: "Cuba",
    zoneName: "America/Havana",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Hermosillo",
    gmtOffset: -25200,
    timestamp: 1725446027,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Indiana/Indianapolis",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Indiana/Knox",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Indiana/Marengo",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Indiana/Petersburg",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Indiana/Tell_City",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Indiana/Vevay",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Indiana/Vincennes",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Indiana/Winamac",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Inuvik",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Iqaluit",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "JM",
    countryName: "Jamaica",
    zoneName: "America/Jamaica",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Juneau",
    gmtOffset: -28800,
    timestamp: 1725442427,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Kentucky/Louisville",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Kentucky/Monticello",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "BQ",
    countryName: "Bonaire, Saint Eustatius and Saba",
    zoneName: "America/Kralendijk",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "BO",
    countryName: "Bolivia",
    zoneName: "America/La_Paz",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "PE",
    countryName: "Peru",
    zoneName: "America/Lima",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Los_Angeles",
    gmtOffset: -25200,
    timestamp: 1725446027,
  },
  {
    countryCode: "SX",
    countryName: "Sint Maarten",
    zoneName: "America/Lower_Princes",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Maceio",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "NI",
    countryName: "Nicaragua",
    zoneName: "America/Managua",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Manaus",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "MF",
    countryName: "Saint Martin",
    zoneName: "America/Marigot",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "MQ",
    countryName: "Martinique",
    zoneName: "America/Martinique",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Matamoros",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Mazatlan",
    gmtOffset: -25200,
    timestamp: 1725446027,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Menominee",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Merida",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Metlakatla",
    gmtOffset: -28800,
    timestamp: 1725442427,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Mexico_City",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "PM",
    countryName: "Saint Pierre and Miquelon",
    zoneName: "America/Miquelon",
    gmtOffset: -7200,
    timestamp: 1725464027,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Moncton",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Monterrey",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "UY",
    countryName: "Uruguay",
    zoneName: "America/Montevideo",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "MS",
    countryName: "Montserrat",
    zoneName: "America/Montserrat",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "BS",
    countryName: "Bahamas",
    zoneName: "America/Nassau",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/New_York",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Nome",
    gmtOffset: -28800,
    timestamp: 1725442427,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Noronha",
    gmtOffset: -7200,
    timestamp: 1725464027,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/North_Dakota/Beulah",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/North_Dakota/Center",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/North_Dakota/New_Salem",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "GL",
    countryName: "Greenland",
    zoneName: "America/Nuuk",
    gmtOffset: -3600,
    timestamp: 1725467627,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Ojinaga",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "PA",
    countryName: "Panama",
    zoneName: "America/Panama",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "SR",
    countryName: "Suriname",
    zoneName: "America/Paramaribo",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Phoenix",
    gmtOffset: -25200,
    timestamp: 1725446027,
  },
  {
    countryCode: "HT",
    countryName: "Haiti",
    zoneName: "America/Port-au-Prince",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "TT",
    countryName: "Trinidad and Tobago",
    zoneName: "America/Port_of_Spain",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Porto_Velho",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "PR",
    countryName: "Puerto Rico",
    zoneName: "America/Puerto_Rico",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "CL",
    countryName: "Chile",
    zoneName: "America/Punta_Arenas",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Rankin_Inlet",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Recife",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Regina",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Resolute",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Rio_Branco",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Santarem",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "CL",
    countryName: "Chile",
    zoneName: "America/Santiago",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "DO",
    countryName: "Dominican Republic",
    zoneName: "America/Santo_Domingo",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    zoneName: "America/Sao_Paulo",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "GL",
    countryName: "Greenland",
    zoneName: "America/Scoresbysund",
    gmtOffset: -3600,
    timestamp: 1725467627,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Sitka",
    gmtOffset: -28800,
    timestamp: 1725442427,
  },
  {
    countryCode: "BL",
    countryName: "Saint Barthelemy",
    zoneName: "America/St_Barthelemy",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/St_Johns",
    gmtOffset: -9000,
    timestamp: 1725462227,
  },
  {
    countryCode: "KN",
    countryName: "Saint Kitts and Nevis",
    zoneName: "America/St_Kitts",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "LC",
    countryName: "Saint Lucia",
    zoneName: "America/St_Lucia",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "VI",
    countryName: "U.S. Virgin Islands",
    zoneName: "America/St_Thomas",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "VC",
    countryName: "Saint Vincent and the Grenadines",
    zoneName: "America/St_Vincent",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Swift_Current",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "HN",
    countryName: "Honduras",
    zoneName: "America/Tegucigalpa",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "GL",
    countryName: "Greenland",
    zoneName: "America/Thule",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    zoneName: "America/Tijuana",
    gmtOffset: -25200,
    timestamp: 1725446027,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Toronto",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "VG",
    countryName: "British Virgin Islands",
    zoneName: "America/Tortola",
    gmtOffset: -14400,
    timestamp: 1725456827,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Vancouver",
    gmtOffset: -25200,
    timestamp: 1725446027,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Whitehorse",
    gmtOffset: -25200,
    timestamp: 1725446027,
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    zoneName: "America/Winnipeg",
    gmtOffset: -18000,
    timestamp: 1725453227,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "America/Yakutat",
    gmtOffset: -28800,
    timestamp: 1725442427,
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    zoneName: "Antarctica/Casey",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    zoneName: "Antarctica/Davis",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    zoneName: "Antarctica/DumontDUrville",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Antarctica/Macquarie",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    zoneName: "Antarctica/Mawson",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    zoneName: "Antarctica/McMurdo",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    zoneName: "Antarctica/Palmer",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    zoneName: "Antarctica/Rothera",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    zoneName: "Antarctica/Syowa",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    zoneName: "Antarctica/Troll",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    zoneName: "Antarctica/Vostok",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "SJ",
    countryName: "Svalbard and Jan Mayen",
    zoneName: "Arctic/Longyearbyen",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "YE",
    countryName: "Yemen",
    zoneName: "Asia/Aden",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "KZ",
    countryName: "Kazakhstan",
    zoneName: "Asia/Almaty",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "JO",
    countryName: "Jordan",
    zoneName: "Asia/Amman",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Anadyr",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
  {
    countryCode: "KZ",
    countryName: "Kazakhstan",
    zoneName: "Asia/Aqtau",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "KZ",
    countryName: "Kazakhstan",
    zoneName: "Asia/Aqtobe",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "TM",
    countryName: "Turkmenistan",
    zoneName: "Asia/Ashgabat",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "KZ",
    countryName: "Kazakhstan",
    zoneName: "Asia/Atyrau",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "IQ",
    countryName: "Iraq",
    zoneName: "Asia/Baghdad",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "BH",
    countryName: "Bahrain",
    zoneName: "Asia/Bahrain",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "AZ",
    countryName: "Azerbaijan",
    zoneName: "Asia/Baku",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "TH",
    countryName: "Thailand",
    zoneName: "Asia/Bangkok",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Barnaul",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "LB",
    countryName: "Lebanon",
    zoneName: "Asia/Beirut",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "KG",
    countryName: "Kyrgyzstan",
    zoneName: "Asia/Bishkek",
    gmtOffset: 21600,
    timestamp: 1725492827,
  },
  {
    countryCode: "BN",
    countryName: "Brunei",
    zoneName: "Asia/Brunei",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Chita",
    gmtOffset: 32400,
    timestamp: 1725503627,
  },
  {
    countryCode: "MN",
    countryName: "Mongolia",
    zoneName: "Asia/Choibalsan",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "LK",
    countryName: "Sri Lanka",
    zoneName: "Asia/Colombo",
    gmtOffset: 19800,
    timestamp: 1725491027,
  },
  {
    countryCode: "SY",
    countryName: "Syria",
    zoneName: "Asia/Damascus",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "BD",
    countryName: "Bangladesh",
    zoneName: "Asia/Dhaka",
    gmtOffset: 21600,
    timestamp: 1725492827,
  },
  {
    countryCode: "TL",
    countryName: "Timor Leste",
    zoneName: "Asia/Dili",
    gmtOffset: 32400,
    timestamp: 1725503627,
  },
  {
    countryCode: "AE",
    countryName: "United Arab Emirates",
    zoneName: "Asia/Dubai",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "TJ",
    countryName: "Tajikistan",
    zoneName: "Asia/Dushanbe",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "CY",
    countryName: "Cyprus",
    zoneName: "Asia/Famagusta",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "PS",
    countryName: "Palestinian Territory",
    zoneName: "Asia/Gaza",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "PS",
    countryName: "Palestinian Territory",
    zoneName: "Asia/Hebron",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "VN",
    countryName: "Vietnam",
    zoneName: "Asia/Ho_Chi_Minh",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "HK",
    countryName: "Hong Kong",
    zoneName: "Asia/Hong_Kong",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "MN",
    countryName: "Mongolia",
    zoneName: "Asia/Hovd",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Irkutsk",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "ID",
    countryName: "Indonesia",
    zoneName: "Asia/Jakarta",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "ID",
    countryName: "Indonesia",
    zoneName: "Asia/Jayapura",
    gmtOffset: 32400,
    timestamp: 1725503627,
  },
  {
    countryCode: "IL",
    countryName: "Israel",
    zoneName: "Asia/Jerusalem",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "AF",
    countryName: "Afghanistan",
    zoneName: "Asia/Kabul",
    gmtOffset: 16200,
    timestamp: 1725487427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Kamchatka",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
  {
    countryCode: "PK",
    countryName: "Pakistan",
    zoneName: "Asia/Karachi",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "NP",
    countryName: "Nepal",
    zoneName: "Asia/Kathmandu",
    gmtOffset: 20700,
    timestamp: 1725491927,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Khandyga",
    gmtOffset: 32400,
    timestamp: 1725503627,
  },
  {
    countryCode: "IN",
    countryName: "India",
    zoneName: "Asia/Kolkata",
    gmtOffset: 19800,
    timestamp: 1725491027,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Krasnoyarsk",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "MY",
    countryName: "Malaysia",
    zoneName: "Asia/Kuala_Lumpur",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "MY",
    countryName: "Malaysia",
    zoneName: "Asia/Kuching",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "KW",
    countryName: "Kuwait",
    zoneName: "Asia/Kuwait",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "MO",
    countryName: "Macao",
    zoneName: "Asia/Macau",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Magadan",
    gmtOffset: 39600,
    timestamp: 1725510827,
  },
  {
    countryCode: "ID",
    countryName: "Indonesia",
    zoneName: "Asia/Makassar",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "PH",
    countryName: "Philippines",
    zoneName: "Asia/Manila",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "OM",
    countryName: "Oman",
    zoneName: "Asia/Muscat",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "CY",
    countryName: "Cyprus",
    zoneName: "Asia/Nicosia",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Novokuznetsk",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Novosibirsk",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Omsk",
    gmtOffset: 21600,
    timestamp: 1725492827,
  },
  {
    countryCode: "KZ",
    countryName: "Kazakhstan",
    zoneName: "Asia/Oral",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "KH",
    countryName: "Cambodia",
    zoneName: "Asia/Phnom_Penh",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "ID",
    countryName: "Indonesia",
    zoneName: "Asia/Pontianak",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "KP",
    countryName: "North Korea",
    zoneName: "Asia/Pyongyang",
    gmtOffset: 32400,
    timestamp: 1725503627,
  },
  {
    countryCode: "QA",
    countryName: "Qatar",
    zoneName: "Asia/Qatar",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "KZ",
    countryName: "Kazakhstan",
    zoneName: "Asia/Qostanay",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "KZ",
    countryName: "Kazakhstan",
    zoneName: "Asia/Qyzylorda",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "SA",
    countryName: "Saudi Arabia",
    zoneName: "Asia/Riyadh",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Sakhalin",
    gmtOffset: 39600,
    timestamp: 1725510827,
  },
  {
    countryCode: "UZ",
    countryName: "Uzbekistan",
    zoneName: "Asia/Samarkand",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "KR",
    countryName: "South Korea",
    zoneName: "Asia/Seoul",
    gmtOffset: 32400,
    timestamp: 1725503627,
  },
  {
    countryCode: "CN",
    countryName: "China",
    zoneName: "Asia/Shanghai",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "SG",
    countryName: "Singapore",
    zoneName: "Asia/Singapore",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Srednekolymsk",
    gmtOffset: 39600,
    timestamp: 1725510827,
  },
  {
    countryCode: "TW",
    countryName: "Taiwan",
    zoneName: "Asia/Taipei",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "UZ",
    countryName: "Uzbekistan",
    zoneName: "Asia/Tashkent",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "GE",
    countryName: "Georgia",
    zoneName: "Asia/Tbilisi",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "IR",
    countryName: "Iran",
    zoneName: "Asia/Tehran",
    gmtOffset: 12600,
    timestamp: 1725483827,
  },
  {
    countryCode: "BT",
    countryName: "Bhutan",
    zoneName: "Asia/Thimphu",
    gmtOffset: 21600,
    timestamp: 1725492827,
  },
  {
    countryCode: "JP",
    countryName: "Japan",
    zoneName: "Asia/Tokyo",
    gmtOffset: 32400,
    timestamp: 1725503627,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Tomsk",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "MN",
    countryName: "Mongolia",
    zoneName: "Asia/Ulaanbaatar",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "CN",
    countryName: "China",
    zoneName: "Asia/Urumqi",
    gmtOffset: 21600,
    timestamp: 1725492827,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Ust-Nera",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "LA",
    countryName: "Laos",
    zoneName: "Asia/Vientiane",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Vladivostok",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Yakutsk",
    gmtOffset: 32400,
    timestamp: 1725503627,
  },
  {
    countryCode: "MM",
    countryName: "Myanmar",
    zoneName: "Asia/Yangon",
    gmtOffset: 23400,
    timestamp: 1725494627,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Asia/Yekaterinburg",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "AM",
    countryName: "Armenia",
    zoneName: "Asia/Yerevan",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "PT",
    countryName: "Portugal",
    zoneName: "Atlantic/Azores",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "BM",
    countryName: "Bermuda",
    zoneName: "Atlantic/Bermuda",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "ES",
    countryName: "Spain",
    zoneName: "Atlantic/Canary",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "CV",
    countryName: "Cabo Verde",
    zoneName: "Atlantic/Cape_Verde",
    gmtOffset: -3600,
    timestamp: 1725467627,
  },
  {
    countryCode: "FO",
    countryName: "Faroe Islands",
    zoneName: "Atlantic/Faroe",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "PT",
    countryName: "Portugal",
    zoneName: "Atlantic/Madeira",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "IS",
    countryName: "Iceland",
    zoneName: "Atlantic/Reykjavik",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "GS",
    countryName: "South Georgia and the South Sandwich Islands",
    zoneName: "Atlantic/South_Georgia",
    gmtOffset: -7200,
    timestamp: 1725464027,
  },
  {
    countryCode: "SH",
    countryName: "Saint Helena",
    zoneName: "Atlantic/St_Helena",
    gmtOffset: 0,
    timestamp: 1725471227,
  },
  {
    countryCode: "FK",
    countryName: "Falkland Islands",
    zoneName: "Atlantic/Stanley",
    gmtOffset: -10800,
    timestamp: 1725460427,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Australia/Adelaide",
    gmtOffset: 34200,
    timestamp: 1725505427,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Australia/Brisbane",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Australia/Broken_Hill",
    gmtOffset: 34200,
    timestamp: 1725505427,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Australia/Darwin",
    gmtOffset: 34200,
    timestamp: 1725505427,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Australia/Eucla",
    gmtOffset: 31500,
    timestamp: 1725502727,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Australia/Hobart",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Australia/Lindeman",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Australia/Lord_Howe",
    gmtOffset: 37800,
    timestamp: 1725509027,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Australia/Melbourne",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Australia/Perth",
    gmtOffset: 28800,
    timestamp: 1725500027,
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    zoneName: "Australia/Sydney",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "NL",
    countryName: "Netherlands",
    zoneName: "Europe/Amsterdam",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "AD",
    countryName: "Andorra",
    zoneName: "Europe/Andorra",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Europe/Astrakhan",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "GR",
    countryName: "Greece",
    zoneName: "Europe/Athens",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "RS",
    countryName: "Serbia",
    zoneName: "Europe/Belgrade",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "DE",
    countryName: "Germany",
    zoneName: "Europe/Berlin",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "SK",
    countryName: "Slovakia",
    zoneName: "Europe/Bratislava",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "BE",
    countryName: "Belgium",
    zoneName: "Europe/Brussels",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "RO",
    countryName: "Romania",
    zoneName: "Europe/Bucharest",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "HU",
    countryName: "Hungary",
    zoneName: "Europe/Budapest",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "DE",
    countryName: "Germany",
    zoneName: "Europe/Busingen",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "MD",
    countryName: "Moldova",
    zoneName: "Europe/Chisinau",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "DK",
    countryName: "Denmark",
    zoneName: "Europe/Copenhagen",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "IE",
    countryName: "Ireland",
    zoneName: "Europe/Dublin",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "GI",
    countryName: "Gibraltar",
    zoneName: "Europe/Gibraltar",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "GG",
    countryName: "Guernsey",
    zoneName: "Europe/Guernsey",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "FI",
    countryName: "Finland",
    zoneName: "Europe/Helsinki",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "IM",
    countryName: "Isle of Man",
    zoneName: "Europe/Isle_of_Man",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "TR",
    countryName: "Turkey",
    zoneName: "Europe/Istanbul",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "JE",
    countryName: "Jersey",
    zoneName: "Europe/Jersey",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Europe/Kaliningrad",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Europe/Kirov",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "UA",
    countryName: "Ukraine",
    zoneName: "Europe/Kyiv",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "PT",
    countryName: "Portugal",
    zoneName: "Europe/Lisbon",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "SI",
    countryName: "Slovenia",
    zoneName: "Europe/Ljubljana",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "GB",
    countryName: "United Kingdom",
    zoneName: "Europe/London",
    gmtOffset: 3600,
    timestamp: 1725474827,
  },
  {
    countryCode: "LU",
    countryName: "Luxembourg",
    zoneName: "Europe/Luxembourg",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "ES",
    countryName: "Spain",
    zoneName: "Europe/Madrid",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "MT",
    countryName: "Malta",
    zoneName: "Europe/Malta",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "AX",
    countryName: "Aland Islands",
    zoneName: "Europe/Mariehamn",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "BY",
    countryName: "Belarus",
    zoneName: "Europe/Minsk",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "MC",
    countryName: "Monaco",
    zoneName: "Europe/Monaco",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Europe/Moscow",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "NO",
    countryName: "Norway",
    zoneName: "Europe/Oslo",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "FR",
    countryName: "France",
    zoneName: "Europe/Paris",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "ME",
    countryName: "Montenegro",
    zoneName: "Europe/Podgorica",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "CZ",
    countryName: "Czechia",
    zoneName: "Europe/Prague",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "LV",
    countryName: "Latvia",
    zoneName: "Europe/Riga",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "IT",
    countryName: "Italy",
    zoneName: "Europe/Rome",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Europe/Samara",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "SM",
    countryName: "San Marino",
    zoneName: "Europe/San_Marino",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "BA",
    countryName: "Bosnia and Herzegovina",
    zoneName: "Europe/Sarajevo",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Europe/Saratov",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "UA",
    countryName: "Ukraine",
    zoneName: "Europe/Simferopol",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "MK",
    countryName: "North Macedonia",
    zoneName: "Europe/Skopje",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "BG",
    countryName: "Bulgaria",
    zoneName: "Europe/Sofia",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "SE",
    countryName: "Sweden",
    zoneName: "Europe/Stockholm",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "EE",
    countryName: "Estonia",
    zoneName: "Europe/Tallinn",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "AL",
    countryName: "Albania",
    zoneName: "Europe/Tirane",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Europe/Ulyanovsk",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "LI",
    countryName: "Liechtenstein",
    zoneName: "Europe/Vaduz",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "VA",
    countryName: "Vatican",
    zoneName: "Europe/Vatican",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "AT",
    countryName: "Austria",
    zoneName: "Europe/Vienna",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "LT",
    countryName: "Lithuania",
    zoneName: "Europe/Vilnius",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    zoneName: "Europe/Volgograd",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "PL",
    countryName: "Poland",
    zoneName: "Europe/Warsaw",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "HR",
    countryName: "Croatia",
    zoneName: "Europe/Zagreb",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "CH",
    countryName: "Switzerland",
    zoneName: "Europe/Zurich",
    gmtOffset: 7200,
    timestamp: 1725478427,
  },
  {
    countryCode: "MG",
    countryName: "Madagascar",
    zoneName: "Indian/Antananarivo",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "IO",
    countryName: "British Indian Ocean Territory",
    zoneName: "Indian/Chagos",
    gmtOffset: 21600,
    timestamp: 1725492827,
  },
  {
    countryCode: "CX",
    countryName: "Christmas Island",
    zoneName: "Indian/Christmas",
    gmtOffset: 25200,
    timestamp: 1725496427,
  },
  {
    countryCode: "CC",
    countryName: "Cocos Islands",
    zoneName: "Indian/Cocos",
    gmtOffset: 23400,
    timestamp: 1725494627,
  },
  {
    countryCode: "KM",
    countryName: "Comoros",
    zoneName: "Indian/Comoro",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "TF",
    countryName: "French Southern Territories",
    zoneName: "Indian/Kerguelen",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "SC",
    countryName: "Seychelles",
    zoneName: "Indian/Mahe",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "MV",
    countryName: "Maldives",
    zoneName: "Indian/Maldives",
    gmtOffset: 18000,
    timestamp: 1725489227,
  },
  {
    countryCode: "MU",
    countryName: "Mauritius",
    zoneName: "Indian/Mauritius",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "YT",
    countryName: "Mayotte",
    zoneName: "Indian/Mayotte",
    gmtOffset: 10800,
    timestamp: 1725482027,
  },
  {
    countryCode: "RE",
    countryName: "Reunion",
    zoneName: "Indian/Reunion",
    gmtOffset: 14400,
    timestamp: 1725485627,
  },
  {
    countryCode: "WS",
    countryName: "Samoa",
    zoneName: "Pacific/Apia",
    gmtOffset: 46800,
    timestamp: 1725518027,
  },
  {
    countryCode: "NZ",
    countryName: "New Zealand",
    zoneName: "Pacific/Auckland",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
  {
    countryCode: "PG",
    countryName: "Papua New Guinea",
    zoneName: "Pacific/Bougainville",
    gmtOffset: 39600,
    timestamp: 1725510827,
  },
  {
    countryCode: "NZ",
    countryName: "New Zealand",
    zoneName: "Pacific/Chatham",
    gmtOffset: 45900,
    timestamp: 1725517127,
  },
  {
    countryCode: "FM",
    countryName: "Micronesia",
    zoneName: "Pacific/Chuuk",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "CL",
    countryName: "Chile",
    zoneName: "Pacific/Easter",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "VU",
    countryName: "Vanuatu",
    zoneName: "Pacific/Efate",
    gmtOffset: 39600,
    timestamp: 1725510827,
  },
  {
    countryCode: "TK",
    countryName: "Tokelau",
    zoneName: "Pacific/Fakaofo",
    gmtOffset: 46800,
    timestamp: 1725518027,
  },
  {
    countryCode: "FJ",
    countryName: "Fiji",
    zoneName: "Pacific/Fiji",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
  {
    countryCode: "TV",
    countryName: "Tuvalu",
    zoneName: "Pacific/Funafuti",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
  {
    countryCode: "EC",
    countryName: "Ecuador",
    zoneName: "Pacific/Galapagos",
    gmtOffset: -21600,
    timestamp: 1725449627,
  },
  {
    countryCode: "PF",
    countryName: "French Polynesia",
    zoneName: "Pacific/Gambier",
    gmtOffset: -32400,
    timestamp: 1725438827,
  },
  {
    countryCode: "SB",
    countryName: "Solomon Islands",
    zoneName: "Pacific/Guadalcanal",
    gmtOffset: 39600,
    timestamp: 1725510827,
  },
  {
    countryCode: "GU",
    countryName: "Guam",
    zoneName: "Pacific/Guam",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "US",
    countryName: "United States",
    zoneName: "Pacific/Honolulu",
    gmtOffset: -36000,
    timestamp: 1725435227,
  },
  {
    countryCode: "KI",
    countryName: "Kiribati",
    zoneName: "Pacific/Kanton",
    gmtOffset: 46800,
    timestamp: 1725518027,
  },
  {
    countryCode: "KI",
    countryName: "Kiribati",
    zoneName: "Pacific/Kiritimati",
    gmtOffset: 50400,
    timestamp: 1725521627,
  },
  {
    countryCode: "FM",
    countryName: "Micronesia",
    zoneName: "Pacific/Kosrae",
    gmtOffset: 39600,
    timestamp: 1725510827,
  },
  {
    countryCode: "MH",
    countryName: "Marshall Islands",
    zoneName: "Pacific/Kwajalein",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
  {
    countryCode: "MH",
    countryName: "Marshall Islands",
    zoneName: "Pacific/Majuro",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
  {
    countryCode: "PF",
    countryName: "French Polynesia",
    zoneName: "Pacific/Marquesas",
    gmtOffset: -34200,
    timestamp: 1725437027,
  },
  {
    countryCode: "UM",
    countryName: "United States Minor Outlying Islands",
    zoneName: "Pacific/Midway",
    gmtOffset: -39600,
    timestamp: 1725431627,
  },
  {
    countryCode: "NR",
    countryName: "Nauru",
    zoneName: "Pacific/Nauru",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
  {
    countryCode: "NU",
    countryName: "Niue",
    zoneName: "Pacific/Niue",
    gmtOffset: -39600,
    timestamp: 1725431627,
  },
  {
    countryCode: "NF",
    countryName: "Norfolk Island",
    zoneName: "Pacific/Norfolk",
    gmtOffset: 39600,
    timestamp: 1725510827,
  },
  {
    countryCode: "NC",
    countryName: "New Caledonia",
    zoneName: "Pacific/Noumea",
    gmtOffset: 39600,
    timestamp: 1725510827,
  },
  {
    countryCode: "AS",
    countryName: "American Samoa",
    zoneName: "Pacific/Pago_Pago",
    gmtOffset: -39600,
    timestamp: 1725431627,
  },
  {
    countryCode: "PW",
    countryName: "Palau",
    zoneName: "Pacific/Palau",
    gmtOffset: 32400,
    timestamp: 1725503627,
  },
  {
    countryCode: "PN",
    countryName: "Pitcairn",
    zoneName: "Pacific/Pitcairn",
    gmtOffset: -28800,
    timestamp: 1725442427,
  },
  {
    countryCode: "FM",
    countryName: "Micronesia",
    zoneName: "Pacific/Pohnpei",
    gmtOffset: 39600,
    timestamp: 1725510827,
  },
  {
    countryCode: "PG",
    countryName: "Papua New Guinea",
    zoneName: "Pacific/Port_Moresby",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "CK",
    countryName: "Cook Islands",
    zoneName: "Pacific/Rarotonga",
    gmtOffset: -36000,
    timestamp: 1725435227,
  },
  {
    countryCode: "MP",
    countryName: "Northern Mariana Islands",
    zoneName: "Pacific/Saipan",
    gmtOffset: 36000,
    timestamp: 1725507227,
  },
  {
    countryCode: "PF",
    countryName: "French Polynesia",
    zoneName: "Pacific/Tahiti",
    gmtOffset: -36000,
    timestamp: 1725435227,
  },
  {
    countryCode: "KI",
    countryName: "Kiribati",
    zoneName: "Pacific/Tarawa",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
  {
    countryCode: "TO",
    countryName: "Tonga",
    zoneName: "Pacific/Tongatapu",
    gmtOffset: 46800,
    timestamp: 1725518027,
  },
  {
    countryCode: "UM",
    countryName: "United States Minor Outlying Islands",
    zoneName: "Pacific/Wake",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
  {
    countryCode: "WF",
    countryName: "Wallis and Futuna",
    zoneName: "Pacific/Wallis",
    gmtOffset: 43200,
    timestamp: 1725514427,
  },
];
