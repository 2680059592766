import React, { useEffect, useState } from "react";

import cn from "classnames";

import { ReactComponent as SortAscIcon } from "assets/icons/sort-asc.svg";
import { ReactComponent as SortDescIcon } from "assets/icons/sort-desc.svg";

import Loader from "../../../components/Loader";

import Row from "./Row";
import styles from "./Table.module.sass";

const columns = [
  {
    name: "Name",
    sort: "name",
  },
  {
    name: "Activity",
    sort: "sum_activities",
  },
  {
    name: "Teams",
    sort: "section_tags_array",
  },
  {
    name: "Tags",
    sort: "tags_array",
  },
  {
    name: "Archive",
  },
];

const Table = ({
  className,
  activeTable,
  items,
  onSortHandler,
  sorting,
  onRowSelectionChange,
  selectedRowsObj = {},
}) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const handleRowSelection = (item) => {
    const newSelectedRows = {
      ...selectedRows,
      [item.id]: selectedRows[item.id] ? false : item,
    };
    setSelectedRows(newSelectedRows);
    onRowSelectionChange(newSelectedRows);
  };

  useEffect(() => {
    setSelectedRows(selectedRowsObj);
  }, [selectedRowsObj]);

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.table)}>
        <div className={cn(styles.row, { [styles.active]: activeTable })}>
          {columns.map((column, index) => (
            <div
              className={cn(styles.col, styles.clickable, {
                [styles.selected]: sorting.sort_by === column.sort,
              })}
              key={index}
              onClick={() => onSortHandler(column.sort)}
            >
              {column.sort &&
                (sorting.sort_by === column.sort &&
                sorting.sort_order === "desc" ? (
                  <SortDescIcon />
                ) : (
                  <SortAscIcon />
                ))}
              &nbsp; {column.name}
            </div>
          ))}
        </div>
        {items &&
          items?.map((x, index) => (
            <Row
              item={x}
              key={index}
              value={selectedFilters.includes(x.id)}
              onChange={() => handleChange(x.id)}
              showActivity
              onRowSelect={handleRowSelection}
              isRowSelected={selectedRows[x.id] || false}
            />
          ))}
      </div>
      <div className={styles.foot}>
        {/* <button className={cn("button-stroke button-small", styles.button)}>
          <Loader className={styles.loader} />
          <span>Load more</span>
        </button> */}
      </div>
    </div>
  );
};

export default Table;
