import React from "react";

import cn from "classnames";

import { Modal } from "components";

import styles from "./ConfirmModal.module.sass";

const ConfirmModal = ({ visible, onClose, onConfirm, onCancel }) => {
  return (
    <Modal
      visible={visible}
      onClose={onClose}
    >
      <div className={styles.delete}>
        <div className={styles.info}>Update Confirmation</div>

        <div className={styles.text}>
          One or more of the emails you entered already exist in the system.{" "}
          <br />
          If you continue, users with these emails will be merged into one
          account, and this is not reversible.
        </div>

        <div className={styles.foot}>
          <button
            className={cn("button button-stroke", styles.button)}
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className={cn("button", styles.button, styles.confirm)}
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
