import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { clear } from "../../slices/appSlice";

const Cleaner = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const unlisten = () => {
      dispatch(clear(props));
    };
    return function cleanup() {
      unlisten();
    };
  }, []);
  return;
};

export default Cleaner;
