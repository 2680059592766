import React, { useState } from "react";

import cn from "classnames";

import Avatar from "../../../../components/Avatar";
import Icon from "../../../../components/Icon";
import { capitalize } from "../../../../utils";
import IntegrationModal from "../IntegrationModals";

import styles from "./Integrations.module.sass";

const Integrations = ({ className, items }) => {
  const [visibleModalIntegration, setVisibleModalIntegration] = useState(false);
  const [integrationType, setIntegrationType] = useState("");
  return (
    <div className={styles.section}>
      <div className={styles.list}>
        {items &&
          items?.map((integration, index) => (
            <div
              className={styles.integration}
              key={index}
            >
              <div className={styles.profileHeader}>
                <div className={styles.details}>
                  <div className={styles.avatar}>
                    <Avatar
                      src={`/images/integration/${integration?.name?.toLowerCase()}.png`}
                      alt="Avatar"
                    />
                  </div>
                  <div className={styles.wrap}>
                    <div className={cn("h5", styles.man)}>
                      {integration.title}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.info}>{integration.description}</div>
              <div className={styles.profileFooter}>
                <div className={styles.metrics}>
                  {"</> "}
                  {integration.metrics.split("").length > 0
                    ? integration.metrics.split(", ").length
                    : 0}{" "}
                  metrics
                </div>
                <button
                  className={cn(
                    `button button-small ${integration.status}`,
                    styles.button
                  )}
                  onClick={() => {
                    setVisibleModalIntegration(true);
                    setIntegrationType(integration.name);
                  }}
                >
                  <Icon
                    name="add"
                    size="20"
                  />
                  {integration.status === "inactive"
                    ? "Integrate"
                    : capitalize(integration.status)}
                </button>
              </div>
            </div>
          ))}
      </div>
      <IntegrationModal
        visible={visibleModalIntegration}
        integration={integrationType}
        onClose={() => {
          setVisibleModalIntegration(false);
          setIntegrationType("");
        }}
      />
    </div>
  );
};

export default Integrations;
