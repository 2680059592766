export const restructuringConversation = (conversation) => {
  const newConversation = [];
  conversation.forEach((message) => {
    newConversation.push({
      role: message?.id[2] === "HumanMessage" ? "user" : "ai",
      message: message.kwargs.content,
    });
  });
  return newConversation;
};

export const scrollToBottom = (ref, scroll) => {
  const messagesContainer = ref.current;
  if (messagesContainer) {
    if (
      messagesContainer.scrollHeight - messagesContainer.scrollTop < 500 ||
      scroll
    ) {
      messagesContainer.scrollTop = messagesContainer?.scrollHeight;
    }
  }
};
