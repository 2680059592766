import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as AddCircled } from "assets/icons/add-circled.svg";
import { ReactComponent as SortAscIcon } from "assets/icons/sort-asc.svg";
import { Modal, TextInput, UserDetails, Icon, Tag } from "components";
import { getAllEmployees } from "slices/employeeSlice";
import {
  createNewTag,
  clearCreatedNewTag,
  getEmployeeTags,
  assignEmployeeToTag,
  unAssignEmployeeFromTag,
} from "slices/tagSlice";

import styles from "./CreateTagModal.module.sass";

const columns = [
  {
    name: "Name",
    sort: true,
  },
  {},
];

const CreateTagModal = ({ visible, onClose }) => {
  const [tag, setTag] = useState("");
  const [assigned, setAssigned] = useState({});
  const [error, setError] = useState(null);

  const { employeeList } = useSelector((state) => state.employee);
  const { createdNewTag } = useSelector((state) => state.tag);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEmployees());
  }, [dispatch]);

  const createTagHandler = async () => {
    if (tag.length === 0) return;
    const action = await dispatch(createNewTag({ name: tag }));

    if (action.error) {
      setError("Tag exists or internal error!");
      return;
    }

    dispatch(getEmployeeTags());
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      createTagHandler();
    }
  };

  const handleAssignEmployee = async (employee) => {
    await dispatch(
      assignEmployeeToTag({ tagId: createdNewTag?.id, userId: employee.id })
    );
    await dispatch(getEmployeeTags());
    setAssigned({ ...assigned, [employee.id]: true });
  };

  const handleUnAssignEmployee = async (employee) => {
    await dispatch(
      unAssignEmployeeFromTag({
        tagId: createdNewTag?.id,
        userId: employee.id,
      })
    );
    await dispatch(getEmployeeTags());
    setAssigned({ ...assigned, [employee.id]: false });
  };

  useEffect(() => {
    return () => {
      dispatch(clearCreatedNewTag());
    };
  }, [dispatch]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      outerClassName={styles.modal}
    >
      <p className={cn("title-purple")}>Create New Tag</p>

      <div className={styles.head}>
        <div className={styles.createTag}>
          {createdNewTag ? (
            <div className={styles.createdTag}>
              <Tag name={tag} />
            </div>
          ) : (
            <TextInput
              className={styles.tagName}
              value={tag}
              error={error}
              onChange={(e) => {
                if (error) setError(null);
                setTag(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              placeholder="Type to create New tag"
              type="text"
              name="tag-name"
              label="Tag Name"
              tooltip
            />
          )}
          <button
            className={cn(
              "button",
              { "button-stroke-blue": !createdNewTag },
              { "disabled": createdNewTag },
              styles.button
            )}
            disabled={createdNewTag}
            onClick={createTagHandler}
          >
            {createdNewTag ? (
              <Icon
                name="check"
                size="24"
              />
            ) : (
              <AddCircled />
            )}
            {createdNewTag ? "Tag Created" : "Create New Tag"}
          </button>
        </div>
      </div>

      <div className={cn(styles.wrapper, { [styles.disable]: !createdNewTag })}>
        {!createdNewTag && <div className={styles.disallow}></div>}
        <p className={styles.tableDescription}>
          Add Employees to tag (optional)
        </p>
        <div className={cn(styles.table)}>
          <div className={cn(styles.headerRow)}>
            {columns.map((column, index) => (
              <div
                className={styles.headerCol}
                key={index}
              >
                {column.sort && <SortAscIcon />}
                &nbsp; {column.name}
              </div>
            ))}
          </div>

          {employeeList?.map((employee) => (
            <div
              key={employee.id}
              className={cn(styles.row)}
            >
              <div className={styles.col}>
                <UserDetails user={employee} />
              </div>
              <div className={styles.col}>
                {assigned[employee.id] ? (
                  <button
                    className={cn("button button-small", styles.button)}
                    onClick={() => handleUnAssignEmployee(employee)}
                    disabled={!createdNewTag}
                  >
                    <Icon
                      name="check"
                      size="24"
                    />
                    Tag added to Employee
                  </button>
                ) : (
                  <button
                    className={cn(
                      "button button-small button-stroke-blue",
                      styles.button
                    )}
                    onClick={() => handleAssignEmployee(employee)}
                    disabled={!createdNewTag}
                  >
                    <AddCircled />
                    Add Tag to Employee
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default CreateTagModal;
