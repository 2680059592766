import React, { useState } from "react";

import cn from "classnames";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { TooltipGlodal, Card, Icon, NewSectionCard } from "components";

import CreateSection from "../../Tags/SectionTag/CreateSection";

import DepartmentCard from "./DepartmentCard";
import styles from "./Overview.module.sass";

const Overview = ({ className }) => {
  const [createSectionModal, setCreateSectionModal] = useState(false);
  const { overview, loading } = useSelector((state) => state.home);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Team Overview"
        classTitle="title-transparent"
        head={
          <button
            className={cn("button button-small", styles.button)}
            onClick={() => setCreateSectionModal(true)}
          >
            <Icon
              name="add"
              size="24"
            />
            <span>Add new Team</span>
          </button>
        }
      >
        {overview?.length > 0 && (
          <div className={styles.titleInfo}>
            Choose the one you'd wish to see more
          </div>
        )}

        <div className={styles.overview}>
          {overview?.length ? (
            <div className={styles.list}>
              {overview.map((department, index) => (
                <DepartmentCard
                  key={index}
                  department={department}
                />
              ))}
            </div>
          ) : !loading ? (
            <NewSectionCard onClick={() => setCreateSectionModal(true)} />
          ) : (
            <Link
              className={styles.emptyItem}
              to="/"
            >
              <div className={styles.inform}>Loading</div>
            </Link>
          )}
        </div>
      </Card>
      <TooltipGlodal />

      <CreateSection
        visible={createSectionModal}
        onClose={() => setCreateSectionModal(false)}
      />
    </>
  );
};

export default Overview;
