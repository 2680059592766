import { ReactComponent as FlashIcon } from "assets/icons/flash.svg";

export const steps = [
  {
    target: ".onboarding-first-step",
    title: (
      <div className="onboarding-title">
        <FlashIcon /> Start here, it's faster!
      </div>
    ),
    content:
      "Select the services your company uses and Metra will extract and display detailed insights from it -- while also adding all personnel automatically!",
    disableBeacon: true,
  },
  {
    target: ".onboarding-second-step",
    title: (
      <div className="onboarding-title">
        <FlashIcon /> Also, add personnel here!
      </div>
    ),
    content:
      "All Metra needs is the company email to add employees onto the system. But remember! You can automatically add employees in bulk by selecting Integrations first!",
    disableBeacon: true,
  },
  {
    target: ".onboarding-third-step",
    title: (
      <div className="onboarding-title">
        <FlashIcon /> Also, add personnel here!
      </div>
    ),
    content:
      "All Metra needs is the company email to add employees onto the system. But remember! You can automatically add employees in bulk by selecting Integrations first!",
    disableBeacon: true,
    placement: "right",
    disableScrolling: true,
  },
  {
    target: ".onboarding-fourth-step",
    title: (
      <div className="onboarding-title">
        <FlashIcon /> Manage all activities
      </div>
    ),
    content:
      "Quickly visualize which employees are getting more work done! Sort by type of service, employee or total number of activities.",
    disableBeacon: true,
    placement: "right",
    disableOverlay: true,
  },
  {
    target: ".onboarding-fifth-step",
    title: (
      <div className="onboarding-title">
        <FlashIcon /> Need help? Invite an editor!
      </div>
    ),
    content:
      "Metra allows executives to invite stakeholders and other managers onto your Space, such as HR representatives, to organize and keep everything ready -- if needed.",
    disableBeacon: true,
    placement: "right",
  },
];
