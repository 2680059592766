import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { TextInput } from "components";
import { getAllIntegrations } from "slices/integrationSlice";

import IntegrationCard from "./IntegrationCard";
import styles from "./Selection.module.sass";

const Selection = ({ selection, onIntegrationSelection }) => {
  const [selectedIntegrations, setSelectedIntegrations] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const { allIntegrations } = useSelector((state) => state.integration);

  useEffect(() => {
    dispatch(getAllIntegrations());
  }, [dispatch]);

  const handleCheck = (id) => {
    const newSelectedIntegrations = selectedIntegrations.includes(id)
      ? selectedIntegrations.filter((integrationId) => integrationId !== id)
      : [...selectedIntegrations, id];

    setSelectedIntegrations(newSelectedIntegrations);
    onIntegrationSelection(newSelectedIntegrations);
  };

  useEffect(() => {
    setSelectedIntegrations(selection);
  }, [selection]);

  return (
    <div className={styles.selection}>
      <div className={styles.settings}>
        <div className={styles.left}>
          <div className={styles.title}>
            All Integrations <span>({allIntegrations?.length})</span>
          </div>
          <TextInput
            className={styles.search}
            placeholder="Search by name"
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
            value={search}
            type="text"
            name="search"
            icon="search"
          />
        </div>
      </div>
      {allIntegrations
        ?.filter((i) =>
          i.name.toLowerCase().includes(search.toLocaleLowerCase())
        )
        .map((integration) => (
          <IntegrationCard
            key={integration.id}
            integration={integration}
            onChange={handleCheck}
            value={selectedIntegrations.includes(integration.id)}
          />
        ))}
    </div>
  );
};

export default Selection;
