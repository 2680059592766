import cn from "classnames";

import { Checkbox } from "components";

import styles from "./IntegrationCard.module.sass";

const integrationIcons = {
  Slack: { img: "/images/integration/slack.png" },
  Github: { img: "/images/integration/github.png", full: true },
  Google: { img: "/images/integration/google.png" },
  Confluence: { img: "/images/integration/confluence.png" },
  Jira: { img: "/images/integration/jira.png" },
  Zoom: { img: "/images/integration/zoom.png", full: true },
  Teams: { img: "/images/integration/teams.png" },
  Outlook: { img: "/images/integration/exchangeonline.png" },
  Salesforce: { img: "/images/integration/salesforce.png" },
};

const IntegrationCard = ({ integration, onChange, value }) => (
  <div
    className={cn(styles.integraion, {
      [styles.selected]: value,
    })}
    key={integration.id}
    onClick={() => onChange(integration.id)}
  >
    <div className={styles.card_header}>
      <div className={styles.tick}>
        <Checkbox
          onChange={() => onChange(integration.id)}
          value={value}
        />
        <img
          width={36}
          src={integrationIcons[integration.name]?.img}
          alt={integration.name}
        />
      </div>
      <h1 className={styles.category}>{integration.category}</h1>
    </div>

    <h1 className={styles.name}>{integration.name}</h1>
    <p className={styles.description}>{integration.description}</p>
  </div>
);

export default IntegrationCard;
