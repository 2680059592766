import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../../../components/Loader";
import {
  updateIntegration,
  validateOutlookIntegration,
} from "../../../../../slices/integrationSlice";

import styles from "./Integration.module.sass";

const Integration = ({ account_id, integration_name, application_id }) => {
  const { status, loading, error, testResponseOutlook } = useSelector(
    (state) => state.integration
  );
  const [informMessage, setInformMessage] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [values, setValues] = useState({
    account_id: account_id,
    integration_name: integration_name,
    application_id: application_id,
  });
  const [canProceed, setCanProceed] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleTestConnection = () => {
    dispatch(validateOutlookIntegration(values));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setInformMessage(null);
    setFormSubmitted(true);
    const formData = new FormData();
    Object.keys(values).forEach((value) => {
      formData.append(value, values[value]);
    });
    dispatch(updateIntegration(formData));
  };

  const preventSubmit = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    setCanProceed(
      !Object.keys(values).some((val) => values[val] === "") &&
        Object.keys(values).length >= 4
    );
  }, [values]);

  useEffect(() => {
    if (formSubmitted) {
      if (error) {
        setInformMessage("Integration failed to update.");
      } else if (status === "succeeded") {
        setInformMessage("Integration updated.");
      }
    }
  }, [status, error, formSubmitted]);

  useEffect(() => {
    setInformMessage(error);
    let timer = setTimeout(() => setInformMessage(null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  useEffect(() => {
    setInformMessage(testResponseOutlook);
    let timer = setTimeout(() => setInformMessage(null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [testResponseOutlook]);

  return (
    <div className={styles.integration}>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.content}>
              <form onSubmit={preventSubmit}>
                <input
                  name="tenant_id"
                  placeholder="Tenant ID"
                  className={styles.input}
                  type="text"
                  onChange={handleChange}
                />
                <input
                  name="application_id"
                  placeholder="Application ID"
                  value={application_id}
                  className={styles.input}
                  type="text"
                  onChange={handleChange}
                />
                <input
                  name="client_secret"
                  placeholder="Client Secret"
                  className={styles.input}
                  type="text"
                  onChange={handleChange}
                />
                {informMessage && (
                  <div className={styles.errorNote}>{informMessage}</div>
                )}
                <button
                  className={cn(
                    `button button-stroke-blue button-small`,
                    styles.buttonTest
                  )}
                  onClick={() => handleTestConnection()}
                >
                  Test Connection
                </button>
                <button
                  type="submit"
                  className={cn(`button button-small`, styles.button, {
                    disabled: !canProceed,
                  })}
                  onClick={handleSubmit}
                >
                  {loading && (
                    <Loader
                      className={styles.loader}
                      white
                    />
                  )}
                  <span>Update</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
