import React, { useEffect, useState } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import NotificationSetting from "components/Notification/NotificationSetting";
import {
  getNotificationPreferences,
  updateNotificationPreferences,
} from "slices/notificationSlice";

import styles from "./Notifications.module.sass";

const Notifications = ({ className }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const integrationType = params.integration;

  const { notificationPreferences } = useSelector(
    (state) => state.notification
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getNotificationPreferences());
  }, [dispatch]);

  const integrationNotifications =
    notificationPreferences?.preferences?.personalized_integration;

  const integrationFriendlyNames =
    notificationPreferences?.friendly_names?.integration;

  const handleSwitchChange = async (key, checked) => {
    setLoading(true);

    let newIntegrationNotifications = {};
    if (integrationNotifications[key]) {
      newIntegrationNotifications = {
        ...integrationNotifications,
        [key]: checked
          ? [...integrationNotifications[key], integrationType]
          : integrationNotifications[key].filter((x) => x !== integrationType),
      };
    } else {
      newIntegrationNotifications = {
        ...integrationNotifications,
        [key]: [integrationType],
      };
    }

    try {
      await dispatch(
        updateNotificationPreferences({
          ...notificationPreferences,
          preferences: {
            ...notificationPreferences.preferences,
            personalized_integration: newIntegrationNotifications,
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cn(styles.item, className)}>
      <div className={cn("title-red", styles.title)}>{"Notifications"}</div>
      <div className={styles.list}>
        {notificationPreferences &&
          Object.keys(integrationFriendlyNames)?.map((key, index) => (
            <NotificationSetting
              key={index}
              title={integrationFriendlyNames[key] || key}
              value={
                integrationNotifications[key]?.includes(integrationType) ||
                false
              }
              onChange={(e) => handleSwitchChange(key, e.target.checked)}
              disabled={loading} 
            />
          ))}
      </div>
    </div>
  );
};

export default Notifications;
